/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { compose } from 'redux';

import { useSelector } from 'react-redux';
import axios from '../../interceptor';
import { convertDate } from '../../assets/js/constants';

import '../../assets/css/shared.scss';
import '../../assets/css/dashboard/dashboardBookings.scss';

import SubTitle from '../Shared/SubTitle';
import BookingCardTile from '../Shared/CardTiles/BookingCardTile';
import DashboardHeader from './DashboardHeader';
import BookNowButton from '../Shared/BookNowButton';

import bookingsIcon from '../../assets/icons/cancelbooking.svg';

import { CustomToast } from '../Shared/withToast';
import withNotificationPopUp from '../Shared/withNotificationPopUp';
import withUserRetriever from '../Shared/withUserRetriever';

import Loading from '../Loading';

function DashboardBookings() {
  const [state, setState] = useState({
    bookings: '',
    bookingsTitle: 'Bookings',
    buttonText: 'Book Now!',
    isLoading: true,
  });

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const { bookingsTitle, bookings, isLoading } = state;

  const componentStyle = {
    color: level.level_color,
  };

  useEffect(() => {
    axios.get('/api/v1/appointments', {
      params: {
        statuses: ['upcoming'],
        max_records: process.env.REACT_APP_MAX_BOOKING_RECORDS || 10,
      },
    })
      .then((res) => setState((prevState) => ({
        ...prevState,
        bookings: res.data,
      })))
      .catch(() => {
        toast(<CustomToast type="error" icon={bookingsIcon} text="Bookings couldn't be retrieved" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, []);

  function renderBookings(upcomingBookings) {
    if (!upcomingBookings || upcomingBookings.length === 0) {
      return (<span className="no-items">No upcoming bookings</span>);
    }

    return (
      upcomingBookings.length > 0
        ? (
          upcomingBookings.map((booking) => (
            <div className="w-full" key={booking.id}>
              <BookingCardTile
                id={booking.id}
                date={convertDate(new Date(booking.date), '', true)}
                hour={booking.time}
                location={booking.location_name}
                backgroundImage={booking.location_photo}
                locationCategory={booking.location_category}
                style={componentStyle}
              />
            </div>
          ))
        ) : <span className="no-items pt-5">No upcoming bookings</span>
    );
  }

  return (
    <div className="flex flex-col h-full dashboard-grey-background-color view-boundaries-bottom">
      <DashboardHeader color={componentStyle.color} title={bookingsTitle} />
      <div className="w-full mt-4 mb-6">
        <SubTitle subTitle="Upcoming Bookings" route="/dashboard" style={componentStyle} />
        <div className="upcoming-bookings-info">New bookings can take up to 10 minutes to appear here. Thanks for your patience!</div>
      </div>
      <div className="w-full bookings-container px-5 md:w-1/2 self-center sm:px-0">
        {
          isLoading
            ? <Loading color={componentStyle.color} text="Bookings" />
            : renderBookings(bookings.upcoming)
        }
      </div>
      <BookNowButton color={componentStyle.color} />
    </div>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(DashboardBookings);
