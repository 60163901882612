import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import '../../../assets/css/shared/filters-box.scss';

/**
 *
 * @param {Boolean} isOpen controls the display of the filters box
 * @param {Boolean} withOverlay if true, then a darken overlay will appear in the background of the filters box (default: true)
 * @param {Function} onApply controls what happens when the filters box is closed
 * @param {Function} onCancel callback that runs whenever the filters box is without clicking on "apply filters" button.
 * @param {String} applyLabel String appended to the apply button. Default: "Apply filters"
 * @param {Node} children the child node (optional).
 * @returns
 */
const FiltersBox = ({
  isOpen,
  withOverlay,
  onApply,
  onCancel,
  applyLabel,
  children,
}) => {
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  useEffect(() => {
    const checkOutsideFiltersBoxClick = (e) => {
      if (isOpen && !document.getElementById('filtersBox').contains(e.target)) {
        onCancel();
      }
    };

    window.addEventListener('click', checkOutsideFiltersBoxClick);
    return () => window.removeEventListener('click', checkOutsideFiltersBoxClick);
  }, [isOpen]);

  const onApplyFilters = (e) => {
    e.stopPropagation(); // required because otherwise, when clicking on applying the filter, the checkoutsideclick function would run as well
    onApply();
  };

  return (
    <>
      {isOpen && withOverlay && <div className="filters-box-overlay" />}
      <div id="filtersBox" className={`filters-box ${isOpen ? 'filters-box--open' : 'filters-box--closed'}`}>
        {isOpen && (
          <>
            {children}
            <button type="button" onClick={onApplyFilters} className={`filters-box-button filters-box-button--level-${level.id}`}>
              {applyLabel}
            </button>
          </>
        )}
      </div>
    </>
  );
};

FiltersBox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  withOverlay: PropTypes.bool,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  applyLabel: PropTypes.string,
  children: PropTypes.node,
};

FiltersBox.defaultProps = {
  onApply: () => { },
  onCancel: () => { },
  applyLabel: 'Apply filters',
  children: null,
  withOverlay: true,
};

export default FiltersBox;
