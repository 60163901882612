import { BOOKING_DAY, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = '';

const bookingDay = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_DAY:
      return action.day;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingDay;
