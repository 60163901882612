import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BookingTimingOptionsModal from './BookingTimingOptionsModal';
import { bookingSoakOffFeet, bookingSoakOffHands, getBookingServices } from '../../selectors/booking';
import { getCurrentLoyaltyLevel } from '../../selectors/user';
import { ReactComponent as IconHands } from '../../assets/icons/hands_icon.svg';
import { ReactComponent as IconFeet } from '../../assets/icons/feet_icon.svg';
import '../../assets/css/booking/soak_offs.scss';
import ToggleSwitch from '../Shared/ToggleSwitch';
import {
  bookingSoakOffHands as setBookingSoakOffHandsIntent,
  bookingSoakOffFeet as setBookingSoakOffFeetIntent,
} from '../../actions/bookings';

/**
 * A component that controls whether or not the customer wants a soak off service for the associated hands and feet service.
 */
const SoakOffs = () => {
  const bookingServices = getBookingServices();
  const level = getCurrentLoyaltyLevel();
  const isHandsSoakActive = bookingSoakOffHands();
  const isFeetSoakActive = bookingSoakOffFeet();

  const color = level.level_color;

  const dispatch = useDispatch();

  const handServices = useMemo(() => bookingServices.filter((category) => category.categoryTypeId === 'hands'), [bookingServices]);
  const canHaveHandsSoakOff = useMemo(
    () => handServices.find((category) => (category.subCategories.find((subCategory) => subCategory.services.find((service) => service.selected)))),
    [bookingServices],
  );

  const feetServices = useMemo(() => bookingServices.filter((category) => category.categoryTypeId === 'feet'));
  const canHaveFeetSoakOff = useMemo(
    () => feetServices.find((category) => (category.subCategories.find((subCategory) => subCategory.services.find((service) => service.selected)))),
    [bookingServices],
  );

  const toggleHandsSoakActive = useCallback((isActive) => dispatch(setBookingSoakOffHandsIntent(isActive)));
  const toggleFeetSoakActive = useCallback((isActive) => dispatch(setBookingSoakOffFeetIntent(isActive)));

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full">
      <BookingTimingOptionsModal />
      <div className="separator" />
      <div className="soak_off">
        <div className="soak_off_title">
          Do you require Soak off?
        </div>

        {canHaveHandsSoakOff && (
          <div
            className={`soak_off_box ${isHandsSoakActive && 'soak_off_box--active'}`}
            style={{ borderColor: isHandsSoakActive && color }}
          >
            <IconHands width="35px" height="35px" fill={color} />
            <span className="soak_off_box_label" style={{ color }}>Hands</span>
            <ToggleSwitch
              isActive={isHandsSoakActive}
              onChange={toggleHandsSoakActive}
              activeColor={color}
              inactiveColor="grey"
            />
          </div>
        )}

        {canHaveFeetSoakOff && (
          <div
            className={`soak_off_box ${isFeetSoakActive && 'soak_off_box--active'}`}
            style={{ borderColor: isFeetSoakActive && color }}
          >
            <IconFeet width="35px" height="35px" fill={color} />
            <span className="soak_off_box_label" style={{ color }}>Feet</span>
            <ToggleSwitch
              isActive={isFeetSoakActive}
              onChange={toggleFeetSoakActive}
              activeColor={color}
              inactiveColor="grey"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SoakOffs;
