import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from './Api';

/**
 * Client API calls
 */
export default () => ({
  /**
   *
   * @returns the current client data.
   */
  get: () => ApiGet({ endpoint: 'client' }),
  /**
   * Sign out the current client
   */
  delete: () => ApiDelete({ endpoint: 'client_auth/sign_out' }),
  /**
   * Delete account
   */
  destroy: () => ApiDelete({ endpoint: 'client_auth' }),
  /**
   * Gets the clients to impersonate
   * @param {Number} page the page
   * @param {String} search the search query
   * @returns the client's impersonation list
   */
  getClientImpersonationList: (page, search) => ApiGet({ endpoint: `client/impersonate_list?page=${page}${search !== '' ? `&search=${search}` : ''}` }),
  /**
   *
   * starts the impersonation of a client
   * @returns the client that is being impersonated
   */
  postStartImpersonating: (clientId) => ApiPost({ endpoint: `client/impersonate?id=${clientId}` }),
  /**
   *
   * stops the impersonation of a client
   * @returns the original client
   */
  postStopImpersonating: () => ApiPost({ endpoint: 'client/stop_impersonating' }),

  /**
   * @returns a promise that contains the current email preferences for the customer
   */
  getEmailSettings: () => ApiGet({ endpoint: 'email_settings' }),

  /**
   *
   * @param {String} emailType the email type
   * @param {Boolean} isActive if the email type channel is active
   */
  updateEmailSettings: ({ emailType, isActive }) => ApiPut({ endpoint: 'email_settings', payload: { email_type: emailType, activate: isActive } }),
});
