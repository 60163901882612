import React from 'react';
import { useHistory } from 'react-router-dom';

import logoTns from '../../assets/img/logo_tns_black.svg';
import logoMarquee from '../../assets/img/logo_marquee_black.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/arrow_left.svg';

import '../../assets/css/signIn.scss';
import '../../assets/css/registration/postVerification.scss';

import GenericButton from '../Shared/GenericButton';
import BeyoutyBonus from '../../config/BeyoutyBonus';

function PostVerification() {
  const history = useHistory();
  const color = getComputedStyle(document.documentElement).getPropertyValue('--color-primary');

  return (
    <div className="sign-in-page-container">
      <div className="sign-in-page">
        <div className="post-verification-back-icon" role="button" tabIndex={0} onClick={() => history.push('/')}>
          <BackIcon style={{ width: '1.5rem', height: '1.5rem' }} stroke="white" />
        </div>

        <div className="logos mt-5 mb-10">
          <div className="pr-5">
            <img className="logo-tns-sign-in" src={logoTns} alt="" />
          </div>
          <div>
            <img className="logo-marquee-sign-in" src={logoMarquee} alt="" />
          </div>
        </div>
        <div className="flex self-center md:w-1/2 sm:px-0 w-full">
          <div className="flex flex-col px-5 w-full items-center">
            <div className="tab-container">
              <div className="tab">

                <div className="post-verification-text mb-4">
                  <div className="mb-2">
                    Great! You’ll receive a
                    WhatsApp or SMS message
                    from us shortly, through
                    which you can verify your
                    account and proceed with
                    your booking.
                  </div>

                  <br />

                  <div className="mb-2">
                    It may take up to 15 minutes
                    for the message to arrive –
                    thanks for your patience.
                  </div>

                  <br />

                  <div className="mb-2">
                    In the meantime, please
                    download our app, where
                    you’ll have instant access to
                    TNS & Marquee bookings,
                    loyalty and more!
                  </div>
                </div>

                <GenericButton
                  color={color}
                  text="DOWNLOAD APP"
                  action={() => window.open(BeyoutyBonus.api.appStorePage)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostVerification;
