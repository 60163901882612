import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/shared.scss';
import '../../assets/css/dashboard/dashboardHeaderImage.scss';
import { convertDate } from '../../assets/js/constants';
import SubTitle from '../Shared/SubTitle';
import calendarIcon from '../../assets/icons/calendar.svg';

function DashboardHeaderImage({
  colors, title, imageSource, locationName, startDate, afterRenderImage, routeBack,
}) {
  const subTitleStyle = {
    color: 'white',
  };
  const componentStyle = {
    // when changing opacity change also const name
    colorOpacity90: colors.concat('E6'), // add 2 bytes for transparency code
    colorOpacity80: colors.concat('CC'), // add 2 bytes for transparency code
    colorOpacity30: colors.concat('1E'),
  };

  return (
    <div className="dashboard-header-image-details-image-container">
      <div className="w-full absolute top-0 py-3">
        <SubTitle subTitle={title} route={routeBack} style={subTitleStyle} />
      </div>
      <div
        className="dashboard-header-image-details-image-gradient"
        style={{
          background: `linear-gradient(-180deg,
                      ${componentStyle.colorOpacity90} 0%,
                      ${componentStyle.colorOpacity80} 17%,
                      ${componentStyle.colorOpacity30} 100%)`,
        }}
      >
        <div className="dashboard-header-image-details-image" style={{ backgroundImage: `url("${imageSource}")` }} />
      </div>
      <div className="dashboard-header-image-details-image-text">
        <span className="dashboard-header-image-details-image-title">{locationName}</span>
        <div className="flex justify-between items-center">
          <img className="dashboard-header-image-details-calendar-icon" src={calendarIcon} alt="" />
          <span className="dashboard-header-image-details-image-subtitle">{convertDate(new Date(startDate), '', true)}</span>
        </div>
        {afterRenderImage()}
        <div className="dashboard-header-image-details-row-separator pt-4" />
      </div>
    </div>
  );
}

DashboardHeaderImage.propTypes = {
  colors: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageSource: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  afterRenderImage: PropTypes.func,
  routeBack: PropTypes.string,
};

DashboardHeaderImage.defaultProps = {
  afterRenderImage: () => {},
  routeBack: 'back',
};

export default DashboardHeaderImage;
