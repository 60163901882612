import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import '../../assets/css/shared/rating.scss';

import { ReactComponent as StartEmptyIcon } from '../../assets/icons/survey/star-empty.svg';
import { ReactComponent as StartFilledIcon } from '../../assets/icons/survey/star-filled.svg';

/**
 * Add an attribute to control star ratings to an object
 * @param {Object} object object to receive ratings
 */
export function addRatingsRangeAttribute(object) {
  object.ratingsRange = [
    { id: 1, selected: false },
    { id: 2, selected: false },
    { id: 3, selected: false },
    { id: 4, selected: false },
    { id: 5, selected: false },
  ];

  updateStarRatings(object, object.rating);
}

/**
 * Update the star rating object
 * @param {Object} object object to receive ratings
 */
export function updateStarRatings(object, rating) {
  object.rating = rating;
  object.ratingsRange.forEach((rate) => {
    if (rate.id <= object.rating) {
      rate.selected = true;
    } else {
      rate.selected = false;
    }
  });
}

/**
 *
 * @param {Object} ratingStore the reference to an outside object that will contain the ratings.
 * The ratingStore is divided into `rating` and `ratingsRange`. Only the rating is required.
 * The ratingsRange is used if an outside component needs to store the rating changes.
 * @param {String} color color of the stars
 * @param {Boolean} disabled if true then the ratings will not be updated, nor will the stars be
 * selectable
 * @param {String} disabledColor color of the stars when the component is disabled
 * @param {Function} handleSelect function to handle star selection, and to enable the ratings
 * @param {String} starClassName use 'star-icon' class name to override properties like height and width
 * @param {String} marginClass class to set a margin around the Rating component
 * @returns rendering of stars rating
 */
function Rating({
  ratingStore,
  color,
  disabled,
  disabledColor,
  handleSelect,
  starClassName,
  marginClass,
}) {
  if (isEmpty(ratingStore.ratingsRange)) {
    addRatingsRangeAttribute(ratingStore);
  }

  return (
    <div className={`stars-container ${marginClass}`}>
      <div className="stars">
        {ratingStore.ratingsRange && ratingStore.ratingsRange.map((rate) => (
          <span
            key={`star-${rate.id}`}
            role="button"
            tabIndex={0}
            onClick={() => handleSelect(ratingStore, rate.id)}
            className={`${disabled ? 'cursor-not-allowed' : ''}`}
          >
            {
              rate.selected
                ? <StartFilledIcon className={starClassName} fill={disabled ? disabledColor : color} />
                : <StartEmptyIcon className={starClassName} fill={disabled ? disabledColor : color} />
            }
          </span>
        ))}
      </div>
    </div>
  );
}

Rating.propTypes = {
  ratingStore: PropTypes.shape({
    rating: PropTypes.number.isRequired,
    ratingsRange: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        selected: PropTypes.bool,
      }),
    ),
  }).isRequired,
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledColor: PropTypes.string,
  handleSelect: PropTypes.func,
  starClassName: PropTypes.string,
  marginClass: PropTypes.string,
};

Rating.defaultProps = {
  disabled: false,
  disabledColor: 'var(--color-expired)',
  handleSelect: () => { },
  starClassName: 'star-icon',
  marginClass: '',
};

export default Rating;
