import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import BookNowButton from '../../Shared/BookNowButton';
import StampCardTile from '../../Shared/CardTiles/StampCardTile';
import SubTitle from '../../Shared/SubTitle';
import withNotificationPopUp from '../../Shared/withNotificationPopUp';
import withUserRetriever from '../../Shared/withUserRetriever';
import RadioInput from '../../Shared/RadioInput';
import DashboardHeader from '../DashboardHeader';

import '../../../assets/css/dashboard/stampCards/stampCards.scss';
import Loading from '../../Loading';
import { CustomToast } from '../../Shared/withToast';

import FiltersBox from '../../Shared/Filters/FiltersBox';
import WithFilters from '../../Shared/Filters/WithFilters';
import FilterLevelIcon from '../../Shared/Filters/FilterLevelIcon';
import StampCards from '../../../api/StampCards';

function DashboardStampCards() {
  const [state, setState] = useState({
    stampCardsTitle: 'Your Wallet',
    stampCards: {
      active: [],
      expired: [],
      completed: [],
    },
    currentViewType: 'active',
    isLoading: true,
    showFilters: false,
  });

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    stampCardsTitle,
    currentViewType,
    stampCards,
    isLoading,
    showFilters,
  } = state;

  const componentStyle = {
    color: level.level_color,
  };

  const { list: getStampCards } = StampCards();

  useEffect(() => {
    getStampCards()
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          stampCards: {
            active: response.data.active,
            completed: response.data.completed,
            expired: response.data.expired,
          },
        }));
      })
      .catch(() => toast(<CustomToast type="error" text="Stamp Cards couldn't be retrieved!" />))
      .finally(() => setState((prevState) => ({ ...prevState, isLoading: false })));
  }, []);

  function renderStampCards() {
    const items = stampCards[currentViewType];

    if (!items || items.length === 0) {
      return (<span className="no-items">No Stamp Cards available</span>);
    }

    const isLastItem = (index, arr) => index + 1 === arr.length;

    return (
      items.map((stampCard, i) => (
        <React.Fragment key={stampCard.id}>
          <StampCardTile
            maxStamps={stampCard.max_ticks + 1}
            stamps={stampCard.current_ticks}
            awardable={stampCard.awardable}
            id={stampCard.id}
            expirationDate={stampCard.end_date}
            description={stampCard.description}
            state={stampCard.state}
            imageUrl={stampCard.image}
            levelId={level.id}
            color={componentStyle.color}
          />
          {
            !isLastItem(i, items)
              ? <div className="my-6 separator-line" />
              : <div className="mb-6" />
          }
        </React.Fragment>
      ))
    );
  }

  const updateRenderedStampCards = (newViewType) => {
    closeFiltersBox();
    setState((prevState) => ({ ...prevState, currentViewType: newViewType }));
  };

  const closeFiltersBox = () => {
    setState((prevState) => ({ ...prevState, showFilters: false }));
  };

  const openFiltersBox = () => {
    setState((prevState) => ({ ...prevState, showFilters: true }));
  };

  const onCancelFilters = (resetFilters) => {
    setState((prevState) => ({
      ...prevState,
      showFilters: false,
      currentViewType,
    }));
    resetFilters({ viewType: currentViewType });
  };

  const initialFilters = useMemo(() => ({
    viewType: 'active',
  }));

  return (
    <WithFilters initialFilters={initialFilters}>
      {({
        activeFilters,
        changeFilter,
        resetFilters,
      }) => (
        <div className="h-full w-full">
          <DashboardHeader color={componentStyle.color} title={stampCardsTitle} />
          {
            isLoading
              ? <Loading color={componentStyle.color} text="Stamp Cards" />
              : (
                <div className="dashboard-stamp-cards-container">
                  <div className="py-4">
                    <SubTitle
                      subTitle="Stamp Cards"
                      route="/dashboard/wallet"
                      style={componentStyle}
                      afterRender={() => <FilterLevelIcon onClick={openFiltersBox} />}
                    />
                  </div>
                  <div className="dashboard-stamp-cards-list">
                    {renderStampCards()}
                  </div>
                </div>
              )
          }
          <BookNowButton color={componentStyle.color} />
          <FiltersBox isOpen={showFilters} onCancel={() => onCancelFilters(resetFilters)} onApply={() => updateRenderedStampCards(activeFilters.viewType)}>
            <div className="stamp-cards-filters">
              <RadioInput id="active" className="stamp-cards-filters-filter" label="Active" onChange={() => changeFilter('viewType', 'active')} isChecked={activeFilters.viewType === 'active'} />
              <RadioInput id="completed" className="stamp-cards-filters-filter" label="Completed" onChange={() => changeFilter('viewType', 'completed')} isChecked={activeFilters.viewType === 'completed'} />
              <RadioInput id="expired" className="stamp-cards-filters-filter" label="Expired" onChange={() => changeFilter('viewType', 'expired')} isChecked={activeFilters.viewType === 'expired'} />
            </div>
          </FiltersBox>
        </div>
      )}
    </WithFilters>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(DashboardStampCards);
