import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import range from 'lodash/range';

import Loading from '../../Loading';
import { CustomToast } from '../../Shared/withToast';
import { dateDifferenceUntilFormat } from '../../../assets/js/constants';
import withUserRetriever from '../../Shared/withUserRetriever';
import withNotificationPopUp from '../../Shared/withNotificationPopUp';

import '../../../assets/css/dashboard/stampCards/stampCardDetails.scss';

import { ReactComponent as LogoTns } from '../../../assets/img/logo_tns_black.svg';
import { ReactComponent as LogoMarquee } from '../../../assets/img/logo_marquee_black.svg';

import { ReactComponent as BackIcon } from '../../../assets/icons/arrow_left.svg';

import renderStamp from './utils';
import StampCards from '../../../api/StampCards';

function DashboardStampCardDetails() {
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const history = useHistory();

  const { id } = useParams();

  const [state, setState] = useState({
    stampCard: '',
    isLoading: true,
  });

  const { stampCard, isLoading } = state;

  const componentStyle = {
    color: level.level_color,
    gradient: `var(--gradient-level${level.id})`,
    colorDarker: `var(--color-level${level.id}-darker)`,
    colorCompleted: 'var(--color-green)',
    colorExpired: 'var(--color-expired)',
    colorExpiredDarker: 'var(--color-expired-darken)',
  };

  const { get: getStampCard } = StampCards();

  useEffect(() => {
    let earlyErrorExit = false;

    getStampCard(id)
      .then((response) => setState((prevState) => ({
        ...prevState,
        stampCard: {
          ...response.data,
          // history.push does not handle absolute paths
          awardable: response.data.awardable ? new URL(response.data.awardable).pathname : '',
        },
      })))
      .catch(() => {
        toast(<CustomToast type="error" text="Could not retrieve stamp card!" />);
        earlyErrorExit = true;
      })
      .finally(() => {
        if (earlyErrorExit) {
          history.push('/dashboard/stampcards');
        } else {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        }
      });
  }, []);

  function renderExpirationDate(stampState, expirationDate) {
    switch (stampState) {
      case 'completed':
        return (
          <div className="stamp-card-details-header-text" style={{ background: componentStyle.colorCompleted }}>
            STAMP CARD COMPLETED
          </div>
        );
      case 'expired':
        return (
          <div className="stamp-card-details-header-text" style={{ background: componentStyle.colorExpired }}>
            STAMP CARD EXPIRED
          </div>
        );
      default:
        return (
          <div className="stamp-card-details-header-text" style={{ background: componentStyle.color }}>
            {`EXPIRES ${dateDifferenceUntilFormat(expirationDate)}`}
          </div>
        );
    }
  }

  function renderStamps(stamps, maxStamps, awardPath) {
    const awardPathRedirect = () => (awardPath ? history.push(awardPath) : {});
    return (
      <ul className="stamp-card-details-stamps-grid">
        {range(maxStamps).map((i) => renderStamp(
          i,
          maxStamps,
          stamps,
          awardPathRedirect,
          'stamp-card-details-circle',
          stampCard.state === 'expired' ? componentStyle.colorExpiredDarker : componentStyle.color,
        ))}
      </ul>
    );
  }

  function renderMiddleIcon() {
    return (
      <div
        className="stamp-card-details-middle"
        style={{
          backgroundColor: stampCard.state === 'expired' ? componentStyle.colorExpired : componentStyle.color,
          fill: 'white',
        }}
      >
        { stampCard.location_category === 'tns'
          ? (
            <LogoTns
              className="stamp-card-details-middle-icon"
              style={{
                color: '#FFFFFF',
              }}
            />
          )
          : (
            <LogoMarquee
              className="stamp-card-details-middle-icon"
              style={{
                color: '#FFFFFF',
              }}
            />
          )}
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <div className="stamp-card-details-header">
        <div role="button" tabIndex={0} onClick={() => history.push('/dashboard/stampcards')}>
          <BackIcon className="stamp-card-details-header-back-icon" stroke="white" />
        </div>
        {
          (stampCard && stampCard.end_date) && renderExpirationDate(stampCard.state, stampCard.end_date)
        }
      </div>
      {
        isLoading
          ? (
            <Loading color="#FFFFFF" backgroundColor={componentStyle.color} text="Stamp Card" />

          ) : (
            <>
              <div
                className="stamp-card-details-top-half"
                style={{ background: stampCard.state === 'expired' ? componentStyle.colorExpired : componentStyle.gradient }}
              >
                <div className="stamp-card-details-image-overlay" />
                <img className="stamp-card-details-image" alt="" src={stampCard.image} style={{ filter: stampCard.state === 'expired' ? 'grayscale(100%)' : 'none' }} />
                <div className="stamp-card-details-top-half-content">
                  <span className="stamp-card-details-top-half-content-name">
                    {stampCard.description}
                  </span>
                </div>
              </div>
              <div
                className="stamp-card-details-bottom-half"
                style={{ background: stampCard.state === 'expired' ? componentStyle.colorExpired : componentStyle.gradient }}
              >
                { stampCard.location_category !== 'All' && renderMiddleIcon()}
                <div className="stamp-card-details-stamps-container">
                  {renderStamps(stampCard.current_ticks, stampCard.max_ticks + 1, stampCard.awardable)}
                  <div className="stamp-card-details-button-wrapper">
                    <button
                      type="button"
                      className="stamp-card-details-button"
                      style={{
                        color: stampCard.state === 'expired' ? 'white' : componentStyle.color,
                        backgroundColor: stampCard.state === 'expired' ? componentStyle.colorExpiredDarker : 'white',
                      }}
                      onClick={() => (stampCard.state === 'completed' ? history.push(stampCard.awardable) : history.push('/dashboard/stampcards'))}
                    >
                      {stampCard.state === 'completed' ? 'View Reward' : 'Ok Got It!'}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )
      }
    </div>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(DashboardStampCardDetails);
