import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../../assets/css/dashboard/shared.scss';

import { ReactComponent as BackIcon } from '../../assets/icons/arrow_left.svg';

function SubTitle({
  subTitle,
  route,
  style,
  afterRender,
}) {
  const history = useHistory();
  const location = useLocation();
  const shouldArrowBeDimmed = (route === 'back' && !location.key);

  function goTo() {
    if (route === 'back' && location.key) {
      history.goBack();
    } else if (route !== 'back') {
      history.push(route);
    }
  }

  return (
    <div className="sub-header justify-center items-center">
      { route && (
        <div role="button" className="dashboard-back-icon" tabIndex={0} onClick={goTo} style={shouldArrowBeDimmed ? { opacity: '0.30' } : {}}>
          <BackIcon className="dashboard-back-icon-width ml-6" stroke={style.color} />
        </div>
      )}
      <span className="sub-header-title" style={{ color: style.color }}>{subTitle}</span>
      <div className="sub-header-afterRender">{afterRender()}</div>
    </div>

  );
}

SubTitle.propTypes = {
  subTitle: PropTypes.string.isRequired,
  route: PropTypes.string,
  style: PropTypes.shape({
    color: PropTypes.string,
    filter: PropTypes.string,
  }),
  afterRender: PropTypes.func,
};

SubTitle.defaultProps = {
  route: '',
  style: { color: '#724467' },
  afterRender: () => {},
};

export default SubTitle;
