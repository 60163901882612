import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import LogoutPopup from './Shared/Popups/LogoutPopup';

import closeIcon from '../assets/icons/cancel.svg';
import logoTns from '../assets/img/logo_tns_black.svg';
import logoMarquee from '../assets/img/logo_marquee_black.svg';

import { getCurrentUser } from '../selectors/user';

import '../assets/css/burger.scss';
import Config from '../config/BeyoutyBonus';

function Burger({ close, color }) {
  const [state, setState] = useState({
    logout: false,
  });
  const user = getCurrentUser();

  const isAdmin = Config.api.testAccount === user.email ? false : Config.api.adminList.includes(user.email);

  const { logout } = state;
  const history = useHistory();

  function goToRoute(route) {
    close();
    history.push(route);
  }

  function openLogOutModal() {
    setState((prevState) => ({ ...prevState, logout: true }));
  }

  function menuTabStyle(pathname, route) {
    const pathParts = pathname.split('/');
    if (pathParts.length > 3) {
      pathParts.pop();
      const joinedPath = pathParts.join('/');
      return { fontFamily: joinedPath === route ? 'NunitoSans-ExtraBold' : 'NunitoSans-Regular' };
    }
    return { fontFamily: pathname === route ? 'NunitoSans-ExtraBold' : 'NunitoSans-Regular' };
  }

  const location = useLocation();

  const routes = {
    home: '/dashboard',
    bookings: '/dashboard/bookings',
    loyalty: '/loyalty-program',
    wallet: '/dashboard/wallet',
    transactions: '/dashboard/transactions',
    settings: '/settings',
    impersonate_list: '/client-impersonation',
  };

  return (
    <div className="burger-menu" style={{ background: color }}>
      <div className="menu-header">
        <div role="button" tabIndex={0} onClick={() => close()}>
          <img className="close-menu" src={closeIcon} alt="" />
        </div>
        <div className="menu-logos">
          <img className="menu-logo" src={logoTns} alt="" />
          <img className="menu-logo" src={logoMarquee} alt="" />
        </div>
      </div>
      <div className="pb-3 overflow-y-auto scrollbar-none">
        <div className="menu-body scrollbar-none">
          <div role="button" tabIndex={0} onClick={() => goToRoute(routes.home)} className="menu-tab">
            <span className="menu-tab-text" style={menuTabStyle(location.pathname, routes.home)}>
              Home
            </span>
          </div>
          <div role="button" tabIndex={0} onClick={() => goToRoute(routes.bookings)} className="menu-tab">
            <span className="menu-tab-text" style={menuTabStyle(location.pathname, routes.bookings)}>
              Upcoming Bookings
            </span>
          </div>
          <div role="button" tabIndex={0} onClick={() => goToRoute(routes.loyalty)} className="menu-tab">
            <span className="menu-tab-text" style={menuTabStyle(location.pathname, routes.loyalty)}>
              Loyalty
            </span>
          </div>
          <div role="button" tabIndex={0} onClick={() => goToRoute(routes.wallet)} className="menu-tab">
            <span className="menu-tab-text" style={menuTabStyle(location.pathname, routes.wallet)}>
              Wallet
            </span>
          </div>
          <div role="button" tabIndex={0} onClick={() => goToRoute(routes.transactions)} className="menu-tab">
            <span className="menu-tab-text" style={menuTabStyle(location.pathname, routes.transactions)}>
              Transactions
            </span>
          </div>
          <div role="button" tabIndex={0} onClick={() => goToRoute(routes.settings)} className="menu-tab">
            <span className="menu-tab-text" style={menuTabStyle(location.pathname, routes.settings)}>
              Settings
            </span>
          </div>
          {
            (user.is_impersonating || isAdmin) && (
              <div role="button" tabIndex={0} onClick={() => goToRoute(routes.impersonate_list)} className="menu-tab">
                <span className="menu-tab-text" style={menuTabStyle(location.pathname, routes.impersonate_list)}>
                  Impersonation
                </span>
              </div>
            )
          }
          <div
            role="button"
            tabIndex={0}
            onClick={() => openLogOutModal()}
            className="menu-tab"
            style={{ borderBottom: 'none' }}
          >
            <span className="menu-tab-text">
              Log Out
            </span>
          </div>
        </div>
        <div className="menu-footer" style={{ background: color }}>
          <span className="copyright">{`Copyright ${new Date().getFullYear()}`}</span>
        </div>
      </div>
      {
        logout && (
          <LogoutPopup color={color} onDismissPopUp={() => setState((prevState) => ({ ...prevState, logout: false }))} />
        )
      }
    </div>
  );
}

Burger.propTypes = {
  close: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

export default Burger;
