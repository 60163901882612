import { BOOKING_SPECIAL_REQUEST, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = '';
const bookingSpecialRequest = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_SPECIAL_REQUEST:
      return action.request;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingSpecialRequest;
