import {
  BOOKING_LOCK_SERVICE_PROVIDER_ID,
  BOOKING_SERVICES,
  CLEAN_BOOKING,
  BOOKING_RESET_PROVIDERS_PREFERENCES,
  SELECT_SERVICE,
} from '../../actions/bookings';

const initialState = {};

const bookingServiceProviderPreferences = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_LOCK_SERVICE_PROVIDER_ID:
      return {
        ...state,
        [action.serviceId]: action.serviceProviderId,
      };
    case BOOKING_SERVICES:
    case BOOKING_RESET_PROVIDERS_PREFERENCES:
      return resetToAnyTherapistForAllServices(action.services);
    case SELECT_SERVICE:
      return {
        ...state,
        [action.service.service_id]: 'any',
      };
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

// Make sure all services start with "Any Therapist" by default
const resetToAnyTherapistForAllServices = (newServices) => {
  if (typeof newServices === 'object') {
    const newPreferences = {};
    newServices.forEach((category) => {
      // This is temporary code while InRoom and Styling Stations are not migrated to the new designs.
      if ('categoryTypeId' in category) {
        category.subCategories.forEach((subCategory) => {
          subCategory.services.filter((service) => service.selected).forEach((service) => {
            newPreferences[service.service_id] = 'any';
          });
        });
      } else {
        category.services.filter((service) => service.selected).forEach((service) => {
          newPreferences[service.service_id] = 'any';
        });
      }
    });
    return newPreferences;
  }

  return initialState;
};

export default bookingServiceProviderPreferences;
