/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import axios from '../../interceptor';
import { convertDate } from '../../assets/js/constants';
import { convertHexToFilter } from '../../assets/js/hsxToFilter';
import clearTimeouts from '../../utils/utils';

import loyaltyLevelsIcon from '../../assets/icons/toasts/loyalty.svg';
import upIcon from '../../assets/icons/up_filled.svg';

import BookNowButton from '../Shared/BookNowButton';
import DashboardHeader from './DashboardHeader';

import '../../assets/css/dashboard/loyaltyProgram.scss';
import '../../assets/css/dashboard/shared.scss';

import { CustomToast } from '../Shared/withToast';
import withNotificationPopUp from '../Shared/withNotificationPopUp';
import withUserRetriever from '../Shared/withUserRetriever';
import getClient from '../../store/client';
import Loading from '../Loading';

function LoyaltyProgram() {
  const [state, setState] = useState({
    loyaltyLevels: [],
    currentLevelColor: '',
    positionArrow: 0,
    showArrow: false,
    positionBullet: 0,
    isLoadingCustomerInfo: true,
    isLoadingLevels: true,
    timeouts: [],
  });

  const history = useHistory();

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);
  const user = useSelector((reduxState) => reduxState.user);
  const spend120 = user.spend_120.cents;
  const toMaintainDate = user.to_maintain_date;
  const toUpgrade = user.to_upgrade_with_vat.value;
  const toMaintainCurrentLevel = user.to_maintain_with_vat.value;

  const {
    loyaltyLevels,
    positionArrow,
    positionBullet,
    showArrow,
    currentLevelColor,
    isLoadingCustomerInfo,
    isLoadingLevels,
    timeouts,
  } = state;

  const dispatch = useDispatch();

  useEffect(() => {
    getClient({ dispatch })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoadingCustomerInfo: false }));
      });

    axios.get('/api/v1/loyalty_levels/all')
      .then((levelsResponse) => {
        const currLevel = levelsResponse.data.filter((l) => l.id === user.customer_loyalty_level.loyalty_level_id)[0];

        setState((prevState) => ({
          ...prevState,
          loyaltyLevels: levelsResponse.data.sort((a, b) => (b.id - a.id)),
          currentLevelColor: currLevel.level_color,
          currLevel,
        }));
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={loyaltyLevelsIcon} text="Loyalty Levels couldn't be retrieved" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoadingLevels: false }));
      });

    if (user) {
      calculatePosition(user.customer_loyalty_level.loyalty_level_id);
    }

    return () => clearTimeouts(timeouts);
  }, []);

  function getBarBackground() {
    if (loyaltyLevels.length > 0) {
      return `linear-gradient(0deg,
        ${loyaltyLevels[3].level_color} 0%, ${loyaltyLevels[2].level_color} 33%, ${loyaltyLevels[1].level_color} 70%, ${loyaltyLevels[0].level_color} 100%)`;
    }
    return 'unset';
  }

  function calcPercentNextLevel() {
    if (loyaltyLevels.length > 0) {
      // maxed level already reached
      if (level.id === 4) {
        return 180;
      }
      const neededToLevel = loyaltyLevels.find((x) => x.id === level.id + 1);
      const result = (180 * spend120) / (neededToLevel.spent_to_get_level);
      return result;
    }
    return 0;
  }

  async function calculatePosition(loyaltyLevelId) {
    return new Promise(() => {
      timeouts.push(setTimeout(async () => {
        const image1 = document.querySelector('#level-1');
        const image2 = document.querySelector('#level-2');
        const image3 = document.querySelector('#level-3');
        const image4 = document.querySelector('#level-4');
        if (image1 && image1.complete && image2 && image2.complete && image3 && image3.complete && image4 && image4.complete) {
          const logoTop = document.querySelector(`#level-${loyaltyLevelId}`).offsetTop;
          const logoHeight = document.querySelector(`#level-${loyaltyLevelId}`).offsetHeight;

          setState((prevState) => ({
            ...prevState,
            showArrow: true,
            positionArrow: (logoTop + (logoHeight / 2)) - 11,
            positionBullet: (logoTop + (logoHeight / 2)) - 6.5,
          }));
        } else {
          await calculatePosition(loyaltyLevelId);
        }
      }, 100));
    });
  }

  function renderLevels() {
    // eslint-disable-next-line no-shadow
    const retLevels = [...loyaltyLevels].map((level) => (
      <div key={`level-${level.id}`} className="level-logos">
        <img id={`level-${level.id}`} style={{ filter: convertHexToFilter(level.level_color) }} className="level-images" src={level.logo} alt="" />
        <div className="flex flex-col pl-5">
          {level.id !== 1
            ? (
              <span className="level-cashback text-left">
                {`Spend ${level.spent_to_get_level}`}
                <br />
                within 120 days
              </span>
            )
            : ''}
        </div>
      </div>
    ));

    return retLevels;
  }

  return (
    <div className="flex flex-col h-full">
      {level && <DashboardHeader color={level.level_color} title="Loyalty Level" />}
      <div className="pb-3 overflow-y-auto flex-1 scrollbar-none dashboard-grey-background-color container-center">
        {
          isLoadingCustomerInfo || isLoadingLevels
            ? (
              <Loading color={level.level_color} text="Levels" />
            ) : (
              <div className="relative">
                <div
                  className="loyalty-program-bg-content-top"
                  style={{
                    height: '200px',
                    background: `linear-gradient(to bottom right,
                      ${level.level_color.concat('E6')} 0%,
                      ${level.level_color} 100%)`,
                  }}
                >
                  <div className="loyalty-program-header w-full flex flex-row justify-center items-center">
                    <div className="w-2/4">
                      <img className="loyalty-program-current-level-logo" src={level.logo} alt="" />
                    </div>
                    <div className="loyalty-program-current-level-text w-2/4 mr-5">
                      Your level is valid until
                      <br />
                      <span className="loyalty-program-current-text-bold">
                        {convertDate(new Date(toMaintainDate), '', true)}
                      </span>
                      <br />
                      Before then, spend
                      <br />
                      <span className="loyalty-program-current-text-bold">
                        { toMaintainCurrentLevel }
                      </span>
                      <br />
                      to maintain your level
                    </div>
                  </div>
                </div>
                <div className="loyalty-program-bg-content-top-opacity" />
                {
                  (loyaltyLevels && user) && (
                  <div className="w-full px-5">
                    <div className="loyalty-title-section opacity-90 pt-5 pb-12" style={{ color: level.level_color }}>
                      How can I upgrade?
                    </div>
                    <div>
                      <div className="loyalty-program-levels">
                        <div className="loyalty-program-levels-bar">
                          { showArrow && <div className="bar-bullet" style={{ top: `${positionBullet}px` }} /> }
                          { showArrow && <div className="bar-arrow" style={{ top: `${positionArrow}px`, borderLeft: `11px solid ${currentLevelColor}` }} /> }
                          <div className="bar" style={{ background: getBarBackground() }} />
                        </div>
                        <div className="loyalty-program-levels-logos">
                          { renderLevels() }
                        </div>
                      </div>
                    </div>
                    <div className="loyalty-title-section opacity-90 pt-5 pb-12" style={{ color: level.level_color }}>
                      Your 120-day Spend
                    </div>
                    { (loyaltyLevels && user)
                        && (
                        <div>
                          <div className="sc-gauge">
                            <div className="sc-background">
                              <div className="sc-percentage" style={{ transform: `rotate(${calcPercentNextLevel()}deg)`, backgroundColor: level.level_color }} />
                              <div className="sc-mask" />
                              <span className="sc-value-top" style={{ color: level.level_color }}>{`AED ${spend120}`}</span>
                            </div>
                          </div>
                          <div className="w-full h-full">
                            <span className="sc-value-bottom">
                              spent in the
                              <br />
                              last 120 days
                            </span>
                          </div>
                        </div>
                        )}
                    <div className="sc-gauge-info-section  w-full flex">
                      <div className="w-1/2">
                        <span>
                          <img className="circle" style={{ backgroundColor: level.level_color }} src={upIcon} alt="" />
                          To Upgrade
                        </span>
                      </div>
                      <div className="w-1/2">
                        <span> Spend </span>
                        <br />
                        <span className="text-120-day-spend" style={{ color: level.level_color }}>
                          {toUpgrade}
                        </span>
                      </div>
                      {/*
                        <div className="w-1/3">
                          <span> In the next </span>
                          <br />
                          <span className="text-120-day-spend" style={{ color: level.level_color }}>
                            {`${dateDifferenceInDays(new Date(), new Date(toMaintainDate))} days`}
                          </span>
                        </div>
                      */}
                    </div>
                  </div>
                  )
                }
              </div>
            )
        }
        <div className="w-full p-5 inline-block">
          {'Learn more about our Loyalty program '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => history.push('/loyalty-program-learn-more')}
            className="underline"
          >
            here
          </span>
          .
        </div>
        <div className="scroll-white-space" />
      </div>
      { level && <BookNowButton color={level.level_color} /> }
    </div>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(LoyaltyProgram);
