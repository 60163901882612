import { ApiPost } from './Api';

const BASE_ENDPOINT = 'log_in_logs';

/**
 * Login Logs API calls
 */
export default () => ({
  logSuccess: (payload) => ApiPost({ endpoint: `${BASE_ENDPOINT}/log_success`, payload }),
  logError: (payload) => ApiPost({ endpoint: `${BASE_ENDPOINT}/log_error`, payload }),
});
