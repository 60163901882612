import {
  BOOKING_CURRENTLY_OPENED_SERVICE_CATEGORY,
  BOOKING_SERVICES,
  BOOKING_TOGGLE_SERVICE_CATEGORY_TYPE,
  BOOKING_UNSELECT_SERVICE_FROM_CATEGORY,
  CLEAN_BOOKING,
  CLOSE_ALL_SERVICE_CATEGORIES,
  SELECT_SERVICE,
} from '../../actions/bookings';

const initialState = [];

const bookingServices = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_SERVICES:
      return action.services;
    case SELECT_SERVICE: {
      const serviceId = action.service.service_id;
      const { categoryTypeId } = action.categoryType;
      // Only 1 addon active per category and sub category
      return state.map((categoryType) => ({
        ...categoryType,
        subCategories: categoryType.categoryTypeId === categoryTypeId ? categoryType.subCategories.map((subCategory) => ({
          ...subCategory,
          services: subCategory.services.map((service) => ({
            ...service,
            selected: service.service_id === serviceId,
          })),
        })) : categoryType.subCategories,
      }));
    }
    case BOOKING_UNSELECT_SERVICE_FROM_CATEGORY: {
      // Since only 1 service from a category can be selected, mark all services from the given category as unselected
      return state.map((categoryType) => ({
        ...categoryType,
        subCategories: categoryType.categoryTypeId === action.categoryTypeId ? categoryType.subCategories.map((subCategory) => ({
          ...subCategory,
          services: subCategory.services.map((service) => ({
            ...service,
            selected: false,
          })),
        })) : categoryType.subCategories,
      }));
    }
    case BOOKING_CURRENTLY_OPENED_SERVICE_CATEGORY: {
      return state.map((categoryType) => ({
        ...categoryType,
        isOpen: categoryType.categoryTypeId === action.categoryTypeId, // only 1 open service category at the same time
      }));
    }
    case BOOKING_TOGGLE_SERVICE_CATEGORY_TYPE: {
      // Multiple service categories can be active (selected != open)!
      return state.map((categoryType) => ({
        ...categoryType,
        isActive: categoryType.categoryTypeId === action.categoryTypeId ? !categoryType.isActive : categoryType.isActive,
      }));
    }
    case CLOSE_ALL_SERVICE_CATEGORIES: {
      return state.map((categoryType) => ({
        ...categoryType,
        isOpen: false,
      }));
    }
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingServices;
