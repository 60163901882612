export const USER_INFORMATION = 'USER_INFORMATION';

export const accountIssue = () => ({
  type: 'ERROR_MESSAGE',
  message: {
    errorTitle: 'Account Issue',
    errorMessage: `Something went wrong processing your request! Please refresh the page.
                If the error persists, please send us an email to support@beyountybonus.com`,
  },
});

export const setUserInfo = (info) => ({
  type: USER_INFORMATION,
  user: info,
});

export const SET_SSO_TOKEN = 'SET_SSO_TOKEN';

export const setSSOToken = (token) => ({
  type: SET_SSO_TOKEN,
  token,
});
