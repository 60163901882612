import React from 'react';
import PropTypes from 'prop-types';

import '../../assets/css/dashboard/slideTreats.scss';
import treatImg from '../../assets/img/treats.png';

function SlideTreats({ color }) {
  return (
    <div className="slide-treat-container">
      <div className="slide-treat-content">
        <div className="slide-treat-text-left">
          <div className="slide-treat-badge-expiration ml-4 mt-4 mb-6" style={{ backgroundColor: color }}> EXPIRES IN 10 DAYS </div>
          <div className="slide-treat-content-title ml-4 mb-4"> MOODY MONDAYS </div>
          <div className="slide-treat-content-text ml-4 mb-4">Earn 2x points on Monday afternoons from 1 to 4 pm.</div>
        </div>
        <div className="slide-treat-img object-cover object-right" style={{ backgroundImage: `url('${treatImg}')` }} />
      </div>
    </div>
  );
}

SlideTreats.propTypes = {
  color: PropTypes.string.isRequired,
};

export default SlideTreats;
