/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import IntlTelInput from 'react-intl-tel-input';
import get from 'lodash/get';
import axios from '../interceptor';

import emailIcon from '../assets/icons/email.svg';
import eyeClosedIcon from '../assets/icons/eye_closed.svg';
import eyeOpenIcon from '../assets/icons/eye_open.svg';

import phoneIcon from '../assets/icons/phone.svg';
import { ReactComponent as BackIcon } from '../assets/icons/arrow_left.svg';
import { cellPhoneForPayload, isValidEmail } from '../assets/js/constants';
import clearTimeouts from '../utils/utils';

import logoTns from '../assets/img/logo_tns_black.svg';
import logoMarquee from '../assets/img/logo_marquee_black.svg';

import 'react-intl-tel-input/dist/main.css';
import '../assets/css/signIn.scss';
import '../assets/css/shared.scss';
import LocalStorageService from './LocalStorageService';

import { CustomToast } from './Shared/withToast';
import { USER_SESSION_LOG_TYPES } from '../utils/api';
import LogInLogs from '../api/LogInLogs';

function SignIn(props) {
  const { logSuccess, logError } = LogInLogs();

  const [state, setState] = useState({
    email: '',
    emailError: false,
    isEmail: true,
    password: '',
    passwordError: false,
    cellPhone: '',
    cellPhoneCountry: {},
    cellPhoneError: false,
    cellPhoneValid: false,
    tabSelected: 'login',
    isForgotPassword: false,
    processing: false,
    logInLogId: useSelector((reduxState) => reduxState.logInLogId) || '',
    isMounted: false,
    passwordShown: false,
    timeouts: [],
  });
  const {
    isEmail,
    email,
    emailError,
    password,
    passwordError,
    cellPhone,
    cellPhoneCountry,
    cellPhoneValid,
    tabSelected,
    isForgotPassword,
    processing,
    logInLogId,
    isMounted,
    passwordShown,
    timeouts,
  } = state;

  function togglePasswordVisibility() {
    setState((prevState) => ({ ...prevState, passwordShown: !passwordShown }));
  }

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isMounted: true }));

    if (!logInLogId) {
      if (isMounted) {
        axios.post('/api/v1/log_in_logs/create_log_in_log')
          .then((res) => {
            dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: res.data.log_in_log_id });
            setState((prevState) => ({ ...prevState, logInLogId: res.data.log_in_log_id }));
          })
          .catch(() => {
            toast(<CustomToast type="error" text="An error occurred on Sign In attempt" />);
          });
      }
    }

    return () => {
      setState((prevState) => ({ ...prevState, isMounted: false }));
      clearTimeouts(timeouts);
    };
  }, [isMounted]);

  function validate() {
    let result = true;
    let errorMessage = 'Unknown error.';
    let errorKey = '';
    let credentials = false;

    const frontendLogPayload = {
      value: email || cellPhone,
      logInLogId,
      tag: USER_SESSION_LOG_TYPES.LOG_IN,
    };

    if (isEmail) {
      frontendLogPayload.value = email;
      errorKey = 'emailError';
      if (email === '' && password === '') {
        result = false;
        credentials = true;
        errorMessage = 'Enter your credentials.';
      } else if (email === '') {
        result = false;
        errorMessage = 'Enter your email.';
      } else if (!isValidEmail(email)) {
        result = false;
        errorMessage = 'Email is not valid.';
      } else if (password === '') {
        result = false;
        errorMessage = 'Enter your password.';
        errorKey = 'passwordError';
      }
    } else if (cellPhone === '' && password === '') {
      result = false;
      credentials = true;
      errorKey = 'cellPhoneError';
      errorMessage = 'Enter your credentials.';
    } else if (cellPhone === '') {
      result = false;
      credentials = true;
      errorKey = 'cellPhoneError';
      errorMessage = 'Enter your phone number.';
    } else if (!cellPhoneValid) {
      frontendLogPayload.value = cellPhone;
      result = false;
      errorMessage = 'Phone number provided is not valid';
      errorKey = 'cellPhoneError';
    } else if (password === '') {
      result = false;
      errorMessage = 'Enter your password.';
      errorKey = 'passwordError';
    }

    if (!result) {
      toast(<CustomToast type="error" text={errorMessage} />);
      frontendLogPayload.error = errorMessage;

      axios.post('/api/v1/log_in_logs/log_error', frontendLogPayload);
      if (credentials) {
        setState((prevState) => ({ ...prevState, [errorKey]: true, passwordError: true }));
        timeouts.push(setTimeout(() => {
          setState((prevState) => ({ ...prevState, [errorKey]: false, passwordError: false }));
        }, 5000));
      } else {
        setState((prevState) => ({ ...prevState, [errorKey]: true }));
        timeouts.push(setTimeout(() => {
          setState((prevState) => ({ ...prevState, [errorKey]: false }));
        }, 5000));
      }
    }

    return result;
  }

  async function fetchWithTimeout(resource, options) {
    const { timeout = 5000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });

    clearTimeout(id);

    return response;
  }

  function signIn() {
    setState((prevState) => ({ ...prevState, processing: true }));
    const payload = { email, password };

    if (cellPhoneValid) {
      payload.cell_phone = cellPhoneForPayload(cellPhoneCountry, cellPhone);
    }
    let response = '';

    if (validate()) {
      fetchWithTimeout(`${process.env.REACT_APP_API_URL}/api/v1/client_auth/sign_in`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      })
        .then((rsp) => {
          response = rsp;
          return rsp.text();
        })
        .then((result) => {
          const res = JSON.parse(result);
          // If the login is successful the client data will be
          // returned in `res.data`, otherwise it will return an
          // object containing `success: false` and a list of `errors`
          if (!res.data && !res.success && res.errors) {
            throw new Error(res.errors[0]);
          }

          if (!(res.data.attributes.loyalty_level && res.data.attributes.customer_loyalty_level)) {
            dispatch({
              type: 'ERROR_MESSAGE',
              message: {
                errorTitle: 'Account Issue',
                errorMessage: `Something went wrong processing your request! Please refresh the page.
                If the error persists, please send us an email to support@beyountybonus.com`,
              },
            });
            throw new Error('We can\'t process your request, please contact the support team at support@beyoutybonus.com');
          }

          let logPayload = {
            value: email || cellPhone,
            logInLogId,
            tag: USER_SESSION_LOG_TYPES.LOG_IN,
          };

          if (!Object.prototype.hasOwnProperty.call(res, 'errors')) {
            const headers = {
              'access-token': response.headers.get('access-token'),
              'cache-control': response.headers.get('cache-control'),
              client: response.headers.get('client'),
              'content-type': response.headers.get('content-type'),
              expiry: response.headers.get('expiry'),
              uid: response.headers.get('uid'),
            };
            LocalStorageService.setObject('headers', headers);

            dispatch({ type: 'USER_INFORMATION', user: res.data.attributes });

            logSuccess(logPayload)
              .then((logResult) => {
                if (isMounted) {
                  dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: logResult.data.log_in_log_id });
                  setState((prevState) => ({ ...prevState, logInLogId: logResult.data.log_in_log_id, processing: false }));
                }
              });

            const { location } = props;
            if (location && location.state && location.state.nextPage) {
              history.push(location.state.nextPage);
            } else {
              history.push('/dashboard');
            }
          } else {
            logPayload = { ...logPayload, error: res.errors[0] };

            toast(<CustomToast type="error" text={res.errors[0]} />);

            logError(logPayload)
              .then((logResult) => {
                dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: logResult.data.log_in_log_id });
                setState((prevState) => ({
                  ...prevState,
                  logInLogId: logResult.data.log_in_log_id,
                  emailError: true,
                  cellPhoneError: true,
                  passwordError: true,
                  processing: false,
                }));

                timeouts.push(setTimeout(() => {
                  setState((prevState) => ({
                    ...prevState,
                    emailError: false,
                    cellPhoneError: false,
                    passwordError: false,
                  }));
                }, 5000));
              });
          }
        })
        .catch((err) => {
          toast(<CustomToast type="error" text={err.message} />);
          setState((prevState) => ({ ...prevState, processing: false }));
        });
    } else {
      setState((prevState) => ({ ...prevState, processing: false }));
    }
  }

  function activation() {
    setState((prevState) => ({ ...prevState, processing: true }));

    let logPayload = {
      value: cellPhone,
      logInLogId,
      tag: USER_SESSION_LOG_TYPES.REQUEST_ACTIVATION,
    };

    if (cellPhoneValid) {
      const finalPhone = cellPhoneForPayload(cellPhoneCountry, cellPhone);

      const payload = {
        cell_phone: finalPhone,
        logInLogId,
        redirect_url: `${window.location.origin}/forgot-password?logInLogId=${logInLogId}`,
      };

      axios.post('/api/v1/client/resend_sms_with_activation_link', payload)
        .then(() => {
          logSuccess(logPayload)
            .then((res) => {
              dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: res.data.log_in_log_id });
              setState((prevState) => ({ ...prevState, logInLogId: res.data.log_in_log_id, processing: false }));
            });
          toast(<CustomToast
            type="info"
            text={`If a matching account with number +${finalPhone} was found an SMS will be sent.`}
          />);
        })
        .catch((res) => {
          if (get(res, 'response.data.error_msg')) {
            logPayload = { ...logPayload, error: res.response.data.error_msg };

            toast(<CustomToast
              type="info"
              text={`If a matching account with number +${finalPhone} was found an SMS will be sent.`}
            />);
            setState((prevState) => ({ ...prevState, processing: false }));
            axios.post('/api/v1/log_in_logs/log_error', logPayload)
              .then((logResult) => {
                dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: logResult.data.log_in_log_id });
                setState((prevState) => ({ ...prevState, logInLogId: logResult.data.log_in_log_id }));
              });
          }
        });
    } else {
      toast(<CustomToast type="error" text="The phone provided is not valid." />);

      logPayload.error = 'The phone provided is not valid.';
      axios.post('/api/v1/log_in_logs/log_error', logPayload);
      setState((prevState) => ({ ...prevState, cellPhoneError: true, processing: false }));
      timeouts.push(setTimeout(() => {
        setState((prevState) => ({ ...prevState, cellPhoneError: false }));
      }, 5000));
    }
  }

  function handleChange(e) {
    setState((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  }

  function changeTab(e) {
    setState((prevState) => ({
      ...prevState,
      tabSelected: e.target.id,
      emailError: false,
      cellPhoneError: false,
      passwordError: false,
      isForgotPassword: false,
      cellPhone: '',
      cellPhoneValid: false,
    }));
  }

  function forgotPassword() {
    setState((prevState) => ({ ...prevState, processing: true }));

    const payload = {
      redirect_url: `${window.location.origin}/forgot-password?logInLogId=${logInLogId}`,
      email,
    };

    let logPayload = {
      value: '',
      logInLogId,
      tag: USER_SESSION_LOG_TYPES.REQUEST_CHANGE_PASSWORD,
    };

    fetchWithTimeout(`${process.env.REACT_APP_API_URL}/api/v1/client_auth/password`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then((rsp) => rsp.text())
      .then((result) => {
        const res = JSON.parse(result);
        if (!Object.prototype.hasOwnProperty.call(res, 'errors')) {
          history.push('/booking');
          toast(
            <CustomToast
              type="success"
              text={
                `An email was sent to ${email} to allow you to reset your password.`
              }
            />,
          );

          logPayload.value = 'Request Change Password';

          logSuccess(logPayload)
            .then((logResult) => {
              dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: logResult.data.log_in_log_id });
              setState((prevState) => ({ ...prevState, logInLogId: logResult.data.log_in_log_id }));
            });
        } else {
          logPayload.value = email;
          logPayload = { ...logPayload, error: JSON.parse(result).errors[0] };

          axios.post('/api/v1/log_in_logs/log_error', logPayload)
            .then((logResult) => {
              dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: logResult.data.log_in_log_id });
              setState((prevState) => ({
                ...prevState, emailError: true, logInLogId: logResult.data.log_in_log_id, processing: false,
              }));
            });

          toast(<CustomToast
            type="success"
            text={`If a matching account was found an email was sent to ${email} to allow you to reset your password.`}
          />);
        }
      })
      .catch(() => {
        toast(<CustomToast
          type="success"
          text={`If a matching account was found an email was sent to ${email} to allow you to reset your password.`}
        />);
        logPayload.value = email;
        logPayload = { ...logPayload, error: 'Server error' };

        axios.post('/api/v1/log_in_logs/log_error', logPayload)
          .then((res) => {
            dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: res.data.log_in_log_id });
            setState((prevState) => ({ ...prevState, logInLogId: res.data.log_in_log_id, processing: false }));
          });
      });
  }

  function setCellPhoneOnLogIn(valid, number, countryData) {
    setCellPhone(valid, number, countryData);
  }

  function setCellPhoneOnActivation(valid, number, countryData) {
    setCellPhone(valid, number, countryData);
  }

  function setCellPhone(valid, number, countryData) {
    let finalNumber = '';
    const numbers = number.split('');
    const lastNumberIntroduced = numbers[numbers.length - 1];

    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(lastNumberIntroduced)) {
      finalNumber = number;
    } else {
      numbers.pop();
      finalNumber = numbers.join('');
    }

    setState((prevState) => ({
      ...prevState,
      cellPhoneCountry: countryData,
      cellPhone: finalNumber,
      cellPhoneValid: valid,
    }));
  }

  function changeLogInMethod(usingEmail) {
    setState((prevState) => ({
      ...prevState,
      isEmail: usingEmail,
      email: '',
      emailError: false,
      password: '',
      passwordError: false,
      cellPhone: '',
      cellPhoneError: false,
      cellPhoneValid: false,
    }));
  }

  function getFooterButton() {
    if (isForgotPassword) {
      return (<button type="button" onClick={forgotPassword} className={!processing ? 'button-sign-in flex justify-center md:w-1/2 sm:py-10 w-full' : 'button-sign-in-grey flex justify-center md:w-1/2 sm:py-10 w-full'}>{processing ? 'Processing...' : 'SUBMIT'}</button>);
    }
    if (tabSelected === 'login') {
      return (<button type="button" onClick={signIn} className={!processing ? 'button-sign-in flex justify-center md:w-1/2 sm:py-10 w-full' : 'button-sign-in-grey flex justify-center md:w-1/2 sm:py-10 w-full'}>{processing ? 'Processing...' : 'LOGIN'}</button>);
    }
    return (<button type="button" onClick={activation} className={!processing ? 'button-sign-in flex justify-center md:w-1/2 sm:py-10 w-full' : 'button-sign-in-grey flex justify-center md:w-1/2 sm:py-10 w-full'}>{processing ? 'Processing...' : 'ACTIVATE'}</button>);
  }

  function renderLogin() {
    return (
      <div className="tab ovf-none">
        {
          isEmail
            ? (
              <div className="flex flex-col w-100">
                <span className="email-text label pb-2">Email</span>
                <div className="relative">
                  <input id="email" value={email} placeholder="Your Email" onChange={(e) => handleChange(e)} className={emailError ? 'input-signin-error mb-4' : 'input-signin mb-4'} type="email" />
                  <img className="email-icon" src={emailIcon} alt="" />
                </div>
              </div>
            )
            : (
              <div className="flex flex-col w-100">
                <span className="phone-number-text label pb-2">Phone number</span>
                <div className="mb-4 w-100 relative">
                  <IntlTelInput
                    fieldId="cellPhone"
                    value={cellPhone}
                    defaultCountry="ae"
                    onPhoneNumberChange={(value, number, countryData) => { setCellPhoneOnLogIn(value, number, countryData); }}
                    containerClassName="intl-tel-input"
                    inputClassName={cellPhoneValid ? 'input-signin-cell-phone' : 'input-signin-cell-phone-error'}
                  />
                  <img className="phone-icon" src={phoneIcon} alt="" />
                </div>
              </div>
            )
        }
        <div className="flex w-full justify-between">
          <span className="password-text label pb-2">Password</span>
          <div
            role="button"
            tabIndex={0}
            onClick={() => setState((prevState) => ({
              ...prevState, isForgotPassword: true, emailError: false, cellPhoneError: false, passwordError: false,
            }))}
          >
            <span className="forgot-password">Forgot Password?</span>
          </div>
        </div>
        <div className="relative">
          <input id="password" value={password} placeholder="Your Password" onChange={(e) => handleChange(e)} className={passwordError ? 'input-signin-error mb-1' : 'input-signin mb-1'} type={passwordShown ? 'text' : 'password'} />
          <img className="pass-icon" onClick={() => togglePasswordVisibility()} src={passwordShown ? eyeOpenIcon : eyeClosedIcon} alt="" />
        </div>
        <div className="flex items-center justify-center py-2">
          <hr className="tab-separator" />
          <span className="separator-text">or</span>
          <hr className="tab-separator" />
        </div>
        <div className="flex items-center justify-center pb-3">
          {
            isEmail
              ? (
                <div className="flex" role="button" tabIndex={0} onClick={() => changeLogInMethod(false)}>
                  <span className="use-email-phone-text">Use your </span>
                  &nbsp;
                  <span className="use-email-phone-text-underline">phone number</span>
                  &nbsp;
                  <span className="use-email-phone-text"> to Login</span>
                </div>
              )
              : (
                <div className="flex" role="button" tabIndex={0} onClick={() => changeLogInMethod(true)}>
                  <span className="use-email-phone-text">Use your </span>
                  &nbsp;
                  <span className="use-email-phone-text-underline">email</span>
                  &nbsp;
                  <span className="use-email-phone-text"> to Login</span>
                </div>
              )
          }
        </div>
      </div>
    );
  }

  function renderActivation() {
    return (
      <div className="tab ovf-none">
        <div className="activation-title">
          <span className="activation-text">Activation</span>
        </div>
        <span className="activation-subtitle">If you are an existing TNS or Marquee customer, you can activate your Beyouty Bonus account by entering your phone number below, after which you will receive an SMS activation link.</span>
        <span className="phone-number-text label pb-3">Phone number</span>
        <div className="pb-3 w-100 relative">
          <IntlTelInput
            fieldId="cellPhone"
            value={cellPhone}
            defaultCountry="ae"
            onPhoneNumberChange={(value, number, countryData) => { setCellPhoneOnActivation(value, number, countryData); }}
            containerClassName="intl-tel-input"
            inputClassName={cellPhoneValid ? 'input-signin-cell-phone' : 'input-signin-cell-phone-error'}
          />
          <img className="phone-icon" src={phoneIcon} alt="" />
        </div>
      </div>
    );
  }

  return (
    <div className="sign-in-page-container">
      <div className="sign-in-page">
        <div className="logos my-10">
          <div className="pr-5">
            <img className="logo-tns-sign-in" src={logoTns} alt="" />
          </div>
          <div>
            <img className="logo-marquee-sign-in" src={logoMarquee} alt="" />
          </div>
        </div>
        <div className="flex self-center md:w-1/2 sm:px-0 w-full">
          <div className="flex flex-col px-5 w-full items-center">
            <div>
              <div className="sign-tabs">
                <div role="button" tabIndex={0} onClick={(e) => changeTab(e)}>
                  <span id="login" className={tabSelected === 'login' ? 'login-tab-title-selected' : 'login-tab-title'}>Login</span>
                </div>
                <div role="button" tabIndex={0} onClick={(e) => changeTab(e)}>
                  <span id="activation" className={tabSelected === 'activation' ? 'activation-tab-title-selected' : 'activation-tab-title'}>Activation</span>
                </div>
              </div>
            </div>
            <div className="tab-container">
              {
                // eslint-disable-next-line no-nested-ternary
                isForgotPassword ? (
                  <div className="flex flex-col w-full items-center">
                    <div className="tab">
                      <div className="forgot-password-title">
                        <div role="button" tabIndex={0} onClick={() => setState((prevState) => ({ ...prevState, isForgotPassword: false }))}>
                          <BackIcon className="back-icon-forgot-password" stroke="#724467" />
                        </div>
                        <span className="forgot-password-text">Forgot Password?</span>
                      </div>
                      <span className="forgot-password-subtitle">To recover your password please enter your email address below and then follow the link sent to you via email.</span>
                      <span className="forgot-password-email-address pb-3">Email</span>
                      <input id="email" value={email} placeholder="Your Email" onChange={(e) => handleChange(e)} className={emailError ? 'input-signin-error mb-3' : 'input-signin mb-3'} type="email" />
                    </div>
                  </div>
                ) : tabSelected === 'login'
                  ? renderLogin()
                  : renderActivation()
              }
              <div className="flex mt-5 justify-center">
                {getFooterButton()}
              </div>
            </div>

            <div className="sign-in-page-footer">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SignIn.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      nextPage: PropTypes.string,
    }),
  }),
};

SignIn.defaultProps = {
  location: {
    state: {},
  },
};

export default SignIn;
