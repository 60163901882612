import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import TransactionsAPI from '../../api/Transactions';
import SurveysAPI from '../../api/Surveys';

import DashboardHeader from './DashboardHeader';
import DashboardHeaderImage from './DashboardHeaderImage';
import BookNowButton from '../Shared/BookNowButton';

import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg';

import noRewardIcon from '../../assets/icons/no_rewards_face.png';
import successImage from '../../assets/icons/success_transaction.png';

import surveysIcon from '../../assets/icons/toasts/survey.svg';
import transactionsIcon from '../../assets/icons/toasts/transaction.svg';

import { ReactComponent as ServicesIcon } from '../../assets/icons/services.svg';
import { ReactComponent as ProductsIcon } from '../../assets/icons/shopping-bag.svg';
import { ReactComponent as PackagesIcon } from '../../assets/icons/transaction/package.svg';

import locationTnsDefaultImg from '../../assets/img/location_tns.jpg';
import locationMarqueeDefaultImg from '../../assets/img/location_marquee.jpg';

import PopUpComponentModal from '../Shared/Popups/PopUpComponentModal';
import SurveyDetailsModal, { SURVEY_MODAL_TOAST_CONTAINER_ID } from './SurveyDetailsModal';
import { CustomToast } from '../Shared/withToast';
import withNotificationPopUp from '../Shared/withNotificationPopUp';
import withUserRetriever from '../Shared/withUserRetriever';

import Loading from '../Loading';

import '../../assets/css/shared.scss';
import '../../assets/css/dashboard/dashboardTransactionDetails.scss';

function DashboardTransactionDetails() {
  const [state, setState] = useState({
    transaction: '',
    survey: '',
    howToWinPoints: false,
    popup: false,
    timeToPopup: 3000, // in milliseconds
    isLoading: true,
  });

  const history = useHistory();

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    transaction, howToWinPoints, survey, popup, timeToPopup, isLoading,
  } = state;

  const { getTransaction } = TransactionsAPI();
  const { getSurvey, updateSurvey } = SurveysAPI();

  const { id } = useParams();

  const surveyTimeoutActionId = useRef(-1);

  useEffect(() => {
    let earlyErrorExit = false;
    // show popup after 3 seconds if the conditions are met
    const surveyTimeoutAction = (status) => setTimeout(() => {
      if (status === 'pending') {
        setState((prevState) => ({ ...prevState, popup: true }));
      }
    }, timeToPopup);

    getTransaction(id)
      .then((res) => {
        const receipt = res.data;
        setState((prevState) => ({ ...prevState, transaction: receipt }));
        // transaction data is required to call the survey
        if (receipt.survey_status && receipt.survey_id) {
          getSurvey(receipt.survey_id)
            .then((surveyRes) => {
              setState((prevState) => ({ ...prevState, survey: surveyRes.data }));
              surveyTimeoutActionId.current = surveyTimeoutAction(receipt.survey_status);
            })
            .catch(() => { }); // ignore errors here
        }
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={transactionsIcon} text="Transaction couldn't be retrieved" />);
        earlyErrorExit = true;
      })
      .finally(() => {
        if (earlyErrorExit) {
          history.push('/dashboard/transactions');
        } else {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        }
      });

    return () => clearTimeout(surveyTimeoutActionId.current);
  }, []);

  function getTileImage() {
    if (!transaction.location_photo) {
      if (transaction.location_category === 'tns') {
        return locationTnsDefaultImg;
      }

      return locationMarqueeDefaultImg;
    }
    return transaction.location_photo;
  }

  /**
   * @param {Array<Object {quantity}>} saleables array containing objects with quantity field
   * @returns sum of quantities of a saleable detail
   */
  function sumSaleableQuantities(saleables) {
    return (saleables && saleables.reduce((acc, saleable) => acc + saleable.quantity, 0)) || 0;
  }

  function renderSurveyLink() {
    const renderLinkText = (surveyData) => {
      if (surveyData.status === 'answered' || surveyData.status === 'closed') {
        return 'View Feedback';
      } if (surveyData.status === 'pending') {
        return 'Provide Feedback';
      }
      return '';
    };

    return (
      (transaction.survey_id && transaction.survey_status && survey) && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            clearTimeout(surveyTimeoutActionId.current);
            setState((prevState) => ({ ...prevState, popup: true }));
          }}
          className="survey-caption caption bottom-0"
        >
          <br />
          {renderLinkText(survey)}
        </div>
      )
    );
  }

  function renderPopUpModal() {
    const handleSubmitFeedback = (surveyData) => {
      const message = {
        success: 'Successfully updated survey.',
        missingProviders: 'To submit your feedback rate all providers.',
        error: 'Unable to update survey. Contact support.',
      };

      const payload = {
        surveyAnswers: surveyData.details.map((detail) => ({ id: detail.detail_id, rating: detail.rating })),
        comment: surveyData.comment,
      };

      const toastConfig = { containerId: SURVEY_MODAL_TOAST_CONTAINER_ID };

      if (payload.surveyAnswers.some((answer) => answer.rating === 0)) {
        toast(<CustomToast type="error" icon={surveysIcon} text={message.missingProviders} />, toastConfig);
      } else if (surveyData.status !== 'closed') {
        updateSurvey(surveyData.id, payload)
          .then((res) => {
            toast(<CustomToast type="success" text={message.success} />);
            setState((prevState) => ({ ...prevState, survey: { ...surveyData, ...res.data }, popup: false }));
          })
          .catch(() => {
            toast(<CustomToast type="error" icon={surveysIcon} text={message.error} />);
            setState((prevState) => ({ ...prevState, popup: false }));
          });
      }
    };

    const handleUpdateSurvey = (data) => {
      setState((prevState) => ({ ...prevState, survey: data }));
    };

    const handleOnCloseModal = () => {
      setState((prevState) => ({ ...prevState, popup: false }));
    };

    const disable = survey && survey.status === 'closed';
    return (
      popup && (
        <PopUpComponentModal handleClose={handleOnCloseModal}>
          <SurveyDetailsModal
            survey={survey}
            style={{ color: level.level_color }}
            inPopUp
            buttonAction={handleSubmitFeedback}
            updateParentSurvey={handleUpdateSurvey}
            handleClose={handleOnCloseModal}
            modalDisabled={disable}
            isNotification={false}
          />
        </PopUpComponentModal>
      )
    );
  }

  function renderSaleable(saleable) {
    return (
      <div className="transaction-details-saleable my-2">
        <span className="transaction-details-regular-text w-2/3">
          {(!Number.isNaN(saleable.quantity) && saleable.quantity > 1) && `${saleable.quantity} x `}
          {saleable.name}
        </span>
        <span className="transaction-details-bold-text">{saleable.amount.value}</span>
        {
          saleable.discount && saleable.discount.map((discount) => (
            <div key={discount.id} className="transaction-details-saleable my-1">
              <span className="transaction-details-small-regular-text w-2/3 ml-4">{discount.name}</span>
              <span className="transaction-details-small-bold-text transaction-details-discount-color">{`-${discount.amount.value}`}</span>
            </div>
          ))
        }
      </div>
    );
  }

  function renderSaleables(title, saleables, Icon, iconClass) {
    return (
      <div className="w-full">
        <div className="flex items-center">
          <Icon className={iconClass} style={{ fill: level.level_color }} />
          <span className="transaction-details-title" style={{ color: level.level_color }}>
            {title}
            {' '}
            (
            {sumSaleableQuantities(saleables)}
            )
          </span>
        </div>
        {
          saleables.map((saleable) => (
            <div key={saleable.id}>
              <div className="px-2">
                {renderSaleable(saleable)}
              </div>
            </div>
          ))
        }
      </div>
    );
  }

  function renderPackagesAndGifts(packagesAndGifts) {
    const { packages } = packagesAndGifts;
    const { gifts } = packagesAndGifts;

    return (
      <div className="w-full">
        <div className="flex items-center">
          <PackagesIcon className="transaction-details-saleable-icon" alt="" style={{ fill: level.level_color }} />
          <span className="transaction-details-title" style={{ color: level.level_color }}>
            Packages and Gift Cards
            (
            {((packages && packages.length) || 0) + ((gifts && gifts.length) || 0)}
            )
          </span>
        </div>

        {packages && <div className="transaction-details-subtitle px-2">Packages</div>}
        {
          packages && packages.map((pack) => (
            <div key={pack.id}>
              <div className="pr-2 pl-6">
                {renderSaleable(pack)}
              </div>
            </div>
          ))
        }

        {gifts && <div className="transaction-details-subtitle px-2">Gifts</div>}
        {
          gifts && gifts.map((gift) => (
            <div key={gift.id}>
              <div className="pr-2 pl-6">
                {renderSaleable(gift)}
              </div>
            </div>
          ))
        }
      </div>
    );
  }

  function renderPayments(payments) {
    return payments.map((payment) => (
      <div key={payment.id} className="flex items-center justify-between">
        <span className="transaction-details-regular-text">{payment.name.replace('Points/Rewards', 'Points Redeemed')}</span>
        <span className="transaction-details-small-primary-text" style={{ color: level.level_color }}>{payment.amount.value}</span>
      </div>
    ));
  }

  function renderTips(serviceProviders, tips) {
    return serviceProviders.map((provider, i) => {
      const tip = tips.find((t) => t.provider_id === provider.id);

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${provider.id}-${i}`} className="flex items-center justify-between">
          <span className="transaction-details-regular-text">{provider.name}</span>
          <span className="transaction-details-small-primary-text" style={{ color: level.level_color }}>
            {tip ? tip.amount.value : 0}
          </span>
        </div>
      );
    });
  }

  function renderSubTotal(subTotal) {
    return (
      <div className="w-full">
        <div className="flex items-center justify-between px-2">
          <span className="transaction-details-subtitle">Subtotal (w/ Vat)</span>
          <span className="transaction-details-bold-text">{subTotal.amount.value}</span>
        </div>
        {
          subTotal.general_discounts && subTotal.general_discounts.map((generalDiscount, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${generalDiscount.id}-${i}`} className="transaction-details-saleable px-2">
              <span className="transaction-details-small-regular-text w-2/3 pl-4">{generalDiscount.name}</span>
              <span className="transaction-details-small-bold-text transaction-details-discount-color">{`-${generalDiscount.amount.value}`}</span>
            </div>
          ))
        }
      </div>
    );
  }

  function hasPayments(totalPayments) {
    return get(totalPayments, 'payments', []).length !== 0 || get(totalPayments, 'tips', []).length !== 0;
  }

  function renderTotal(amount, totalPayments) {
    return (
      <div className="w-full">
        <div className={`transaction-details-large-title flex items-center justify-between px-2 ${!hasPayments(totalPayments) ? 'pb-8' : ''}`} style={{ color: level.level_color }}>
          <span>Total (w/ Vat)</span>
          <span>{`${amount.currency_iso} ${amount.value}`}</span>
        </div>

        {
          hasPayments(totalPayments) && (
            <div className="transaction-details-payments-container">
              {
                (get(totalPayments, 'payments', []).length > 0) && (
                  <>
                    <span className="transaction-details-title mb-1" style={{ color: level.level_color }}>
                      Payments
                    </span>

                    {renderPayments(totalPayments.payments)}

                    <div className="transaction-details-light-separator my-2" />

                    <div className={`flex items-center justify-between ${(get(totalPayments, 'tips', []).length === 0) ? 'mb-6' : 'mb-2'}`}>
                      <span className="transaction-details-bold-text">Tendered</span>
                      {transaction.tendered && <span className="transaction-details-small-primary-text" style={{ color: level.level_color }}>{`${transaction.tendered.currency_iso} ${transaction.tendered.value}`}</span>}
                    </div>
                  </>
                )
              }

              <>
                <span className="flex justify-between transaction-details-title" style={{ color: level.level_color }}>
                  <span>Tips</span>
                  <span>
                    {(get(totalPayments, 'tips', []).length > 0) ? '' : 0}
                  </span>
                </span>
                {(get(totalPayments, 'tips', []).length > 0) && renderTips(transaction.service_providers, totalPayments.tips)}
              </>
            </div>
          )
        }
      </div>
    );
  }

  function renderPointsCard(pointsEarned, clientLevel) {
    return pointsEarned > 0 ? (
      <div className={`mb-4 reward-border-lvl${clientLevel.id}`}>
        <div className="transaction-details-points-card-container" style={{ backgroundColor: clientLevel.level_color }}>
          <div className="w-3/4">
            <img className="transaction-details-celebrate-icon" src={successImage} alt="" />
          </div>
          <div className="w-3/4 transaction-details-points-card-text">
            Congrats!
            <br />
            You&apos;ve earned
            {` ${pointsEarned} points `}
            on this transaction.
          </div>
        </div>
      </div>
    ) : (
      <div className={`mb-4 mt-6 reward-border-lvl${clientLevel.id}`}>
        <div className="transaction-details-points-card-container" style={{ backgroundColor: clientLevel.level_color }}>
          <div className="w-3/4">
            <img className="transaction-details-noPoints-icon" src={noRewardIcon} alt="" />
          </div>
          <div className="w-3/4 transaction-details-points-card-text">
            <span>
              No points were earned on this transaction.
            </span>
            <div role="button" tabIndex={0} onClick={() => showHowToWinPoints()}>
              <span className="underline">
                {'Here\'s how to earn points.'}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function showHowToWinPoints() {
    setState((prevState) => ({ ...prevState, howToWinPoints: !howToWinPoints }));
  }

  return (
    <div className="flex flex-col h-full view-boundaries-bottom">
      {
        howToWinPoints && <div role="button" tabIndex={0} className="terms-overlay" onClick={() => showHowToWinPoints()} />
      }
      <DashboardHeader color={level.level_color} title="Transactions" />
      {
        isLoading
          ? (
            <Loading color={level.level_color} text="Transaction Details" />
          )
          : (
            <div className="flex flex-col w-full transaction-details-container md:w-1/2 self-center sm:px-0">
              {/* Image */}
              <DashboardHeaderImage
                colors={level.level_color}
                title="Receipt"
                imageSource={getTileImage()}
                locationName={transaction.location_name}
                startDate={transaction.date}
                afterRenderImage={renderSurveyLink}
                routeBack="/dashboard/transactions"
              />
              {/* Details */}
              <div className="h-full dashboard-grey-background-color">
                <div className="transaction-details">
                  {/* Services and Products */}
                  {transaction.services && renderSaleables('Services', transaction.services, ServicesIcon, 'transaction-details-services-icon')}
                  {(transaction.services && (transaction.products || transaction.packages_and_gifts || transaction.subtotal)) && (
                    <div className="transaction-details-heavy-separator my-4" />
                  )}
                  {transaction.products && renderSaleables('Products', transaction.products, ProductsIcon, 'transaction-details-saleable-icon')}
                  {(transaction.products && (transaction.packages_and_gifts || transaction.subtotal)) && (
                    <div className="transaction-details-heavy-separator my-4" />
                  )}
                  {/* Packages and Gifts */}
                  {transaction.packages_and_gifts && renderPackagesAndGifts(transaction.packages_and_gifts)}
                  {(transaction.packages_and_gifts && transaction.subtotal) && (
                    <div className="transaction-details-heavy-separator my-4" />
                  )}
                  {/* Subtotal */}
                  {transaction.subtotal && renderSubTotal(transaction.subtotal)}
                  {/* Total */}
                  <div className="my-4" style={{ borderBottom: `2px solid ${level.level_color}` }} />
                  {transaction.amount && renderTotal(transaction.amount, transaction.total_payments)}
                  {/* Points Card */}
                  {renderPointsCard(transaction.points_earned, level)}
                </div>
                {howToWinPoints && (
                  <div className="win-points mb-16">
                    <div className="flex flex-col w-full">
                      <div className="flex justify-between w-full mb-3">
                        <span className="how-to-win-points-title" style={{ color: level.level_color }}>How can I earn points?</span>
                        <div role="button" tabIndex={0} onClick={() => showHowToWinPoints()} className="terms-conditions-close">
                          <CloseIcon className="how-to-win-points-close-icon" alt="" style={{ fill: level.level_color }} />
                        </div>
                      </div>
                      <span className="how-to-win-points-text">Earn points against all non-discounted services and gift cards, paid for with cash or credit card.</span>
                    </div>
                  </div>
                )}
              </div>
              {survey && renderPopUpModal()}
            </div>
          )
      }
      <BookNowButton color={level.level_color} />
    </div>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(DashboardTransactionDetails);
