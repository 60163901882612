import { BOOKING_LOG_ID, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = '';

const bookingLogId = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_LOG_ID:
      return action.bookingLogId;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingLogId;
