import { combineReducers } from 'redux';
import logInLogId from './logInLogId';
import userInformation from './userInformation';
import vouchersRecordsPage from './vouchersRecordsPage';
import vouchersRecordsOffset from './vouchersRecordsOffset';
import vouchersRecordsLoadMore from './vouchersRecordsLoadMore';
import voucherStatus from './voucherStatus';
import statusFilter from './statusFilter';
import packages from './packages';
import giftCards from './giftCards';
import errorMessage from './errorMessage';
import notificationUpdates from './notificationUpdates';
import lastSurvey from './lastSurvey';
import pwaInstallation from './pwaInstallation';
import bookingsReducer from './bookings';
import ssoToken from './ssoToken';

const appReducer = combineReducers({
  user: userInformation,
  logInLogId,
  booking: bookingsReducer,
  vouchersRecordsPage,
  vouchersRecordsOffset,
  vouchersRecordsLoadMore,
  voucherStatus,
  statusFilter,
  packages,
  giftCards,
  errorMessage,
  notificationUpdates,
  lastSurvey,
  pwaInstallation,
  ssoToken,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'CLEAN_STORE') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
