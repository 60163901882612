import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../../../assets/css/bookingCardTile.scss';
import '../../../assets/css/shared.scss';

import locationIcon from '../../../assets/icons/location.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import clockIcon from '../../../assets/icons/clock.svg';

import locationTnsDefaultImg from '../../../assets/img/location_tns.jpg';
import locationMarqueeDefaultImg from '../../../assets/img/location_marquee.jpg';

function BookingCardTile({
  id, location, locationCategory, date, hour, backgroundImage, style,
}) {
  const history = useHistory();

  function getTileImage() {
    if (!backgroundImage) {
      if (locationCategory === 'tns') {
        return locationTnsDefaultImg;
      }
      return locationMarqueeDefaultImg;
    }
    return backgroundImage;
  }

  return (
    <div className="booking-card-tile-parent">
      <div className="flex flex-start booking-status-stripe" style={{ backgroundColor: style.color }} />
      <div role="button" tabIndex={0} className="booking-card-tile" onClick={() => history.push(`/dashboard/bookings/${id}`)}>
        <div className="booking-card-details">
          <div className="flex items-end">
            <img className="booking-details-icon" src={locationIcon} alt="" />
            <span className="booking-card-text">{location}</span>
          </div>
          <div className="flex items-end">
            <img className="booking-details-icon" src={calendarIcon} alt="" />
            <span className="booking-card-text">{date}</span>
          </div>
          <div className="flex items-end">
            <img className="booking-details-icon" src={clockIcon} alt="" />
            <span className="booking-card-text">{hour}</span>
          </div>
        </div>
        <div
          className="booking-card-image"
          style={{ backgroundImage: `url(${getTileImage()})` }}
        />
      </div>
    </div>
  );
}

BookingCardTile.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  locationCategory: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  hour: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  style: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }),
};

BookingCardTile.defaultProps = {
  style: { color: '#5D3553', filter: 'var(--filter-primary)' },
  backgroundImage: '',
};

export default BookingCardTile;
