import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as LogoTns } from '../../assets/img/logo_tns_black.svg';
import { ReactComponent as LogoMarquee } from '../../assets/img/logo_marquee_black.svg';

/**
 * @param {locationCategory} string location category
 * @param {styleClass} string css class name containing the styles for the logo
 * @param {style} string if the filter must be dynamically set on the logos
 * @returns LocationLogo component
 */
function LocationLogo({ locationCategory, styleClass, style }) {
  const renderLogo = (category) => {
    if (category === 'tns') {
      return <LogoTns className={`${styleClass}`} style={style} alt="TNS Logo" />;
    }
    return <LogoMarquee className={`${styleClass}`} style={style} alt="Marquee Logo" />;
  };

  return (
    <div className={`${styleClass}-container`}>
      {
        locationCategory ? (
          renderLogo(locationCategory)
        ) : (
          <div className="flex ml-2">
            <LogoTns className={`${styleClass}-both`} style={style} alt="TNS Logo" />
            &nbsp;
            <LogoMarquee className={`${styleClass}-both`} style={style} alt="Marquee Logo" />
          </div>
        )
      }
    </div>
  );
}

export default LocationLogo;

LocationLogo.propTypes = {
  locationCategory: PropTypes.string,
  styleClass: PropTypes.string.isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
    filter: PropTypes.string,
  }),
};

LocationLogo.defaultProps = {
  locationCategory: '',
  style: {
    color: '',
    filter: '',
  },
};
