/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { toast } from 'react-toastify';
import { compose } from 'redux';

import { useSelector } from 'react-redux';
import axios from '../../interceptor';

import BookNowButton from '../Shared/BookNowButton';
import DashboardHeader from './DashboardHeader';
import SubTitle from '../Shared/SubTitle';

import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg';

import beyoutyBonusImage from '../../assets/img/beyouty_bonus_no_tns.svg';
import loyaltyBg from '../../assets/img/loyalty_bg.svg';

import { ReactComponent as DownArrow } from '../../assets/icons/chevron/left.svg';
import loyaltyLevelsIcon from '../../assets/icons/toasts/loyalty.svg';

import { CustomToast } from '../Shared/withToast';
import withUserRetriever from '../Shared/withUserRetriever';
import withNotificationPopUp from '../Shared/withNotificationPopUp';

import '../../assets/css/dashboard/loyaltyProgramLearnMore.scss';
import 'swiper/css';
import Loading from '../Loading';

function LoyaltyProgramLearnMore() {
  const [state, setState] = useState({
    levels: '',
    loyaltyTitle: 'Loyalty Program',
    currentIndex: 0,
    isLoading: true,
    terms: {
      show: false,
      level: '',
      levelName: '',
      text: '',
      color: '',
    },
  });

  const userLevel = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    levels,
    loyaltyTitle,
    currentIndex,
    isLoading,
    terms,
  } = state;

  const componentStyle = {
    color: userLevel.level_color,
    // when changing opacity change also const name
    colorOpacity90: userLevel.level_color.concat('E6'), // add 2 bytes for transparency code
  };

  const subTitleStyle = {
    color: 'white',
  };

  useEffect(() => {
    axios.get('/api/v1/loyalty_levels/all')
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          levels: res.data,
          currentIndex: res.data.findIndex((l) => l.name === userLevel.name),
          terms: setTermsAndConditions(userLevel),
        }));
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={loyaltyLevelsIcon} text="Loyalty Levels couldn't be retrieved!" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, []);

  function renderHeader() {
    return (
      <div className="loyalty-learn-more-header">
        <div
          className="loyalty-learn-more-header-gradient"
          style={{
            background: `linear-gradient(to top,
                        ${componentStyle.colorOpacity90} 0%,
                        ${componentStyle.color} 100%)`,
          }}
        >
          <div className="loyalty-learn-more-header-bg" style={{ backgroundImage: `url("${loyaltyBg}")` }} />
        </div>
        <div className="loyalty-learn-more-header-content">
          <div className="loyalty-learn-more-header-content-body">
            <SubTitle subTitle="More Info" route="/loyalty-program" style={subTitleStyle} />
            <img className="w-1/2" src={beyoutyBonusImage} alt="" />

            <div className="loyalty-learn-more-header-content-text">
              <p className="loyalty-learn-more-header-title">
                MORE REWARDS, MORE VALUE
              </p>

              <div className="loyalty-learn-more-header-text">
                <p>Congratulations! You’re part of the region’s most rewarding beauty loyalty program.</p>

                <p>Level up through the tiers, earn points and receive exclusive gifts along the way!</p>
              </div>
            </div>
          </div>
          <div
            className="loyalty-learn-more-header-how"
            role="button"
            tabIndex={0}
            onClick={() => document.getElementById('scrollable').scrollIntoView({ behavior: 'smooth' })}
          >
            <span className="loyalty-learn-more-header-how-text">HOW DOES IT WORK?</span>
            <DownArrow className="loyalty-learn-more-header-icon" style={{ fill: 'white', stroke: 'white', strokeWidth: '3px' }} />
          </div>
        </div>
      </div>
    );
  }

  function renderLevelCardsSlider(loyaltyLevels) {
    const settings = {
      slidesPerView: 3,
      centeredSlides: true,
      initialSlide: currentIndex,
      loop: true,
      slideToClickedSlide: true,
      onSlideChange: (core) => {
        const { realIndex } = core;
        setState((prevState) => ({
          ...prevState,
          currentIndex: realIndex,
          terms: setTermsAndConditions(levels[realIndex]),
        }));
      },
    };

    const swiperInactiveSliderStyle = (color, isActive) => ({
      backgroundColor: color,
      transform: isActive ? '' : 'scale(0.75)',
    });

    return (
      <Swiper {...settings}>
        {loyaltyLevels.map((l) => (
          <SwiperSlide key={l.id}>
            {({ isActive }) => (
              <div
                className="loyalty-learn-more-card pb-2"
                style={swiperInactiveSliderStyle(l.level_color, isActive)}
              >
                <img className="loyalty-learn-more-card-logo" src={l.logo} alt="" />
                <div className="loyalty-learn-more-card-text">{l.name}</div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }

  function renderLoyaltyLevelBonus(loyaltyLevelName) {
    switch (loyaltyLevelName) {
      case 'BEYOUTIFUL':
      case 'FABYOULOUS':
        return 'Enjoy 10% off soak-offs';
      case 'SPECTACYOULAR':
      case 'STYOUPENDOUS':
        return 'Enjoy 50% off soak-offs';
      default:
        return '';
    }
  }

  function renderLoyaltyLevelAward(loyaltyLevelName) {
    switch (loyaltyLevelName) {
      case 'SPECTACYOULAR':
        return 'Receive a complimentary Hair Treatment when you first reach this level';
      case 'STYOUPENDOUS':
        return 'Receive a complimentary Blow-Dry and Hair Treatment when you first reach this level';
      default:
        return '';
    }
  }

  function setTermsAndConditions(level) {
    return {
      ...terms,
      level: level.id,
      levelName: level.name,
      text: level.terms_and_conditions,
      color: level.level_color,
    };
  }

  function showTermsAndConditions() {
    setState((prevState) => ({
      ...prevState,
      terms: {
        ...terms,
        show: !terms.show,
      },
    }));
  }

  function renderTermsAndConditions(termsData) {
    return (
      <div className={`loyalty-learn-more-terms-conditions ${termsData.show ? '' : 'hidden'}`}>
        <>
          <div className="loyalty-learn-more-terms-conditions-header">
            <span style={{ color: termsData.color }} className="loyalty-learn-more-terms-conditions-title">
              Additional terms and conditions
            </span>
            <div
              role="button"
              tabIndex={0}
              onClick={() => setState((prevState) => ({ ...prevState, terms: { ...termsData, show: false } }))}
            >
              <CloseIcon className="loyalty-learn-more-terms-conditions-close-icon" fill={termsData.color} />
            </div>
          </div>
        </>
        <div className="loyalty-learn-more-content-text mb-10 view-boundaries-bottom">
          <p>
            Points are awarded against all spend on
            non-discounted services and gift cards.
          </p>

          <p>
            Points expire 365 days from the date they are issued.
            Points and Vouchers cannot be redeemed during freeze periods: 10 days prior to Eid Al Adha &amp; Eid Al Fitr;
            <span>
              {' '}
              March 15
              <sup>th </sup>
              {' '}
              to 31
              <sup>st </sup>
              {' '}
              ; December 15
              <sup>th </sup>
              {' '}
              to 31
              <sup>st </sup>
              {' '}
              .
            </span>
          </p>

          <p>Points and vouchers cannot be redeemed against discounted services.</p>

          <p>Points cannot be redeemed against retail products.</p>
        </div>
      </div>

    );
  }

  const currentLevel = levels && levels[currentIndex];

  const pointsRule = (level) => {
    let pointPercentage = 40;
    if (level.id === 2) {
      pointPercentage = 60;
    }
    if (level.id === 4) {
      pointPercentage = 80;
    }
    return pointPercentage;
  };

  function renderPointsRule(level) {
    const percentage = pointsRule(level);
    return `Points usage will be capped at ${percentage}% of the total bill`;
  }

  return (
    <div className="flex flex-col h-full dashboard-grey-background-color">
      <DashboardHeader color={componentStyle.color} title={loyaltyTitle} />
      <div className="w-full loyalty-learn-more-content-container md:w-1/2 self-center sm:px-0">
        {
          renderHeader()
        }
        {
          isLoading
            ? (
              <Loading color={componentStyle.color} text="Levels" />
            ) : (
              <>
                <div id="scrollable" className="text-center loyalty-learn-more-content-title my-4" style={{ color: componentStyle.color }}>
                  Tier Levels
                </div>
                {
                  renderLevelCardsSlider(levels)
                }
                {
                  currentLevel && (
                    <div className="loyalty-learn-more-content-level">
                      <div className="loyalty-learn-more-content-level-title" style={{ color: currentLevel.level_color }}>
                        {`LEVEL ${currentIndex + 1}`}
                        <br />
                        {currentLevel.name}
                      </div>
                      <div className="loyalty-learn-more-content-text">
                        {
                          currentLevel.to_upgrade.value === '0'
                            ? 'No minimum spend required'
                            : `Spend ${currentLevel.to_upgrade.currency_iso} ${currentLevel.to_upgrade.value}
                            within ${Math.round(currentLevel.points_expiration / 30)} months to qualify`
                        }

                        <div className="separator-line-bold mt-4 mb-2" />

                        <p>{`Earn ${parseInt(currentLevel.percentage, 10)} points for every 100 Dhs spent at TNS & Marquee`}</p>
                        <p>
                          Redeem your points from Monday to Thursday against all services at TNS and Specialist treatments at Marquee
                        </p>
                        <p>{renderLoyaltyLevelBonus(currentLevel.name)}</p>
                        <p>{renderPointsRule(currentLevel)}</p>
                        <p>{renderLoyaltyLevelAward(currentLevel.name)}</p>
                      </div>
                      <div className="w-full loyalty-learn-more-content-text" style={{ color: currentLevel.level_color }}>
                        <span>Press </span>
                        <span
                          role="button"
                          tabIndex={0}
                          onClick={() => showTermsAndConditions()}
                          className="loyalty-learn-more-content-terms"
                        >
                          here
                        </span>
                        <span> to read additional terms and conditions</span>
                      </div>
                    </div>
                  )
                }
              </>
            )
        }
      </div>
      {
        renderTermsAndConditions(terms)
      }
      <BookNowButton color={componentStyle.color} />
    </div>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(LoyaltyProgramLearnMore);
