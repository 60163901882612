import React from 'react';
import PropTypes from 'prop-types';

import '../../assets/css/shared/toggle-switch.scss';

/**
 * Toggle switch component
 *
 * @param {String} activeColor the color of the switch when the status is "on"
 * @param {String} inactiveColor the color of the switch when the status is "off"
 * @param {Function} onChange callback to call when the status changes
 * @param {Boolean} isActive controls whether or not the switch is on
 * @returns
 */
const ToggleSwitch = ({
  activeColor,
  inactiveColor,
  onChange,
  isActive,
  isDisabled,
}) => (
  <div className="toggle-switch">
    <input
      className="toggle-switch-input"
      type="checkbox"
      onChange={() => !isDisabled && onChange(!isActive)}
      checked={isActive}
      disabled={isDisabled}
    />
    <div
      className="toggle-switch-bg"
      style={{
        backgroundColor: isActive ? activeColor : inactiveColor,
      }}
    />
    <div className="toggle-switch-switch" />
  </div>
);

ToggleSwitch.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  activeColor: PropTypes.string.isRequired,
  inactiveColor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  isDisabled: false,
};

export default ToggleSwitch;
