/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from '../../interceptor';

import DashboardHeader from './DashboardHeader';
import VoucherCardTile from '../Shared/CardTiles/VoucherCardTile';
import PackageCardTile from '../Shared/CardTiles/PackageCardTile';
import GiftCardTile from '../Shared/CardTiles/GiftCardTile';
import BookNowButton from '../Shared/BookNowButton';
import DashboardComponentHeader from '../Shared/DashboardComponentHeader';
import ColoredLinearProgress from '../Shared/ColoredLinearProgress';
import { convertDate, dateDifferenceInDays } from '../../assets/js/constants';

import 'swiper/css';
import 'swiper/css/pagination';
import '../../assets/css/dashboard/dashboardWallet.scss';

import vouchersIcon from '../../assets/icons/toasts/vouchers.svg';
import packagesIcon from '../../assets/icons/toasts/packages.svg';
import giftCardsIcon from '../../assets/icons/toasts/giftcards.svg';
import stampCardsIcon from '../../assets/icons/toasts/stamps.svg';
import rightIcon from '../../assets/icons/chevron/right.svg';

import withUserRetriever from '../Shared/withUserRetriever';
import { CustomToast } from '../Shared/withToast';
import withNotificationPopUp from '../Shared/withNotificationPopUp';

import Loading from '../Loading';
import StampCardTile from '../Shared/CardTiles/StampCardTile';
import StampCards from '../../api/StampCards';

function DashboardWallet() {
  const history = useHistory();

  const [state, setState] = useState({
    walletTitle: 'Your Wallet',
    vouchers: '',
    stampCards: '',
    giftCards: '',
    packages: '',
    stamps: '',
    sliderRecords: 3,
    isLoadingVouchers: true,
    isLoadingStampCards: true,
    isLoadingPackages: true,
    isLoadingGiftCards: true,
  });

  const user = useSelector((reduxState) => reduxState.user);
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    walletTitle,
    vouchers,
    stampCards,
    packages,
    giftCards,
    sliderRecords,
    isLoadingVouchers,
    isLoadingStampCards,
    isLoadingPackages,
    isLoadingGiftCards,
  } = state;

  const componentStyle = {
    color: level.level_color,
    bgGradient: `linear-gradient(to bottom right,
      ${level.level_color.concat('E6')} 0%,
      ${level.level_color} 100%)`,
  };

  const { list: getStampCards } = StampCards();

  useEffect(() => {
    // vouchers
    axios.get('/api/v1/vouchers', {
      params: { max_records_per_page: sliderRecords },
    })
      .then((res) => setState((prevState) => ({ ...prevState, vouchers: res.data.active })))
      .catch(() => {
        toast(<CustomToast type="error" icon={vouchersIcon} text="Vouchers couldn't be retrieved!" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoadingVouchers: false }));
      });
    // stamp cards
    getStampCards({ max_records: sliderRecords })
      .then((res) => setState((prevState) => ({ ...prevState, stampCards: res.data.active })))
      .catch(() => {
        toast(<CustomToast type="error" icon={stampCardsIcon} text="Stamp Cards couldn't be retrieved!" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoadingStampCards: false }));
      });
    // packages
    axios.get('/api/v1/package_items', {
      params: { max_records: sliderRecords },
    })
      .then((res) => setState((prevState) => ({ ...prevState, packages: res.data.active })))
      .catch(() => {
        toast(<CustomToast type="error" icon={packagesIcon} text="Package Items couldn't be retrieved!" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoadingPackages: false }));
      });
    // gift cards
    axios.get('/api/v1/gift_certificates', {
      params: { max_records: sliderRecords },
    })
      .then((res) => setState((prevState) => ({ ...prevState, giftCards: res.data.active })))
      .catch(() => {
        toast(<CustomToast type="error" icon={giftCardsIcon} text="Gift Cards couldn't be retrieved!" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoadingGiftCards: false }));
      });
  }, []);

  function swiperSettings() {
    return {
      slidesPerView: 1.15,
      centeredSlides: true,
      spaceBetween: 20,
    };
  }

  function renderVouchers(vouchersData) {
    if (!vouchersData || vouchersData.length === 0) {
      return (<span className="no-items">No active vouchers available</span>);
    }

    const settings = swiperSettings();

    return (
      <Swiper {...settings}>
        {
          vouchersData.map((voucherData) => (
            <SwiperSlide key={voucherData.customer_voucher_id}>
              <VoucherCardTile
                id={voucherData.id}
                state={voucherData.status}
                locationCategory={voucherData.location_category}
                img={voucherData.image}
                textColor={voucherData.text_color}
                date={new Date(voucherData.end_date)}
                name={voucherData.short_name}
                style={componentStyle}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>
    );
  }

  function renderStampCards(stampCardsData) {
    if (!stampCardsData || stampCardsData.length === 0) {
      return (<span className="no-items">No active stamp cards available</span>);
    }

    const settings = swiperSettings();

    return (
      <Swiper {...settings}>
        {
          stampCardsData.map((stampCardData) => (
            <SwiperSlide key={stampCardData.id}>
              <div className="pt-2">
                <StampCardTile
                  maxStamps={stampCardData.max_ticks + 1}
                  stamps={stampCardData.current_ticks}
                  expirationDate={stampCardData.end_date}
                  description={stampCardData.description}
                  imageUrl={stampCardData.image}
                  state={stampCardData.state}
                  levelId={level.id}
                  color={componentStyle.color}
                  id={stampCardData.id}
                  awardable={stampCardData.awardable}
                />
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    );
  }

  function renderPackages(packagesData) {
    if (!packagesData || packagesData.length === 0) {
      return (<span className="no-items">No packages available</span>);
    }

    const settings = swiperSettings();

    return (
      <Swiper {...settings}>
        {
          packagesData.map((packageData) => (
            <SwiperSlide key={packageData.id}>
              <PackageCardTile
                locationCategory={packageData.location_category}
                status={packageData.status}
                photo={packageData.display_photo}
                remaining={packageData.remaining_quantity}
                service={packageData.description}
                date={packageData.expires_on}
                style={componentStyle}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>
    );
  }

  function renderGiftCards(giftCardsData) {
    if (!giftCardsData || giftCardsData.length === 0) {
      return (<span className="no-items">No gift cards available</span>);
    }

    const settings = swiperSettings();

    return (
      <Swiper {...settings}>
        {
          giftCardsData.map((giftCardData) => (
            <SwiperSlide key={giftCardData.id}>
              <GiftCardTile
                locationCategory={giftCardData.location_category}
                status={giftCardData.status}
                photo={giftCardData.display_photo}
                remaining={giftCardData.remaining_amount}
                date={giftCardData.expiration_date}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>
    );
  }

  /**
   * Calculate how close the user is from having is points expire.
   * Start date is the beginning of the customer loyalty level and
   * the end date is the expiring_points date.
   *
   * @returns a normalised value between 0 and 100 for the time remaining until points expire.
   */
  function calculateBarProgress() {
    if (!user.expiring_points) {
      return 0;
    }

    const startDate = new Date(user.customer_loyalty_level.start_date);
    const currentDate = new Date();
    const endDate = new Date(user.expiring_points[0]);

    return (dateDifferenceInDays(startDate, currentDate) / dateDifferenceInDays(startDate, endDate)) * 100;
  }

  function renderWalletHeaderCard() {
    return (
      <div>
        <div className="dashboard-wallet-img-container mb-6 md:w-1/2 sm:px-0">
          <div className="primary-card dashboard-wallet-img mt-5">
            <div className="dashboard-wallet-img-content">
              <div className="w-full absolute top-0">
                <div className="flex justify-between p-4">
                  <div>
                    <div className="flex flex-col">
                      <span className="dashboard-wallet-img-content-title">
                        BE-YOU-TY POINTS
                      </span>
                      <div
                        role="button"
                        tabIndex={0}
                        className="flex flex-start items-center cursor-pointer"
                        onClick={() => history.push('/loyalty-program-learn-more')}
                      >
                        <span className="dashboard-wallet-img-content-opacity-text">
                          Learn more
                        </span>
                        <img className="dashboard-wallet-right-icon" src={rightIcon} alt="" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col">
                      <span className="dashboard-wallet-img-content-opacity-text">
                        Current Points
                      </span>
                      <span className="dashboard-wallet-img-content-title">
                        {`${user.points === null ? 0 : user.points} Points`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full absolute bottom-0">
                <div className="flex flex-col">
                  <div className="mb-2">
                    <ColoredLinearProgress color={componentStyle.color} value={user.expiring_points ? calculateBarProgress() : 0} />
                  </div>
                  {
                    user.expiring_points && (
                      <div>
                        <div className="flex items-center justify-between">
                          <span className="dashboard-wallet-img-content-opacity-caption">
                            {`Expiring on ${convertDate(new Date(user.expiring_points[0]), '/', true)}`}
                          </span>
                          <span className="dashboard-wallet-img-content-caption">
                            {`
                              ${user.expiring_points && user.expiring_points.length === 2 ? user.expiring_points[1] : 0}
                              Points
                            `}
                          </span>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full dashboard-grey-background-color view-boundaries-bottom">
      <DashboardHeader title={walletTitle} color={componentStyle.color} />
      <div className="dashboard-wallet-container md:w-1/2 sm:px-0">
        <div className="dashboard-wallet-bg-content-top" style={{ background: componentStyle.bgGradient }} />
        <div className="dashboard-wallet-bg-content-top-opacity" />
        {
          renderWalletHeaderCard()
        }
        <div className="w-full">
          <div className="dashboard-wallet-section-container">
            <div className="px-6">
              <DashboardComponentHeader title="Your Vouchers" color={componentStyle.color} routeText="See all" route="/dashboard/vouchers" />
            </div>
            {
              isLoadingVouchers
                ? <Loading color={componentStyle.color} text="Vouchers" />
                : renderVouchers(vouchers)
            }
          </div>
          <div className="dashboard-wallet-section-container">
            <div className="px-6">
              <DashboardComponentHeader title="Your Stamp Cards" color={componentStyle.color} routeText="See all" route="/dashboard/stampcards" />
            </div>
            {
              isLoadingStampCards
                ? <Loading color={componentStyle.color} text="Stamp Cards" />
                : renderStampCards(stampCards)
            }
          </div>
          <div className="dashboard-wallet-section-container">
            <div className="px-6">
              <DashboardComponentHeader title="Your Packages" color={componentStyle.color} routeText="See all" route="/dashboard/packages" />
            </div>
            {
              isLoadingPackages
                ? <Loading color={componentStyle.color} text="Packages" />
                : renderPackages(packages)
            }
          </div>
          <div className="dashboard-wallet-section-container">
            <div className="px-6">
              <DashboardComponentHeader title="Your Gift Cards" color={componentStyle.color} routeText="See all" route="/dashboard/giftcards" />
            </div>
            {
              isLoadingGiftCards
                ? <Loading color={componentStyle.color} text="Gift Cards" />
                : renderGiftCards(giftCards)
            }
          </div>
        </div>
      </div>
      <BookNowButton color={componentStyle.color} />
    </div>
  );
}

export default compose(
  withUserRetriever,
  withNotificationPopUp,
)(DashboardWallet);
