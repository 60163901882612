/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Select from '../Shared/Select';
import {
  bookingTime,
  bookingAvailableStartingTimes as setBookingAvailableStaringTimes,
  bookingServiceProviders,
  bookingRemoveAllProviders,
  bookingDay,
  bookingAddOns,
  bookingSpecialRequest,
  clearBookingTime,
  resetMovable,
} from '../../actions/bookings';

import {
  getBookingLocation,
  getBookingServices,
  getBookingServiceType,
  getBookingTime,
  getBookingDate,
  getDaysForStartingTimes,
  getStartingTimesForDay,
  getBookingAvailableStartingTimes,
  getBookingServiceProviderPreferences,
  bookingSoakOffHands,
  bookingSoakOffFeet,
  getBookingLogId,
} from '../../selectors/booking';
import BookingTimingOptionsModal from './BookingTimingOptionsModal';
import BookingsAPI from '../../api/Booking';
import '../../assets/css/booking/bookingsStartingTime.scss';
import { CustomToast } from '../Shared/withToast';
import ChooseStartingTimeDate from './ChooseStartingTimeDate';
import Loading from '../Loading';
import { getCurrentLoyaltyLevel } from '../../selectors/user';
import { isTimeoutError } from '../../utils/api';
import { timeoutEndpointLogErrorPayload } from './consts';
import { convertTZ } from '../../assets/js/constants';

function ChooseStartingTimes() {
  const dispatch = useDispatch();
  const services = getBookingServices();
  const bookingLocation = getBookingLocation();
  const bookingServiceType = getBookingServiceType();
  const level = getCurrentLoyaltyLevel();
  const currentBookingDay = getBookingDate();
  const bookingLogId = getBookingLogId();
  const bookingServiceProviderPreferences = getBookingServiceProviderPreferences();
  const currentBookingTime = getBookingTime();
  const startingTimesDays = getBookingAvailableStartingTimes();
  const daysForStartingTimes = getDaysForStartingTimes();
  const wantsSoakOffHands = bookingSoakOffHands();
  const wantsSoakOffFeet = bookingSoakOffFeet();
  const startingTimesForSelection = getStartingTimesForDay(currentBookingDay).map((time) => ({ key: time, value: time }));
  const anyDayWithStartingTimes = Object.keys(startingTimesDays).some((key) => startingTimesDays[key].length > 0);

  const [isLoadingStartingTimes, setIsLoadingStartingTimes] = useState(false);

  const { getAvailableStartingTimes, saveLogError } = BookingsAPI();

  const onChangeStartingTime = ({ item }) => {
    // Reset the service providers because after re-selecting the new starting time, then we need to call the
    // availability service again to fetch the new service providers
    dispatch(bookingServiceProviders(''));
    dispatch(bookingTime(item.value));
    dispatch(bookingAddOns([]));
  };

  const changeBookingDayWithoutChangingOtherConfigs = (day) => {
    dispatch(bookingDay(day));
    dispatch(clearBookingTime());
  };

  const onChangeBookingDate = (bookingDate) => {
    // Reset other required data
    dispatch(bookingAddOns([]));
    dispatch(resetMovable());
    dispatch(bookingSpecialRequest(''));
    setIsLoadingStartingTimes(true);

    // Reset the selected booking time since they will change now, so the customer needs to select a new one from the
    // new set of options
    dispatch(bookingTime(''));

    // Reset the service providers because after re-selecting the new starting time, then we need to call the
    // availability service again to fetch the new service providers
    dispatch(bookingRemoveAllProviders());

    let workingServices = [];

    // TODO: Temporary fix while in-room and styling stations screen is not migrated to new designs
    if (bookingServiceType.name === 'In-chair services') {
      workingServices = services.flatMap((category) => category.subCategories);
    }
    const servs = workingServices.map((c) => ({ services: c.services.filter((s) => s.selected) })).filter((c) => c.services.length > 0);
    const servicesForApi = [].concat(...servs.map((category) => category.services.map((service) => service.service_id)));

    getAvailableStartingTimes({
      bookingDate,
      bookingLocationId: bookingLocation.id,
      services: servicesForApi,
      soakOffHands: wantsSoakOffHands,
      soakOffFeet: wantsSoakOffFeet,
      serviceTypeId: bookingServiceType.id,
      serviceProviderPreferences: bookingServiceProviderPreferences,
    })
      .then((resp) => {
        dispatch(setBookingAvailableStaringTimes(resp.data));
        dispatch(bookingAddOns([]));
      })
      .catch((error) => {
        if (isTimeoutError(error)) {
          saveLogError(timeoutEndpointLogErrorPayload({ bookingLogId, error }));
        }
        toast(<CustomToast type="error" text="No available starting times for this date, please try to change the date" />);
      })
      .finally(() => {
        setIsLoadingStartingTimes(false);
      });
  };

  const color = level.level_color;

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full h-full">
      {isLoadingStartingTimes && <Loading color={color} backgroundColor="white" text="Available starting times" />}
      {!isLoadingStartingTimes && (
        <>
          <BookingTimingOptionsModal onChangeDate={onChangeBookingDate} />
          <div className="separator" />
          {daysForStartingTimes.length > 0 && anyDayWithStartingTimes && (
            <ChooseStartingTimeDate
              data={startingTimesDays}
              initialDate={convertTZ(new Date(currentBookingDay))}
              onChange={changeBookingDayWithoutChangingOtherConfigs}
            />
          )}

          {startingTimesForSelection.length > 0 && (
            <Select
              onChange={onChangeStartingTime}
              collection={startingTimesForSelection}
              value={currentBookingTime}
              placeholder="Starting time"
              prompt="Starting time"
              classNames={{
                container: 'bookings-starting-time',
              }}
              startOpened
            />
          )}
          {anyDayWithStartingTimes && startingTimesForSelection.length === 0 && (
            <div className="bookings-starting-time-no-availability" style={{ color }}>No available times for this day, please choose another day.</div>
          )}
          {!anyDayWithStartingTimes && (
            <div className="bookings-starting-time-no-availability" style={{ color }}>
              No date available to perform the services selected, with the selected service providers. Please choose another date or other service provider(s).
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ChooseStartingTimes;
