// The API booking log types
export const BOOKING_LOG_TYPES = {
  LOCATION: 0,
  DATE: 1,
  SERVICE_TYPE: 2,
  SERVICES: 3,
  PROVIDERS: 4,
  PRE_BOOK: 5,
  SPECIAL_REQUEST: 6,
  CONFIRMED: 7,
  BOOKED: 8,
  APPOINTMENT_NOT_FOUND: 9,
  BOOKING_TIMEOUT: 17,
  ADD_ONS: 18,
  PROVIDER_PREFERENCES: 19,
  STARTING_TIME: 20,
  DELETE_PRE_BOOK: 22,
};

export const USER_SESSION_LOG_TYPES = {
  LOG_IN: 10,
  REQUEST_CHANGE_PASSWORD: 11,
  CLICKED_CHANGE_PASSWORD_EMAIL: 12,
  PASSWORD_CHANGED: 13,
  REQUEST_ACTIVATION: 14,
  CLICKED_ACTIVATION_EMAIL: 15,
  USER_ACTIVATED: 16,
};

/**
 * Extract the error message from the response. Should be used only
 * for the API's custom error handling.
 *
 * @param {Error} error The response inside the catch of the promise.
 * @param {Integer} index The index of the error message. Will usually be the first one.
 * @returns The detail of the error message from the API's custom error response.
 */
export const getApiCustomErrorMessage = (error, index = 0) => error.response.data.errors[index].detail;

/**
 *
 * @param {Error} error the error
 * @returns true if the error code represents a timeout
 */
export const isTimeoutError = (error) => error.code && error.code === 'ECONNABORTED';
