import React from 'react';
import PropTypes from 'prop-types';

import '../../assets/css/shared/pagination.scss';

import { ReactComponent as FirstPageIcon } from '../../assets/icons/navigation/angles-left-solid.svg';
import { ReactComponent as PreviousPageIcon } from '../../assets/icons/navigation/angle-left-solid.svg';
import { ReactComponent as NextPageIcon } from '../../assets/icons/navigation/angle-right-solid.svg';
import { ReactComponent as LastPageIcon } from '../../assets/icons/navigation/angles-right-solid.svg';

function Pagination({
  currentPage, totalPages, setCurrentPage, color,
}) {
  function previousPage() {
    if (currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  function firstPage() {
    if (currentPage - 1 > 0) {
      setCurrentPage(1);
    }
  }

  function nextPage() {
    if (currentPage + 1 <= totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }

  function lastPage() {
    if (currentPage + 1 < totalPages) {
      setCurrentPage(totalPages);
    }
  }

  function goToPage(page) {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  }

  const pageNumberColor = { borderColor: color, color };

  const selectedPageNumberColor = { borderColor: color, backgroundColor: color, color: 'white' };

  return (
    <div className="flex pagination-container w-full mt-4 mb-2">
      {/* first page button << */}
      <div className="pagination-button-container">
        <button
          type="button"
          onClick={() => firstPage()}
          style={{
            visibility: currentPage - 1 > 1 ? 'visible' : 'hidden',
            borderColor: color,
          }}
          disabled={!(currentPage - 1 > 1)}
          className="pagination-button"
        >
          <FirstPageIcon className="pagination-nav-button" fill={color} />
        </button>
      </div>
      {/* previous page button <  */}
      <div className="pagination-button-container">
        <button
          type="button"
          onClick={() => previousPage()}
          style={{
            visibility: currentPage > 1 ? 'visible' : 'hidden',
            borderColor: color,
          }}
          disabled={!(currentPage > 1)}
          className="pagination-button"
        >
          <PreviousPageIcon className="pagination-nav-button" fill={color} />
        </button>
      </div>
      {/* static 5th to last page  */}
      {
        totalPages > 4 && currentPage >= totalPages - 1 && (
          <div className="pagination-button-container">
            <button type="button" onClick={() => goToPage(totalPages - 4)} className="pagination-button" style={pageNumberColor}>
              <span className="center-page-nr">{totalPages - 4}</span>
            </button>
          </div>
        )
      }
      {/* static 4th to last page  */}
      {
        totalPages > 3 && currentPage === totalPages && (
          <div className="pagination-button-container">
            <button type="button" onClick={() => goToPage(totalPages - 3)} className="pagination-button" style={pageNumberColor}>
              <span className="center-page-nr">{totalPages - 3}</span>
            </button>
          </div>
        )
      }
      {/* currentPage - 2 page  */}
      {
          currentPage - 2 > 0 && (
          <div className="pagination-button-container">
            <button type="button" onClick={() => goToPage(currentPage - 2)} className="pagination-button" style={pageNumberColor}>
              <span className="center-page-nr">{currentPage - 2}</span>
            </button>
          </div>
          )
      }
      {/* currentPage - 1 page  */}
      {
        currentPage - 1 > 0 && (
          <div className="pagination-button-container">
            <button type="button" onClick={() => goToPage(currentPage - 1)} className="pagination-button" style={pageNumberColor}>
              <span className="center-page-nr">{currentPage - 1}</span>
            </button>
          </div>
        )
      }
      {/* currentPage */}
      <div className="pagination-button-container">
        <button type="button" className="pagination-button" style={selectedPageNumberColor}>
          <span className="center-page-nr">{currentPage}</span>
        </button>
      </div>
      {/* currentPage + 1 */}
      {
        currentPage < totalPages && (
          <div className="pagination-button-container">
            <button type="button" onClick={() => goToPage(currentPage + 1)} className="pagination-button" style={pageNumberColor}>
              <span className="center-page-nr">{currentPage + 1}</span>
            </button>
          </div>
        )
      }
      {/* currentPage + 2 */}
      {
        currentPage + 1 < totalPages && (
          <div className="pagination-button-container">
            <button type="button" onClick={() => goToPage(currentPage + 2)} className="pagination-button" style={pageNumberColor}>
              <span className="center-page-nr">{currentPage + 2}</span>
            </button>
          </div>
        )
      }
      {/* static 4th page */}
      {
        totalPages > 3 && currentPage <= 1 && (
          <div className="pagination-button-container">
            <button type="button" onClick={() => goToPage(4)} className="pagination-button" style={pageNumberColor}>
              <span className="center-page-nr">4</span>
            </button>
          </div>
        )
      }
      {/* static 5th page */}
      {
        totalPages > 4 && currentPage <= 2 && (
          <div className="pagination-button-container">
            <button type="button" onClick={() => goToPage(5)} className="pagination-button" style={pageNumberColor}>
              <span className="center-page-nr">5</span>
            </button>
          </div>
        )
      }
      {/* next page button > */}
      <div className="pagination-button-container">
        <button
          type="button"
          onClick={() => nextPage()}
          style={{
            visibility: currentPage < totalPages ? 'visible' : 'hidden',
            borderColor: color,
          }}
          disabled={!(currentPage < totalPages)}
          className="pagination-button"
        >
          <NextPageIcon className="pagination-nav-button" fill={color} />
        </button>
      </div>
      {/* last page button >> */}

      <div className="pagination-button-container">
        <button
          type="button"
          onClick={() => lastPage()}
          style={{
            visibility: currentPage + 1 < totalPages ? 'visible' : 'hidden',
            borderColor: color,
          }}
          disabled={!(currentPage + 1 < totalPages)}
          className="pagination-button"
        >
          <LastPageIcon className="pagination-nav-button" fill={color} />
        </button>
      </div>

    </div>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

export default Pagination;
