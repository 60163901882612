import range from 'lodash/range';
import ceil from 'lodash/ceil';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import '../../../assets/css/dashboard/stampCards/stampCardTile.scss';

import { convertDate, dateDifferenceUntilFormat } from '../../../assets/js/constants';
import renderStamp from '../../Dashboard/Stamps/utils';

function StampCardTile({
  maxStamps,
  stamps,
  expirationDate,
  description,
  state,
  imageUrl,
  levelId,
  color,
  awardable,
  id,
}) {
  const componentStyle = {
    color,
    colorDarker: `var(--color-level${levelId}-darker)`,
    colorCompleted: 'var(--color-green)',
    colorExpired: 'var(--color-expired)',
    colorExpiredDarker: 'var(--color-expired-darken)',
  };

  function renderStatus(date) {
    switch (state) {
      case 'completed':
        return (
          <div className="stamp-card-tile-status" style={{ background: componentStyle.colorCompleted }}>
            <div className="stamp-card-tile-status-text">
              STAMP CARD COMPLETED
            </div>
          </div>
        );
      case 'expired':
        return (
          <div className="stamp-card-tile-status" style={{ background: componentStyle.colorExpired }}>
            <div className="stamp-card-tile-status-text">
              STAMP CARD EXPIRED
            </div>
          </div>
        );
      default:
        return (
          <div className="stamp-card-tile-status" style={{ background: componentStyle.colorDarker }}>
            <div className="stamp-card-tile-status-text">
              {`EXPIRES ${dateDifferenceUntilFormat(date)}`}
            </div>
          </div>
        );
    }
  }

  const stampBodyColors = {
    background: state === 'expired' ? componentStyle.colorExpired : componentStyle.color,
    borderColor: state === 'expired' ? componentStyle.colorExpiredDarker : componentStyle.colorDarker,
  };

  const history = useHistory();

  const handleAwardAction = () => history.push(awardable);
  const handleButtonAction = () => history.push(`/dashboard/stampcards/${id}`);

  return (
    <div className="stamp-card-tile-container">
      <div
        className="stamp-card-tile-body"
        role="button"
        tabIndex={0}
        onClick={handleButtonAction}
        style={stampBodyColors}
      >
        <img className="stamp-card-tile-image" src={imageUrl} alt="" style={{ filter: state === 'expired' ? 'grayscale(100%)' : 'none' }} />
        {renderStatus(expirationDate)}
        <ul
          className="stamp-card-tile-stamps-container"
          style={{
            width: `${maxStamps * 10.5}%`,
            gridTemplateColumns: `repeat(auto-fit, minmax(${100 / ceil(maxStamps / 2)}%, 1fr))`,
          }}
        >
          {range(maxStamps).map((i) => renderStamp(
            i,
            maxStamps,
            stamps,
            handleAwardAction,
            'stamp-card-tile-circle',
            state === 'expired' ? componentStyle.colorExpiredDarker : componentStyle.colorDarker,
          ))}
        </ul>
      </div>
      {
        description && (
          <>
            <div className="stamp-card-tile-condition">
              <div className="stamp-card-tile-condition-text" style={{ color: state === 'expired' ? componentStyle.colorExpired : componentStyle.colorDarker }}>
                {description}
              </div>
            </div>
            {state !== 'expired' && (
              <div className="stamp-card-tile-use-by">
                <div className="stamp-card-tile-use-by-text">
                  {`Use by: ${convertDate(new Date(expirationDate), '', true)}`}
                </div>
              </div>
            )}
          </>
        )
      }

    </div>
  );
}

StampCardTile.propTypes = {
  maxStamps: PropTypes.number.isRequired,
  stamps: PropTypes.arrayOf(PropTypes.shape),
  expirationDate: PropTypes.string,
  description: PropTypes.string,
  state: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  levelId: PropTypes.number,
  awardable: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
};

StampCardTile.defaultProps = {
  expirationDate: '',
  description: '',
  imageUrl: '',
  levelId: 1,
  awardable: '',
  color: 'var(--color-primary)',
  stamps: [],
};

export default React.memo(StampCardTile);
