export const userInformation = () => ({
  type: 'USER_INFORMATION',
});

export const logIn = () => ({
  type: 'LOGGED_IN',
});

export const notificationUpdates = (notifications) => ({
  type: 'NOTIFICATION_UPDATES',
  notifications,
});

export const lastSurvey = (survey, seen) => ({
  type: 'LAST_SURVEY',
  survey,
  seen,
});

export const logInLogId = (logInLog) => ({
  type: 'LOG_IN_LOG_ID',
  logInLog,
});

export const packages = (packageItems) => ({
  type: 'PACKAGES',
  packageItems,
});

export const giftCards = (giftCardsItems) => ({
  type: 'GIFT_CARDS',
  giftCardsItems,
});

export const vouchersRecordsPage = (page) => ({
  type: 'VOUCHERS_RECORDS_PAGE',
  page,
});

export const vouchersRecordsOffset = (offset) => ({
  type: 'VOUCHERS_RECORDS_OFFSET',
  offset,
});

export const vouchersRecordsLoadMore = (loadMore) => ({
  type: 'VOUCHERS_RECORDS_LOAD_MORE',
  loadMore,
});

export const voucherStatus = (status) => ({
  type: 'VOUCHER_STATUS',
  status,
});

export const errorMessage = (message) => ({
  type: 'ERROR_MESSAGE',
  message,
});

export const SHOW_PWA_INSTALLATION_MODAL = 'SHOW_PWA_INSTALLATION_MODAL';
export const HIDE_PWA_INSTALLATION_MODAL = 'HIDE_PWA_INSTALLATION_MODAL';

export const showPwaInstructionsModal = () => ({
  type: SHOW_PWA_INSTALLATION_MODAL,
});

export const hidePwaInstructionsModal = () => ({
  type: HIDE_PWA_INSTALLATION_MODAL,
});
