import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import errorImage from '../assets/img/page_not_found.png';

import '../assets/css/errorPage.scss';
import { DEFAULT_LEVEL_COLOR } from '../assets/js/constants';

function ErrorPage({ errorTitle, errorMessage }) {
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);
  const color = (level && level.level_color) || DEFAULT_LEVEL_COLOR;

  return (
    <div className="error-page-container">
      <div
        style={{
          background: `linear-gradient(to bottom, ${color.concat('CC')} 0%, ${color} 100%)`,
          zIndex: -2,
          height: '100%',
        }}
      >
        <div className="w-full">
          <div className="absolute error-page-bg-circle-big" />
          <div className="absolute error-page-bg-circle-small float-center" />
        </div>
        <div className="w-full">
          <div className="error-page-header-content">
            <div className="error-page-title">{errorTitle}</div>
            <div className="error-page-problem">{errorMessage}</div>
            <div className="error-page-customer-info p-2 mt-4" style={{ backgroundColor: color }}>
              Please refresh the page.
              <br />
              If the problem persists check back later.
              <br />
              <div className="error-reload-button" style={{ color }} role="button" tabIndex="0" onClick={() => window.location.reload()}>
                Reload page
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <img className="error-page-img" src={errorImage} alt="" />
        </div>
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string,
};

ErrorPage.defaultProps = {
  errorTitle: 'Request Error',
  errorMessage: 'Something went wrong.',
};

export default ErrorPage;
