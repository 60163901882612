const vouchersRecordsPage = (state = '', action) => {
  switch (action.type) {
    case 'VOUCHERS_RECORDS_PAGE':
      return action.page;
    default:
      return state;
  }
};

export default vouchersRecordsPage;
