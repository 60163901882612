import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../../../assets/css/transactionCardTile.scss';

import locationIcon from '../../../assets/icons/location.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import transactionIcon from '../../../assets/icons/transaction.svg';

import locationTnsDefaultImg from '../../../assets/img/location_tns.jpg';
import locationMarqueeDefaultImg from '../../../assets/img/location_marquee.jpg';

function TransactionCardTile({
  id, location, locationCategory, date, amount, backgroundImage, canAnswer, color,
}) {
  const history = useHistory();

  function getTileImage() {
    if (!backgroundImage) {
      if (locationCategory === 'tns') {
        return locationTnsDefaultImg;
      }
      return locationMarqueeDefaultImg;
    }
    return backgroundImage;
  }

  return (
    <div role="button" tabIndex={0} onClick={() => history.push(`/dashboard/transactions/${id}`)} className="transaction-card-tile">
      <div className="transaction-stripe" style={{ backgroundColor: canAnswer ? 'var(--color-yellow)' : color }} />
      <div className="transaction-card-details">
        <div className="flex items-end">
          <img className="transaction-details-icon" src={locationIcon} alt="" />
          <span>{location}</span>
        </div>
        <div className="flex items-end">
          <img className="transaction-details-icon" src={calendarIcon} alt="" />
          <span>{date}</span>
        </div>
        <div className="flex items-end">
          <img className="transaction-details-icon" src={transactionIcon} alt="" />
          <span>{`AED ${amount.value}`}</span>
        </div>
      </div>
      <div
        className="transaction-card-image"
        style={{ backgroundImage: `url(${getTileImage()})` }}
      />
    </div>
  );
}

TransactionCardTile.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  locationCategory: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  canAnswer: PropTypes.bool.isRequired,
  amount: PropTypes.shape({
    value: PropTypes.string.isRequired,
    currency_iso: PropTypes.string.isRequired,
  }).isRequired,
  backgroundImage: PropTypes.string,
  color: PropTypes.string.isRequired,
};

TransactionCardTile.defaultProps = {
  backgroundImage: '',
};

export default TransactionCardTile;
