import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CustomToast } from './Shared/withToast';
import Client from '../api/Client';

import { DEFAULT_LEVEL_COLOR } from '../assets/js/constants';
import Loading from './Loading';
import LocalStorageService from './LocalStorageService';

function LogOut() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { delete: signOut, postStopImpersonating } = Client();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.is_impersonating) {
      postStopImpersonating()
        .catch(() => {
          toast(<CustomToast type="error" text="Failed to stop impersonation!" />);
        }).finally(() => signOutAndCleanStore());
    } else {
      signOutAndCleanStore();
    }
  }, []);

  function signOutAndCleanStore() {
    signOut().finally(() => {
      LocalStorageService.clear();
      dispatch({ type: 'CLEAN_STORE' });
      history.push('/clients/sign_in');
    });
  }

  return (
    <Loading color={DEFAULT_LEVEL_COLOR} isCover backgroundColor="white" text="Logging out!" />
  );
}

export default LogOut;
