import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import BookingsAPI from '../../api/Booking';
import '../../assets/css/booking/chooseServiceType.scss';
import '../../assets/css/shared.scss';

import { CustomToast } from '../Shared/withToast';
import Loading from '../Loading';
import { ReactComponent as ChairIcon } from '../../assets/icons/brand/in-chair.svg';
import { ReactComponent as RoomIcon } from '../../assets/icons/brand/in-room.svg';

import BookingTimingOptionsModal from './BookingTimingOptionsModal';
import {
  bookingAddOns,
  bookingRemoveAllProviders,
  bookingServiceProviders,
  bookingServices,
  bookingServiceType,
  bookingSpecialRequest,
  clearBookingStartingTimes,
  clearBookingTime,
  resetMovable,
} from '../../actions/bookings';
import {
  getBookingLocation,
  getBookingLogId,
  getBookingServiceType,
} from '../../selectors/booking';
import { getCurrentLevel } from '../../selectors/user';
import { isTimeoutError } from '../../utils/api';
import { timeoutEndpointLogErrorPayload } from './consts';

function ChooseServiceType({ color }) {
  const userCurrentLevel = getCurrentLevel();
  const location = getBookingLocation();
  const [state, setState] = useState({
    serviceType: getBookingServiceType() || '',
    bookingLogId: getBookingLogId() || '',
    serviceTypes: [],
    isLoading: true,
    currentLevel: userCurrentLevel,
  });

  const {
    serviceType, bookingLogId, serviceTypes, currentLevel, isLoading,
  } = state;
  const dispatch = useDispatch();
  const { getServiceTypes, saveLogError } = BookingsAPI();

  useEffect(() => {
    const payload = { locationId: location.id, bookingLogId };
    getServiceTypes(payload)
      .then((res) => {
        setState((prevState) => ({ ...prevState, serviceTypes: res.data.map((st) => ({ ...st, isChecked: st.id === serviceType.id })) }));
      })
      .catch((err) => {
        if (isTimeoutError(err)) {
          saveLogError(timeoutEndpointLogErrorPayload({ bookingLogId, error: err }));
        }

        if (get(err, 'response.response.data')) {
          toast(<CustomToast type="error" text={err.response.data} />);
        }
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, []);

  function changeServiceType(id) {
    serviceTypes.forEach((st) => {
      if (st.id === id) {
        st.isChecked = true;
      } else {
        st.isChecked = false;
      }
    });

    setState((prevState) => ({ ...prevState, serviceTypes }));

    dispatch(clearBookingTime());
    dispatch(clearBookingStartingTimes());
    dispatch(bookingServiceType(serviceTypes.find((st) => st.id === id)));
    dispatch(bookingServices([]));
    dispatch(bookingAddOns([]));
    dispatch(bookingRemoveAllProviders());
    dispatch(bookingServiceProviders(''));
    dispatch(resetMovable());
    dispatch(bookingSpecialRequest(''));
  }

  function renderServiceTypes() {
    const st = serviceTypes.map((s) => (
      <div role="button" tabIndex={0} key={s.id} className={s.isChecked ? `service-type-container-selected-lvl${currentLevel}` : 'service-type-container'} onClick={() => changeServiceType(s.id)}>
        <div className="flex justify-between w-full">
          <div className="flex flex-1">
            {
              s.id === 4
                ? <ChairIcon className="service-type-icon" alt="" style={{ fill: color }} />
                : <RoomIcon className="service-type-icon" alt="" style={{ fill: color }} />
            }
            <div className="service-type-information-container">
              <label className="service-type-name" style={{ color }}>{s.name}</label>
              <label className="service-type-description">{s.description}</label>
            </div>
          </div>
          <div className="flex self-center">
            <label className={`flex custom-checkbox-container-lvl${currentLevel}`}>
              <input key={s.id} type="checkbox" checked={s.isChecked} onChange={() => { }} />
              <span className={`custom-checkbox-lvl${currentLevel}`} />
            </label>
          </div>
        </div>
      </div>
    ));

    return st;
  }

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full">
      <BookingTimingOptionsModal />
      <div className="separator" />
      <div className="flex flex-col w-full px-5 overflow-scroll scrollbar-none">
        <div className="pt-6">
          {
            isLoading
              ? <Loading color={color} backgroundColor="white" text="Service Types" />
              : renderServiceTypes()
          }
        </div>
      </div>
    </div>
  );
}

ChooseServiceType.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ChooseServiceType;
