import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import rootReducer from '../reducers/index';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = process.env.NODE_ENV !== 'production'
  ? createStore(rootReducer, composeEnhancer(applyMiddleware(logger)))
  : createStore(rootReducer, composeEnhancer());

export default store;
