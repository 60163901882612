import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter_icon.svg';

/**
 * Filter icon. The icon color will depend on the loyalty level of the current user.
 * @param {function} onClick the callback that runs on click of the icon
 */
const FilterLevelIcon = ({ onClick }) => {
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);
  return <FilterIcon onClick={onClick} stroke={level.level_color} fill={level.level_color} />;
};

FilterLevelIcon.propTypes = {
  onClick: PropTypes.func,
};

FilterLevelIcon.defaultProps = {
  onClick: () => { },
};

export default FilterLevelIcon;
