import { BOOKING_LOCATIONS, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = {
  clientLocations: [],
  otherLocations: [],
};

const bookingLocations = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_LOCATIONS:
      return action.locations;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingLocations;
