import React, {
  useCallback,
  useEffect,
  useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { groupBy, includes, reduce } from 'lodash';

import { getCurrentLoyaltyLevel } from '../../selectors/user';
import {
  getBookingLocation,
  getBookingLogId,
  getBookingServiceType,
  getBookingServices,
  isSequentialBooking,
} from '../../selectors/booking';

import { ReactComponent as IconFace } from '../../assets/icons/face_icon.svg';
import { ReactComponent as IconHands } from '../../assets/icons/hands_icon.svg';
import { ReactComponent as IconFeet } from '../../assets/icons/feet_icon.svg';

import BookingApi from '../../api/Booking';

import { CustomToast } from '../Shared/withToast';

import {
  bookingOpenServiceCategory,
  bookingSelectService,
  bookingServices,
  bookingSoakOffHands as intentSoakOffHands,
  bookingSoakOffFeet as intentSoakOffFeet,
  bookingToggleServiceCategory,
  closeAllServicesCategories,
  unselectServiceFromCategory,
} from '../../actions/bookings';
import BookingTimingOptionsModal from './BookingTimingOptionsModal';
import Loading from '../Loading';
import CategoryType from './CategoryType';
import CategoryServicesModal from './CategoryServicesModal';

const { getServices } = BookingApi();

/**
 * A component that controls the selection of services for a booking.
 * This version works by grouping each service sub category on a top category (which is called a category type).
 *
 * The customer can pick ONE service from each category type.
 */
const ChooseServiceWithCategoryTypes = () => {
  const level = getCurrentLoyaltyLevel();
  const isSeqBooking = isSequentialBooking();
  const services = getBookingServices();
  const serviceType = getBookingServiceType();
  const bookingLogId = getBookingLogId();
  const location = getBookingLocation();
  const color = level.level_color;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  /**
   * This function simply transforms the categories API data that comes with the format
   * {
   *  "hands": [
   *    {
   *       category_id: 1,
   *       category_name: "Manicure"
   *       services: []
   *    }
   *  ],
   *  "feet": [...]
   * }
   *
   * Into something like
   *
   * [
   *    {
   *       categoryTypeId: "hands",
   *       name: "Hands",
   *       selected: false,
   *       subCategories: [
   *          {
   *             ...
   *          }
   *       ]
   *    },
   *    {
   *       categoryTypeId: "feet",
   *       name: "Feet",
   *       selected: false,
   *       subCategories: [
   *          {
   *             ...
   *          }
   *       ]
   *    }
   * ]
   */
  const addSelectionInformationToCategories = (categoriesApiData) => {
    const categoriesGroupedByType = groupBy(categoriesApiData, 'category_type_id');
    return reduce(categoriesGroupedByType, (iterator, subCategories, categoryTypeId) => {
      // The top services of this top category
      const subCategoriesMapped = subCategories.map((subCategory) => ({
        ...subCategory,
        services: subCategory.services.map((service) => ({
          ...service,
          selected: false,
        })),
      }));

      const newIterator = [
        ...iterator,
        {
          categoryTypeId,
          name: subCategories[0].category_type_name,
          isActive: false, // this controls if that category type is currently selected by the user.
          isOpen: false, // this controls if the that category is currently opened as a popup
          subCategories: subCategoriesMapped,
        },
      ];
      return newIterator;
    }, []);
  };

  const fetchServices = async () => {
    if (services.length === 0) {
      try {
        setIsLoading(true);
        const response = await getServices({ bookingLocationId: location.id, serviceTypeId: serviceType.id, bookingLogId });
        const servicesPerCategory = response.data.services_per_category;
        dispatch(bookingServices(addSelectionInformationToCategories(servicesPerCategory)));
      } catch (error) {
        toast(<CustomToast type="error" text="Services couldn't be retrieved!" />);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(fetchServices, []);

  const isAnyCategoryOpen = useMemo(() => services.filter((category) => category.isOpen)).length > 0;
  const knownCategories = useMemo(() => services.filter((category) => category.categoryTypeId !== 'unknown'), [services]);
  const activeCategories = useMemo(() => services.filter((category) => category.isActive), [services]);
  const activeCategoryNames = useMemo(() => activeCategories.map((cat) => cat.name));
  const currentlyOpenedCategory = useMemo(() => services.find((category) => category.isOpen));
  const CATEGORY_TYPES_ICONS = useMemo(() => ({
    feet: IconFeet,
    face_body: IconFace,
    hands: IconHands,
  }));

  /**
   * This function adds/removes the given category from the list of active categories
   * ("hands", "feet", etc).
   *
   * If it is setting a category as active, also triggers the opening
   * of the sub categories popup modal (the same action happens if the user clicks on the
   * services dropdown when the sub category is active).
   *
   * @param {Boolean} isActive if true, it means that this category is currently active
   * @param {String} categoryTypeId the category type id
   */
  const onToggleCategory = (isActive, categoryTypeId) => {
    // Toggle the active state of the given category.
    dispatch(bookingToggleServiceCategory(categoryTypeId));

    if (isActive) {
      dispatch(bookingOpenServiceCategory(categoryTypeId));
    } else {
      // If we're disabling this category type, let's also unselect the service from that category and the associated
      // soak off service
      dispatch(unselectServiceFromCategory(categoryTypeId));

      if (categoryTypeId === 'feet') {
        dispatch(intentSoakOffFeet(false));
      } else if (categoryTypeId === 'hands') {
        dispatch(intentSoakOffHands(false));
      }
    }
  };

  const onSelectService = useCallback((service) => {
    dispatch(bookingSelectService(currentlyOpenedCategory, service));
    dispatch(closeAllServicesCategories());
  });

  /**
   * This function runs whenever the customer clicks on the services picker of a given category.
   * @param {String} categoryTypeId the category id
   */
  const onCategoryServicePickerClick = useCallback((categoryTypeId) => {
    dispatch(bookingOpenServiceCategory(categoryTypeId));
  });

  /**
   * This functions runs whenever the customer closes the service picker modal (regardless of the current category)
   *
   * If the popup closes but the customer didn't pick any service, that category is marked as unselected.
   */
  const closeAnyServiceCategory = useCallback(() => {
    const selectedServiceOfCategoryBeingClosed = findSelectedService(currentlyOpenedCategory.subCategories);
    if (selectedServiceOfCategoryBeingClosed.service_name === '') {
      onToggleCategory(false, currentlyOpenedCategory.categoryTypeId);
    }
    dispatch(closeAllServicesCategories());
  }, [currentlyOpenedCategory]);

  const renderTitle = () => {
    if (isSeqBooking) {
      return <div className="services-title">NOOP</div>;
    }

    return (
      <div className="services-title">
        <span>Select up to 3 services</span>
        <span>(these will start at the same time)</span>
      </div>
    );
  };

  const findSelectedService = (subCategories) => {
    const subCategoryWithSelectedService = subCategories.find((subCategory) => subCategory.services.some((service) => service.selected));

    if (subCategoryWithSelectedService) {
      return subCategoryWithSelectedService.services.find((service) => service.selected);
    }

    return { service_name: '' };
  };

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full h-full">
      <BookingTimingOptionsModal />
      {isAnyCategoryOpen && (
        <CategoryServicesModal
          subCategories={currentlyOpenedCategory.subCategories}
          onSelectService={onSelectService}
          onClose={closeAnyServiceCategory}
        />
      )}
      <div className="separator" />
      {isLoading && <Loading color={color} backgroundColor="white" text="Services" />}
      {!isLoading && (
        <div className="w-full h-full overflow-scroll scrollbar-none px-5 pb-5">
          {renderTitle()}
          {knownCategories.map((category) => (
            <CategoryType
              category={category}
              key={category.categoryTypeId}
              Icon={CATEGORY_TYPES_ICONS[category.categoryTypeId]}
              isActive={includes(activeCategoryNames, category.name)}
              onToggle={(isCategoryActive) => onToggleCategory(isCategoryActive, category.categoryTypeId)}
              onServicePickerClick={() => onCategoryServicePickerClick(category.categoryTypeId)}
              selectedService={findSelectedService(category.subCategories || [])}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ChooseServiceWithCategoryTypes;
