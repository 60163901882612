import { ApiGet } from './Api';

/**
 * Transaction API calls
 */
export default () => ({
  /**
   *
   * @param {Integer} id the transaction id
   * @returns an hash with the transaction details.
   */
  getTransaction: (id) => ApiGet({ endpoint: `transactions/${id}` }),
});
