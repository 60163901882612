import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';

import DashboardHeader from '../Dashboard/DashboardHeader';
import SubTitle from '../Shared/SubTitle';

import withNotificationPopUp from '../Shared/withNotificationPopUp';
import withUserRetriever from '../Shared/withUserRetriever';

import { CustomToast } from '../Shared/withToast';
import BookNowButton from '../Shared/BookNowButton';
import Loading from '../Loading';

import '../../assets/css/settings/email.scss';

import { getCurrentUser } from '../../selectors/user';
import CommunicationChannels from '../../api/CommunicationChannels';
import ToggleSwitch from '../Shared/ToggleSwitch';

const CHANNEL_TO_TITLE = {
  wa_transactional: {
    title: 'Whatsapp Transactional Info',
    description: 'Post-sale receipts and appointment related whatsapp communication.',
  },
  wa_loyalty: {
    title: 'Whatsapp Loyalty Info',
    description: 'All whatsapp messages related to your loyalty account, such as vouchers, stamp cards, loyalty level and points updates.',
  },
  email_transactional: {
    title: 'Transactional emails',
    description: 'Post-sale receipts and appointment related communication sent via Email.',
  },
  email_loyalty: {
    title: 'Loyalty Related Emails',
    description: 'All emails related to your loyalty account, such as vouchers, stamp cards, loyalty level and points updates.',
  },
};

const CHANNEL_ENABLED = 'on';
const CHANNEL_DISABLED = 'off';

function CommunicationPreferences() {
  const [state, setState] = useState({
    settings: '',
    settingsSnapshot: '',
    isLoading: true,
  });

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);
  const user = getCurrentUser();

  const { settings, isLoading } = state;

  const componentStyle = {
    color: level.level_color,
    inactiveColor: '#838383',
  };

  const { get, update } = CommunicationChannels();

  useEffect(() => {
    get()
      .then((response) => setState((prevState) => ({ ...prevState, settings: response.data, settingsSnapshot: response.data })))
      .catch(() => toast(<CustomToast type="error" text="Unable to retrieve communication preferences." />))
      .finally(() => setState((prevState) => ({ ...prevState, isLoading: false })));
  }, []);

  function handleChange(channel, value) {
    setState((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [channel]: value === CHANNEL_ENABLED ? CHANNEL_DISABLED : CHANNEL_ENABLED,
      },
    }));

    return updateApiCall(channel, value);
  }

  const updateApiCall = (channel, value) => {
    update({
      channel,
      value: value === CHANNEL_ENABLED ? CHANNEL_DISABLED : CHANNEL_ENABLED,
    }).then((response) => {
      setState((prevState) => ({
        ...prevState,
        settings: response.data,
        settingsSnapshot: response.data,
      }));
    }).catch(() => {
      toast(<CustomToast type="error" text="Unable to update channel status" />);
      setState((prevState) => ({
        ...prevState,
        settings: prevState.settingsSnapshot,
      }));
    });
  };

  function renderToggle(key, value) {
    return (
      <ToggleSwitch
        isActive={value === CHANNEL_ENABLED}
        onChange={() => handleChange(key, value)}
        activeColor={componentStyle.color}
        inactiveColor={componentStyle.inactiveColor}
        isDisabled={user.is_impersonating}
      />
    );
  }

  const getTitle = (key) => CHANNEL_TO_TITLE[key].title;
  const getDescription = (key) => CHANNEL_TO_TITLE[key].description;

  function renderPreference(channel, value) {
    return (
      <div key={channel} className="settings-email-option">
        <div className="settings-email-option-header" style={{ color: componentStyle.color }}>
          <div className="settings-email-option-title">{getTitle(channel)}</div>
          {renderToggle(channel, value)}
        </div>
        <div className="settings-email-option-text">{getDescription(channel)}</div>
      </div>
    );
  }

  function renderCommunicationChannels() {
    return (
      <div className="settings-email-options">
        {Object.entries(settings).map((channel) => renderPreference(channel[0], channel[1]))}
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <DashboardHeader color={componentStyle.color} title="Settings" />
      <div className="settings-email-content-container">
        <div className="my-4">
          <SubTitle subTitle="Communication Preferences" route="/settings" style={{ color: componentStyle.color }} />
        </div>
        {isLoading && <Loading color={componentStyle.color} />}
        {!isLoading && renderCommunicationChannels()}
      </div>
      <BookNowButton color={componentStyle.color} />
    </div>
  );
}

export default compose(
  withUserRetriever,
  withNotificationPopUp,
)(CommunicationPreferences);
