import React from 'react';
import PropTypes from 'prop-types';

import '../../assets/css/dashboard/shared.scss';

function GenericButton({
  color,
  text,
  disable,
  action,
  className,
}) {
  return (
    <div className={`button ${className}`}>
      <button
        type="button"
        onClick={action}
        className={
          disable
            ? 'button-disable cursor-not-allowed md:w-1/2 sm: w-full'
            : 'button-primary flex justify-center md:w-1/2 sm: w-full'
        }
        style={disable ? {} : { borderRadius: 4, backgroundColor: color, borderColor: color }}
      >
        {text}
      </button>
    </div>
  );
}

GenericButton.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  action: PropTypes.func.isRequired,
  disable: PropTypes.bool,
};

GenericButton.defaultProps = {
  disable: false,
  className: '',
};

export default GenericButton;
