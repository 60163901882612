import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { toast } from 'react-toastify';
import IntlTelInput from 'react-intl-tel-input';
import get from 'lodash/get';
import {
  isValidEmail, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, EXTERNAL_TYPES, REGISTRATION_TYPES,
  cellPhoneForPayload,
} from '../../assets/js/constants';

import RegistrationsAPI from '../../api/Registration';
import { CustomToast } from '../Shared/withToast';

import logoTns from '../../assets/img/logo_tns_black.svg';
import logoMarquee from '../../assets/img/logo_marquee_black.svg';
import eyeOpenIcon from '../../assets/icons/eye_open.svg';
import eyeClosedIcon from '../../assets/icons/eye_closed.svg';
import phoneIcon from '../../assets/icons/phone.svg';

import '../../assets/css/registration/registration.scss';
import { getApiCustomErrorMessage } from '../../utils/api';
import Loading from '../Loading';

function NonOrganicRegistration() {
  const urlParams = new URLSearchParams(window.location.search);

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    cellPhone: '',
    email: '',
    birthday: '',
    password: '',
    passwordShown: false,
    passwordConfirmation: '',
    passwordConfirmationShown: false,
    hasLengthUserError: true,
    hasLengthConfirmError: true,
    hasMatchingError: true,
    hasEmailError: true,
    lengthErrorText: 'Password must have at least 6 characters!',
    matchingErrorText: 'Passwords must match!',
    emailErrorText: 'Email is not valid !',
    cellPhoneError: true,
    cellPhoneCountry: {},
    locations: '',
    location: '',
    error: false,
    processing: false,
    registrationType: urlParams.get('registration_type'),
    referralToken: urlParams.get('referral_token'),
    linkToken: urlParams.get('link_token'),
    hasLinkToken: false,
  });

  const { referral: registerReferral, link: registerLink, findContact } = RegistrationsAPI();

  const {
    firstName,
    lastName,
    cellPhone,
    cellPhoneCountry,
    email,
    birthday,
    password,
    passwordShown,
    passwordConfirmation,
    passwordConfirmationShown,
    hasLengthUserError,
    hasLengthConfirmError,
    hasMatchingError,
    hasEmailError,
    lengthErrorText,
    matchingErrorText,
    emailErrorText,
    cellPhoneError,
    error,
    processing,
    registrationType,
    referralToken,
    linkToken,
    hasLinkToken,
  } = state;

  const history = useHistory();

  useEffect(() => {
    if (Object.values(EXTERNAL_TYPES).includes(registrationType)) {
      findContact(linkToken)
        .then((res) => setState((prevState) => ({
          ...prevState, cellPhone: res.data.contact, hasLinkToken: true, cellPhoneError: false,
        })))
        .catch(() => toast(<CustomToast type="error" text="Could not find cellphone associated with link!" />));
    }
  }, []);

  function handleChange(e) {
    setState((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  }

  function validLength(pass) {
    return pass.length >= MIN_PASSWORD_LENGTH && pass.length <= MAX_PASSWORD_LENGTH;
  }

  function validMatch(pass, passConfirm) {
    return pass === passConfirm;
  }

  function handleChangeEmail(e) {
    const emailError = !isValidEmail(e.target.value);

    setState((prevState) => ({
      ...prevState,
      email: e.target.value,
      hasEmailError: emailError,
    }));
  }

  function handleChangeUserPassword(e) {
    const isLengthError = !validLength(e.target.value);
    const isMatchError = !validMatch(passwordConfirmation, e.target.value);
    setState((prevState) => ({
      ...prevState,
      password: e.target.value,
      hasLengthUserError: isLengthError,
      hasMatchingError: isMatchError,
    }));
  }

  function handleChangeConfirmPassword(e) {
    const isLengthError = !validLength(e.target.value);
    const isMatchError = !validMatch(password, e.target.value);
    setState((prevState) => ({
      ...prevState,
      passwordConfirmation: e.target.value,
      hasLengthConfirmError: isLengthError,
      hasMatchingError: isMatchError,
    }));
  }

  function handleDatePickerChange(e) {
    setState((prevState) => ({ ...prevState, birthday: e }));
  }

  function togglePasswordVisibility() {
    setState((prevState) => ({ ...prevState, passwordShown: !passwordShown }));
  }

  function togglePasswordConfirmVisibility() {
    setState((prevState) => ({ ...prevState, passwordConfirmationShown: !passwordConfirmationShown }));
  }

  function setCellPhone(valid, number, countryData) {
    let finalNumber = '';
    const numbers = number.split('');
    const lastNumberIntroduced = numbers[numbers.length - 1];

    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(lastNumberIntroduced)) {
      finalNumber = number;
    } else {
      numbers.pop();
      finalNumber = numbers.join('');
    }

    setState((prevState) => ({
      ...prevState,
      cellPhone: finalNumber,
      cellPhoneError: !valid,
      cellPhoneCountry: countryData,
    }));
  }

  function hasError() {
    return error
      || cellPhoneError
      || hasLengthUserError
      || hasLengthConfirmError
      || hasMatchingError
      || hasEmailError;
  }

  function submitRegistration() {
    const payload = {
      registration_type: registrationType,
      first_name: firstName,
      last_name: lastName,
      cellphone: cellPhoneForPayload(cellPhoneCountry, cellPhone),
      email,
      birthday: birthday || null,
      password,
      password_confirmation: passwordConfirmation,
    };

    setState((prevState) => ({ ...prevState, processing: true }));

    const isReferralRegistration = registrationType === REGISTRATION_TYPES.REFERRAL;
    const isLinkRegistration = Object.values(EXTERNAL_TYPES).includes(registrationType);

    if (isReferralRegistration) {
      payload.referral_token = referralToken;
      handleRegistrationResponse(registerReferral(payload));
    } else if (isLinkRegistration) {
      payload.link_token = linkToken;
      handleRegistrationResponse(registerLink(payload));
    } else {
      toast(<CustomToast type="error" text="Invalid registration!" />);
      setState((prevState) => ({ ...prevState, processing: false }));
    }
  }

  function handleRegistrationResponse(promise) {
    promise
      .then((response) => {
        toast(<CustomToast type="success" text="Your account is ready!" />);
        setState((prevState) => ({ ...prevState, processing: false }));
        history.push(`/booking?sso_token=${response.data.sso_token}`);
      })
      .catch((err) => {
        if (get(err, 'response.data')) {
          toast(<CustomToast type="error" text={getApiCustomErrorMessage(err)} />);
        }
        setState((prevState) => ({ ...prevState, processing: false }));
      });
  }

  function RegistrationHeader() {
    if (registrationType === 'referral') {
      return <ReferralHeader />;
    }
    return <GenericHeader />;
  }

  function GenericHeader() {
    return (
      <>
        <span className="registration-title">Register</span>
        <span className="registration-text">
          Please create your account below to proceed with your booking.
        </span>
      </>
    );
  }

  function ReferralHeader() {
    return (
      <>
        <span className="registration-title">You&apos;ve been invited to TNS!</span>
        <span className="registration-text">
          Submit your details below to create a loyalty account and
          receive your voucher for AED 100 worth of services
        </span>
      </>
    );
  }

  return (
    <div className="registration-page">
      <div className="registration-bg" />
      <div className="registration-page-content">
        <div className="logos my-10">
          <div className="pr-5">
            <img className="logo-tns-sign-in" src={logoTns} alt="" />
          </div>
          <div>
            <img className="logo-marquee-sign-in" src={logoMarquee} alt="" />
          </div>
        </div>

        <div className="px-5">
          <div className="registration-registration tab-container">
            <RegistrationHeader />
            <span className="registration-text-primary pb-3">First Name *</span>

            <input
              id="firstName"
              placeholder="Your First Name"
              value={firstName}
              onChange={handleChange}
              className={`${error ? 'registration-input-error' : 'registration-input'} mb-3`}
              type="text"
              required
            />
            <span className="registration-text-primary pb-3">Last Name *</span>

            <input
              id="lastName"
              placeholder="Your Last Name"
              value={lastName}
              onChange={handleChange}
              className={`${error ? 'registration-input-error' : 'registration-input'} mb-2`}
              type="text"
              required
            />

            {
              hasLinkToken
                ? ''
                : (
                  <>
                    <hr className="registration-tab-separator" />
                    <span className="registration-text-primary pb-3">Phone Number *</span>
                    <div className="relative">
                      <IntlTelInput
                        id="cellPhone"
                        fieldId="cellPhone"
                        value={cellPhone}
                        defaultCountry="ae"
                        onPhoneNumberChange={(value, number, countryData) => { setCellPhone(value, number, countryData); }}
                        containerClassName="intl-tel-input"
                        inputClassName={cellPhoneError ? 'registration-cellphone-error' : 'registration-cellphone'}
                      />
                      <img className="phone-icon" src={phoneIcon} alt="" />
                    </div>
                    {
                      cellPhoneError && (
                        <div className="current-error mb-2">
                          Invalid phone number.
                        </div>
                      )
                    }

                    <hr className="registration-tab-separator" />
                  </>
                )
            }

            <span className="registration-text-primary pb-3">Email Address *</span>
            <input
              id="email"
              placeholder="Your Email Address"
              value={email}
              onChange={handleChangeEmail}
              className={`${hasEmailError ? 'registration-input-error' : 'registration-input'} mb-3`}
              type="email"
              required
            />
            {
              hasEmailError && (
                <div className="current-error -mt-1 mb-2">
                  {hasEmailError ? emailErrorText : ''}
                </div>
              )
            }
            <span className="registration-text-primary pb-3">Birthday (Optional)</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="dd/MM/yyyy"
                value={birthday === '' ? null : birthday}
                placeholder="06/09/1994"
                views={['year', 'month', 'date']}
                InputProps={{ className: `${error ? 'registration-input-error' : 'registration-input'} mb-3 w-full` }}
                onChange={handleDatePickerChange}
                clearable
                animateYearScrolling
              />
            </MuiPickersUtilsProvider>

            <hr className="registration-tab-separator" />

            <span className="registration-text-primary pb-3">Password *</span>
            <div className="relative">
              <input
                id="password"
                placeholder="*******"
                minLength={MIN_PASSWORD_LENGTH}
                maxLength={MAX_PASSWORD_LENGTH}
                value={password}
                onChange={handleChangeUserPassword}
                className={`${hasLengthUserError ? 'registration-input-error' : 'registration-input'} mb-3`}
                type={passwordShown ? 'text' : 'password'}
              />
              <div role="button" tabIndex={0} className="password-icon-new" onClick={togglePasswordVisibility}>
                <img src={passwordShown ? eyeOpenIcon : eyeClosedIcon} alt="" />
              </div>
              {
                hasLengthUserError && (
                  <div className="current-error -mt-1 mb-2">
                    {hasLengthUserError ? lengthErrorText : ''}
                  </div>
                )
              }
            </div>

            <span className="registration-text-primary pb-3">Confirm Password *</span>
            <div className="relative">
              <input
                id="passwordConfirmation"
                placeholder="*******"
                minLength={MIN_PASSWORD_LENGTH}
                maxLength={MAX_PASSWORD_LENGTH}
                value={passwordConfirmation}
                onChange={handleChangeConfirmPassword}
                className={
                  `${hasLengthConfirmError || hasMatchingError
                    ? 'registration-input-error'
                    : 'registration-input'}`
                }
                type={passwordConfirmationShown ? 'text' : 'password'}
              />
              <div role="button" tabIndex={0} className="password-icon-new" onClick={togglePasswordConfirmVisibility}>
                <img src={passwordConfirmationShown ? eyeOpenIcon : eyeClosedIcon} alt="" />
              </div>
              {
                (hasLengthConfirmError || hasMatchingError) && (
                  <div className="current-error my-2">
                    {hasLengthConfirmError ? lengthErrorText : ''}
                    {(hasLengthConfirmError && hasMatchingError) && <br />}
                    {hasMatchingError ? matchingErrorText : ''}
                  </div>
                )
              }
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                onClick={submitRegistration}
                disabled={processing || hasError()}
                className={processing || hasError()
                  ? 'button-grey flex justify-center md:w-1/2 sm: w-full mt-8'
                  : 'button-sign-in flex justify-center md:w-1/2 sm: w-full mt-8'}
              >
                {processing
                  ? <Loading color="white" backgroundColor="var(--color-grey)" size={2} iconOnly />
                  : 'SUBMIT'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NonOrganicRegistration;
