// TODO: Add here all the remaining global configurations for the app (env vars, etc)

export default {
  config: {
    bookings: {
      TimerActive: (process.env.REACT_APP_BOOKINGS_TIMER_ACTIVE || 'true') === 'true',
    },
    timeZone: process.env.REACT_APP_LOCATION_TIMEZONE || 'Asia/Dubai',
    hiddenComponents: (process.env.REACT_APP_HIDDEN_COMPONENTS || '').split(','),
  },
  api: {
    defaultTimeout: process.env.REACT_APP_API_DEFAULT_TIMEOUT ? parseInt(process.env.REACT_APP_API_DEFAULT_TIMEOUT, 10) : 10000,
    baseURL: process.env.REACT_APP_API_URL,
    adminList: process.env.REACT_APP_ADMIN_EMAIL_LIST?.split(',') || ['salman@houseofenspa.com'],
    testAccount: process.env.REACT_APP_TEST_ACCOUNT,
    appStorePage: process.env.REACT_APP_STORE_PAGE,
  },
};
