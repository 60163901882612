import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/shared/radio-input.scss';

/**
 *
 * @param {String} label the radio input label
 * @param {Integer} id the id of HTML radio input
 * @param {Function} onChange the function that runs when the radio input value changes
 * @param {Boolean} isChecked indicates whether this radio input should be checked or not
 * @param {String} className (Optional) a classname for the whole radio input wrapper
 */
const RadioInput = ({
  label,
  id,
  onChange,
  isChecked,
  className,
}) => {
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  return (
    <div className={`radio-input ${className}`}>
      <label
        htmlFor={id}
        className={
          `radio-input-label
          radio-input-label--${isChecked ? 'checked' : ''}
          radio-input-label--${isChecked ? 'checked' : ''}--level-${level.id}`
        }
      >
        {label}
      </label>
      <input
        id={id}
        className={`radio-input-circle radio-input-circle-level${level.id}`}
        type="radio"
        onChange={(e) => onChange({ event: e, key: id })}
        name={label}
        checked={isChecked}
      />
    </div>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

RadioInput.defaultProps = {
  onChange: () => {},
  className: '',
};

export default RadioInput;
