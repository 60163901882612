import { BOOKING_SOAK_OFF_FEET, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = false;

const bookingSoakOffFeet = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_SOAK_OFF_FEET:
      return action.intent;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingSoakOffFeet;
