import React from 'react';
import PropTypes from 'prop-types';

import LoadingIcon from '../assets/icons/loading/loading';
import '../assets/css/loading.scss';

import { DEFAULT_LEVEL_COLOR } from '../assets/js/constants';

function Loading({
  isCover, text, color, backgroundColor, size, iconOnly,
}) {
  let componentStyle = {
    color,
    backgroundColor,
  };

  if (isCover) {
    componentStyle = {
      color: 'white',
      backgroundColor: color,
    };
  }

  return (
    <div
      className={`loading-container ${isCover && 'justify-center'}`}
      style={{ backgroundColor: componentStyle.backgroundColor }}
    >
      <div className={`loading-icon animate-spin ${iconOnly ? '' : 'my-4'}`} style={{ width: `${size}rem`, height: `${size}rem` }}>
        <LoadingIcon color={componentStyle.color} />
      </div>
      {
        !iconOnly && <div className="loading-text" style={{ color: componentStyle.color }}>{`Loading ${text}...`}</div>
      }
    </div>
  );
}

Loading.propTypes = {
  isCover: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  backgroundColor: PropTypes.string,
  iconOnly: PropTypes.bool,
};

Loading.defaultProps = {
  isCover: false,
  color: DEFAULT_LEVEL_COLOR,
  text: '',
  size: '',
  iconOnly: false,
  backgroundColor: 'var(--grey-background)',
};

export default Loading;
