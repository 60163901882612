import {
  BOOKING_ADDONS,
  BOOKING_CURRENTLY_OPENED_ADD_ON_CATEGORY,
  BOOKING_SELECT_ADD_ON_SERVICE,
  BOOKING_TOGGLE_ADD_ON_CATEGORY,
  BOOKING_UNSELECT_ADD_ON_FROM_CATEGORY,
  CLEAN_BOOKING,
  CLOSE_ALL_ADD_ON_CATEGORIES,
} from '../../actions/bookings';

const initialState = [];

const bookingAddOns = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_ADDONS:
      return action.addOns;
    case BOOKING_CURRENTLY_OPENED_ADD_ON_CATEGORY:
      return state.map((addOnCategory) => ({
        ...addOnCategory,
        isOpen: addOnCategory.categoryId === action.addOnCategoryId, // only 1 open addon category at the same time
      }));
    case BOOKING_SELECT_ADD_ON_SERVICE: {
      const { flattened, categoryId } = action.category;

      // Only 1 addon active per category
      return state.map((addOnCategory) => {
        if (addOnCategory.categoryId === categoryId) {
          if (!flattened) {
            return {
              ...addOnCategory,
              subCategories: addOnCategory.subCategories.map((subCategory) => ({
                ...subCategory,
                services: subCategory.services.map((service) => ({
                  ...service,
                  selected: service.service_id === action.service.service_id,
                })),
              })),
            };
          }

          return {
            ...addOnCategory,
            services: addOnCategory.services.map((service) => ({
              ...service,
              selected: service.service_id === action.service.service_id,
            })),
          };
        }

        return addOnCategory;
      });
    }
    case BOOKING_TOGGLE_ADD_ON_CATEGORY:
      // Multiple add on categories can be selected (selected != open)!
      return state.map((addOnCategory) => ({
        ...addOnCategory,
        isActive: addOnCategory.categoryId === action.addOnCategoryId ? !addOnCategory.isActive : addOnCategory.isActive,
      }));
    case BOOKING_UNSELECT_ADD_ON_FROM_CATEGORY: {
      const { categoryId } = action;

      // Since only 1 add on category can be selected, mark all services from the given category as unselected
      return state.map((addOnCategory) => {
        if (addOnCategory.categoryId === categoryId) {
          if (addOnCategory.flattened) {
            return {
              ...addOnCategory,
              services: addOnCategory.services.map((service) => ({
                ...service,
                selected: false,
              })),
            };
          }

          return {
            ...addOnCategory,
            subCategories: addOnCategory.subCategories.map((subCategory) => ({
              ...subCategory,
              services: subCategory.services.map((service) => ({
                ...service,
                selected: false,
              })),
            })),
          };
        }

        return addOnCategory;
      });
    }
    case CLOSE_ALL_ADD_ON_CATEGORIES: {
      return state.map((addOnCategory) => ({
        ...addOnCategory,
        isOpen: false,
      }));
    }
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingAddOns;
