/* eslint-disable indent */
import axios from 'axios';
import store from './store';
import LocalStorageService from './components/LocalStorageService';
import Config from './config/BeyoutyBonus';
import { isTimeoutError } from './utils/api';

axios.defaults.timeout = Config.api.defaultTimeout;
axios.defaults.baseURL = Config.api.baseURL;

axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  const headers = LocalStorageService.getObject('headers');
  const { ssoToken } = store.getState(); // we use store here because this function is not a function component
  const configClone = config;

  configClone.headers.common.ContentType = 'application/json';

  if (headers) {
    configClone.headers.common['access-token'] = headers['access-token'];
    configClone.headers.common.token_type = headers.token_type;
    configClone.headers.common.client = headers.client;
    configClone.headers.common.expiry = headers.expiry;
    configClone.headers.common.uid = headers.uid;
  }
  if (ssoToken) {
    configClone.headers.sso_token = ssoToken;
  }

  return config;
});

axios.interceptors.response.use((response) =>
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // eslint-disable-next-line implicit-arrow-linebreak
  response,

  (error) => {
    // error page redirection only happens on server errors or
    // page not found.
    if (isTimeoutError(error)) {
      store.dispatch({
        type: 'ERROR_MESSAGE',
        message: {
          errorTitle: 'Request Timeout',
          errorMessage: 'Your request took too long to process. Try again later.',
        },
      });
    } else if (typeof (error.response) === 'undefined') {
      store.dispatch({ type: 'ERROR_MESSAGE', message: { errorMessage: error.message } });
    } else if (error.response.status === 401) {
      LocalStorageService.clear();
      window.location.replace('/clients/sign_in');
    } else if (error.response.status >= 500 && error.response.status <= 599) {
      store.dispatch({ type: 'ERROR_MESSAGE', message: { errorTitle: 'Server Error' } });
    }

    return Promise.reject(error);
  });

export default axios;
