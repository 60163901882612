/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import withNotificationPopUp from '../Shared/withNotificationPopUp';
import DashboardHeader from '../Dashboard/DashboardHeader';
import withUserRetriever from '../Shared/withUserRetriever';
import BookNowButton from '../Shared/BookNowButton';
import SubTitle from '../Shared/SubTitle';
import '../../assets/css/settings/help.scss';

function Help() {
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const style = {
    color: level.level_color,
  };

  return (
    <div className="flex flex-col h-full dashboard-grey-background-color">
      <DashboardHeader color={style.color} title="Settings" />
      <div className="flex justify-center w-full my-4">
        <SubTitle subTitle="Help" style={style} route="/settings" />
      </div>
      <div className="flex flex-col w-full help-container px-5 md:w-1/2 self-center sm:px-0">
        <div>
          <div className="settings-list">
            Describe your concern and leave us your contact
            info at
            {' '}
            <a style={{ display: 'contents', color: level.level_color }} href="mailto:support@beyoutybonus.com">support@beyoutybonus.com.</a>
            <p className="py-2">We’ll get back to you within 24 hours.</p>
          </div>
        </div>
      </div>
      <BookNowButton color={style.color} />
    </div>
  );
}
export default compose(
  withUserRetriever,
  withNotificationPopUp,
)(Help);
