import { BOOKING_APPOINTMENT_END_TIME, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = '';

const bookingAppointmentEndTime = (state = '', action) => {
  switch (action.type) {
    case BOOKING_APPOINTMENT_END_TIME:
      return action.appointmentEndTime;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingAppointmentEndTime;
