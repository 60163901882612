import omit from 'lodash/omit';
import {
  BOOKING_MOVABLE, BOOKING_MOVABLE_DEL, CLEAN_BOOKING, RESET_MOVABLE,
} from '../../actions/bookings';

const initialState = {};
const bookingMovable = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_MOVABLE:
      return {
        ...state,
        [action.serviceId]: action.movable,
      };
    case BOOKING_MOVABLE_DEL:
      return {
        ...omit(state, action.serviceId),
      };
    case CLEAN_BOOKING:
    case RESET_MOVABLE:
      return initialState;
    default:
      return state;
  }
};

export default bookingMovable;
