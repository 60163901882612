import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../../assets/css/dashboard/slideBooking.scss';

import locationIcon from '../../assets/icons/location.svg';
import calendarIcon from '../../assets/icons/calendar.svg';
import clockIcon from '../../assets/icons/clock.svg';

import locationTnsDefaultImg from '../../assets/img/location_tns.jpg';
import locationMarqueeDefaultImg from '../../assets/img/location_marquee.jpg';

function SlideBooking({
  id, location, locationCategory, date, hour, backgroundImage, color,
}) {
  const history = useHistory();

  function getTileImage() {
    if (!backgroundImage) {
      if (locationCategory === 'tns') {
        return locationTnsDefaultImg;
      }
      return locationMarqueeDefaultImg;
    }
    return backgroundImage;
  }

  return (
    <div className="bookingSlider-card-tile-parent bookingSlider-slider-parent">
      <div className="flex flex-start bookingSlider-status-stripe" style={{ backgroundColor: color }} />
      <div role="button" tabIndex={0} onClick={() => history.push(`/dashboard/bookings/${id}`)} className="bookingSlider-card-tile-slider bookingSlider-card-tile">
        <div className="bookingSlider-card-details">
          <div className="flex items-end">
            <img className="bookingSlider-details-icon" src={locationIcon} alt="" />
            <span>{location}</span>
          </div>
          <div className="flex">
            <div className="flex items-end pr-2">
              <img className="bookingSlider-details-icon" src={calendarIcon} alt="" />
              <span>{date}</span>
            </div>
            <div className="flex items-end">
              <img className="bookingSlider-details-icon" src={clockIcon} alt="" />
              <span>{hour}</span>
            </div>
          </div>
        </div>
        <div
          className="bookingSlider-card-image"
          style={{ backgroundImage: `url(${getTileImage()})` }}
        />
      </div>
    </div>
  );
}

SlideBooking.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  locationCategory: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  hour: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  color: PropTypes.string.isRequired,
};

SlideBooking.defaultProps = {
  backgroundImage: '',
};

export default SlideBooking;
