import { BOOKING_LOG_TYPES } from '../../utils/api';

export const SOAK_OFF_CATEGORY = 'Soak-Off (Gel Removal)';
export const ignoreSoakOffServices = (servicesArray) => (servicesArray || []).filter((service) => service.category_name !== SOAK_OFF_CATEGORY);
export const ADD_ON_HANDS_CATEGORY = 'Add-Ons (Hands)';
export const ADD_ON_FEET_CATEGORY = 'Add-Ons (Feet)';

// This addon category has two sub categories inside: Facial (in-chair) and Threading/Tinting
export const ADD_ON_FACE_BODY_CATEGORY = 'Face & Body';

// The order in which the add ons categories should be shown in the add ons page
export const PREFERRED_ADD_ONS_CATEGORY_ORDER = [ADD_ON_HANDS_CATEGORY, ADD_ON_FEET_CATEGORY, ADD_ON_FACE_BODY_CATEGORY];

export const ADD_ON_FACIAL_IN_CHAIR = 'Facial (in-chair)';
export const ADD_ON_THREADING = 'Threading/Tinting';
export const MASSAGE_IN_CHAIR = 'Massage (in-chair)';

// These add ons categories have no subcategories inside them (so, the services are flattened)
export const ADD_ONS_WITH_NO_SUBCATEGORIES = [ADD_ON_HANDS_CATEGORY, ADD_ON_FEET_CATEGORY];

/**
 * All the wizard booking steps
*/
export const STEPS = {
  LOCATION: 'STEP_LOCATION',
  DATE: 'STEP_DATE',
  SERVICE_TYPE: 'STEP_SERVICE_TYPE',
  SERVICES: 'STEP_SERVICES',
  SERVICE_PROVIDER_PREFERENCES: 'STEP_SERVICE_PROVIDER_PREFERENCES',
  STARTING_TIME: 'STEP_STARTING_TIME',
  SERVICE_PROVIDER: 'STEP_SERVICE_PROVIDER',
  ADD_ONS: 'STEP_ADD_ONS',
  SPECIAL_REQUEST: 'STEP_SPECIAL_REQUEST',
  SUMMARY: 'STEP_SUMMARY',
  SUMMARY_CONFIRMED: 'STEP_SUMMARY_CONFIRMED',
  SOAK_OFFS: 'STEP_SOAK_OFFS',
};

/**
 * Total number of steps
 */
export const TOTAL_STEPS = Object.keys(STEPS).length;

/**
 * All steps should contain the timer except the last step after the booking has been confirmed.
 */
export const STEPS_WITH_TIMER = Object.values(STEPS).filter((step) => step !== STEPS.SUMMARY_CONFIRMED);

/**
 * The title of each step
 */
export const STEPS_TITLE = {
  [STEPS.LOCATION]: 'Select Location',
  [STEPS.DATE]: 'Select Date',
  [STEPS.SERVICE_TYPE]: 'Select Service Type',
  [STEPS.SERVICES]: 'Select Your Services',
  [STEPS.STARTING_TIME]: 'Select Start Time',
  [STEPS.SERVICE_PROVIDER]: '',
  [STEPS.ADD_ONS]: 'Select Add-Ons',
  [STEPS.SPECIAL_REQUEST]: 'Special Request',
  [STEPS.SUMMARY]: 'Booking Confirmation',
  [STEPS.SUMMARY_CONFIRMED]: 'Booking Confirmed',
  [STEPS.SOAK_OFFS]: 'Soak Off',
};

export const timeoutEndpointLogErrorPayload = ({ bookingLogId, error }) => ({
  booking_log_id: bookingLogId,
  tag: BOOKING_LOG_TYPES.BOOKING_TIMEOUT,
  error: { message: `Booking API Timeout - ${error.config.url.replace(/\/api\/v[0-9]*\//, '')}` },
});
