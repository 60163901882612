const vouchersRecordsLoadMore = (state = '', action) => {
  switch (action.type) {
    case 'VOUCHERS_RECORDS_LOAD_MORE':
      return action.loadMore;
    default:
      return state;
  }
};

export default vouchersRecordsLoadMore;
