import { BOOKING_LOCATION, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = '';
const bookingLocation = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_LOCATION:
      return action.location;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingLocation;
