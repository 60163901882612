import { ApiGet } from './Api';

/**
 * Voucher API calls
 */
export default () => ({
  /**
   *
   * @param {Object} params the params for the call.
   * @returns a list with all the current vouchers (includes active, expired and redeemed vouchers)
   */
  list: (params) => ApiGet({ endpoint: 'vouchers', params }),

  /**
   *
   * @param {Integer} id the voucher id
   * @returns an hash with the voucher details.
   */
  get: (id) => ApiGet({ endpoint: `vouchers/${id}` }),

  /**
   *
   * @param {Object} params the params for the call.
   * @returns a list with paginated vouchers
   */
  paginate: (params) => ApiGet({ endpoint: 'vouchers/pagination_vouchers', params }),
});
