import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from '../interceptor';

import '../assets/css/header.scss';
import '../assets/css/shared.scss';

import logoTns from '../assets/img/logo_tns_black.svg';
import logoMarquee from '../assets/img/logo_marquee_black.svg';
import menuIcon from '../assets/icons/icon_menu.svg';
import notificationIcon from '../assets/icons/alert.svg';
import Burger from './Burger';
import LocalStorageService from './LocalStorageService';
import { CustomToast } from './Shared/withToast';
import { getSSOToken, getCurrentUser } from '../selectors/user';

function Header({ title, color }) {
  const [showBurger, setShowBurger] = useState(false);
  const notifications = useSelector((reduxState) => reduxState.notificationUpdates) || '';
  const lastSurvey = useSelector((reduxState) => reduxState.lastSurvey) || '';
  const user = getCurrentUser();
  const ssoToken = getSSOToken();

  const dispatch = useDispatch();
  const history = useHistory();

  const isValidNotificationUpdate = (notification) => {
    const isValidType = [
      'level_upgrade',
      'birthday',
      'stamp_card_tick',
      'new_stamp_card',
      'stamp_card_completed',
    ].includes(notification.type);

    return notification.award_id && isValidType && !notification.read;
  };

  useEffect(() => {
    axios.get('/api/v1/app_notifications/new_notifications')
      .then((res) => {
        const notificationData = res.data;
        dispatch({
          type: 'NOTIFICATION_UPDATES',
          notificationUpdates: {
            total: notificationData.total,
            data: notificationData.data.filter((n) => isValidNotificationUpdate(n)),
          },
        });

        const survey = notificationData.survey || '';

        dispatch({
          type: 'LAST_SURVEY',
          lastSurvey: {
            survey,
            seen: lastSurvey.seen,
          },
        });
      })
      .catch(() => {
        toast(<CustomToast type="error" text="Could not retrieve new notifications." />);
      });
  }, []);

  const headers = LocalStorageService.getObject('headers');

  return (
    <div className="flex flex-col view-boundaries-top">
      <div className="header-container md:w-1/2 sm:px-0 px-5">
        {
          showBurger
            ? (
              <>
                <div className="menu-overlay" />
                <Burger close={() => setShowBurger(!showBurger)} color={color} />
              </>
            )
            : ''
        }
        <div className="flex flex-row items-center justify-between w-full">
          <div className="items-start self-center burger-icon-container">
            {
              (headers && headers['access-token']) || ssoToken
                ? (
                  <div role="button" tabIndex={0} onClick={() => setShowBurger(!showBurger)}>
                    <img className="burger-icon" src={menuIcon} alt="" />
                  </div>
                ) : ''
            }
          </div>
          {
            // eslint-disable-next-line no-nested-ternary
            title ? (
              <div className="header-title flex flex-col items-center">
                {title}
                { user.is_impersonating && !showBurger && (
                  <div className="mt-1 impersonation-badge">
                    Impersonating
                  </div>
                )}
              </div>
            )
              : user.is_impersonating && !showBurger ? (
                <div className="mt-1 impersonation-badge" style={{ color: 'white', borderColor: 'white' }}>
                  Impersonating
                </div>
              ) : (
                <div className="logos">
                  <div className="pr-3">
                    <img className="logo-tns" src={logoTns} alt="" />
                  </div>
                  <div>
                    <img className="logo-marquee" src={logoMarquee} alt="" />
                  </div>
                </div>
              )
            }

          <div className="self-center messages-icon-container text-center">
            {
              (headers && headers['access-token']) || ssoToken
                ? (
                  <div role="button" tabIndex={0} onClick={() => history.push('/dashboard/messages')}>
                    <img className="notification-icon" src={notificationIcon} alt="" />
                    {
                      notifications.total > 0 && (
                        <span className="new-notification new-notification-circle" style={{ color }}>
                          {notifications.total}
                        </span>
                      )
                    }
                  </div>
                ) : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};

Header.defaultProps = {
  title: '',
  color: 'var(--color-primary',
};

export default Header;
