import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

/* eslint-disable import/no-extraneous-dependencies */
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';

import 'react-datepicker/dist/react-datepicker.css';
import '../../assets/css/booking/bookingDatePicker.scss';
import '../../assets/css/booking/shared.scss';
import '../../assets/css/shared.scss';
import capitalize from 'lodash/capitalize';
import getDate, { getCurrentBookingLocationWorkingTimes } from '../../utils/booking';
import { getBookingLocation } from '../../selectors/booking';
import { MONTH_IDX_MAP } from '../../assets/js/constants';

const ReadOnlyDate = forwardRef(({ value }, ref) => (
  <div className="bookings-date-picker-read-only" ref={ref}>
    {new Date(value).toDateString()}
  </div>
));

ReadOnlyDate.propTypes = {
  value: PropTypes.string,
};

ReadOnlyDate.defaultProps = {
  value: '',
};

const BookingDatePicker = ({
  isInline,
  currentDate,
  onChangeDate,
  isReadOnly,
  showDateOutOfLevelBounds,
}) => {
  const dateFormat = 'yyyy/MM/dd';
  const currentLevel = useSelector((reduxState) => reduxState.user).loyalty_level;
  const getDayClassName = () => `bg-lvl${currentLevel.id}`;
  const {
    closeHour,
    closeMinutes,
  } = getCurrentBookingLocationWorkingTimes(getBookingLocation());
  const initialDate = getDate(closeMinutes, closeHour);
  const minDate = subDays(initialDate, 0);
  const maxDate = addDays(initialDate, parseInt(currentLevel.booking_date_range, 10) - 1);

  // The customer can pick up to max date + 3 days on the "Choose Starting time Screen" (business decided logic)
  // so, to avoid visual confusion on the date picker, we never show any date greater than maxDate
  let selectedDate = currentDate;
  if (selectedDate > maxDate && !showDateOutOfLevelBounds) {
    selectedDate = maxDate;
  }

  return (
    <DatePicker
      dateFormat={dateFormat}
      formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
      selected={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChangeDate}
      inline={!isReadOnly && isInline}
      readOnly={isReadOnly}
      dayClassName={getDayClassName}
      customInput={isReadOnly ? <ReadOnlyDate /> : null}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="react-datepicker__header">
          {!prevMonthButtonDisabled
            && (
              <button
                type="button"
                className={`react-datepicker__navigation react-datepicker__navigation--previous header-bg-lvl${currentLevel.id}`}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              />
            )}
          <div className="react-datepicker__current-month">
            {`${capitalize(MONTH_IDX_MAP[date.getMonth()].long)} ${date.getFullYear()}`}
          </div>
          {!nextMonthButtonDisabled
            && (
              <button
                type="button"
                className={`react-datepicker__navigation react-datepicker__navigation--next header-bg-lvl${currentLevel.id}`}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              />
            )}
        </div>
      )}
    />
  );
};

BookingDatePicker.propTypes = {
  isInline: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  showDateOutOfLevelBounds: PropTypes.bool,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  onChangeDate: PropTypes.func,
};

BookingDatePicker.defaultProps = {
  isInline: false,
  showDateOutOfLevelBounds: false,
  isReadOnly: false,
  onChangeDate: () => {},
};

export default BookingDatePicker;
