import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import DashboardHeader from '../Dashboard/DashboardHeader';
import BookingLine from './BookingLine';
import ChooseLocation from './ChooseLocation';
import ChooseDate from './ChooseDate';
import ChooseServiceType from './ChooseServiceType';
import ChooseServiceProvider from './ChooseServiceProvider';
import LockServiceProviders from './LockServiceProviders';
import SpecialRequest from './SpecialRequest';
import Summary from './Summary';
import Timer from '../Timer';
import BeyoutyBonus from '../../config/BeyoutyBonus';
import '../../assets/css/shared.scss';
import '../../assets/css/booking/booking.scss';
import servicesIcon from '../../assets/icons/toasts/services.svg';

import LocalStorageService from '../LocalStorageService';
import { CustomToast } from '../Shared/withToast';
import withUserRetriever from '../Shared/withUserRetriever';
import {
  bookingAppointment,
  bookingAppointmentEndTime,
  cleanBooking,
  bookingAddOns as setBookingAddOns,
  bookingAvailableStartingTimes as setBookingAvailableStaringTimes,
  bookingServiceProviders,
  bookingServiceProviderForServices,
  bookingLockServiceProviderPreference,
  bookingServiceType,
  bookingServiceProvidersSnapshot,
  bookingConfirmed,
} from '../../actions/bookings';

import {
  getBookingAddOns,
  getBookingAppointmentId,
  getBookingDate,
  getBookingLocation,
  getBookingLogId,
  getBookingServiceProviderPreferences,
  getBookingServiceProviders,
  getBookingServices,
  getBookingServiceType,
  getBookingSpecialRequest,
  getBookingTime,
  getMovableServicesState,
  preferencesAdaptedToApiLog,
  getBookingConfirmed,
  isSequentialBooking,
  bookingSoakOffHands,
  bookingSoakOffFeet,
  getBookingAddOnsFlattened,
} from '../../selectors/booking';

import BookingsAPI from '../../api/Booking';
import { getCurrentLoyaltyLevel, getCurrentUser, hasVisitedInTheLastYear } from '../../selectors/user';
import ChooseStartingTime from './ChooseStartingTime';
import { BOOKING_LOG_TYPES, isTimeoutError } from '../../utils/api';
import Loading from '../Loading';
import getSSOToken from '../../utils/sessions';
import { addSSOTokenToSearchParams } from '../../utils/browser';
import {
  ADD_ONS_WITH_NO_SUBCATEGORIES,
  ADD_ON_FACE_BODY_CATEGORY,
  ADD_ON_FACIAL_IN_CHAIR,
  PREFERRED_ADD_ONS_CATEGORY_ORDER,
  ADD_ON_THREADING,
  STEPS,
  STEPS_WITH_TIMER,
  ignoreSoakOffServices,
  MASSAGE_IN_CHAIR,
  timeoutEndpointLogErrorPayload,
} from './consts';
import ChooseServiceWithCategoryTypes from './ChooseServiceWithCategoryTypes';
import ChooseService from './ChooseService';
import ChooseAddOnsWithCategories from './ChooseAddOnsWithCategories';
import SoakOffs from './SoakOffs';

const {
  saveLog,
  saveLogError,
  getServiceTypes,
  deletePreBook,
  confirmBooking,
  preBook,
  getServiceProvidersForServices,
  getServiceProvidersAvailability,
  getAvailableStartingTimes,
  getAddOnsAvailability,
} = BookingsAPI();

/**
 *
 * @returns The base booking component
 */
const Booking = () => {
  // Store Selectors
  const movableServices = getMovableServicesState();
  const bookingLocation = getBookingLocation();
  const preferencesTransformedToLog = preferencesAdaptedToApiLog();
  const bookingLogId = getBookingLogId();
  const bookingDate = getBookingDate();
  const bookingTime = getBookingTime();
  const bookingSelectedServiceType = getBookingServiceType();
  const wantsSoakOffHands = bookingSoakOffHands();
  const wantsSoakOffFeet = bookingSoakOffFeet();
  const visitedInTheLastYear = hasVisitedInTheLastYear();

  let bookingServices = getBookingServices();

  // NOTE: This is only useful for the new services screen!
  // For InRoom and Styling Stations, this variable isn't used
  const activeCategories = bookingServices.filter((category) => category.isActive);

  /**
   * A major hard-coded temporary fix for the new services format! This works only while we still use the old component
   * "ChooseService" only for in room services!
   * When we get rid of that component, we can refactor the way we handle the services on this component.
   *
   * This is because the service categories for in room services are not wrapped in top categories (they are all
   * flattened).
   */
  if (bookingSelectedServiceType.name === 'In-chair services') {
    const flattenedCategories = bookingServices.flatMap((category) => category.subCategories);
    bookingServices = flattenedCategories;
  }

  const bookingServiceProviderPreferences = getBookingServiceProviderPreferences();
  const bookingAddOns = getBookingAddOnsFlattened();
  const bookingAddOnsUnflattened = getBookingAddOns();
  const serviceProviders = getBookingServiceProviders();
  const appointmentId = getBookingAppointmentId();
  const bookingSpecialRequest = getBookingSpecialRequest();
  const isBookingConfirmed = getBookingConfirmed();
  const sequentialBooking = isSequentialBooking();
  const user = getCurrentUser();
  const level = getCurrentLoyaltyLevel();
  const ssoToken = getSSOToken();

  /**
  * Returns the current selected service categories
  */
  const selectedServiceCategories = useMemo(
    () => bookingServices.map((c) => ({ services: c.services.filter((s) => s.selected) })).filter((c) => c.services.length > 0),
    [bookingServices],
  );

  // State selectors
  const [currentStep, goToStep] = useState(STEPS.LOCATION);

  const [state, setState] = useState({
    confirmed: false,
    response: '',
    buttonText: 'Next',
    buttonConfirmText: 'Confirm Booking',
    buttonIsActive: true,
    timeout: false,
    addOn: false,
  });

  const {
    confirmed,
    buttonText,
    buttonConfirmText,
    buttonIsActive,
    timeout,
    addOn,
  } = state;

  const dispatch = useDispatch();

  const preBookIdRef = useRef(0);
  const appointmentLogIdRef = useRef(0);
  const appointmentConfirmedRef = useRef(false);

  // Alert the user when reloading the page
  const alertUser = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (LocalStorageService.getValue('alert')) {
      showTimeoutAlert();
    }

    if (timeout) {
      handleTimeout(alertUser);
    } else if (!isBookingConfirmed) {
      if (user) {
        window.addEventListener('beforeunload', alertUser);
        return () => {
          window.removeEventListener('beforeunload', alertUser);
        };
      }
    }
  }, [currentStep, timeout, isBookingConfirmed]);

  useEffect(() => {
    const handlePreBookCleanup = () => clearPreBook(
      'Left unfinished pre-book.',
      preBookIdRef.current,
      appointmentLogIdRef.current,
      appointmentConfirmedRef.current,
    );

    // for page refresh
    window.addEventListener('beforeunload', handlePreBookCleanup);

    return () => {
      handlePreBookCleanup();
      dispatch(cleanBooking());
      window.removeEventListener('beforeunload', handlePreBookCleanup);
    };
  }, []);

  const handleTimeout = (alerter) => {
    LocalStorageService.setValue('alert', true);
    const timeoutLogPayload = {
      booking_log_id: bookingLogId,
      tag: BOOKING_LOG_TYPES.BOOKING_TIMEOUT,
      error: {
        message: 'Booking Timeout',
      },
    };

    saveLogError(timeoutLogPayload)
      .catch(() => { })
      .finally(() => {
        window.removeEventListener('beforeunload', alerter);
        if (ssoToken) {
          addSSOTokenToSearchParams(ssoToken);
        }
        window.location.reload();
      });
  };

  const showTimeoutAlert = () => {
    LocalStorageService.remove('alert');

    let time = '';

    if (process.env.REACT_APP_TIMER_FOR_BOOKING < 600) {
      time = new Date(300 * 1000).toISOString().substring(15, 16);
    } else {
      time = new Date(300 * 1000).toISOString().substring(14, 16);
    }

    toast(<CustomToast type="error" text={`Your booking has timed out. Please try again and complete your booking within ${time} minutes`} />);
  };

  const clearPreBook = (message, id, logId, isConfirmed) => {
    if (id && logId && !isConfirmed) {
      const payload = {
        bookingId: id,
        bookingLogId: logId,
        message,
      };

      deletePreBook(payload).catch(() => {
        toast(<CustomToast type="error" text="Something went wrong!" />);
      }).catch((error) => {
        if (isTimeoutError(error)) {
          saveLogError(timeoutEndpointLogErrorPayload({ bookingLogId, error }));
        }
        toast(<CustomToast type="error" text="Something went wrong!" />);
      }).finally(() => {
        preBookIdRef.current = undefined;
        appointmentLogIdRef.current = undefined;
        appointmentConfirmedRef.current = undefined;
      });
    }
  };

  const changeTimeout = () => {
    setState((prevState) => ({ ...prevState, timeout: true }));
  };

  const isNextStepValid = (step) => {
    switch (step) {
      case STEPS.LOCATION:
        return !!bookingLocation;
      case STEPS.DATE:
        return !!bookingLocation;
      case STEPS.SERVICE_TYPE:
        return !!bookingSelectedServiceType;
      case STEPS.SERVICES:
        if (bookingServices) {
          const selectedServices = bookingServices.map((c) => ({ services: c.services.filter((s) => s.selected) })).filter((c) => c.services.length > 0);
          if (selectedServices.length > 0) {
            if (bookingSelectedServiceType.name === 'In-chair services') {
              // Check if a category is selected but no service picked, if so, the customer cannot proceed (must select a service)
              const activeCategoriesWithSelectedServices = activeCategories.filter(
                (category) => category.subCategories.filter(
                  (subCategory) => subCategory.services.filter((service) => service.selected).length > 0,
                ).length > 0,
              ).flat().length;

              return activeCategoriesWithSelectedServices === activeCategories.length;
            }
            return selectedServices.length > 0;
          }
          return false;
        }
        return false;
      case STEPS.STARTING_TIME:
        return !!bookingTime;
      case STEPS.SERVICE_PROVIDER:
        return !!serviceProviders;
      case STEPS.ADD_ONS: {
        const activeAddOnsCategories = bookingAddOnsUnflattened.filter((addOnCategory) => addOnCategory.isActive);
        if (activeAddOnsCategories.length > 0) {
          // Get all addons categories that are active but with no service selected.
          const categoriesActiveWithNoSelectedServices = activeAddOnsCategories.filter((addOnCategory) => {
            if (addOnCategory.flattened) {
              const activeServices = addOnCategory.services.filter((service) => service.selected);
              return activeServices.length === 0;
            }

            let selectedSubCategoryService = 0;
            addOnCategory.subCategories.forEach((subCategory) => {
              selectedSubCategoryService += subCategory.services.filter((s) => s.selected).length;
            });

            return selectedSubCategoryService === 0;
          });

          return categoriesActiveWithNoSelectedServices.length === 0;
        }

        return true;
      }
      case STEPS.SPECIAL_REQUEST:
        if (bookingSpecialRequest) {
          return bookingSpecialRequest.length > 0;
        }
        return false;
      default:
        return true;
    }
  };

  const chooseStartingTimes = () => {
    if (selectedServiceCategories.length > 0) {
      const servicesForApi = [].concat(...selectedServiceCategories.map((category) => category.services.map((service) => service.service_id)));
      setState((prevState) => ({ ...prevState, buttonIsActive: false, buttonText: 'Processing' }));

      const providerPreferencesLogPayload = {
        booking_log_id: bookingLogId,
        tag: BOOKING_LOG_TYPES.PROVIDER_PREFERENCES,
        data: {
          provider_preferences: preferencesTransformedToLog,
        },
      };

      saveLog(providerPreferencesLogPayload);

      getAvailableStartingTimes({
        bookingDate,
        bookingLocationId: bookingLocation.id,
        services: servicesForApi,
        serviceTypeId: bookingSelectedServiceType.id,
        serviceProviderPreferences: bookingServiceProviderPreferences,
        soakOffHands: wantsSoakOffHands,
        soakOffFeet: wantsSoakOffFeet,
      })
        .then((resp) => {
          dispatch(setBookingAvailableStaringTimes(resp.data));
          dispatch(setBookingAddOns([]));
          goToStep(STEPS.STARTING_TIME);
        })
        .catch((error) => {
          if (isTimeoutError(error)) {
            saveLogError(timeoutEndpointLogErrorPayload({ bookingLogId, error }));
          }
          toast(<CustomToast type="error" text="No available starting times for this date, please try to change the date" />);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, buttonIsActive: true, buttonText: 'Next' }));
        });
    }
  };

  const getServiceProvidersAvailableForServicesSelected = () => {
    if (visitedInTheLastYear) {
      setState((prevState) => ({ ...prevState, buttonText: 'Processing', buttonIsActive: false }));

      const servicesForApi = [].concat(...selectedServiceCategories.map((category) => category.services.map((service) => ({ service_id: service.service_id, service_name: service.service_name }))));

      const logData = { services: servicesForApi };

      // Only TNS/InChair allows for soak off services
      if (!sequentialBooking) {
        logData.soak_off_hands = wantsSoakOffHands;
        logData.soak_off_feet = wantsSoakOffFeet;
      }

      saveLog({
        booking_log_id: bookingLogId,
        tag: BOOKING_LOG_TYPES.SERVICES,
        data: logData,
      });

      getServiceProvidersForServices({ serviceIds: servicesForApi.flatMap((service) => service.service_id), locationId: bookingLocation.id, sequentialBooking })
        .then((resp) => {
          dispatch(bookingServiceProviderForServices(resp.data));
          goToStep(STEPS.SERVICE_PROVIDER_PREFERENCES);
        })
        .catch((error) => {
          if (isTimeoutError(error)) {
            saveLogError(timeoutEndpointLogErrorPayload({ error, bookingLogId }));
          }
          toast(<CustomToast type="error" text="Error fetching service providers for the selected services. Please try again later." />);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, buttonText: 'Next', buttonIsActive: true }));
        });
    } else {
      chooseStartingTimes();
    }
  };

  const chooseServiceProviders = () => {
    const payloadTime = {
      booking_log_id: bookingLogId,
      tag: BOOKING_LOG_TYPES.STARTING_TIME,
      data: { date: bookingDate, time: bookingTime },
    };

    saveLog(payloadTime);

    if (bookingServices) {
      setState((prevState) => ({
        ...prevState, buttonIsActive: false, buttonText: 'Processing', addOn: false,
      }));

      if (selectedServiceCategories.length > 0) {
        const servicesForApi = [].concat(...selectedServiceCategories.map((category) => category.services.map((service) => service.service_id)));

        getServiceProvidersAvailability({
          bookingDate,
          bookingTime,
          bookingLocationId: bookingLocation.id,
          services: servicesForApi,
          soakOffHands: wantsSoakOffHands,
          soakOffFeet: wantsSoakOffFeet,
          bookingLogId,
          serviceTypeId: bookingSelectedServiceType.id,
          bookingServiceProviderPreferences,
        })
          .then((response) => {
            const availabilityResponse = response.data;

            if (!visitedInTheLastYear) {
              availabilityResponse.forEach((service) => {
                service.service_providers.forEach((provider) => {
                  if (provider.system_assigned) {
                    service.identifier_provider_id = provider.service_provider_id;
                    service.system_assigned_provider = provider;
                  }

                  // Because the provider might have already been set by the preferences step
                  if (service.provider === null || service.provider === undefined) {
                    service.provider = '';
                  }
                });
              });
            }

            dispatch(bookingServiceProviders(availabilityResponse));
            dispatch(bookingServiceProvidersSnapshot(availabilityResponse));

            if (!visitedInTheLastYear) {
              addOnOrPrebook(availabilityResponse);
              return;
            }

            const availableServiceProviders = availabilityResponse.map((s) => ({ service_id: s.service_id, service_providers: s.service_providers.filter((sp) => sp.available) }))
              .reduce((filtered, mapped) => {
                filtered[mapped.service_id] = mapped.service_providers.map((sp) => sp.service_provider_id);
                return filtered;
              }, {});

            // check to see if the preferred service providers are still available
            Object.entries(availableServiceProviders).forEach(([sId, availableProviders]) => {
              const providerPreference = bookingServiceProviderPreferences[sId];
              // clear preference if no longer available for each service
              if (!availableProviders.includes(providerPreference)) {
                dispatch(bookingLockServiceProviderPreference(sId, 'any'));
              }
            });

            goToStep(STEPS.SERVICE_PROVIDER);
            setState((prevState) => ({
              ...prevState, buttonIsActive: true, buttonText: 'Next',
            }));
          })
          .catch((error) => {
            if (isTimeoutError(error)) {
              saveLogError(timeoutEndpointLogErrorPayload({ error, bookingLogId }));
            }
            toast(<CustomToast type="error" text="No providers are available for the services chosen at this date." />);
            setState((prevState) => ({ ...prevState, buttonIsActive: true, buttonText: 'Next' }));
          });
      } else {
        toast(<CustomToast type="error" icon={servicesIcon} text="Please select at least one service." />);
        setState((prevState) => ({ ...prevState, buttonIsActive: true, buttonText: 'Next' }));
      }
    } else {
      throw new Error('No services selected, cannot compute availability!');
    }
  };

  const canSelectSoakOffServices = () => {
    if (bookingSelectedServiceType.name === 'In-chair services') {
      const handServices = bookingServices.filter((category) => category.category_type_id === 'hands');
      const feetServices = bookingServices.filter((category) => category.category_type_id === 'feet');
      const selectedAtLeastOneHandsService = handServices.find((category) => category.services.find((service) => service.selected));
      const selectedAtLeastOneFeetService = feetServices.find((category) => category.services.find((service) => service.selected));
      return selectedAtLeastOneHandsService || selectedAtLeastOneFeetService;
    }

    return false;
  };

  const actionForNextStep = () => {
    switch (currentStep) {
      case STEPS.LOCATION:
        chooseLocation();
        break;
      case STEPS.DATE:
        chooseDate();
        break;
      case STEPS.SERVICE_TYPE:
        chooseServiceType();
        break;
      case STEPS.SERVICES:
        if (canSelectSoakOffServices()) {
          goToStep(STEPS.SOAK_OFFS);
        } else {
          getServiceProvidersAvailableForServicesSelected();
        }
        break;
      case STEPS.SOAK_OFFS:
        getServiceProvidersAvailableForServicesSelected();
        break;
      case STEPS.SERVICE_PROVIDER_PREFERENCES:
        chooseStartingTimes();
        break;
      case STEPS.STARTING_TIME:
        chooseServiceProviders();
        break;
      case STEPS.SERVICE_PROVIDER:
        addOnOrPrebook();
        break;
      case STEPS.ADD_ONS:
        if (addOn) {
          onPreBook();
        } else {
          goToStep(STEPS.SPECIAL_REQUEST);
        }
        break;
      case STEPS.SPECIAL_REQUEST:
        goToStep(STEPS.SUMMARY);
        break;
      default:
        throw new Error(`Don't know what to do when clicking next on step ${currentStep}`);
    }
  };

  const previousStep = () => {
    switch (currentStep) {
      case STEPS.SPECIAL_REQUEST: {
        let backStep = null;

        if (!addOn) {
          backStep = STEPS.SERVICE_PROVIDER;
        }

        clearPreBook('Editing booking.', appointmentId, bookingLogId, isBookingConfirmed);

        if (!visitedInTheLastYear) {
          if (addOn) {
            backStep = STEPS.ADD_ONS;
          } else {
            backStep = STEPS.STARTING_TIME;
          }
        } else if (addOn) {
          backStep = STEPS.ADD_ONS;
        } else {
          backStep = STEPS.SERVICE_PROVIDER;
        }

        goToStep(backStep);
        break;
      }
      case STEPS.SERVICES: {
        if (bookingLocation.location_category_id === 2) {
          goToStep(STEPS.DATE);
        } else {
          goToStep(STEPS.SERVICE_TYPE);
        }
        break;
      }
      case STEPS.DATE:
        goToStep(STEPS.LOCATION);
        break;
      case STEPS.STARTING_TIME: {
        if (!visitedInTheLastYear) {
          // If the customer only picked soak off services he will be back at the services step
          // because the preferences page is unavailable, the same applies for old clients
          goToStep(STEPS.SERVICES);
          break;
        }
        goToStep(STEPS.SERVICE_PROVIDER_PREFERENCES);
        break;
      }
      case STEPS.SERVICE_TYPE:
        goToStep(STEPS.DATE);
        break;
      case STEPS.SERVICE_PROVIDER_PREFERENCES:
        if (canSelectSoakOffServices()) {
          goToStep(STEPS.SOAK_OFFS);
        } else {
          goToStep(STEPS.SERVICES);
        }
        break;
      case STEPS.SERVICE_PROVIDER:
        goToStep(STEPS.STARTING_TIME);
        break;
      case STEPS.ADD_ONS:
        if (!visitedInTheLastYear) {
          goToStep(STEPS.STARTING_TIME);
          break;
        }
        goToStep(STEPS.SERVICE_PROVIDER);
        break;
      case STEPS.SUMMARY:
        goToStep(STEPS.SPECIAL_REQUEST);
        break;
      case STEPS.SOAK_OFFS:
        goToStep(STEPS.SERVICES);
        break;
      default:
        throw new Error(`Don't know how to go back from state ${currentStep}`);
    }
  };

  const chooseLocation = () => {
    appointmentLogIdRef.current = bookingLogId;

    if (bookingLocation) {
      const locationLogPayload = {
        booking_log_id: bookingLogId,
        tag: BOOKING_LOG_TYPES.LOCATION,
        data: {
          location_id: bookingLocation.id,
          location_name: bookingLocation.name,
        },
      };
      saveLog(locationLogPayload);
      goToStep(STEPS.DATE);
    }
  };

  const chooseDate = async () => {
    const dateLogPayload = {
      booking_log_id: bookingLogId,
      tag: BOOKING_LOG_TYPES.DATE,
      data: { date: bookingDate },
    };

    saveLog(dateLogPayload);

    if (bookingLocation.location_category_id === 2) {
      const serviceTypePayload = {
        locationId: bookingLocation.id,
        bookingLogId,
      };

      try {
        const response = await getServiceTypes(serviceTypePayload);
        dispatch(bookingServiceType(response.data[0]));
      } catch (error) {
        if (isTimeoutError(error)) {
          saveLogError(timeoutEndpointLogErrorPayload({ error, bookingLogId }));
        }
      } finally {
        goToStep(STEPS.SERVICES);
      }
    } else {
      goToStep(STEPS.SERVICE_TYPE);
    }
  };

  const chooseServiceType = () => {
    if (bookingSelectedServiceType) {
      const serviceTypeLogPayload = {
        booking_log_id: bookingLogId,
        tag: BOOKING_LOG_TYPES.SERVICE_TYPE,
        data: {
          service_type_id: bookingSelectedServiceType.id,
          service_type_name: bookingSelectedServiceType.name,
        },
      };

      saveLog(serviceTypeLogPayload);
      goToStep(STEPS.SERVICES);
    }
  };

  const addOnOrPrebook = (inlineProviders) => {
    const currentServiceProviders = [...(inlineProviders || serviceProviders)];

    const providersLogPayload = {
      booking_log_id: bookingLogId,
      tag: BOOKING_LOG_TYPES.PROVIDERS,
      data: { providers: currentServiceProviders },
    };

    saveLog(providersLogPayload);

    const newObject = (s) => {
      let systemAssigned = false;
      let currProvider = '';

      if (s.provider) {
        currProvider = s.provider;
      } else {
        systemAssigned = true;
        currProvider = s.system_assigned_provider;
      }

      const [currCategory] = bookingServices.filter((category) => category.services.filter((service) => service.service_id === s.service_id).length > 0);

      return {
        service_id: s.service_id,
        service_provider_id: currProvider.service_provider_id,
        system_assigned: systemAssigned,
        category_id: currCategory.category_id,
        end_time: currProvider.end_time,
      };
    };

    const mostPerformed = (addOnServiceA, addOnServiceB) => addOnServiceB.count - addOnServiceA.count;

    // Only in chair services allow for add ons
    if (bookingSelectedServiceType.name === 'In-chair services') {
      if (bookingAddOns.length === 0) {
        getAddOnsAvailability({
          bookingLogId,
          bookingDate,
          locationId: bookingLocation.id,
          /**
           * Ignore soak off categories because there's no add ons for soak offs
          */
          serviceProviders: ignoreSoakOffServices(currentServiceProviders).map((sp) => newObject(sp)),
        })
          .then((res) => {
            // Prepare addons data for the add ons screen
            const handsAndFeetAddons = res.data.filter((addOnCategory) => ADD_ONS_WITH_NO_SUBCATEGORIES.includes(addOnCategory.category_name));

            const prepareServiceDataToStore = (s) => ({
              ...s,
              selected: false,
            });

            const facialInChairCategory = res.data.find((addOnCategory) => addOnCategory.category_name === ADD_ON_FACIAL_IN_CHAIR);
            let facialInChairCategoryNormalized = null;
            if (facialInChairCategory) {
              facialInChairCategoryNormalized = {
                ...facialInChairCategory,
                services: facialInChairCategory.services.map(prepareServiceDataToStore).sort(mostPerformed),
              };
            }

            const threadingCategory = res.data.find((addOnCategory) => addOnCategory.category_name === ADD_ON_THREADING);
            let threadingCategoryNormalized = null;
            if (threadingCategory) {
              threadingCategoryNormalized = {
                ...threadingCategory,
                services: threadingCategory.services.map(prepareServiceDataToStore).sort(mostPerformed),
              };
            }

            const massageInChairCategory = res.data.find((addOnCategory) => addOnCategory.category_name === MASSAGE_IN_CHAIR);
            let massageInChairCategoryNormalized = null;
            if (massageInChairCategory) {
              massageInChairCategoryNormalized = {
                ...massageInChairCategory,
                services: massageInChairCategory.services.map(prepareServiceDataToStore).sort(mostPerformed),
              };
            }

            const faceBodySubCategoriesNormalized = [
              facialInChairCategoryNormalized,
              threadingCategoryNormalized,
              massageInChairCategoryNormalized,
            ].filter((category) => category !== null);

            // These Addons have subcategories inside them (just like the services step)
            const faceBodyAddOnsCategoryNormalized = {
              categoryId: 'face_body',
              name: ADD_ON_FACE_BODY_CATEGORY,
              flattened: false,
              isOpen: false,
              isActive: false,
              subCategories: faceBodySubCategoriesNormalized,
            };

            // These AddOns do not have sub categories inside them (the categories are flattened)
            const handsAndFeetAddonsDataNormalized = handsAndFeetAddons.map((row) => ({
              categoryId: row.category_id,
              name: row.category_name,
              flattened: true,
              isOpen: false,
              isActive: false,
              services: row.services.map(prepareServiceDataToStore).sort(mostPerformed),
            }));

            const addOnsData = [handsAndFeetAddonsDataNormalized];

            if (faceBodyAddOnsCategoryNormalized.subCategories.length > 0) {
              addOnsData.push(faceBodyAddOnsCategoryNormalized);
            }

            const addOnsCategoriesOrdered = addOnsData.flat().sort((addOnCatA, addOnCatB) => (
              PREFERRED_ADD_ONS_CATEGORY_ORDER.indexOf(addOnCatA.name) - PREFERRED_ADD_ONS_CATEGORY_ORDER.indexOf(addOnCatB.name)
            ));

            dispatch(setBookingAddOns(addOnsCategoriesOrdered));
            goToStep(STEPS.ADD_ONS);
            setState((prevState) => ({
              ...prevState,
              buttonIsActive: true,
              buttonText: 'Next',
              addOn: true,
            }));
          })
          .catch((error) => {
            if (isTimeoutError(error)) {
              saveLogError(timeoutEndpointLogErrorPayload({ error, bookingLogId }));
            }
            const AddonsLogPayload = {
              booking_log_id: bookingLogId,
              tag: BOOKING_LOG_TYPES.ADD_ONS,
              data: {
                message: 'No Add-Ons where found',
              },
            };

            saveLog(AddonsLogPayload).finally(() => onPreBook({ nextStep: STEPS.SPECIAL_REQUEST, inlineProviders: currentServiceProviders }));
          });
      } else {
        goToStep(STEPS.ADD_ONS);
      }
    } else {
      onPreBook({ nextStep: STEPS.SPECIAL_REQUEST, inlineProviders: currentServiceProviders });
    }
  };

  const onPreBook = ({ nextStep = STEPS.ADD_ONS, inlineProviders = undefined } = {}) => {
    setState((prevState) => ({ ...prevState, buttonIsActive: false, buttonText: 'Processing...' }));

    const currentServiceProviders = [...(inlineProviders || serviceProviders)];
    if (addOn) {
      bookingAddOns.map((cat) => cat.services.filter((serv) => serv.selected)).filter((catSelected) => catSelected.length).forEach((servSelected) => {
        const currentAddon = servSelected[0];
        const associatedService = currentServiceProviders.filter((s) => s.service_id === currentAddon.service_associated_id);

        const setSystemAssignedProvider = (addon, provider) => {
          addon.system_assigned_provider = provider;
        };

        if (associatedService.length > 0) {
          if (associatedService[0].provider) {
            // eslint-disable-next-line prefer-destructuring
            currentAddon.provider = currentAddon.service_providers[0];
          } else {
            setSystemAssignedProvider(currentAddon, currentAddon.service_providers[0]);
          }
        } else {
          setSystemAssignedProvider(currentAddon, currentAddon.service_providers[0]);
        }

        currentAddon.addon = true;
        currentServiceProviders.push(currentAddon);
      });

      const addonsLogPayload = {
        booking_log_id: bookingLogId,
        tag: BOOKING_LOG_TYPES.ADD_ONS,
        data: {
          providers: currentServiceProviders,
        },
      };

      saveLog(addonsLogPayload);
    }

    preBook({
      locationId: bookingLocation.id,
      serviceProviders: currentServiceProviders,
      bookingLogId,
      appointmentId,
      movableServices,
    })
      .then((response) => {
        preBookIdRef.current = response.data.appointment_id;
        dispatch(bookingAppointment(response.data.appointment_id));
        dispatch(bookingAppointmentEndTime(response.data.appointment_end_time));
        if (addOn) {
          goToStep(STEPS.SPECIAL_REQUEST);
        } else {
          goToStep(nextStep);
        }
        setState((prevState) => ({
          ...prevState,
          buttonIsActive: true,
          buttonText: 'Next',
        }));
      }).catch((err) => {
        if (get(err, 'response.status')) {
          if (err.response.status === 409) {
            toast(<CustomToast
              type="error"
              text={`${err.response.data.error_title}. ${err.response.data.error_msg}`}
            />);
          } else if (user.is_impersonating) {
            // TODO: This should be refactored when impersonating is implemented correctly
            // mock backend returned end date
            // let duration = 0;
            // const bookedServices = payload.service_service_providers.filter((s) => s.addon !== true);
            // const addOnServices = payload.service_service_providers.filter((s) => s.addon === true);
            // if (sequentialBooking) {
            //   bookedServices.forEach((service) => {
            //     duration += service.system_assigned_service_length_in_minutes;
            //   });
            // } else {
            //   duration = Math.max(...bookedServices.map((b) => b.system_assigned_service_length_in_minutes));
            // }

            // // add addOn time
            // if (addOnServices.length > 0) {
            //   duration += Math.max(...addOnServices.map((b) => b.system_assigned_service_length_in_minutes));
            // }

            // const finishTime = new Date();
            // const timeSplit = bookingTime.split(':');
            // finishTime.setHours(parseInt(timeSplit[0], 10));
            // finishTime.setMinutes(parseInt(timeSplit[1], 10) + duration);

            // dispatch(bookingAppointmentEndTime(finishTime.toISOString()));
            // setState((prevState) => ({
            //   ...prevState, step: step + (addOn ? 1 : 2), buttonIsActive: true, buttonText: 'Next',
            // }));
          } else {
            toast(<CustomToast type="error" text="The appointment could not be created!" />);
          }
        }
        setState((prevState) => ({ ...prevState, buttonIsActive: true, buttonText: 'Next' }));
      });
  };

  const onConfirmBooking = () => {
    setState((prevState) => ({ ...prevState, buttonIsActive: false, buttonConfirmText: 'Processing...' }));

    confirmBooking({ appointmentId, bookingLogId })
      .then(() => {
        goToStep(STEPS.SUMMARY_CONFIRMED);
        setState((prevState) => ({
          ...prevState, confirmed: true, buttonIsActive: true, buttonConfirmText: 'Confirm Booking',
        }));
      })
      .catch((err) => {
        if (isTimeoutError(err)) {
          saveLogError(timeoutEndpointLogErrorPayload({ error: err, bookingLogId }));
        }
        if (get(err, 'response.data.error_msg')) {
          toast(<CustomToast
            type="error"
            text={`${err.response.data.error_title} ${err.response.data.error_msg}.`}
          />);
          setState((prevState) => ({ ...prevState, buttonIsActive: true, buttonConfirmText: 'Confirm Booking' }));
        }
      });
  };

  const renderStep = () => {
    switch (currentStep) {
      case STEPS.LOCATION:
        return <ChooseLocation color={level.level_color} />;
      case STEPS.DATE:
        return <ChooseDate color={level.level_color} />;
      case STEPS.SERVICE_TYPE:
        return <ChooseServiceType color={level.level_color} />;
      case STEPS.SERVICES: {
        // The "InRoom" and "Styling Stations" services screen will still be the old component for now
        if (bookingSelectedServiceType.name === 'In-chair services') {
          return <ChooseServiceWithCategoryTypes />;
        }

        return <ChooseService color={level.level_color} />;
      }
      case STEPS.SOAK_OFFS:
        return <SoakOffs />;
      case STEPS.SERVICE_PROVIDER_PREFERENCES:
        return <LockServiceProviders color={level.level_color} />;
      case STEPS.STARTING_TIME:
        return <ChooseStartingTime />;
      case STEPS.SERVICE_PROVIDER:
        return <ChooseServiceProvider color={level.level_color} />;
      case STEPS.ADD_ONS:
        return <ChooseAddOnsWithCategories />;
      case STEPS.SPECIAL_REQUEST:
        return <SpecialRequest color={level.level_color} />;
      case STEPS.SUMMARY:
        return <Summary color={level.level_color} />;
      case STEPS.SUMMARY_CONFIRMED:
        appointmentConfirmedRef.current = confirmed;
        dispatch(bookingConfirmed(confirmed));
        return <Summary confirmed={confirmed} color={level.level_color} />;
      default:
        return '';
    }
  };

  const renderButtons = () => {
    switch (currentStep) {
      case STEPS.SPECIAL_REQUEST:
        return (
          <button
            type="button"
            onClick={actionForNextStep}
            className="button-primary flex justify-center md:w-1/2 sm: w-full"
            style={{ backgroundColor: level.level_color, border: `.0625rem solid ${level.level_color}` }}
          >
            {isNextStepValid(currentStep) ? 'Next' : 'Skip'}
          </button>
        );
      case STEPS.SUMMARY:
        return (
          <button
            type="button"
            onClick={onConfirmBooking}
            className={buttonIsActive && !user.is_impersonating ? 'button-primary flex justify-center md:w-1/2 sm: w-full' : 'button-grey flex justify-center md:w-1/2 sm: w-full'}
            style={buttonIsActive && !user.is_impersonating ? { backgroundColor: level.level_color, border: `.0625rem solid ${level.level_color}` } : {}}
          >
            {buttonConfirmText}
          </button>
        );
      case STEPS.SUMMARY_CONFIRMED:
        return (
          <div className="flex flex-col md:w-1/2 sm: w-full">
            <button
              type="button"
              onClick={() => window.location.reload()}
              className="button-primary flex justify-center md:w-1/2 sm: w-full"
              style={{ backgroundColor: level.level_color, border: `.0625rem solid ${level.level_color}` }}
            >
              Book Another Session
            </button>
          </div>
        );
      default:
        return (
          <button
            type="button"
            onClick={actionForNextStep}
            className={(isNextStepValid(currentStep) && buttonIsActive) ? `button-primary-lvl${level.id} flex justify-center md:w-1/2 sm: w-full` : 'button-grey flex justify-center md:w-1/2 sm: w-full'}
          >
            {buttonIsActive ? buttonText : <Loading color="white" backgroundColor="var(--color-grey)" size={2} iconOnly />}
          </button>
        );
    }
  };

  return (
    <div className="flex flex-col h-full">
      <DashboardHeader color={level.level_color} title="Bookings" />
      <div className="flex justify-center w-full booking-title">
        <BookingLine
          back={previousStep}
          currentStep={currentStep}
          color={level.level_color}
          currentLocation={bookingLocation.location_category_id}
        />
      </div>
      <div id="container-height" className="flex justify-center w-full body-container booking-container h-full view-boundaries-bottom">
        {renderStep()}
      </div>
      <div className="footer footer-booking">
        <div className="footer-buttons">
          {(STEPS_WITH_TIMER.includes(currentStep) && BeyoutyBonus.config.bookings.TimerActive) && (
            <div className="timer">
              <Timer callTimeout={changeTimeout} />
            </div>
          )}
          {renderButtons()}
        </div>
      </div>
    </div>
  );
};

export default compose(
  withUserRetriever,
)(Booking);
