const errorMessage = (state = '', action) => {
  switch (action.type) {
    case 'ERROR_MESSAGE':
      return action.message;
    default:
      return state;
  }
};

export default errorMessage;
