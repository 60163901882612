import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { CustomToast } from './Shared/withToast';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../assets/js/constants';

import axios from '../interceptor';

import '../assets/css/signIn.scss';
import '../assets/css/shared.scss';

import imageBackground from '../assets/img/sign_in_background.png';
import logoTns from '../assets/img/logo_tns_black.svg';
import logoMarquee from '../assets/img/logo_marquee_black.svg';
import eyeClosedIcon from '../assets/icons/eye_closed.svg';
import eyeOpenIcon from '../assets/icons/eye_open.svg';
import { ReactComponent as BackIcon } from '../assets/icons/arrow_left.svg';
import LogInLogs from '../api/LogInLogs';
import { USER_SESSION_LOG_TYPES } from '../utils/api';

function ForgotPassword() {
  const { logSuccess, logError } = LogInLogs();

  const [state, setState] = useState({
    password: '',
    passwordConfirmation: '',
    hasLengthUserError: false,
    hasLengthConfirmError: false,
    hasMatchingError: false,
    lengthErrorText: 'Password must have at least 6 characters!',
    matchingErrorText: 'Passwords must match!',
    processing: false,
    passwordShown: false,
    passwordShownConfirmation: false,
  });

  const {
    password,
    passwordConfirmation,
    processing,
    passwordShown,
    passwordShownConfirmation,
    hasLengthUserError,
    hasLengthConfirmError,
    hasMatchingError,
    lengthErrorText,
    matchingErrorText,
  } = state;

  const dispatch = useDispatch();
  const history = useHistory();

  function togglePasswordVisibility() {
    setState((prevState) => ({ ...prevState, passwordShown: !passwordShown }));
  }

  function togglePasswordConfirmVisibility() {
    setState((prevState) => ({ ...prevState, passwordShownConfirmation: !passwordShownConfirmation }));
  }

  useEffect(() => {
    const url = new URL(window.location.href);
    const logInLogId = url.searchParams.get('logInLogId');

    if (logInLogId) {
      const payload = {
        value: 'Clicked Change Password Email',
        logInLogId,
        tag: USER_SESSION_LOG_TYPES.CLICKED_CHANGE_PASSWORD_EMAIL,
      };

      logSuccess(payload)
        .then((res) => {
          dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: res.data.log_in_log_id });
        });
    }

    if (!validMatch(passwordConfirmation)) {
      setState((prevState) => ({ ...prevState, hasMatchingError: true }));
    }
  }, []);

  function validLength(pass) {
    return pass.length >= MIN_PASSWORD_LENGTH;
  }

  function validMatch(pass, passConfirm) {
    return pass === passConfirm;
  }

  function resetPassword() {
    setState((prevState) => ({ ...prevState, processing: true }));

    const url = new URL(window.location.href);
    const payload = {
      password,
      password_confirmation: passwordConfirmation,
      uid: url.searchParams.get('uid'),
      'access-token': url.searchParams.get('access-token'),
      client: url.searchParams.get('client'),
    };

    let logPayload = {
      value: 'Password Changed',
      logInLogId: url.searchParams.get('logInLogId'),
      tag: USER_SESSION_LOG_TYPES.PASSWORD_CHANGED,
    };

    axios.put('/api/v1/client_auth/password', payload)
      .then((res) => {
        logSuccess(logPayload)
          .then((logRes) => {
            dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: logRes.data.log_in_log_id });
          });

        history.push('/clients/sign_in');
        toast(<CustomToast type="success" text={res.data.message} />);
      })
      .catch(async (err) => {
        logPayload = { ...logPayload, error: err.response.data.errors.full_messages };
        await logError(logPayload)
          .then((res) => {
            dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: res.data.log_in_log_id });
          });
        toast(<CustomToast type="error" text={err.response.data.errors.full_messages} />);
      });
  }

  function handleChangeUserPassword(e) {
    const isLengthError = !validLength(e.target.value);
    const isMatchError = !validMatch(passwordConfirmation, e.target.value);
    setState((prevState) => ({
      ...prevState,
      password: e.target.value,
      hasLengthUserError: isLengthError,
      hasMatchingError: isMatchError,
    }));
  }

  function handleChangeConfirmPassword(e) {
    const isLengthError = !validLength(e.target.value);
    const isMatchError = !validMatch(password, e.target.value);
    setState((prevState) => ({
      ...prevState,
      passwordConfirmation: e.target.value,
      hasLengthConfirmError: isLengthError,
      hasMatchingError: isMatchError,
    }));
  }

  return (
    <div className="sign-in-page-container" style={{ backgroundImage: `url(${imageBackground})` }}>
      <div className="sign-in-page">
        <div className="logos my-10">
          <div className="pr-5">
            <img className="logo-tns-sign-in" src={logoTns} alt="" />
          </div>
          <div>
            <img className="logo-marquee-sign-in" src={logoMarquee} alt="" />
          </div>
        </div>
        <div className="flex self-center md:w-1/2 sm:px-0 w-full">
          <div className="flex flex-col px-5 w-full items-center">
            <div className="tab-container">
              <div className="reset-title flex" style={{ alignItems: 'center' }}>
                <div role="button" tabIndex={0} onClick={() => history.push('/clients/sign_in')}>
                  <BackIcon className="back-icon-forgot-password" stroke="#724467" />
                </div>
                <span>Reset Password</span>
              </div>
              <span className="tab-text">Type your new password, that will be used from now on.</span>
              <p />
              <div className="relative">
                <span className="label-pwd text-primary py-3">New Password</span>
                <input
                  id="password"
                  value={password}
                  placeholder="Your New Password"
                  required="required"
                  minLength={MIN_PASSWORD_LENGTH}
                  maxLength={MAX_PASSWORD_LENGTH}
                  onChange={handleChangeUserPassword}
                  className={
                    hasLengthUserError || hasMatchingError
                      ? 'input-signin-error mb-3 mt-2'
                      : 'input-signin mb-3 mt-2'
                  }
                  type={passwordShown ? 'text' : 'password'}
                />
                <div role="button" tabIndex={0} className="pass-icon-new" onClick={() => togglePasswordVisibility()}>
                  <img src={passwordShown ? eyeOpenIcon : eyeClosedIcon} alt="" />
                </div>
                {
                  hasLengthUserError && (
                    <div className="current-error -mt-1 mb-2">
                      {hasLengthUserError ? lengthErrorText : ''}
                    </div>
                  )
                }
              </div>
              <div className="relative">
                <span className="label-pwd text-primary pb-3">Confirm Password</span>
                <input
                  id="passwordConfirmation"
                  value={passwordConfirmation}
                  placeholder="Your New Password"
                  required="required"
                  minLength={MIN_PASSWORD_LENGTH}
                  maxLength={MAX_PASSWORD_LENGTH}
                  onChange={handleChangeConfirmPassword}
                  className={
                    hasLengthConfirmError || hasMatchingError
                      ? 'input-signin-error mt-2'
                      : 'input-signin mt-2'
                  }
                  type={passwordShownConfirmation ? 'text' : 'password'}
                />
                <div role="button" tabIndex={0} className="pass-icon-new" onClick={() => togglePasswordConfirmVisibility()}>
                  <img src={passwordShownConfirmation ? eyeOpenIcon : eyeClosedIcon} alt="" />
                </div>
                {
                  (hasLengthConfirmError || hasMatchingError) && (
                  <div className="current-error my-2">
                    {hasLengthConfirmError ? lengthErrorText : ''}
                    {(hasLengthConfirmError && hasMatchingError) && <br />}
                    {hasMatchingError ? matchingErrorText : ''}
                  </div>
                  )
                }
              </div>
              <button
                type="button"
                onClick={() => resetPassword()}
                disabled={processing || hasLengthUserError || hasLengthConfirmError || hasMatchingError}
                className={!(processing || hasLengthUserError || hasLengthConfirmError || hasMatchingError)
                  ? 'button-sign-in flex justify-center md:w-1/2 sm: w-full mt-8'
                  : 'button-grey flex justify-center md:w-1/2 sm: w-full mt-8'}
              >
                {processing ? 'Processing...' : 'SUBMIT'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
