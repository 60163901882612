/* eslint-disable import/no-duplicates */
import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import { toast } from 'react-toastify';

import axios from '../../interceptor';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/triangle/down.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/triangle/right.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as IconServices } from '../../assets/icons/services.svg';
import BookingTimingOptionsModal from './BookingTimingOptionsModal';
import iconServices from '../../assets/icons/services.svg';

import '../../assets/css/booking/chooseService.scss';
import '../../assets/css/shared.scss';
import { CustomToast } from '../Shared/withToast';
import BookingsApi from '../../api/Booking';
import Loading from '../Loading';
import {
  bookingAddOns,
  bookingRemoveAllProviders,
  bookingRestrictions,
  bookingServiceProviders,
  bookingServices,
  clearBookingStartingTimes,
} from '../../actions/bookings';
import {
  getBookingLocation,
  getBookingLogId,
  getBookingServiceRestrictions,
  getBookingServices,
  getBookingServiceType,
} from '../../selectors/booking';
import { timeoutEndpointLogErrorPayload } from './consts';
import { isTimeoutError } from '../../utils/api';

const { saveLogError } = BookingsApi();

// TODO: Only used for InRoom and Styling Stations services.
// When we use the new designs for those pages, we can get rid of this component.
function ChooseService({ color }) {
  const [state, setState] = useState({
    bookingLocation: getBookingLocation() || '',
    bookingLogId: getBookingLogId() || '',
    services: getBookingServices() || [],
    servicesRestriction: getBookingServiceRestrictions() || [],
    serviceType: getBookingServiceType() || '',
    search: '',
    servicesChosen: [],
    showServicesChosen: false,
    bottom: 147,
    isLoading: (getBookingServices() || []).length === 0,
    currentLevel: useSelector((reduxState) => reduxState.user).customer_loyalty_level.loyalty_level_id,
  });

  const {
    bookingLocation,
    bookingLogId,
    services,
    search,
    serviceType,
    servicesRestriction,
    servicesChosen,
    showServicesChosen,
    bottom,
    isLoading,
    currentLevel,
  } = state;
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById('container-height').style.marginBottom = '95px';

    const payload = {
      location_id: bookingLocation.id,
      service_type_id: serviceType.id,
      booking_log_id: bookingLogId,
    };

    if (services.length === 0) {
      axios.post('/api/v1/appointments/services', payload)
        .then((res) => {
          res.data.services_per_category.forEach((category) => {
            category.selected = false;
            category.services.forEach((service) => {
              service.selected = false;
              service.locked = false;
            });
          });

          dispatch(bookingRestrictions(res.data.services_category_restrictions));

          setState((prevState) => ({ ...prevState, services: res.data.services_per_category, servicesRestriction: res.data.services_category_restrictions }));
        })
        .catch((error) => {
          if (isTimeoutError(error)) {
            saveLogError(timeoutEndpointLogErrorPayload({ error, bookingLogId }));
          }
          toast(<CustomToast type="error" icon={iconServices} text="Services couldn't be retrieved!" />);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });
    } else {
      [].concat(...services.map((cat) => cat.services.filter((serv) => serv.selected)).filter((catSelected) => catSelected.length)).forEach((service) => {
        servicesChosen.push(service);
      });

      if (servicesChosen.length > 0) {
        document.getElementById('container-height').style.marginBottom = '140px';
      }

      setState((prevState) => ({ ...prevState, servicesChosen, isLoading: false }));
    }

    return () => {
      const container = document.getElementById('container-height');
      if (container) {
        container.style.marginBottom = '95px';
      }
    };
  }, []);

  function handleChange(e) {
    const { id, value } = e.target;

    services.forEach((cat) => { cat.selected = true; });

    setState((prevState) => ({ ...prevState, [id]: value, services }));
  }

  function toggleCategory(id) {
    const value = services.filter((c) => c.category_id === id)[0].selected;
    services.filter((c) => c.category_id === id)[0].selected = !value;

    setState((prevState) => ({ ...prevState, services }));
  }

  function selectService(event, categoryId, serviceId) {
    if (event.target && event.target.tagName === 'INPUT') {
      return;
    }

    dispatch(clearBookingStartingTimes());

    let currServicesChosen = servicesChosen;
    let currBottom = 147;
    const currServices = services;
    const maxServicesForCurrentCategory = 1;

    // confirm selected service
    const currentCategory = currServices.filter((category) => category.category_id === categoryId);
    if (currentCategory.length > 0) {
      const currentServices = currentCategory[0].services.filter((service) => service.service_id === serviceId);

      if (currentServices.length > 0) {
        const selectedService = currentServices[0];
        // toggled an unselected service, add it to chosen services
        if (!selectedService.selected) {
          currServicesChosen.push(selectedService);
          selectedService.selected = true;
          // toggled a selected service, remove it from chosen services
        } else {
          currServicesChosen = removeFromArray(currServicesChosen, selectedService.service_id);
          selectedService.selected = false;
        }
        currBottom = realocateToggleServiceChosenIcon(currServicesChosen);
      }
    }

    const countOfCurrentSelectedServices = currServicesChosen.length;

    // in-room (upto 3 services between the categories waxing, threading/tinting).
    if (bookingLocation.location_category_id === 1) { // TNS
      if (serviceType.name === 'In-room services') { // in-room
        handleGlobalServiceToggle(currentCategory, categoryId, currServices, currServicesChosen, currBottom);
      } else if (countOfCurrentSelectedServices < 3) { // in-chair
        toggleServicesPerCategory(categoryId, currServices, currServicesChosen, maxServicesForCurrentCategory, currBottom);
      } else { // lock every service, at this point the maximum has been reached
        lockAllServices(services, currServicesChosen, currBottom);
      }
    } else if (bookingLocation.location_category_id === 2) { // MARQUEE
      handleGlobalServiceToggle(currentCategory, categoryId, currServices, currServicesChosen, currBottom);
    }

    if (countOfCurrentSelectedServices > 0) {
      document.getElementById('container-height').style.marginBottom = '140px';
    } else {
      document.getElementById('container-height').style.marginBottom = '95px';
    }

    dispatch(bookingServices(cloneDeep(currServices)));
    dispatch(bookingRemoveAllProviders());
    dispatch(bookingAddOns([]));
    dispatch(bookingServiceProviders(''));

    setState((prevState) => ({
      ...prevState, services: currServices, servicesChosen: currServicesChosen, bottom: currBottom,
    }));
  }

  function realocateToggleServiceChosenIcon(currServicesChosen) {
    if (showServicesChosen) {
      if (currServicesChosen.length === 1) {
        return 201;
      }
      return 167 + (39 * currServicesChosen.length);
    }

    return 147;
  }

  function handleGlobalServiceToggle(category, categoryId, locationServices, chosenServices, currBottom) {
    if (category.length === 0 || chosenServices.length === 0) {
      enableAllServices(locationServices, chosenServices, currBottom);
    } else if (chosenServices.length <= 3) {
      toggleServices(categoryId, locationServices, chosenServices, 3, currBottom);
    } else { // other categories
      lockAllServices(services, chosenServices, currBottom);
    }
  }

  /**
   * Toggle the services that can be chosen "globally".
   * The {limitToggles} parameter works "globally" for
   * the categories to which restrictions do not apply.
   */
  function toggleServices(categoryId, currServices, currServicesChosen, limitToggles, currBottom) {
    if (currServicesChosen.length >= limitToggles) {
      lockServicesWithCondition(currServices, currServices, () => true);
    } else {
      unlockServicesWithCondition(currServices, () => true);
    }

    applyServiceRestrictions(categoryId, currServices, currServicesChosen);

    setState((prevState) => ({
      ...prevState, services: currServices, servicesChosen: currServicesChosen, bottom: currBottom,
    }));
  }

  /**
   * Toggle services per category. The {limitToggles} parameter works
   * locally to the category where the services are chosen.
   * Restrictions apply independently of the {limitToggle}.
   */
  function toggleServicesPerCategory(categoryId, currServices, currServicesChosen, limitToggles, currBottom) {
    // per category requires finer control for the limitToggles, validate the limit for the category
    const servicesChosenPerCategory = currServicesChosen.filter((chosenService) => chosenService.category_id === categoryId);
    const categoryPredicate = (category) => category.category_id === categoryId;
    if (servicesChosenPerCategory.length >= limitToggles) {
      lockServicesWithCondition(currServices, servicesChosenPerCategory, categoryPredicate);
    } else {
      unlockServicesWithCondition(currServices, categoryPredicate);
    }

    applyServiceRestrictions(categoryId, currServices, servicesChosenPerCategory);

    setState((prevState) => ({
      ...prevState, services: currServices, servicesChosen: currServicesChosen, bottom: currBottom,
    }));
  }

  function unlockServicesWithCondition(currServices, unlockCategoryCondition) {
    currServices.forEach((category) => {
      if (unlockCategoryCondition(category)) {
        category.services.forEach((service) => {
          service.locked = false;
        });
      }
    });
  }

  function lockServicesWithCondition(currServices, chosenServices, lockCategoryCondition) {
    currServices.forEach((category) => {
      if (lockCategoryCondition(category)) {
        category.services.forEach((service) => {
          if (!includesInArray(chosenServices, service.service_id)) {
            service.locked = true;
          }
        });
      }
    });
  }

  /**
   * Function that applies the restrictions for a given category.
   * It locks or unlocks restrictions for the currently selected category
   * depending on the {lock} parameter.
   * If a service selection from a category is removed, so are the restrictions
   * for said service.
   */
  function applyServiceRestrictions(categoryId, currServices, chosenServices) {
    const restriction = servicesRestriction.filter((sr) => sr[0] === categoryId);
    if (restriction.length > 0) {
      const forbiddenCategories = restriction[0][1].split(',').map((id) => parseInt(id, 10));
      // lock restrictions if there selected services
      const lock = chosenServices.length !== 0;

      currServices.filter((category) => forbiddenCategories.includes(category.category_id)).forEach((category) => {
        category.services.forEach((service) => {
          service.locked = lock;
        });
      });
    }
  }

  function lockAllServices(currServices, currServicesChosen, currBottom) {
    currServices.forEach((category) => {
      category.services.forEach((service) => {
        if (!currServicesChosen.includes(service)) {
          service.locked = true;
        }
      });
    });

    setState((prevState) => ({
      ...prevState, services: currServices, servicesChosen: currServicesChosen, bottom: currBottom,
    }));
  }

  function enableAllServices(currServices, currServicesChosen, currBottom) {
    currServices.forEach((category) => {
      category.services.forEach((service) => {
        if (!currServicesChosen.includes(service)) {
          service.locked = false;
        }
      });
    });

    setState((prevState) => ({
      ...prevState, services: currServices, servicesChosen: currServicesChosen, bottom: currBottom,
    }));
  }

  function removeFromArray(array, item) {
    return array.filter((service) => service.service_id !== item);
  }

  function includesInArray(array, item) {
    return array.some((service) => service.service_id === item);
  }

  function serviceClass(service) {
    let className = '';

    if (service.selected) {
      className = `service-container-selected-lvl${currentLevel}`;
    } else {
      className = service.locked ? 'service-container-locked' : 'service-container';
    }

    return className;
  }

  function openServicesChosen() {
    let currBottom = 147;

    if (!showServicesChosen) {
      if (servicesChosen.length === 1) {
        currBottom = 201;
      } else {
        currBottom = 162 + (39 * servicesChosen.length);
      }
    }

    setState((prevState) => ({ ...prevState, showServicesChosen: !showServicesChosen, bottom: currBottom }));
  }

  function renderServices(catId, categoryServices) {
    const retServices = categoryServices.filter((serv) => serv.service_name.toLowerCase().includes(search.toLowerCase())).map((service) => (
      <div role="button" tabIndex={0} key={service.service_id} className={serviceClass(service)} onClick={(e) => selectService(e, catId, service.service_id)}>
        <span className="service">{service.service_name}</span>
        <label className="flex custom-checkbox-container-table">
          <input type="checkbox" checked={service.selected} onChange={() => { }} />
          <span className={service.locked ? `custom-checkbox-table-lvl${currentLevel} disable` : `custom-checkbox-table-lvl${currentLevel} enable`} />
        </label>
      </div>
    ));

    return retServices;
  }

  function renderServicesCategories() {
    const retCategory = services.map((category) => (
      <div role="button" tabIndex={0} key={category.category_id} className="service-category">
        <div role="button" tabIndex={0} onClick={() => toggleCategory(category.category_id)} className="category-name">
          {
            category.selected
              ? <ArrowDownIcon className="arrow-icon" alt="" style={{ fill: color }} />
              : <ArrowRightIcon className="arrow-icon" alt="" style={{ fill: color }} />
          }
          <span style={{ color }}>{category.category_name}</span>
        </div>
        {
          category.selected
            ? (
              <div className="services">
                {renderServices(category.category_id, category.services)}
              </div>
            ) : ''
        }
      </div>
    ));

    return retCategory;
  }

  function renderServicesChosen() {
    const retServices = servicesChosen.map((service) => (
      <div key={`${service.service_id}-chosen`} className="service-chosen">
        <span className="service-chosen-text">{service.service_name}</span>
        <div role="button" tabIndex={0} onClick={(e) => selectService(e, service.category_id, service.service_id)}>
          <CloseIcon className="close-icon" alt="" style={{ fill: color }} />
        </div>
      </div>
    ));

    return retServices;
  }

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full">
      <BookingTimingOptionsModal />
      <div className="separator" />
      {
        isLoading
          ? <Loading color={color} backgroundColor="white" text="Services" />
          : (
            <div className="w-full pb-6 overflow-y-scroll scrollbar-none">
              <div className="search-container">
                <SearchIcon className="search-icon absolute" alt="" />
                <input id="search" className="input-primary w-full" onChange={(e) => handleChange(e)} placeholder="Search for a Service" type="text" value={search} />
              </div>
              <div className="flex flex-col w-full px-5 overflow-scroll scrollbar-none">
                <div className="w-full services-container">
                  {renderServicesCategories()}
                </div>
                {servicesChosen.length > 0
                  ? (
                    <div className="w-full px-5">
                      <div role="button" tabIndex={0} style={{ bottom: `${bottom}px`, backgroundColor: color }} onClick={() => openServicesChosen()} className="toggle-services-chosen">
                        {showServicesChosen
                          ? (<CloseIcon className="close-services-chosen-icon" alt="" style={{ fill: 'white' }} />)
                          : (<IconServices className="close-services-chosen-icon" alt="" style={{ fill: 'white' }} />)}
                      </div>
                      <div className="services-chosen-container">
                        <div className="services-chosen">
                          <span className="services-chosen-text" style={{ color }}>
                            {`${servicesChosen.length} ${servicesChosen.length > 1 ? 'Services' : 'Service'} Selected`}
                          </span>
                          {showServicesChosen
                            ? (
                              <div className="services-chosen-list">
                                {renderServicesChosen()}
                              </div>
                            ) : ''}
                        </div>
                      </div>
                    </div>
                  ) : ''}
              </div>
            </div>

          )
      }
    </div>
  );
}

ChooseService.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ChooseService;
