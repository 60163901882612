import { ApiGet, ApiPost } from './Api';

/**
 * Registration API calls
 */
export default () => ({
  /**
   * Registers a client through a link
   * @param {Object} payload the registration payload
   * @returns
   */
  link: (payload) => ApiPost({
    endpoint: 'external_links/register',
    payload,
    timeout: 60000,
  }),

  /**
   * Registers a client through a referral
   * @param {Object} payload the registration payload
   * @returns
   */
  referral: (payload) => ApiPost({
    endpoint: 'referrals/accept_referral',
    payload,
    timeout: 60000,
  }),

  /**
   * Sends a whatsapp message with a verification url for the client
   * @param {String} cellPhone the client's cellphone
   * @returns
   */
  sendVerification: (cellphone, registrationType) => ApiPost({
    endpoint: 'external_links/send_verification',
    payload: { cellphone, registration_type: registrationType },
  }),

  /**
   * Finds the contact associated with alink token
   * @param {String} cellPhone the client's cellphone
   * @returns
   */
  findContact: (linkToken) => ApiGet({
    endpoint: 'external_links/link_contact',
    params: { link_token: linkToken },
  }),
});
