import { ApiGet, ApiPut } from './Api';

/**
 * Transaction API calls
 */
export default () => ({
  /**
   *
   * @param {Integer} id the survey id
   * @returns an hash with the survey details.
   */
  getSurvey: (id) => ApiGet({ endpoint: `surveys/${id}` }),

  /**
   * Updates a survey
   *
   * @param {Integer} id the survey id
   * @returns an hash with the survey details.
   */
  updateSurvey: (id, { surveyAnswers, comment }) => {
    const payload = {
      survey_answers: surveyAnswers,
      comment,
    };

    return ApiPut({ endpoint: `surveys/${id}`, payload });
  },
});
