import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { getBookingSpecialRequest } from '../../selectors/booking';
import BookingTimingOptionsModal from './BookingTimingOptionsModal';

import { bookingSpecialRequest } from '../../actions/bookings';
import '../../assets/css/booking/specialRequest.scss';
import '../../assets/css/shared.scss';

function SpecialRequest({ color }) {
  const request = getBookingSpecialRequest();
  const dispatch = useDispatch();

  const setRequest = debounce((event) => {
    dispatch(bookingSpecialRequest(event.target.value));
  }, 150);

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full">
      <BookingTimingOptionsModal isDateReadOnly showTimeSelector isTimeReadOnly />
      <div className="separator" />
      <div className="flex flex-col w-full items-center px-5 overflow-scroll scrollbar-none flex-1">
        <div className="flex flex-col w-full items-center flex-1 my-6">
          <div className="box-title" style={{ background: color }}>
            <span>Any special requests?</span>
          </div>
          <div className="request-container">
            <textarea
              className="request"
              onChange={(e) => setRequest(e)}
              defaultValue={request || ''}
              placeholder="Write a description of the special request here. If you do not have any special request you can leave it blank."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SpecialRequest.propTypes = {
  color: PropTypes.string.isRequired,
};

export default SpecialRequest;
