import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import GiftCardTile from '../Shared/CardTiles/GiftCardTile';
import SubTitle from '../Shared/SubTitle';
import BookNowButton from '../Shared/BookNowButton';
import DashboardHeader from './DashboardHeader';

import Loading from '../Loading';
import WithFilters from '../Shared/Filters/WithFilters';
import FiltersBox from '../Shared/Filters/FiltersBox';
import RadioInput from '../Shared/RadioInput';
import FilterLevelIcon from '../Shared/Filters/FilterLevelIcon';

import giftCardIcon from '../../assets/icons/toasts/giftcards.svg';

import '../../assets/css/dashboard/dashboardGiftCards.scss';
import { CustomToast } from '../Shared/withToast';
import withNotificationPopUp from '../Shared/withNotificationPopUp';
import withUserRetriever from '../Shared/withUserRetriever';
import GiftCards from '../../api/GiftCards';

function DashboardGiftCards() {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    walletTitle: 'Your Wallet',
    giftCards: useSelector((reduxState) => reduxState.giftCards) || {
      active: '',
      expired: '',
    },
    showFilters: false,
    currentViewType: 'active',
    isLoading: true,
  });

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    walletTitle, showFilters, currentViewType, giftCards, isLoading,
  } = state;

  const componentStyle = {
    color: level.level_color,
  };

  const { list: getGiftCards } = GiftCards();
  const giftCardParams = { max_records: process.env.REACT_APP_MAX_GIFT_CARD_RECORDS || 10 };

  useEffect(() => {
    getGiftCards(giftCardParams)
      .then((res) => {
        dispatch({ type: 'GIFT_CARDS', giftCards: res.data });
        setState((prevState) => ({
          ...prevState,
          giftCards: {
            active: res.data.active,
            redeemed: res.data.redeemed,
            expired: res.data.expired,
          },
        }));
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={giftCardIcon} text="Gift Cards couldn't be retrieved" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, []);

  function renderGiftCards(gifts) {
    if (!gifts || gifts.length === 0) {
      return (<span className="no-items">No gift cards available</span>);
    }

    return gifts.map((giftCardItem, index) => (
      <div className="w-full" key={`${giftCardItem.id}`}>
        <GiftCardTile
          locationCategory={giftCardItem.location_category}
          status={giftCardItem.status}
          photo={giftCardItem.display_photo}
          remaining={giftCardItem.remaining_amount}
          date={giftCardItem.expiration_date}
        />
        {(index + 1 < gifts.length) && <div className="separator-line my-4 opacity-30" />}
      </div>
    ));
  }

  const updateRenderedGiftCards = (newViewType) => {
    closeFiltersBox();
    setState((prevState) => ({ ...prevState, currentViewType: newViewType }));
  };

  const closeFiltersBox = () => {
    setState((prevState) => ({ ...prevState, showFilters: false }));
  };

  const openFiltersBox = () => {
    setState((prevState) => ({ ...prevState, showFilters: true }));
  };

  const onCancelFilters = (resetFilters) => {
    setState((prevState) => ({
      ...prevState,
      showFilters: false,
      currentViewType,
    }));
    resetFilters({ viewType: currentViewType });
  };

  const initialFilters = useMemo(() => ({
    viewType: 'active',
  }));

  return (
    <WithFilters initialFilters={initialFilters}>
      {({
        activeFilters,
        changeFilter,
        resetFilters,
      }) => (
        <div className="flex flex-col h-full dashboard-grey-background-color view-boundaries-bottom">
          <DashboardHeader color={componentStyle.color} title={walletTitle} />
          <div className="mt-4 mb-6">
            <SubTitle
              subTitle="Gift Cards"
              route="/dashboard/wallet"
              style={componentStyle}
              afterRender={() => <FilterLevelIcon onClick={openFiltersBox} />}
            />
          </div>
          <div className="w-full gift-cards-container pb-5 px-5 md:w-1/2 self-center sm:px-0">
            {
              isLoading
                ? <Loading color={componentStyle.color} text="Gift Cards" />
                : renderGiftCards(giftCards[currentViewType])
            }
          </div>
          <BookNowButton color={componentStyle.color} />
          <FiltersBox isOpen={showFilters} onCancel={() => onCancelFilters(resetFilters)} onApply={() => updateRenderedGiftCards(activeFilters.viewType)}>
            <div className="voucher-filters">
              <RadioInput id="active" className="voucher-filters-filter" label="Active" onChange={() => changeFilter('viewType', 'active')} isChecked={activeFilters.viewType === 'active'} />
              <RadioInput id="redeemed" className="voucher-filters-filter" label="Redeemed" onChange={() => changeFilter('viewType', 'redeemed')} isChecked={activeFilters.viewType === 'redeemed'} />
              <RadioInput id="expired" className="voucher-filters-filter" label="Expired" onChange={() => changeFilter('viewType', 'expired')} isChecked={activeFilters.viewType === 'expired'} />
            </div>
          </FiltersBox>
        </div>
      )}
    </WithFilters>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(DashboardGiftCards);
