import keys from 'lodash/keys';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import axios from '../interceptor';
import {
  API_VERSION_V1,
  isMethodAllowed,
  API_METHODS,
  methodAllowsPayload,
  API_GET,
  API_POST,
  API_PUT,
  API_DELETE,
} from './consts';
import BeyoutyBonus from '../config/BeyoutyBonus';

/**
 *
 * @param {String} version the api version to use. Default: 'v1'
 * @param {String} method the HTTP method to use (mandatory)
 * @param {String} endpoint the API endpoint (mandatory)
 * @param {Object} payload the payload for the call (only used when @method allows HTTP payload)
 * @param {Object} params the HTTP params for the endpoint (default: {)})
 * @returns A promise with the response of the HTTP call.
 */
const Api = ({
  version = API_VERSION_V1,
  method,
  endpoint,
  payload,
  params = {},
  timeout = null,
}) => {
  const baseEndpoint = `/api/${version}`;
  const requestTimeout = timeout || BeyoutyBonus.api.defaultTimeout;

  if (!isMethodAllowed(method)) {
    throw new Error(`Method ${method.description} is not allowed. Allowed methods: ${keys(API_METHODS)}`);
  }

  if (isUndefined(endpoint) || isNull(endpoint) || isEmpty(endpoint)) {
    throw new Error('Endpoint is blank');
  }

  const httpMethod = API_METHODS[method];
  const apiEndpoint = `${baseEndpoint}/${endpoint}`;

  if (methodAllowsPayload(method)) {
    return axios[httpMethod](apiEndpoint, payload, { params, timeout: requestTimeout });
  }

  return axios[httpMethod](apiEndpoint, { params, timeout: requestTimeout });
};

/**
 *
 * @param {String} endpoint the GET endpoint
 * @param {Object} params the request params
 * @returns a promise with the http response.
 */
export const ApiGet = ({ endpoint, params, ...rest }) => Api({
  method: API_GET,
  endpoint,
  params,
  ...rest,
});

/**
 *
 * @param {String} endpoint the POST endpoint
 * @param {Object} payload the request payload
 * @param {Object} options optional configuration for the call
 * @returns a promise with the http response.
 */
export const ApiPost = ({ endpoint, payload, ...rest }) => Api({
  method: API_POST,
  endpoint,
  payload,
  ...rest,
});

/**
 *
 * @param {String} endpoint the PUT endpoint
 * @param {Object} payload the request payload
 * @returns a promise with the http response.
 */
export const ApiPut = ({ endpoint, payload, ...rest }) => Api({
  method: API_PUT,
  endpoint,
  payload,
  ...rest,
});

/**
 *
 * @param {String} endpoint the DELETE endpoint
 * @returns a promise with the http response.
 */
export const ApiDelete = ({ endpoint, ...rest }) => Api({ method: API_DELETE, endpoint, ...rest });

export default Api;
