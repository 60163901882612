/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import '../../../assets/css/dashboard/vouchers/dashboardVoucherDetails.scss';

import { convertDate, dateDifferenceUntilFormat } from '../../../assets/js/constants';

import vouchersIcon from '../../../assets/icons/toasts/vouchers.svg';
import tnsLogo from '../../../assets/img/logo_tns.svg';
import marqueeLogo from '../../../assets/img/logo_marquee.svg';

import { ReactComponent as BackIcon } from '../../../assets/icons/arrow_left.svg';

import Loading from '../../Loading';

import { CustomToast } from '../../Shared/withToast';
import withNotificationPopUp from '../../Shared/withNotificationPopUp';
import withUserRetriever from '../../Shared/withUserRetriever';
import Vouchers from '../../../api/Vouchers';

function DashboardVoucherDetails() {
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const [state, setState] = useState({
    voucher: '',
    isLoading: true,
    componentStyle: {
      color: '',
      color70: '',
      backgroundGradient: '',
      colorRedeemed: '',
      colorExpired: '',
    },
  });

  const {
    voucher, isLoading, componentStyle,
  } = state;

  const history = useHistory();

  const { id } = useParams();

  const { get: getVoucher } = Vouchers();

  useEffect(() => {
    let earlyErrorExit = false;
    getVoucher(id)
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          voucher: res.data,
          componentStyle: setStatusColors(res.data.status),
        }));
      })
      .catch((err) => {
        if (get(err, 'response.status')) {
          toast(<CustomToast type="error" icon={vouchersIcon} text={`${err.response.data.error_msg}`} />);
          earlyErrorExit = true;
        }
      })
      .finally(() => {
        if (earlyErrorExit) {
          history.push('/dashboard/vouchers');
        } else {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        }
      });
  }, []);

  function setStatusColors(status) {
    if (status === 'expired') {
      return {
        color: 'black',
        color70: '#000000B3', // opacity at 70%
        backgroundGradient: 'linear-gradient(to bottom, var(--color-expired) 0%, var(--color-expired-darken) 30%)',
        colorExpired: 'var(--color-expired-darken)',
      };
    }
    return {
      color: level.level_color,
      color70: level.level_color.concat('B3'), // opacity at 70%
      backgroundGradient: `linear-gradient(to bottom, ${level.level_color.concat('B3')} 0%, ${level.level_color} 30%)`,
      colorRedeemed: 'var(--color-green)',
    };
  }

  function renderStatusBanner(status, expirationDate) {
    let { color } = componentStyle;
    if (status === 'redeemed') {
      color = componentStyle.colorRedeemed;
    }
    if (status === 'expired') {
      color = componentStyle.colorExpired;
    }

    return (
      <div className="voucher-details-header-text" style={{ background: color }}>
        {renderStatusText(status, expirationDate)}
      </div>
    );
  }

  function renderStatusText(status, expirationDate) {
    if (status === 'redeemed') {
      return 'VOUCHER REDEEMED';
    } if (status === 'expired') {
      return 'VOUCHER EXPIRED';
    }
    return `EXPIRES ${dateDifferenceUntilFormat(expirationDate)}`;
  }

  return (
    <div className="flex w-full h-full">
      <div
        className="voucher-details-container view-boundaries-top view-boundaries-bottom"
        style={{ background: componentStyle.backgroundGradient }}
      >
        <div className="voucher-details-header">
          <div role="button" tabIndex={0} onClick={() => history.push('/dashboard/vouchers')}>
            <BackIcon className="voucher-details-header-back-icon" stroke="white" />
          </div>
          {
            voucher && (voucher.end_date && voucher.status && renderStatusBanner(voucher.status, voucher.end_date))
          }
        </div>
        {
          isLoading
            ? (
              <Loading color="#FFFFFF" backgroundColor={componentStyle.color70} text="Voucher" />
            ) : (
              <div className="voucher-details">
                <div className="voucher-details-info" style={{ color: componentStyle.color }}>
                  <>
                    <span className="voucher-details-title">{voucher.short_name}</span>
                    <p className="voucher-details-text">
                      {renderStatusText(voucher.status, convertDate(new Date(voucher.end_date), '', true))}
                    </p>
                  </>

                  <span className="voucher-details-subtitle py-2">T&amp;Cs</span>
                  <p className="voucher-details-text">
                    Voucher cannot be redeemed during discount freeze periods: 10 days prior
                    to Eid Al Adha and Eid Al Fitr;
                    <br />
                    <span>
                      March 15
                      <sup>th</sup>
                      {' '}
                      to 31
                      <sup>st </sup>
                      {' '}
                      ; December 15
                      <sup>th</sup>
                      {' '}
                      to 31
                      <sup>st </sup>
                      ;
                    </span>

                  </p>
                </div>
                <div className="voucher-details-img-container">
                  {voucher.location_category ? (
                    <img className="voucher-details-logo" src={voucher.location_category === 'marquee' ? marqueeLogo : tnsLogo} alt="TNS" />
                  ) : (
                    <div className="voucher-both-logo-details">
                      <img src={tnsLogo} alt="TNS" />
                      <img src={marqueeLogo} alt="MQ" />
                    </div>
                  )}
                  <div className="voucher-details-dashed-line" />
                  <img className="voucher-details-img" src={voucher.image} alt="" />
                </div>
              </div>
            )
        }
      </div>
    </div>
  );
}

export default compose(
  withUserRetriever,
  withNotificationPopUp,
)(DashboardVoucherDetails);
