// BookingTimingOptionsModal

/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import PopUpComponentModal, { POPUP_INSIDE_EVENT_ID } from '../Shared/Popups/PopUpComponentModal';
import BookingDatePicker from './BookingDatePicker';
import Select from '../Shared/Select';
import BookingsAPI from '../../api/Booking';
import {
  bookingCalendarHighlighted,
  bookingDay,
  bookingRemoveAllProviders,
  bookingTime,
  toggleBookingTimingOptionsModal,
} from '../../actions/bookings';
import '../../assets/css/booking/bookingTimeOptionsModal.scss';
import {
  getCurrentDayStartingTimes,
  getBookingDate,
  getBookingTime,
  getIsBookingsTimingModalOpen,
  getBookingLogId,
} from '../../selectors/booking';
import { getCurrentLoyaltyLevel } from '../../selectors/user';
import { BOOKING_LOG_TYPES } from '../../utils/api';

const BookingTimingOptionsModal = ({
  isDateReadOnly,
  isTimeReadOnly,
  showTimeSelector,
  onChangeDate,
  onChangeTime,
}) => {
  const isBookingsTimingModalOpen = getIsBookingsTimingModalOpen();
  const currentBookingDay = getBookingDate();
  const currentLevel = getCurrentLoyaltyLevel();
  const bookingLogId = getBookingLogId();
  const currentBookingTime = getBookingTime();
  const initialSnapshot = { time: currentBookingTime, date: currentBookingDay };

  const [settings, updateSettings] = useState(initialSnapshot);
  const { time, date } = settings;

  const dispatch = useDispatch();
  const { saveLog } = BookingsAPI();

  // Every time the modal opens, we want to update the initial snapshot. This is because the component may have already
  // been mounted on the page and the date might have changed in the mean time
  useEffect(() => {
    if (isBookingsTimingModalOpen) {
      updateSettings({
        time: currentBookingTime,
        date: currentBookingDay,
      });
    }
  }, [isBookingsTimingModalOpen]);

  const closeModal = () => {
    dispatch(toggleBookingTimingOptionsModal());
    dispatch(bookingCalendarHighlighted(false));
  };

  const updateDate = (bookingDate) => {
    updateSettings({ time, date: typeof bookingDate === 'string' ? bookingDate : bookingDate.toDateString() });
  };

  const updateTime = (newTime) => {
    updateSettings({ date, time: newTime });
  };

  const onCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    updateSettings(initialSnapshot);
    closeModal();
  };

  const onSave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // Reset the service providers because after re-selecting the new starting time, then we need to call the
    // availability service again to fetch the new service providers
    dispatch(bookingRemoveAllProviders());
    closeModal();

    if (time !== currentBookingTime && time !== undefined) {
      dispatch(bookingTime(time));
      onChangeTime(time);
    }

    if (date !== currentBookingDay && date !== undefined) {
      dispatch(bookingDay(typeof date === 'string' ? date : date.toDateString()));
      onChangeDate(date);
    }

    const payloadTime = {
      booking_log_id: bookingLogId,
      tag: BOOKING_LOG_TYPES.STARTING_TIME,
      data: { date, time: bookingTime },
    };
    saveLog(payloadTime);
  };

  const startingTimes = getCurrentDayStartingTimes().map((startingTime) => ({ key: startingTime, value: startingTime }));
  const isFullReadOnly = isDateReadOnly && isTimeReadOnly;
  const computeTitle = useMemo(() => {
    if (isDateReadOnly && isTimeReadOnly) {
      return 'Booking Details closed';
    }
    if (isDateReadOnly) {
      return 'Change Start Time';
    }
    return 'Change Date Selection';
  });

  return isBookingsTimingModalOpen && (
    <PopUpComponentModal fullWidth handleClose={closeModal} className="booking-timing-options-modal-wrapper">
      <div
        popup-id={POPUP_INSIDE_EVENT_ID}
        className="booking-timing-container"
        style={{ height: isDateReadOnly && isTimeReadOnly ? 'auto' : '100%' }}
      >
        <div className={`booking-timing-options-title booking-timing-options-title--level-${currentLevel.id}`}>{computeTitle}</div>
        <BookingDatePicker
          isInline
          onCancel={closeModal}
          isReadOnly={isDateReadOnly}
          currentDate={new Date(date)}
          showDateOutOfLevelBounds={isDateReadOnly}
          onChangeDate={(newDay) => updateDate(newDay.toDateString())}
        />
        {showTimeSelector && (
          <div className={`booking-timing-options-time-selector-wrapper ${isFullReadOnly ? 'booking-timing-options-time-selector-wrapper--readOnly' : ''} `}>
            {isTimeReadOnly && (
              <div className="booking-timing-options-read-only">
                Starting at
                &nbsp;
                {time}
              </div>
            )}
            {!isTimeReadOnly && (
              <Select
                readOnly={isTimeReadOnly}
                onChange={({ item }) => updateTime(item.value)}
                collection={startingTimes}
                value={time}
                startOpened
                prompt="Starting time"
                placeholder="Starting time"
                classNames={{
                  container: 'booking-timing-options-modal-time-selector',
                  header: isTimeReadOnly ? 'booking-timing-options-time-selector--readOnlyHeader' : '',
                }}
              />
            )}
          </div>
        )}
        {(!isDateReadOnly || !isTimeReadOnly) && (
          <div className="booking-timing-options-starting-time-buttons">
            <button
              className="booking-timing-options-starting-time-buttons booking-timing-options-starting-time-buttons--cancel"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className={`booking-timing-options-starting-time-buttons booking-timing-options-starting-time-buttons--save booking-timing-options-starting-time-buttons--save--level-${currentLevel.id}`}
              type="button"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </PopUpComponentModal>
  );
};

BookingTimingOptionsModal.propTypes = {
  isDateReadOnly: PropTypes.bool,
  showTimeSelector: PropTypes.bool,
  isTimeReadOnly: PropTypes.bool,
  onChangeDate: PropTypes.func,
  onChangeTime: PropTypes.func,
};

BookingTimingOptionsModal.defaultProps = {
  isDateReadOnly: false,
  isTimeReadOnly: false,
  showTimeSelector: false,
  onChangeDate: () => {},
  onChangeTime: () => {},
};

export default BookingTimingOptionsModal;
