import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import GenericButton from './GenericButton';

function BookNowButton({
  color,
}) {
  const history = useHistory();

  return (
    <div className="footer">
      <GenericButton
        color={color}
        className="footer-buttons"
        text="Book Now!"
        action={() => history.push('/booking')}
      />
    </div>
  );
}

BookNowButton.propTypes = {
  color: PropTypes.string.isRequired,
};

export default BookNowButton;
