import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';

import withNotificationPopUp from '../Shared/withNotificationPopUp';

import DashboardHeader from '../Dashboard/DashboardHeader';

import '../../assets/css/shared.scss';
import '../../assets/css/impersonation/clientList.scss';

import withUserRetriever from '../Shared/withUserRetriever';
import Pagination from '../Shared/Pagination';

import { CustomToast } from '../Shared/withToast';
import Loading from '../Loading';

import ClientsAPI from '../../api/Client';

import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

import ClientImpersonationCard from './ClientImpersonationCard';

function ClientImpersonateList() {
  const user = useSelector((reduxState) => reduxState.user);
  const level = user.loyalty_level;
  const color = level.level_color;
  const [state, setState] = useState({
    clientList: [],
    isLoading: true,
    search: '',
    totalPages: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const {
    clientList, isLoading, search, totalPages,
  } = state;
  const { getClientImpersonationList } = ClientsAPI();

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    getClientImpersonationList(currentPage, search)
      .then((res) => {
        setState((prevState) => ({ ...prevState, clientList: res.data.data.attributes, totalPages: res.data.data.total_pages }));
      })
      .catch(() => {
        toast(<CustomToast type="error" text="Couldn't retrieve client list!" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, [currentPage, search, user.is_impersonating]);

  function handleChange(e) {
    setState((prevState) => ({ ...prevState, search: e.target.value }));
  }

  function renderClientList() {
    return (
      <div className="mt-2">
        {
          user.is_impersonating && (
            <ClientImpersonationCard
              client={
                {
                  clientId: user.client_id,
                  name: user.name,
                  email: user.email,
                  cellPhone: user.cell_phone,
                }
              }
              isImpersonating
              color={color}
            />
          )
        }
        {
          (
            clientList && clientList.length > 0
              ? (
                clientList.map((client) => (
                  <ClientImpersonationCard
                    client={
                      {
                        clientId: client.client_id,
                        name: client.name,
                        email: client.email,
                        cellPhone: client.cell_phone,
                      }
                    }
                    color={color}
                  />
                ))
              )
              : (
                <span className="no-items">No clients</span>
              )
          )
        }
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full dashboard-grey-background-color view-boundaries-bottom">
      <DashboardHeader color={color} title="Impersonation" />
      <div className="px-5 pb-2 flex w-full search-container">
        <SearchIcon className="search-icon absolute" alt="" />
        <input id="search" className="input-primary w-full" onChange={(e) => handleChange(e)} placeholder="Search for a client" type="text" value={search} />
      </div>
      <div className="flex-col w-full impersonation-list-container">
        {
          isLoading
            ? <Loading color={color} backgroundColor="white" text="Client Impersonation" />
            : renderClientList()
        }
      </div>

      { totalPages > 1 && (
        <div className="w-full">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            color={color}
          />
        </div>
      )}
    </div>

  );
}

export default compose(
  withUserRetriever,
  withNotificationPopUp,
)(ClientImpersonateList);
