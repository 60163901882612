import { CLEAN_BOOKING, TOGGLE_BOOKING_TIMING_OPTIONS } from '../../actions/bookings';

const initialState = false;

const bookingTimingModalOpen = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_BOOKING_TIMING_OPTIONS:
      return !state;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingTimingModalOpen;
