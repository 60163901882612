import React from 'react';
import PropTypes from 'prop-types';

import '../../assets/css/booking/weekDay.scss';
import { getCurrentLoyaltyLevel } from '../../selectors/user';
import { convertTZ } from '../../assets/js/constants';

function WeekDay({
  day,
  onClick,
  disabled,
  isActive,
  idx,
}) {
  const currentLevel = getCurrentLoyaltyLevel();
  const monthDay = (date) => convertTZ(new Date(date)).getDate();
  const weekDay = (date) => convertTZ(new Date(date.toString())).toLocaleString('en-US', { weekday: 'short' });

  const dayClass = `
    weekDay
    weekDay--level-${currentLevel.id}
    ${disabled && isActive ? `weekDay--level-${currentLevel.id}--active--disabled` : ''}
    ${disabled && !isActive ? `weekDay--level-${currentLevel.id}--disabled` : ''}
    ${isActive && !disabled ? `weekDay--level-${currentLevel.id}--active` : ''}
  `;

  return (
    <div
      className={dayClass}
      key={day}
      role="button"
      onClick={onClick}
      tabIndex={idx}
    >
      <div className="weekDay_week">{weekDay(day)}</div>
      <div className="weekDay_number">{monthDay(day)}</div>
    </div>
  );
}

WeekDay.propTypes = {
  day: PropTypes.instanceOf(Date).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  idx: PropTypes.number.isRequired,
};

WeekDay.defaultProps = {
  onClick: () => {},
};

export default WeekDay;
