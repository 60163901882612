const bookingPackages = (state = '', action) => {
  switch (action.type) {
    case 'PACKAGES':
      return action.packages;
    default:
      return state;
  }
};

export default bookingPackages;
