import { ApiGet, ApiPut } from './Api';

/**
 * Client Communication Channels
 */
export default () => ({
  /**
   *
   * @returns the current client data.
   */
  get: () => ApiGet({ endpoint: 'communication_channels' }),
  /**
   * Sign out the current client
   */
  update: (preferences) => ApiPut({ endpoint: 'communication_channels', payload: preferences }),
});
