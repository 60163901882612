const LocalStorageService = {
  getValue: (key) => localStorage.getItem(key),
  setValue: (key, value) => localStorage.setItem(key, value),
  getObject: (key) => JSON.parse(localStorage.getItem(key)),
  setObject: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
  remove: (key) => localStorage.removeItem(key),
  clear: () => localStorage.clear(),
};

export default LocalStorageService;
