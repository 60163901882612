import BeyoutyBonus from '../../config/BeyoutyBonus';

export const workHours = {
  1: '8:00',
  2: '8:10',
  3: '8:20',
  4: '8:30',
  5: '8:40',
  6: '8:50',
  7: '9:00',
  8: '9:10',
  9: '9:20',
  10: '9:30',
  11: '9:40',
  12: '9:50',
  13: '10:00',
  14: '10:10',
  15: '10:20',
  16: '10:30',
  17: '10:40',
  18: '10:50',
  19: '11:00',
  20: '11:10',
  21: '11:20',
  22: '11:30',
  23: '11:40',
  24: '11:50',
  25: '12:00',
  26: '12:10',
  27: '12:20',
  28: '12:30',
  29: '12:40',
  30: '12:50',
  31: '13:00',
  32: '13:10',
  33: '13:20',
  34: '13:30',
  35: '13:40',
  36: '13:50',
  37: '14:00',
  38: '14:10',
  39: '14:20',
  40: '14:30',
  41: '14:40',
  42: '14:50',
  43: '15:00',
  44: '15:10',
  45: '15:20',
  46: '15:30',
  47: '15:40',
  48: '15:50',
  49: '16:00',
  50: '16:10',
  51: '16:20',
  52: '16:30',
  53: '16:40',
  54: '16:50',
  55: '17:00',
  56: '17:10',
  57: '17:20',
  58: '17:30',
  59: '17:40',
  60: '17:50',
  61: '18:00',
  62: '18:10',
  63: '18:20',
  64: '18:30',
  65: '18:40',
  66: '18:50',
  67: '19:00',
  68: '19:10',
  69: '19:20',
  70: '19:30',
  71: '19:40',
  72: '19:50',
  73: '20:00',
  74: '20:10',
  75: '20:20',
  76: '20:30',
  77: '20:40',
  78: '20:50',
  79: '21:00',
  80: '21:10',
  81: '21:20',
  82: '21:30',
  83: '21:40',
  84: '21:50',
};

export const constWorkHours = {
  1: '8:00',
  2: '8:10',
  3: '8:20',
  4: '8:30',
  5: '8:40',
  6: '8:50',
  7: '9:00',
  8: '9:10',
  9: '9:20',
  10: '9:30',
  11: '9:40',
  12: '9:50',
  13: '10:00',
  14: '10:10',
  15: '10:20',
  16: '10:30',
  17: '10:40',
  18: '10:50',
  19: '11:00',
  20: '11:10',
  21: '11:20',
  22: '11:30',
  23: '11:40',
  24: '11:50',
  25: '12:00',
  26: '12:10',
  27: '12:20',
  28: '12:30',
  29: '12:40',
  30: '12:50',
  31: '13:00',
  32: '13:10',
  33: '13:20',
  34: '13:30',
  35: '13:40',
  36: '13:50',
  37: '14:00',
  38: '14:10',
  39: '14:20',
  40: '14:30',
  41: '14:40',
  42: '14:50',
  43: '15:00',
  44: '15:10',
  45: '15:20',
  46: '15:30',
  47: '15:40',
  48: '15:50',
  49: '16:00',
  50: '16:10',
  51: '16:20',
  52: '16:30',
  53: '16:40',
  54: '16:50',
  55: '17:00',
  56: '17:10',
  57: '17:20',
  58: '17:30',
  59: '17:40',
  60: '17:50',
  61: '18:00',
  62: '18:10',
  63: '18:20',
  64: '18:30',
  65: '18:40',
  66: '18:50',
  67: '19:00',
  68: '19:10',
  69: '19:20',
  70: '19:30',
  71: '19:40',
  72: '19:50',
  73: '20:00',
  74: '20:10',
  75: '20:20',
  76: '20:30',
  77: '20:40',
  78: '20:50',
  79: '21:00',
  80: '21:10',
  81: '21:20',
  82: '21:30',
  83: '21:40',
  84: '21:50',
  85: '22:00',
  86: '22:10',
  87: '22:20',
  88: '22:30',
  89: '22:40',
  90: '22:50',
};

export const convertTZ = (date, tzString = BeyoutyBonus.config.timeZone) => new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString }));

export const MONTH_IDX_MAP = {
  0: {
    short: 'jan',
    long: 'january',
  },
  1: {
    short: 'feb',
    long: 'february',
  },
  2: {
    short: 'mar',
    long: 'march',
  },
  3: {
    short: 'apr',
    long: 'april',
  },
  4: {
    short: 'may',
    long: 'may',
  },
  5: {
    short: 'jun',
    long: 'june',
  },
  6: {
    short: 'jul',
    long: 'july',
  },
  7: {
    short: 'aug',
    long: 'august',
  },
  8: {
    short: 'sept',
    long: 'september',
  },
  9: {
    short: 'oct',
    long: 'october',
  },
  10: {
    short: 'nov',
    long: 'november',
  },
  11: {
    short: 'dec',
    long: 'december',
  },
};

export function convertDate(date, separator = '/', spaced = false, reversed = false) {
  const currentDate = date.toString();
  const parts = currentDate.split(' ');
  const months = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };

  if (spaced) {
    return `${parts[2]} ${parts[1]} ${parts[3]}`;
  }

  if (reversed) {
    return parts[3] + separator + months[parts[1]] + separator + parts[2];
  }
  return parts[2] + separator + months[parts[1]] + separator + parts[3];
}

export function isValidEmail(email) {
  const regex = new RegExp(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/, 'i');
  return regex.test(email);
}

export function timeConvert(n) {
  const num = n;
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  if (rminutes === 0) {
    return `${rhours}h`;
  }
  return `${rhours}h ${rminutes} min`;
}

// difference in months
export function dateDifferenceInMonths(dateFrom, dateTo) {
  return dateTo.getMonth() - dateFrom.getMonth()
    + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
}

// difference in days
export function dateDifferenceInDays(dateFrom, dateTo) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
  const utc2 = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

/**
 * Displays time format until @date is reached in months.
 * Whenever it is less than one month, display in days.
 *
 * @param {Date} date the date to calculate
 */
export function dateDifferenceUntilFormat(date) {
  const from = new Date();
  const to = new Date(date);

  let untilTime = dateDifferenceInMonths(from, to);
  if (untilTime < 1) {
    untilTime = dateDifferenceInDays(from, to);
    if (untilTime === 0) {
      untilTime = 'TODAY';
    } else {
      untilTime = untilTime < 2 ? `IN ${untilTime} DAY` : `IN ${untilTime} DAYS`;
    }
  } else {
    untilTime = untilTime < 2 ? `IN ${untilTime} MONTH` : `IN ${untilTime} MONTHS`;
  }
  return untilTime;
}

export const DEFAULT_LEVEL_COLOR = '#724467';

export const EXTERNAL_TYPES = {
  INSTAGRAM_TNS: 'instagram_tns',
  INSTAGRAM_MARQUEE: 'instagram_marquee',
  WEBSITE_TNS: 'website_tns',
  WEBSITE_MARQUEE: 'website_marquee',
};

export const REGISTRATION_TYPES = {
  NORMAL: 'organic',
  REFERRAL: 'referral',
  ...EXTERNAL_TYPES,
};

export const MIN_PASSWORD_LENGTH = 6;
export const MAX_PASSWORD_LENGTH = 128;

/**
 *
 * @param {Object} countryData an object with the phone's country data
 * @param {String} number the cell phone string
 * @returns a cell phone string correct for server payloads.
 */
export const cellPhoneForPayload = (countryData, number) => {
  const trimmedPhone = number.replace(/\s/g, '');
  const { dialCode } = countryData;

  if (dialCode === null || dialCode === undefined || dialCode === '') {
    return trimmedPhone;
  }

  if (number.includes(`+${dialCode}`)) {
    return trimmedPhone;
  }

  return `+${dialCode}${trimmedPhone}`;
};
