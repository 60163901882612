/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { convertDate } from '../../assets/js/constants';
import BookNowButton from '../Shared/BookNowButton';
import Client from '../../api/Client';

import withNotificationPopUp from '../Shared/withNotificationPopUp';
import SubTitle from '../Shared/SubTitle';

import DashboardHeader from '../Dashboard/DashboardHeader';

import '../../assets/css/shared.scss';
import '../../assets/css/settings/profile.scss';

import withUserRetriever from '../Shared/withUserRetriever';

import { ReactComponent as IconDefaultProfile } from '../../assets/icons/settings/default_avatar.svg';
import { ReactComponent as IconRight } from '../../assets/icons/chevron/right.svg';

import { CustomToast } from '../Shared/withToast';
import PopUpComponentModal from '../Shared/Popups/PopUpComponentModal';
import PopUpModal from '../Shared/Popups/PopUpModal';
import LocalStorageService from '../LocalStorageService';

function Profile() {
  const dispatch = useDispatch();
  const user = useSelector((reduxState) => reduxState.user);
  const history = useHistory();
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);
  const color = level.level_color;

  const [state, setState] = useState({
    popup: false,
  });

  const { popup } = state;

  const { destroy: deleteAccount } = Client();

  function renderDeleteAccount() {
    const handleOnCloseModal = () => setState((prevState) => ({ ...prevState, popup: false }));

    const openModal = () => setState((prevState) => ({ ...prevState, popup: true }));

    const clearData = () => {
      LocalStorageService.clear();
      dispatch({ type: 'CLEAN_STORE' });
      history.push('/clients/sign_in');
    };

    const handleAccountDeletion = () => {
      deleteAccount()
        .then(() => {
          setState((prevState) => ({ ...prevState, popup: false }));
          toast(<CustomToast type="success" text="Successfully deleted account." />);
          clearData();
        })
        .catch(() => {
          handleOnCloseModal();
          toast(<CustomToast type="error" text="Unable to delete account. Contact support." />);
        });
    };

    const buttonDeleteText = 'Delete Account';
    const popupTitleText = 'Account Deletion';
    const popupText = 'Are you sure you want to delete your account?\nYou can\'t undo this action.';
    const style = {
      color: getComputedStyle(document.documentElement).getPropertyValue('--color-cancel'),
    };

    return (
      <div className="flex items-center justify-center">
        <button
          type="button"
          className="w-full flex justify-left md:w-1/2 underline"
          onClick={openModal}
          style={{ color }}
        >
          Delete Account
        </button>
        {
          popup && (
            <PopUpComponentModal handleClose={handleOnCloseModal}>
              <PopUpModal
                handleClose={handleOnCloseModal}
                buttonAction={handleAccountDeletion}
                buttonText={buttonDeleteText}
                iconConfig={{ Component: IconDefaultProfile, styleClass: 'profile-deletion-icon-width' }}
                titleConfig={{ content: popupTitleText }}
                content={popupText}
                goBack
                style={style}
              />
            </PopUpComponentModal>
          )
        }
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <DashboardHeader color={color} title="Settings" />
      <div className="pb-3 overflow-y-auto scrollbar-none container-center">
        <div className="relative">
          <div
            className="settings-bg-content-top"
            style={{
              background: `linear-gradient(to bottom right,
              ${level.level_color.concat('E6')} 0%,
              ${level.level_color} 100%)`,
            }}
          >
            <div className="w-full pt-4">
              <SubTitle subTitle="Your Account" style={{ color: '#FFFFFF' }} route="/settings" />
              <IconDefaultProfile className="icon-settings-profile-header mt-4" alt="" />
              <div className="profile-activation-date pt-4" style={{ color }}>
                Active since
                {' '}
                {convertDate(new Date(user.loyalty_activation_date), '', true)}
              </div>
            </div>
          </div>
          <div className="settings-bg-content-top-opacity" />
        </div>
        <div className="profile-form-container px-5 h-full">
          <div className="tab mt-6 px-5">
            <div className="flex flex-col w-100">
              <span className="label-profile-form mb-2" style={{ color }}>Name</span>
              <div className="relative">
                <input id="firstName" value={user.name} className="input-profile mb-4" type="text" readOnly />
              </div>
            </div>
            <div className="flex flex-col w-100">
              <span className="label-profile-form mb-2" style={{ color }}>Email</span>
              <div className="relative">
                <input id="email" value={user.email} className="input-profile mb-4" type="email" readOnly />
              </div>
            </div>
            {!user.is_impersonating && (
              <div className="flex flex-col w-100">
                <span className="label-profile-form mb-2" style={{ color }}>Password</span>
                <div className="relative">
                  <input id="password" value="*******" className="input-profile mb-4" type="password" readOnly />
                  <div role="button" className="right-label-input" tabIndex={0} onClick={() => history.push('/settings/changePassword')}>
                    <span style={{ color }}>Change Password</span>
                    <IconRight className="icon-right-settings" alt="" style={{ fill: color, stroke: color, filter: color }} />
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col w-100">
              <span className="label-profile-form mb-2" style={{ color }}>Date of Birth</span>
              <div className="relative">
                <input id="dateOfBirth" value={user.birthday ? convertDate(new Date(user.birthday), '', true) : 'Not set'} className="input-profile mb-4 dateOfBirth" type="text" disabled />
              </div>
            </div>

            {renderDeleteAccount()}
          </div>
        </div>
      </div>
      <div className="footer-spacing-profile" />
      <BookNowButton color={level.level_color} />
    </div>
  );
}

export default compose(
  withUserRetriever,
  withNotificationPopUp,
)(Profile);
