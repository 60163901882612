const vouchersRecordsOffset = (state = '', action) => {
  switch (action.type) {
    case 'VOUCHERS_RECORDS_OFFSET':
      return action.offset;
    default:
      return state;
  }
};

export default vouchersRecordsOffset;
