import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import '../../assets/css/booking/bookingLine.scss';
import '../../assets/css/shared.scss';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as IconCalendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as IconClock } from '../../assets/icons/clock.svg';
import { toggleBookingTimingOptionsModal } from '../../actions/bookings';
import { getCurrentLoyaltyLevel } from '../../selectors/user';
import { STEPS, STEPS_TITLE, TOTAL_STEPS } from './consts';

// The last step is not visible, is purely computational
const BOOKING_VISIBLE_STEPS = TOTAL_STEPS - 1;

const STEP_TO_TIMING_OPTIONS_ICON = {
  [STEPS.SERVICE_TYPE]: IconCalendar,
  [STEPS.SOAK_OFFS]: IconCalendar,
  [STEPS.SERVICES]: IconCalendar,
  [STEPS.SERVICE_PROVIDER_PREFERENCES]: IconCalendar,
  [STEPS.SERVICE_PROVIDER]: IconClock,
  [STEPS.STARTING_TIME]: IconCalendar,
};

function BookingLine({
  currentStep,
  back,
  color,
  currentLocation,
}) {
  const [state, setState] = useState({
    step: currentStep,
    location: currentLocation,
  });
  const { step, location } = state;
  const dispatch = useDispatch();
  const currentLevelColor = getCurrentLoyaltyLevel().level_color;

  useEffect(() => {
    setState((prevState) => ({ ...prevState, step: currentStep, location: currentLocation }));
  }, [currentStep, currentLocation]);

  function renderTitle() {
    if (step === STEPS.SERVICE_PROVIDER) {
      return location === 1 ? 'Confirm Therapist(s)' : 'Confirm Stylist';
    }

    if (step === STEPS.SERVICE_PROVIDER_PREFERENCES) {
      return location === 1 ? 'Select Therapist(s)' : 'Select Stylist';
    }
    return STEPS_TITLE[step];
  }

  const toggleBookingTimingOptionsPopup = (e) => {
    e.stopPropagation();
    dispatch(toggleBookingTimingOptionsModal());
  };

  const renderOpenBookingTimingOptionsModal = () => {
    const Icon = STEP_TO_TIMING_OPTIONS_ICON[currentStep];
    if (Icon) {
      return (
        <div className="calendar-clock">
          <Icon
            id="booking-line-calendar"
            width="40px"
            height="40px"
            strokeWidth="0"
            fill={currentLevelColor}
            onClick={toggleBookingTimingOptionsPopup}
          />
        </div>
      );
    }
    return <div />;
  };

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full">
      <div className="booking-line-wrapper">
        {
          currentStep === STEPS.LOCATION || currentStep === STEPS.SUMMARY_CONFIRMED
            ? <div />
            : (
              <div role="button" tabIndex={0} onClick={back} className="relative back-icon">
                <ArrowLeft className="absolute bottom-0 top-0 m-auto left-icon-width ml-6" alt="" style={{ stroke: color }} />
              </div>
            )
        }
        <div className="title mx-auto" style={{ color }}>
          {renderTitle()}
        </div>
        <div className="absolute right-0">
          {renderOpenBookingTimingOptionsModal()}
        </div>
      </div>
      <div className="flex w-full booking-lines">
        <div style={{ width: `${(step / BOOKING_VISIBLE_STEPS) * 100}%`, backgroundColor: color }} />
      </div>
    </div>
  );
}

BookingLine.propTypes = {
  currentStep: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  currentLocation: PropTypes.number,
};

BookingLine.defaultProps = {
  currentLocation: 0,
};

export default BookingLine;
