import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import axios from '../../interceptor';

import { ReactComponent as IconLocation } from '../../assets/icons/location.svg';
import { ReactComponent as IconDate } from '../../assets/icons/calendar.svg';
import { ReactComponent as IconClock } from '../../assets/icons/clock.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/triangle/down.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/triangle/right.svg';
import { ReactComponent as IconServices } from '../../assets/icons/services.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/warning.svg';
import { ReactComponent as RatingIcon } from '../../assets/icons/rating.svg';
import { ReactComponent as IconDuration } from '../../assets/icons/duration.svg';

import {
  getBookingLocation,
  getBookingDate,
  getBookingServiceProviders,
  getBookingSpecialRequest,
  getBookingAppointmentId,
  getBookingLogId,
  getBookingAppointmentEndTime,
  getBookingAddOnsFlattened,
} from '../../selectors/booking';

import BookingsAPI from '../../api/Booking';
import { convertDate, convertTZ, timeConvert } from '../../assets/js/constants';

import '../../assets/css/booking/summary.scss';
import '../../assets/css/shared.scss';

import { CustomToast } from '../Shared/withToast';
import { getHoursAndMinutesFromDate } from '../../utils/date';
import { isTimeoutError } from '../../utils/api';
import { timeoutEndpointLogErrorPayload } from './consts';

const { saveLogError } = BookingsAPI();

function Summary({ confirmed, color }) {
  const location = getBookingLocation();
  const day = getBookingDate();
  const serviceProviders = getBookingServiceProviders();
  const bookingAddOns = getBookingAddOnsFlattened();
  const request = getBookingSpecialRequest();
  const appointmentId = getBookingAppointmentId();
  const bookingLogId = getBookingLogId() || '';
  const appointmentEndTime = getBookingAppointmentEndTime();

  const [state, setState] = useState({
    servicesAndAddonsWithProvider: [],
  });

  const { servicesAndAddonsWithProvider } = state;

  useEffect(() => {
    document.getElementById('container-height').style.marginBottom = '95px';
    const payload = {
      appointment_id: appointmentId,
      booking_special_request: request,
      booking_log_id: bookingLogId,
    };

    axios.post('/api/v1/appointments/booking_special_request', payload)
      .then(() => {})
      .catch((err) => {
        if (isTimeoutError(err)) {
          saveLogError(timeoutEndpointLogErrorPayload({ error: err, bookingLogId }));
        }
        if (get(err, 'response.data.error_msg')) {
          toast(<CustomToast
            type="error"
            text={`${err.response.data.error_title} ${err.response.data.error_msg}`}
          />);
        }
      });

    const categories = [];
    const categoriesFound = [];

    const orderedProvidersByStartTime = serviceProviders.sort((s1, s2) => {
      const providerS1StartTime = new Date((s1.provider || s1.system_assigned_provider).start_time);
      const providerS2StartTime = new Date((s2.provider || s2.system_assigned_provider).start_time);

      return providerS1StartTime > providerS2StartTime;
    });

    orderedProvidersByStartTime.forEach((service) => {
      let currCategory = '';
      const currCategoryId = service.category_id;
      let newCategory = true;

      if (categoriesFound.includes(currCategoryId)) {
        newCategory = false;
        [currCategory] = [...categories].filter((category) => category.id === currCategoryId);
      } else {
        currCategory = {
          name: service.category_name,
          id: service.category_id,
          selected: true,
          time: 0,
          services: [],
        };
      }

      let serviceProvider = '';
      let isSystemAssigned = false;
      if (service.provider) {
        serviceProvider = service.provider;
        currCategory.time += parseInt(service.provider.service_length_in_minutes, 10);
      } else {
        isSystemAssigned = true;
        serviceProvider = service.system_assigned_provider;
        currCategory.time += parseInt(service.system_assigned_service_length_in_minutes, 10);
      }

      currCategory.services.push({
        service_name: service.service_display_name,
        service_id: service.service_id,
        provider_name: isSystemAssigned ? `any ${location.location_category_id === 1 ? 'therapist' : 'stylist'}` : serviceProvider.service_provider_display_name,
        provider_tenure: isSystemAssigned ? null : serviceProvider.service_provider_tenure,
        service_time: serviceProvider.service_length_in_minutes,
        start_time: serviceProvider.start_time,
      });

      if (newCategory) {
        categoriesFound.push(currCategoryId);
        categories.push(currCategory);
      }
    });

    if (bookingAddOns.length > 0) {
      [].concat(...bookingAddOns.map((bookingAddOn) => bookingAddOn.services)).filter((addOn) => (addOn).selected).forEach((addOn) => {
        const [correspondentService] = categories.filter((cat) => cat.services.filter((serv) => serv.service_id === addOn.service_associated_id).length > 0);

        const currCategory = {
          name: addOn.category_name.replace(/[()]/g, '').replace(/[. ]/i, ' | '),
          id: addOn.category_id,
          selected: true,
          time: addOn.system_assigned_service_length_in_minutes,
        };

        currCategory.services = [];
        const serviceProvider = addOn.service_providers[0];
        currCategory.services.push({
          service_name: addOn.service_name,
          service_id: addOn.service_id,
          provider_name: serviceProvider.system_assigned ? `any ${location.location_category_id === 1 ? 'therapist' : 'stylist'}` : addOn.service_providers[0].service_provider_display_name,
          provider_tenure: serviceProvider.system_assigned ? null : serviceProvider.service_provider_tenure,
          service_time: addOn.system_assigned_service_length_in_minutes,
          start_time: serviceProvider.start_time,
        });

        const serviceIndex = categories.indexOf(correspondentService);
        categories.splice(serviceIndex + 1, 0, currCategory);
      });
    }

    setState((prevState) => ({ ...prevState, servicesAndAddonsWithProvider: categories }));
  }, []);

  function calculateStartTime() {
    let minMinutes = Number.MAX_VALUE;
    let currentHour = '';
    let currProvider = '';

    serviceProviders.forEach((service) => {
      currProvider = service.provider ? service.provider : service.system_assigned_provider;
      const [hour, minutes] = currProvider.start_time.split('T')[1].split('.')[0].split(':');

      const currMinutes = parseInt(hour, 10) * 60 + parseInt(minutes, 10);

      if (currMinutes < minMinutes) {
        currentHour = `${hour}:${minutes}`;
        minMinutes = currMinutes;
      }
    });

    return currentHour;
  }

  function getServiceTime(time) {
    return time >= 60 ? timeConvert(time) : `${time} min`;
  }

  function convertAppointmentEndTime() {
    const [hour, minutes] = appointmentEndTime.split('T')[1].split('.')[0].split(':');
    return `${hour}:${minutes}`;
  }

  function toggleCategory(id) {
    const value = servicesAndAddonsWithProvider.filter((c) => c.id === id)[0].selected;
    servicesAndAddonsWithProvider.filter((c) => c.id === id)[0].selected = !value;

    setState((prevState) => ({ ...prevState, servicesAndAddonsWithProvider }));
  }

  function renderServices(services) {
    const retServices = services.map((service) => (
      <div key={`s-${service.service_id}`} className="pl-4 pb-2 flex w-full flex-col">
        <div className="flex w-full justify-between">
          <span className="data-text-primary w-2/3 pl-1" style={{ color }}>{service.service_name}</span>
          <span className="data-text-primary w-1/3 pl-2 text-right" style={{ color }}>{getHoursAndMinutesFromDate(convertTZ(new Date(service.start_time)))}</span>
        </div>
        <span className="data-text-provider pl-1 italic">
          {`by ${service.provider_name}`}
          { service.provider_tenure && (
            <span className="pl-1 provider-tenure-text">{`(with us since ${convertDate(new Date(service.provider_tenure), '', true)})`}</span>
          )}
        </span>

      </div>
    ));

    return retServices;
  }

  function renderCategories() {
    const retServices = servicesAndAddonsWithProvider.map((category, index) => (
      <div className={index === servicesAndAddonsWithProvider.length - 1 ? 'category-container' : 'category-container-with-border-bottom'} role="button" tabIndex={0} key={category.id}>
        <div className="category-title" role="button" tabIndex={0} onClick={() => toggleCategory(category.id)}>
          <div className="flex">
            {
              category.selected
                ? <ArrowDownIcon className="arrow-icon-category" alt="" style={{ fill: color }} />
                : <ArrowRightIcon className="arrow-icon-category" alt="" style={{ fill: color }} />
            }
            <span>{`${category.name} (${category.services.length})`}</span>
          </div>
          <div className="flex justify-center items-center">
            <IconDuration className="arrow-icon-category" alt="" style={{ fill: color }} />
            <span className="data-text-primary" style={{ color }}>{`${getServiceTime(category.time)}`}</span>
          </div>
        </div>
        {
          category.selected
            ? (
              <div className="services">
                {renderServices(category.services)}
              </div>
            ) : ''
        }
      </div>
    ));

    return retServices;
  }

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full">
      <div className={confirmed ? 'subtitle booking-confirmed' : 'subtitle confirm-booking'}>
        {confirmed
          ? (<RatingIcon className="rating-icon" alt="" />)
          : (<AlertIcon className="alert-icon" alt="" />)}
        <span>{confirmed ? 'Your booking has been confirmed' : 'Please confirm your booking details'}</span>
      </div>
      <div className="separator" />
      <div className="flex flex-col  w-full items-center px-5 overflow-scroll scrollbar-none">
        <div className="w-full my-6">
          <span className="box-title" style={{ background: color }}>Booking Summary</span>
          <div className="container">
            <div className="data-title pb-1">
              <IconLocation className="icon" alt="" style={{ fill: color }} />
              <span className="placeholder" style={{ color }}>Location</span>
            </div>
            <span className="data-text pl-2 pb-6">{location.name}</span>
            <div className="data-title pb-1">
              <IconDate className="icon" style={{ fill: color }} alt="" />
              <span className="placeholder" style={{ color }}>Date</span>
            </div>
            <span className="data-text pl-1 pb-6">{convertDate(day, '/', true)}</span>
            <div className="flex w-full">
              <div className="data-title pb-1">
                <IconClock className="icon" style={{ fill: color }} alt="" />
                <span className="placeholder" style={{ color }}>Time</span>
              </div>
            </div>
            <div className="flex w-full pl-1">
              <div className="flex flex-col w-1/2">
                <div className="data-title">
                  <span className="placeholder-time" style={{ color }}>Start</span>
                </div>
                <span className="data-text pb-6">{calculateStartTime()}</span>
              </div>
              <div className="flex flex-col w-1/2">
                <div className="data-title">
                  <span className="placeholder-time" style={{ color }}>Approx. End</span>
                </div>
                <span className="data-text pb-6">{convertAppointmentEndTime()}</span>
              </div>
            </div>
            <div className="flex w-full">
              <div className="flex pb-1">
                <IconServices className="icon" alt="" style={{ fill: color }} />
                <span className="placeholder" style={{ color }}>{`Services and ${location.location_category_id === 1 ? 'Therapists' : 'Stylists'}`}</span>
              </div>
            </div>
            <div className="pb-6">
              {renderCategories()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Summary.propTypes = {
  confirmed: PropTypes.bool,
  color: PropTypes.string.isRequired,
};

Summary.defaultProps = {
  confirmed: false,
};

export default Summary;
