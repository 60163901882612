import includes from 'lodash/includes';
import keys from 'lodash/keys';

export const API_VERSION_V1 = 'v1';

export const API_GET = 'API_GET';
export const API_POST = 'API_POST';
export const API_PUT = 'API_PUT';
export const API_PATCH = 'API_PATCH';
export const API_DELETE = 'API_DELETE';

export const API_METHODS = {
  [API_GET]: 'get',
  [API_POST]: 'post',
  [API_PUT]: 'put',
  [API_PATCH]: 'patch',
  [API_DELETE]: 'delete',
  [API_PUT]: 'put',
};

/**
 *
 * @param {String} method the http method
 * @returns true if this method is a valid one.
 */
export const isMethodAllowed = (method) => includes(keys(API_METHODS), method);

/**
 *
 * @param {String} method the http method
 * @returns true if this method allows HTTP payload.
 */
export const methodAllowsPayload = (method) => includes([API_POST, API_PATCH, API_PUT], method);
