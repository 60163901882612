import React from 'react';
import PropTypes from 'prop-types';

import '../../assets/css/booking/booking_category_services_popup.scss';
import { getCurrentLoyaltyLevel } from '../../selectors/user';
import CategoryService from './CategoryService';

/**
 * Component responsible for rendering the services of a sub category.
 *
 * @param {String} title the title of the sub category.
 * @param {Array} services the list of services of this sub category.
 * @param {Function} onSelectService callback to run when the user selects a service.
 */
const SubCategory = ({ title, services, onSelectService }) => {
  const level = getCurrentLoyaltyLevel();
  const color = level.level_color;

  return (
    <>
      <div
        className="category_services_popup_section_header"
        style={{
          backgroundColor: color,
        }}
      >
        {title}
      </div>
      <div className="category_services_popup_section_services">
        {services.map((service) => (
          <CategoryService
            key={service.service_name}
            service={({
              ...service,
              name: service.service_name,
              selected: service.selected,
            })}
            onSelect={onSelectService}
          />
        ))}
      </div>
    </>
  );
};

SubCategory.propTypes = {
  title: PropTypes.string.isRequired,
  services: PropTypes.arrayOf(PropTypes.shape({
    service_name: PropTypes.string.isRequired,
  })).isRequired,
  onSelectService: PropTypes.func.isRequired,
};

export default SubCategory;
