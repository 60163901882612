/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import '../../../assets/css/dashboard/packageCardTile.scss';

import { convertDate } from '../../../assets/js/constants';

import locationTnsDefaultImg from '../../../assets/img/location_tns.jpg';
import locationMarqueeDefaultImg from '../../../assets/img/location_marquee.jpg';

import packageBg from '../../../assets/img/package-bg.png';

import calendarIcon from '../../../assets/icons/calendar.svg';
import LocationLogo from '../LocationLogo';

function PackageCardTile({
  // eslint-disable-next-line no-unused-vars
  locationCategory, status, photo, remaining, service, date, style,
}) {
  function getTileImageUrl(packagePhoto, category) {
    if (!packagePhoto) {
      return category === 'tns' ? locationTnsDefaultImg : locationMarqueeDefaultImg;
    }
    return packagePhoto;
  }

  function renderStatusBanner() {
    if (status === 'active') {
      return (
        <div className="package-card-tile-status" style={{ backgroundColor: componentStyle.color80 }}>
          <div className="package-card-tile-status-text" style={{ color: 'white' }}>
            {`${remaining} REMAINING`}
          </div>
        </div>
      );
    }
    if (status === 'redeemed') {
      return (
        <div className="package-card-tile-status" style={{ backgroundColor: componentStyle.colorGreen }}>
          <div className="package-card-tile-status-text" style={{ color: 'white' }}>
            PACKAGE REDEEMED
          </div>
        </div>
      );
    }
    return (
      <div className="package-card-tile-status" style={{ backgroundColor: componentStyle.colorExpiredDarker }}>
        <div className="package-card-tile-status-text" style={{ color: 'white' }}>
          PACKAGE EXPIRED
        </div>
      </div>
    );
  }

  const componentStyle = {
    color: style.color,
    colorGreen: 'var(--color-green)',
    color66: style.color.concat('66'),
    color80: style.color.concat('80'),
    colorExpiredDarker: 'var(--color-expired-darken)',
    locationLogo: { fill: 'white' },
  };

  return (
    <div role="button" tabIndex={0} className="package-card-tile">
      <div
        className="package-card-tile-img"
        style={{
          backgroundImage: `url(${getTileImageUrl(packageBg, locationCategory)})`,
          backgroundColor: componentStyle.color66,
          filter: status === 'expired' && 'grayscale(100%)',
        }}
      />
      <div className="package-card-tile-info">
        <LocationLogo
          locationCategory={locationCategory}
          styleClass="package-card-tile-logo"
          style={componentStyle.locationLogo}
        />
        <div className="package-card-tile-info-text">
          <span className="package-card-tile-service">{service}</span>
          {renderStatusBanner()}
        </div>
      </div>
      <div className="package-card-status">
        <div className="package-card-status-opacity px-1">
          <span className="package-card-status-text">
            <div className="flex items-center">
              <img className="package-card-status-icon" src={calendarIcon} alt="" />
              <span className="package-card-status-use-by">Use by</span>
            </div>
          </span>
          <span className="package-card-status-text text-right">
            {convertDate(new Date(date), '', true)}
          </span>
        </div>
      </div>
    </div>
  );
}

PackageCardTile.propTypes = {
  locationCategory: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  photo: PropTypes.string,
  remaining: PropTypes.number.isRequired,
  service: PropTypes.string.isRequired,
  date: PropTypes.any.isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
    filter: PropTypes.string,
  }),
};

PackageCardTile.defaultProps = {
  photo: '',
  style: { color: '#5D3553', filter: 'var(--filter-primary)' },
};

export default PackageCardTile;
