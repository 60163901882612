import { useSelector } from 'react-redux';
import { convertTZ } from '../assets/js/constants';

export const getCurrentUserLocations = () => getCurrentUser().customer_locations;
export const getCurrentUser = () => useSelector((reduxState) => reduxState.user);
export const getCurrentLoyaltyLevel = () => getCurrentUser().loyalty_level;
export const getCurrentLevel = () => getCurrentUser().customer_loyalty_level.loyalty_level_id;
export const getSSOToken = () => useSelector((reduxState) => reduxState.ssoToken);
export const hasVisitedInTheLastYear = () => {
  const user = getCurrentUser();
  const lastSale = user.last_sale;
  const today = convertTZ(new Date());
  today.setYear(today.getFullYear() - 1);
  return lastSale !== undefined && lastSale !== null && convertTZ(new Date(lastSale)) >= today;
};
