import { ApiGet } from './Api';

/**
 * Stamp Card API calls
 */
export default () => ({
  /**
   *
   * @param {Object} params the params for the call.
   * @returns a list with all the current stamp cards (includes active, expired and completed stamp cards)
   */
  list: (params) => ApiGet({ endpoint: 'stamp_cards', params }),

  /**
   *
   * @param {Integer} id the stamp card id
   * @returns an hash with the stamp card details.
   */
  get: (id) => ApiGet({ endpoint: `stamp_cards/${id}` }),
});
