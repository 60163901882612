/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import withNotificationPopUp from '../Shared/withNotificationPopUp';

import DashboardHeader from '../Dashboard/DashboardHeader';

import '../../assets/css/shared.scss';
import '../../assets/css/settings/settings.scss';

import withUserRetriever from '../Shared/withUserRetriever';

import { convertDate } from '../../assets/js/constants';
import LogoutPopup from '../Shared/Popups/LogoutPopup';

import { ReactComponent as IconRight } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as IconAccount } from '../../assets/icons/settings/account.svg';
import { ReactComponent as IconNotification } from '../../assets/icons/alert.svg';
import { ReactComponent as IconMail } from '../../assets/icons/settings/mail.svg';
import { ReactComponent as IconHelp } from '../../assets/icons/settings/help.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/settings/logout.svg';
import { ReactComponent as IconDefaultProfile } from '../../assets/icons/settings/default_avatar.svg';

function Settings() {
  const history = useHistory();
  const user = useSelector((reduxState) => reduxState.user);
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);
  const color = level.level_color;

  const [state, setState] = useState({
    logout: false,
  });
  const { logout } = state;

  function openLogOutModal() {
    setState((prevState) => ({ ...prevState, logout: true }));
  }

  function renderOption(OptionIcon, route, text) {
    return (
      <div role="button" className="flex py-3 justify-center" tabIndex={0} onClick={() => history.push(route)}>
        <div className="w-1/6">
          <OptionIcon className="icon-settings-left" alt="" style={{ color, fill: color, stroke: color }} />
        </div>
        <div className="w-4/6 text-left text-options">{text}</div>
        <div className="w-1/6">
          <IconRight className="icon-settings-right" alt="" style={{ stroke: color }} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full dashboard-grey-background-color">
      <DashboardHeader color={color} title="Settings" />
      <div className="settings-container scrollbar-none grey-background container-center">
        <div
          className="settings-bg-content-top"
          style={{
            background: `linear-gradient(to bottom right,
              ${level.level_color.concat('E6')} 0%,
              ${level.level_color} 100%)`,
          }}
        >
          <div className="settings-header">
            <IconDefaultProfile className="icon-settings-header" alt="" />
            <div className="settings-current-level-text ml-2">
              <div className="settings-person-name">
                {user.name}
              </div>
              <div className="text-white settings-activation-date">
                Active since
                {' '}
                {convertDate(new Date(user.loyalty_activation_date), '', true)}
              </div>
            </div>
          </div>
        </div>
        <div className="settings-bg-content-top-opacity" />
        <div className="section-cards-settings-container px-5">
          <div className="settings-title-section opacity-90 my-4" style={{ color }}>Settings</div>
          {renderOption(IconAccount, '/settings/profile', 'Your Account')}
          {renderOption(IconNotification, '/dashboard/messages', 'Notifications')}
          {renderOption(IconMail, '/settings/communications', 'Communication Preferences')}
          {renderOption(IconHelp, '/settings/help', 'Help')}
        </div>
      </div>
      <div role="button" className="mb-8 flex flex-row justify-center items-center" tabIndex={0} onClick={() => openLogOutModal()}>
        <div style={{ color: level.level_color }}> Logout </div>
        <div className="ml-4">
          <LogoutIcon className="settings-logout-icon" alt="" style={{ fill: color, color, stroke: color }} />
        </div>
      </div>
      {
        logout && (
          <LogoutPopup color={level.level_color} onDismissPopUp={() => setState((prevState) => ({ ...prevState, logout: false }))} />
        )
      }
    </div>
  );
}

export default compose(
  withUserRetriever,
  withNotificationPopUp,
)(Settings);
