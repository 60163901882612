import { React } from 'react';
import PropTypes from 'prop-types';

import { convertDate } from '../../../assets/js/constants';

import '../../../assets/css/dashboard/giftCardTile.scss';

import locationTnsDefaultImg from '../../../assets/img/location_tns.jpg';
import locationMarqueeDefaultImg from '../../../assets/img/location_marquee.jpg';
import LocationLogo from '../LocationLogo';

function GiftCardTile({
  locationCategory, status, photo, remaining, date,
}) {
  const getTileImageUrl = (giftCardPhoto, category) => {
    if (!giftCardPhoto) {
      return category === 'tns' ? locationTnsDefaultImg : locationMarqueeDefaultImg;
    }
    return giftCardPhoto;
  };

  function renderStatusBanner() {
    if (status === 'active') {
      return (
        <div className="gift-card-status-use-by">
          {`Use by ${convertDate(new Date(date), '', true)}`}
        </div>
      );
    }
    if (status === 'redeemed') {
      return (
        <div className="gift-card-status-tag" style={{ backgroundColor: componentStyle.colorGreen }}>
          <div className="gift-card-status-tag-text" style={{ color: 'white' }}>
            GIFTCARD REDEEMED
          </div>
        </div>
      );
    }
    return (
      <div className="gift-card-status-tag" style={{ backgroundColor: componentStyle.colorExpiredDarker }}>
        <div className="gift-card-status-tag-text" style={{ color: 'white' }}>
          GIFTCARD EXPIRED
        </div>
      </div>
    );
  }

  const componentStyle = {
    colorGreen: 'var(--color-green)',
    colorExpiredDarker: 'var(--color-expired-darken)',
    locationLogo: status === 'expired'
      ? { fill: 'white', backgroundColor: 'var(--color-expired-darken)' }
      : { fill: 'white', backgroundColor: '#5D3553' },
  };

  return (
    <div role="button" tabIndex={0} className="gift-card-tile">
      <div
        className="gift-card-tile-img opacity-50"
        style={{
          backgroundImage: `url(${getTileImageUrl(photo, locationCategory)})`,
          filter: status === 'expired' && 'grayscale(100%)',
        }}
      />
      <LocationLogo
        locationCategory={locationCategory}
        styleClass="gift-card-tile-logo"
        style={componentStyle.locationLogo}
      />
      <div className="gift-card-status">
        <div className="gift-card-status-opacity">
          <div className="w-full pl-1 pr-2 flex justify-between items-center">
            <div className="gift-card-status-remaining">
              {`${remaining} Remaining`}
            </div>
            {renderStatusBanner()}
          </div>
        </div>
      </div>
    </div>
  );
}

GiftCardTile.propTypes = {
  locationCategory: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  photo: PropTypes.string,
  remaining: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

GiftCardTile.defaultProps = {
  photo: '',
};

export default GiftCardTile;
