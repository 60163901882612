/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import queryParamValue from '../../utils/queryParams';
import {
  ANDROID_OS,
  IOS_OS,
  getMobileOS,
  isUsingNativeIOSApp,
  isUsingNativeAndroidApp,
} from '../../utils/browser';

const APP_STORE_APPLE = 'https://apps.apple.com/us/app/be-you-ty-bonus/id6449249567';

const APP_STORE_ANDROID = 'https://play.google.com/store/apps/details?id=com.repeto.enspa.beyouty_bonus';

/**
 * Wrap component that will redirect the customer to the correct store (App Store on iOS or Play Store on Android)
 * when the site is loaded with a special query param and if the user is not browsing the website from the native app.
 *
 * @param {ReactElement} Component component to be wrapped
 * @returns ReactElement
 */
const withAppStoreRedirect = (Component) => (props) => {
  const shouldRedirect = queryParamValue('store_redirect') === 'true';

  if (!shouldRedirect) {
    return <Component {...props} />;
  }

  const mobileOs = getMobileOS();

  if (mobileOs === IOS_OS && !isUsingNativeIOSApp()) {
    window.location = APP_STORE_APPLE;
    return null;
  }

  if (mobileOs === ANDROID_OS && !isUsingNativeAndroidApp()) {
    window.location = APP_STORE_ANDROID;
    return null;
  }

  return <Component {...props} />;
};

export default withAppStoreRedirect;
