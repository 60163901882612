const giftCards = (state = '', action) => {
  switch (action.type) {
    case 'GIFT_CARDS':
      return action.giftCards;
    default:
      return state;
  }
};

export default giftCards;
