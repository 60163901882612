/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import '../../../assets/css/dashboard/voucherCardTile.scss';

import { useHistory } from 'react-router-dom';
import defaultVoucherImg from '../../../assets/img/voucher.png';
import LocationLogo from '../LocationLogo';

import { dateDifferenceUntilFormat } from '../../../assets/js/constants';

function VoucherCardTile({
  id, img, state, locationCategory, textColor, name, date, style,
}) {
  const history = useHistory();

  const componentStyle = {
    color: style.color,
    colorRedeemed: 'var(--color-green)',
    colorExpired: 'var(--color-expired)',
    colorExpiredDarker: 'var(--color-expired-darken)',
    textColor,
    locationLogo: { fill: 'white' },
  };

  function renderStatus() {
    switch (state) {
      case 'redeemed':
        return (
          <div className="voucher-card-tile-status" style={{ background: componentStyle.colorRedeemed }}>
            <div className="voucher-card-tile-status-text" style={{ color: 'white' }}>
              VOUCHER REDEEMED
            </div>
          </div>
        );
      case 'expired':
        return (
          <div className="voucher-card-tile-status" style={{ background: componentStyle.colorExpired }}>
            <div className="voucher-card-tile-status-text" style={{ color: 'white' }}>
              VOUCHER EXPIRED
            </div>
          </div>
        );
      default:
        return (
          <div className="voucher-card-tile-status" style={{ background: componentStyle.color.concat('80') }}>
            <div className="voucher-card-tile-status-text" style={{ color: componentStyle.textColor }}>
              {`EXPIRES ${dateDifferenceUntilFormat(date)}`}
            </div>
          </div>
        );
    }
  }

  function handleOnClick() {
    history.push(`/dashboard/vouchers/${id}`);
  }

  return (
    <div className="voucher-card-tile-parent" style={{ backgroundColor: state !== 'expired' && componentStyle.color }}>
      <div className={`voucher-card-tile-img--${state}`} style={{ backgroundImage: `url(${img}), url(${defaultVoucherImg})` }} />
      <div role="button" tabIndex={0} className="voucher-card-tile" onClick={handleOnClick}>
        <LocationLogo
          locationCategory={locationCategory}
          styleClass="voucher-card-tile-logo"
          style={componentStyle.locationLogo}
        />
        <div className="voucher-card-tile-data">
          {renderStatus()}
          <div className="absolute bottom-0 mx-4 mb-4">
            <div className="voucher-card-tile-name" style={{ color: componentStyle.textColor }}>{name}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

VoucherCardTile.propTypes = {
  id: PropTypes.string.isRequired,
  img: PropTypes.string,
  state: PropTypes.string.isRequired,
  locationCategory: PropTypes.string,
  date: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  style: PropTypes.shape({
    color: PropTypes.string,
    filter: PropTypes.string,
  }),
};

VoucherCardTile.defaultProps = {
  img: defaultVoucherImg,
  locationCategory: '',
  textColor: 'var(--grey-font)',
  style: PropTypes.shape({
    color: 'white',
    filter: 'var(--filter-white)',
  }),
};

export default VoucherCardTile;
