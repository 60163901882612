/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_LEVEL_COLOR } from '../../assets/js/constants';
import { getSSOToken } from '../../selectors/user';
import getClient from '../../store/client';
import Loading from '../Loading';

/**
 * Wrap component to maintain user in the redux store for certain components
 * that might require the 'USER_INFORMATION' reducer.
 *
 * @param {ReactElement} Component component to be wrapped
 * @returns ReactElement
 */
const withUserRetriever = (Component) => (props) => {
  const user = useSelector((reduxState) => reduxState.user);
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);
  const color = (level && level.level_color) || DEFAULT_LEVEL_COLOR;
  const ssoToken = getSSOToken();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      getClient({ dispatch });
    }
  }, [ssoToken]);

  return (
    <>
      {
        user ? <Component {...props} /> : <Loading isCover color={color} />
      }
    </>
  );
};

export default withUserRetriever;
