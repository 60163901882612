import { BOOKING_APPOINTMENT, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = '';

const bookingAppointment = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_APPOINTMENT:
      return action.appointmentId;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingAppointment;
