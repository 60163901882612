import { BOOKING_SERVICE_PROVIDER_FOR_SERVICES, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = {};

const bookingServiceProviderForServices = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_SERVICE_PROVIDER_FOR_SERVICES:
      return action.serviceProviders;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingServiceProviderForServices;
