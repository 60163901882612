import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import rightIcon from '../../assets/icons/chevron/right.svg';

import '../../assets/css/dashboardcomponentheader.scss';

function DashboardComponentHeader({
  title, color, routeText, route,
}) {
  const history = useHistory();

  function goTo() {
    history.push(route);
  }

  return (
    <div className="component-header">
      <span className="component-title-section opacity-90" style={{ color }}>{title}</span>
      {
        (route && routeText) && (
          <div role="button" tabIndex={0} onClick={() => goTo()} className="flex items-center">
            <span className="component-header-route-text">{routeText}</span>
            <img className="component-header-right-icon" src={rightIcon} alt="" />
          </div>
        )
      }
    </div>
  );
}

DashboardComponentHeader.propTypes = {
  title: PropTypes.string.isRequired,
  routeText: PropTypes.string,
  route: PropTypes.string,
  color: PropTypes.string.isRequired,
};

DashboardComponentHeader.defaultProps = {
  routeText: '',
  route: '',
};

export default DashboardComponentHeader;
