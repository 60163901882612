import React from 'react';
import PopUpModal from './PopUpModal';

import { ReactComponent as HappyBirthday } from '../../../assets/img/happy_birthday.svg';
import { ReactComponent as TNSLogo } from '../../../assets/img/logo_tns_black.svg';
import { ReactComponent as MarqueeLogo } from '../../../assets/img/logo_marquee_black.svg';

import StampCardTile from '../CardTiles/StampCardTile';

export const renderLevelUpgradePopUp = (notification, handleButtonAction, handleOnCloseModal) => (
  <PopUpModal
    handleClose={handleOnCloseModal}
    buttonAction={handleButtonAction}
    buttonText="View Voucher"
    iconConfig={{
      image: notification.level_logo,
      styleClass: 'popup-level-upgrade-icon',
    }}
    titleConfig={{ content: 'Congratulations' }}
    content={`You are now a ${notification.level_name} member!
      To celebrate your level upgrade we offer you a voucher...`}
    style={{
      color: notification.level_color,
    }}
  />
);

export const renderBirthdayPopUp = (notification, handleButtonAction, handleOnCloseModal) => (
  <PopUpModal
    handleClose={handleOnCloseModal}
    buttonAction={handleButtonAction}
    buttonText="View Gift"
    iconConfig={{
      Component: HappyBirthday,
      styleClass: 'popup-birthday-icon',
    }}
    titleConfig={{
      content: 'Wishing you a wonderful year ahead!',
      styleClass: 'popup-birthday-title',
    }}
    content="Here's a little something from us to celebrate your special day!"
    style={{
      color: notification.level_color,
    }}
  />
);

export const renderStampComponentPopUp = (notification, handleButtonAction, handleOnCloseModal, stampProps) => {
  const { stamp } = notification;

  return (
    <PopUpModal
      handleClose={handleOnCloseModal}
      buttonAction={handleButtonAction}
      buttonText={stampProps.buttonText}
      iconConfig={{
        Component: StampCardTile,
        props: {
          id: stamp.id,
          maxStamps: stamp.max_ticks + 1,
          stamps: notification.type === 'new_stamp_card' ? [] : stamp.current_ticks,
          expirationDate: stamp.end_date,
          state: stampProps.state,
          imageUrl: stamp.image,
          handleButtonAction,
          levelId: notification.level_id,
          color: notification.level_color,
        },
        styleClass: 'popup-stamp-new',
      }}
      titleConfig={{
        content: stampProps.title,
        styleClass: 'popup-stamp-earned-title',
      }}
      content={notification.message}
      style={{
        color: notification.level_color,
      }}
    />
  );
};

export const renderStampEarnedPopUp = (notification, handleButtonAction, handleOnCloseModal) => (
  <PopUpModal
    handleClose={handleOnCloseModal}
    buttonAction={handleButtonAction}
    buttonText="View Stamp Card"
    iconConfig={{
      Component: notification.stamp.current_ticks[notification.stamp.current_ticks.length - 1][1] === 'tns' ? TNSLogo : MarqueeLogo,
      styleClass: 'popup-stamp-earned-icon',
    }}
    titleConfig={{
      content: 'Stamp Earned',
      styleClass: 'popup-stamp-earned-title',
    }}
    content={notification.message}
    style={{
      color: notification.level_color,
    }}
  />
);
