import React from 'react';
import PropTypes from 'prop-types';

import '../../assets/css/booking/booking_service_category_types.scss';
import { getCurrentLoyaltyLevel } from '../../selectors/user';
import Select from '../Shared/Select';

/**
 * Component to render a box to activate/de-activate a given category. When active, it renders a service picker
 * that the customer can open to see the sub categories of that category along with their services.
 *
 * @param {Object} category the category data.
 * @param {Boolean} isActive indicates if this category is active.
 * @param {React<Component>} Icon the icon component of the category.
 * @param {Function} onToggle callback to call whenever the category is activated/disabled.
 * @param {Function} onServicePickerClick callback to call whenever the services picker is clicked.
 * @param {Object} selectedService the currently selected service
 * @param {Array} children children to render
 */
const CategoryType = ({
  category,
  isActive,
  Icon,
  onToggle,
  onServicePickerClick,
  selectedService,
  children,
}) => {
  const level = getCurrentLoyaltyLevel();
  const color = level.level_color;

  const onToggleCategoryType = (e) => {
    e.stopPropagation();
    onToggle(!isActive);
  };

  return (
    <label
      htmlFor={category.name}
      className={`category_type category_type${isActive && '--active'}`}
      style={{
        borderColor: isActive && color,
      }}
    >
      <div className="category_type_row">
        <div className="category_type_icon"><Icon width="35px" height="35px" fill={color} /></div>
        <span className="category_type_name" style={{ color }}>
          {category.name}
        </span>
        <div className={`custom-checkbox-container-lvl${level.id} category_type_checkbox`}>
          <input
            id={category.name}
            type="checkbox"
            checked={isActive}
            readOnly
            onChange={onToggleCategoryType}
          />
          <span className={`custom-checkbox-lvl${level.id}`} />
        </div>
      </div>

      {isActive && (
        <div className="category_type_row category_type_row--col">
          <Select
            classNames={{
              container: 'category_type_select',
              header: 'category_type_select_header',
            }}
            customOnClickFn={onServicePickerClick}
            openable={false}
            placeholder="Specify your Service"
            collection={[]}
            startOpened={false}
            value={selectedService.service_name}
            withSearch={false}
          />
          {children}
        </div>
      )}
    </label>
  );
};

CategoryType.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subCategories: PropTypes.arrayOf(
      PropTypes.shape({
        services: PropTypes.arrayOf(
          PropTypes.shape({
            service_name: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  Icon: PropTypes.shape({}).isRequired,
  onToggle: PropTypes.func.isRequired,
  onServicePickerClick: PropTypes.func.isRequired,
  selectedService: PropTypes.shape({
    service_name: PropTypes.string,
  }),
  children: PropTypes.node,
};

CategoryType.defaultProps = {
  selectedService: {
    service_name: '',
  },
  children: null,
};

export default CategoryType;
