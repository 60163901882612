/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import once from 'lodash/once';
import getClient from '../../store/client';
import axios from '../../interceptor';

import PopUpComponentModal from './Popups/PopUpComponentModal';
import { CustomToast } from './withToast';

import 'swiper/css';
import 'swiper/css/pagination';
import '../../assets/css/dashboard/shared.scss';

import {
  renderBirthdayPopUp,
  renderLevelUpgradePopUp,
  renderStampComponentPopUp,
  renderStampEarnedPopUp,
} from './Popups/NotificationPopupRenders';

import { orderStampNotifications } from '../Dashboard/Stamps/utils';
import SurveyDetailsModal from '../Dashboard/SurveyDetailsModal';
import surveysIcon from '../../assets/icons/toasts/survey.svg';

import { getCurrentUser } from '../../selectors/user';
import Config from '../../config/BeyoutyBonus';

/**
 * Higher-order component that enables a child component to show
 * popup notifications related with the new notifications that are
 * received.
 * Take special note that this component will always be rendered first,
 * the `Header` which checks for new notifications only updates later.
 *
 * @param {ReactNode} Component the component to be rendered
 * @returns {ReactNode}
 */
const withNotificationPopUp = (Component) => (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const notificationUpdates = useSelector((reduxState) => reduxState.notificationUpdates);
  const lastSurvey = useSelector((reduxState) => reduxState.lastSurvey);
  const user = getCurrentUser();

  const useStyles = makeStyles({
    swiperActiveDot: {
      background: 'white',
      opacity: 1,
    },
  });

  const classes = useStyles();

  const swiperSettings = {
    modules: [Pagination],
    slidesPerView: 1.2,
    centeredSlides: true,
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      bulletActiveClass: classes.swiperActiveDot,
    },
  };

  const handleOnCloseModal = once(() => {
    // mark popup notifications as seen
    const isValidType = (notification) => [
      'level_upgrade',
      'birthday',
      'stamp_card_tick',
      'new_stamp_card',
      'stamp_card_completed',
    ].includes(notification.type);

    if (notificationUpdates.data.length > 0 || (!lastSurvey.seen && lastSurvey.survey)) {
      notificationUpdates.data = notificationUpdates.data.filter((n) => isValidType(n));
      axios.post('/api/v1/app_notifications/mark_as_seen', {
        id: notificationUpdates.data.map((n) => n.id),
      })
        .then(() => {
          dispatch({
            type: 'NOTIFICATION_UPDATES',
            notificationUpdates: {
              total: notificationUpdates.total - notificationUpdates.data.length, // update count
              data: '', // remove all updates
            },
          });
          dispatch({
            type: 'LAST_SURVEY',
            lastSurvey: {
              survey: lastSurvey.survey,
              seen: true,
            },
          });
        }).catch(() => dispatch({ type: 'NOTIFICATION_UPDATES', notificationUpdates: { total: 0, data: '' } }));
    }
    // update client information
    getClient({ dispatch });
  });

  const renderLevelUpgrade = (notification) => {
    const buttonAction = () => {
      history.push(`/dashboard/vouchers/${notification.award_id}`);
      handleOnCloseModal();
    };

    return renderLevelUpgradePopUp(notification, buttonAction, handleOnCloseModal);
  };

  const renderBirthday = (notification) => {
    const buttonAction = () => {
      history.push(`/dashboard/vouchers/${notification.award_id}`);
      handleOnCloseModal();
    };

    return renderBirthdayPopUp(notification, buttonAction, handleOnCloseModal);
  };

  const renderNewStampComponent = (notification) => {
    const buttonAction = () => {
      history.push(`/dashboard/stampcards/${notification.award_id}`);
      handleOnCloseModal();
    };

    return renderStampComponentPopUp(
      notification,
      buttonAction,
      handleOnCloseModal,
      {
        title: 'New Stamp Card Alert',
        buttonText: 'View Stamp Card',
        // override stamp data
        stamps: 0,
        state: '',
      },
    );
  };

  const renderCompletedStampComponent = (notification) => {
    const buttonAction = () => {
      history.push(`/dashboard/stampcards/${notification.award_id}`);
      handleOnCloseModal();
    };

    return renderStampComponentPopUp(
      notification,
      buttonAction,
      handleOnCloseModal,
      {
        title: 'Stamp Card Completed',
        buttonText: 'View Stamp Card',
        // override stamp data
        stamps: notification.stamp.max_ticks,
        state: 'completed',
      },
    );
  };

  const renderStampEarned = (notification) => {
    const buttonAction = () => {
      history.push(`/dashboard/stampcards/${notification.award_id}`);
      handleOnCloseModal();
    };

    return renderStampEarnedPopUp(notification, buttonAction, handleOnCloseModal);
  };

  const renderLastSurvey = (surveyResponse) => {
    const message = {
      success: 'Survey answered with success!',
      missingProviders: 'To submit your feedback rate all providers.',
      error: 'Something went wrong, could not update Survey.',
      errorTestAccount: 'Answering surveys is disabled for your account. Please contact the support team.',
    };

    const buttonAction = (surveyData) => {
      const payload = {
        survey_answers: surveyData.details.map((detail) => ({ id: detail.detail_id, rating: detail.rating })),
        comment: surveyData.comment,
      };

      if (payload.survey_answers.some((answer) => answer.rating === 0)) {
        toast(<CustomToast type="error" icon={surveysIcon} text={message.missingProviders} />);
      } else {
        axios.put(`api/v1/surveys/${surveyData.id}`, payload)
          .then(() => {
            handleOnCloseModal();
            toast(<CustomToast type="success" text={message.success} />);
          })
          .catch(() => {
            toast(<CustomToast type="error" text={Config.api.testAccount === user.email ? message.errorTestAccount : message.error} />);
          });
      }
    };

    return (
      <div style={{ height: '100%', overflow: 'scroll' }}>
        <SurveyDetailsModal
          survey={surveyResponse}
          style={{ color: surveyResponse.survey_color }}
          inPopUp
          buttonAction={buttonAction}
          updateParentSurvey={() => { }}
          handleClose={handleOnCloseModal}
          modalDisabled={false}
          isNotification
        />
      </div>
    );
  };

  const popupNotifications = (notes) => notes && notes.length >= 1 && notes.every((note) => note.award_id !== null);

  return (
    <>
      <>
        <Component {...props} />
      </>
      {
        (((!lastSurvey.seen) && (lastSurvey.survey) && (!location.pathname.includes('dashboard/transactions'))) || popupNotifications(notificationUpdates.data)) && (
          <PopUpComponentModal handleClose={handleOnCloseModal} fullWidth>
            <Swiper {...swiperSettings}>
              {
                orderStampNotifications(notificationUpdates.data).map((notification, i) => (
                  <SwiperSlide key={`${notification.type}-${i + 1}`}>
                    {notification.type === 'level_upgrade' && renderLevelUpgrade(notification)}
                    {notification.type === 'birthday' && renderBirthday(notification)}
                    {notification.type === 'stamp_card_tick' && renderStampEarned(notification)}
                    {notification.type === 'new_stamp_card' && renderNewStampComponent(notification)}
                    {notification.type === 'stamp_card_completed' && renderCompletedStampComponent(notification)}
                  </SwiperSlide>
                ))
              }
              {
                ((!lastSurvey.seen) && (lastSurvey.survey)) && (!location.pathname.includes('dashboard/transactions')) && (
                  <SwiperSlide key="999">
                    {renderLastSurvey(lastSurvey.survey)}
                  </SwiperSlide>
                )
              }
              <div className="swiper-pagination pagination-on-top" style={{ position: 'fixed', top: '87%' }} />
            </Swiper>
          </PopUpComponentModal>
        )
      }
    </>
  );
};

export default withNotificationPopUp;
