import {
  BOOKING_CLEAR_BOOKING_TIME,
  BOOKING_TIME,
  CLEAN_BOOKING,
} from '../../actions/bookings';

const initialState = '';

const bookingTime = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_TIME:
      return action.time;
    case BOOKING_CLEAR_BOOKING_TIME:
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingTime;
