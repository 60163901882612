import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getCurrentLoyaltyLevel } from '../../selectors/user';

import '../../assets/css/booking/booking_category_services.scss';

/**
 * A component that renders a single service inside the categories modal.
 *
 * @param {Object} service the service object
 * @param {Function} onSelect the callback to call when a service is selected
 * @returns
 */
const CategoryService = ({ service, onSelect }) => {
  const level = getCurrentLoyaltyLevel();
  const color = level.level_color;

  const onClick = (e, serviceClicked) => {
    e.stopPropagation();
    onSelect(serviceClicked);
  };

  const serviceElementId = useMemo(() => `service_${service.name}`, [service]);

  useEffect(() => {
    if (service.selected) {
      document.getElementById(serviceElementId).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <div className="category_service" key={service.name}>
      <div className="category_service_name">
        {service.name}
      </div>
      <button
        type="button"
        id={serviceElementId}
        className={`category_service_btn ${service.selected && 'category_service_btn--selected'}`}
        onClick={(e) => onClick(e, service)}
        style={{
          backgroundColor: color,
        }}
      >
        {service.selected ? 'Selected' : 'Select'}
      </button>
    </div>
  );
};

CategoryService.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default CategoryService;
