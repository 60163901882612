import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default () => {
  const { search } = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const ssoTokenFromStore = useSelector((reduxState) => reduxState.ssoToken);
  const ssoToken = searchParams.get('sso_token') || ssoTokenFromStore;
  return ssoToken;
};
