import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import '../../assets/css/dashboard/slideWallet.scss';

function SlideWallet({
  color, walletTitle, amount, imgUrl, route,
}) {
  const history = useHistory();

  return (
    <>
      <div className="slide-wallet-container mb-1">
        <div role="button" tabIndex={0} onClick={() => history.push(route)} className="slide-wallet-content" style={{ backgroundColor: color }}>
          <div className="slide-wallet-text-left">
            <img className="slide-wallet-image  ml-4 mt-5 mb-2" src={imgUrl} alt="" />
            <div className="slide-wallet-content-title ml-4 ">
              {` ${walletTitle} `}
            </div>
            <div className="slide-wallet-content-text ml-4 mb-4">
              {/* This assumes that the walletTitle is pluralized */}
              {` ${amount} ${amount === 1 ? walletTitle.substring(0, walletTitle.length - 1) : walletTitle}`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SlideWallet.propTypes = {
  color: PropTypes.string.isRequired,
  walletTitle: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  imgUrl: PropTypes.string.isRequired,
  route: PropTypes.string,
};

SlideWallet.defaultProps = {
  route: '',
};

export default SlideWallet;
