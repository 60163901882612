import { convertTZ } from '../assets/js/constants';

export const getCurrentBookingLocationWorkingTimes = (currentBookingLocation) => {
  const locationOpenHours = currentBookingLocation.open_hour.split('T');
  const locationCloseHours = currentBookingLocation.close_hour.split('T');
  locationOpenHours.shift();
  locationCloseHours.shift();
  const locOpenHour = locationOpenHours.join().split(':')[0];
  const locOpenMinutes = locationOpenHours.join().split(':')[1];
  const locCloseHour = locationCloseHours.join().split(':')[0];
  const locCloseMinutes = locationCloseHours.join().split(':')[1];
  const openHour = parseInt(locOpenHour, 10);
  const openMinutes = parseInt(locOpenMinutes, 10);
  const closeHour = parseInt(locCloseHour, 10);
  const closeMinutes = parseInt(locCloseMinutes, 10);
  return {
    openHour,
    openMinutes,
    closeHour,
    closeMinutes,
  };
};

const getDate = (locationCloseMinutes, locationCloseHour) => {
  const today = convertTZ(new Date());

  const verifyIfCurrentHourSurpassesCloseHour = today.getHours() + parseInt(process.env.REACT_APP_MIN_HOURS_BEFORE_BOOKING, 10) > locationCloseHour;
  const verifyIfCloseMinutesEqualsZero = locationCloseMinutes === 0;

  const verifyIfCurrentHourEqualsCloseHourMinusOne = today.getHours() + parseInt(process.env.REACT_APP_MIN_HOURS_BEFORE_BOOKING, 10) === locationCloseHour - 1;
  const verifyIfCurrentHourEqualsCloseHour = today.getHours() + parseInt(process.env.REACT_APP_MIN_HOURS_BEFORE_BOOKING, 10) === locationCloseHour;

  const verifyIfCurrentMinutesBetweenZeroAndCloseMinutes = today.getMinutes() >= 0 && today.getMinutes() <= (locationCloseMinutes === 0 ? 50 : locationCloseMinutes - 10);

  if ((verifyIfCurrentHourEqualsCloseHour && verifyIfCloseMinutesEqualsZero) || verifyIfCurrentHourSurpassesCloseHour) {
    today.setDate(today.getDate() + 1);
    today.setHours(0, 0, 0);
  }
  if (verifyIfCloseMinutesEqualsZero) {
    if (verifyIfCurrentHourEqualsCloseHourMinusOne && !verifyIfCurrentMinutesBetweenZeroAndCloseMinutes) {
      today.setDate(today.getDate() + 1);
      today.setHours(0, 0, 0);
    }
  } else if (verifyIfCurrentHourEqualsCloseHour && !verifyIfCurrentMinutesBetweenZeroAndCloseMinutes) {
    today.setDate(today.getDate() + 1);
    today.setHours(0, 0, 0);
  }

  return today;
};

export default getDate;
