/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import axios from '../../interceptor';
import withNotificationPopUp from '../Shared/withNotificationPopUp';
import { CustomToast } from '../Shared/withToast';
import SubTitle from '../Shared/SubTitle';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../assets/js/constants';

import DashboardHeader from '../Dashboard/DashboardHeader';

import '../../assets/css/shared.scss';
import '../../assets/css/settings/profile.scss';
import '../../assets/css/settings/changePassword.scss';

import withUserRetriever from '../Shared/withUserRetriever';

import eyeOpenIcon from '../../assets/icons/eye_open.svg';
import eyeClosedIcon from '../../assets/icons/eye_closed.svg';

function ChangePassword() {
  const user = useSelector((reduxState) => reduxState.user);
  const level = user.loyalty_level;
  const color = level.level_color;

  const [state, setState] = useState({
    currentPassword: '',
    password: '',
    passwordConfirmation: '',
    processing: false,
    oldPasswordShown: false,
    passwordShown: false,
    passwordShownConfirmation: false,
    areInputsValid: false,
    touchedCurrentPassword: false,
    touchedPassword: false,
    touchedPasswordConfirmation: false,
  });

  const {
    currentPassword,
    password,
    passwordConfirmation,
    processing,
    oldPasswordShown,
    passwordShown,
    passwordShownConfirmation,
    areInputsValid,
    touchedCurrentPassword,
    touchedPassword,
    touchedPasswordConfirmation,
  } = state;

  useEffect(() => {
    setState((prevState) => ({
      ...prevState, areInputsValid: !checkForErrors(),
    }));
  }, [password, passwordConfirmation, currentPassword]);

  function handleChangeCurrentPassword(e) {
    setState((prevState) => ({ ...prevState, currentPassword: e.target.value, touchedCurrentPassword: true }));
  }

  function handleChangePassword(e) {
    setState((prevState) => ({ ...prevState, password: e.target.value, touchedPassword: true }));
  }

  function handleChangePasswordConfirmation(e) {
    setState((prevState) => ({ ...prevState, passwordConfirmation: e.target.value, touchedPasswordConfirmation: true }));
  }

  function toggleCurrentPasswordVisibility() {
    setState((prevState) => ({ ...prevState, oldPasswordShown: !oldPasswordShown }));
  }

  function togglePasswordConfirmVisibility() {
    setState((prevState) => ({ ...prevState, passwordShownConfirmation: !passwordShownConfirmation }));
  }

  function togglePasswordVisibility() {
    setState((prevState) => ({ ...prevState, passwordShown: !passwordShown }));
  }

  function checkForErrors() {
    if (checkNewPasswordsLength()) {
      return true;
    }
    if (checkNewPasswordsDif()) {
      return true;
    }
    if (checkCurrentPassword()) {
      return true;
    }
    return false;
  }
  function checkNewPasswordLength() {
    return password.length < MIN_PASSWORD_LENGTH || password.length > MAX_PASSWORD_LENGTH;
  }

  function checkNewPasswordConfirmationLength() {
    return passwordConfirmation.length < MIN_PASSWORD_LENGTH || passwordConfirmation.length > MAX_PASSWORD_LENGTH;
  }

  function checkNewPasswordsLength() {
    if (checkNewPasswordLength()) {
      return true;
    }
    if (checkNewPasswordConfirmationLength()) {
      return true;
    }
    return false;
  }

  function checkNewPasswordsDif() {
    return password !== passwordConfirmation;
  }

  function checkCurrentPassword() {
    return currentPassword.length < 1;
  }

  function resetPassword() {
    if (checkForErrors()) {
      return false;
    }

    setState((prevState) => ({ ...prevState, processing: true }));

    const payload = {
      current_password: currentPassword,
      password,
      password_confirmation: passwordConfirmation,
    };
    axios.put('/api/v1/client/change_password', payload)
      .then(() => {
        toast(<CustomToast
          type="success"
          text="Password changed successfully. "
        />);
        setState((prevState) => ({
          ...prevState,
          password: '',
          passwordConfirmation: '',
          currentPassword: '',
          touchedCurrentPassword: false,
          touchedPassword: false,
          touchedPasswordConfirmation: false,
        }));
      })
      .finally(() => setState((prevState) => ({ ...prevState, processing: false })))
      .catch((e) => {
        if (get(e, 'response.data.error_msg')) {
          toast(<CustomToast
            type="error"
            text={`${e.response.data.error_msg}`}
          />);
        }
      });
    return true;
  }

  return (
    <div className="flex flex-col h-full">
      <DashboardHeader color={color} title="Settings" />
      <div className="pb-3 overflow-y-auto scrollbar-none container-center">
        <div className="flex justify-center w-full my-4">
          <SubTitle subTitle="Change Password" style={{ color }} route="/settings/profile" />
        </div>
        <div className="bg-white px-5">
          <div className="tab mt-6 px-5">
            <div className="flex flex-col w-100">
              <span className="label-profile-form mb-2" style={{ color }}>Current Password</span>
              <div className="relative">
                <input
                  id="currentPassword"
                  value={currentPassword}
                  placeholder="Your Current Password"
                  onInput={handleChangeCurrentPassword}
                  className={touchedCurrentPassword && currentPassword.length < 1 ? 'input-signin-error mb-4' : 'input-profile mb-4'}
                  type={oldPasswordShown ? 'text' : 'password'}
                />
                <img
                  className="password-icon-new "
                  onClick={toggleCurrentPasswordVisibility}
                  src={oldPasswordShown ? eyeOpenIcon : eyeClosedIcon}
                  alt=""
                />
              </div>
            </div>
            <div className="flex flex-col w-100">
              <span className="label-profile-form mb-2" style={{ color }}>New Password</span>
              <div className="relative">
                <input
                  id="password"
                  value={password}
                  placeholder="Your New Password"
                  onInput={handleChangePassword}
                  className={(touchedPassword && checkNewPasswordLength()) || checkNewPasswordsDif() ? 'input-signin-error mb-4' : 'input-profile mb-4'}
                  type={passwordShown ? 'text' : 'password'}
                />
                <span className="current-error">
                  {touchedPassword && checkNewPasswordLength() ? 'Password must have between 6 and 128 characters.' : '' }
                </span>
                <img className="password-icon-new " onClick={togglePasswordVisibility} src={passwordShown ? eyeOpenIcon : eyeClosedIcon} alt="" />
              </div>
            </div>
            <div className="flex flex-col w-100">
              <span className="label-profile-form mb-2" style={{ color }}>New Password Confirmation</span>
              <div className="relative">
                <input
                  id="passwordConfirmation"
                  value={passwordConfirmation}
                  placeholder="Your New Password"
                  onInput={handleChangePasswordConfirmation}
                  className={(touchedPasswordConfirmation && checkNewPasswordConfirmationLength()) || checkNewPasswordsDif() ? 'input-signin-error mb-4' : 'input-profile mb-4'}
                  type={passwordShownConfirmation ? 'text' : 'password'}
                />
                <div className="current-error">
                  {touchedPasswordConfirmation && checkNewPasswordConfirmationLength() ? 'Password confirmation must have between 6 and 128 characters.' : '' }
                </div>
                <img className="password-icon-new " onClick={togglePasswordConfirmVisibility} src={passwordShownConfirmation ? eyeOpenIcon : eyeClosedIcon} alt="" />
              </div>
              <div className="current-error">
                {touchedPasswordConfirmation && checkNewPasswordsDif() ? 'Passwords must match.' : '' }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer-buttons">
          <button
            type="button"
            onClick={resetPassword}
            disabled={processing || !areInputsValid}
            className={!processing && areInputsValid
              ? `button-primary-lvl${level.id} flex justify-center md:w-1/2 sm: w-full`
              : 'button-grey flex justify-center md:w-1/2 sm: w-full'}
          >
            {processing ? 'Processing...' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withUserRetriever,
  withNotificationPopUp,
)(ChangePassword);
