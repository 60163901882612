import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BookingTimingOptionsModal from './BookingTimingOptionsModal';
import CategoryType from './CategoryType';
import { getBookingAddOns, getBookingServices } from '../../selectors/booking';
import { ReactComponent as IconHands } from '../../assets/icons/hands_icon.svg';
import { ReactComponent as IconFeet } from '../../assets/icons/feet_icon.svg';
import { ReactComponent as IconFace } from '../../assets/icons/face_icon.svg';
import {
  bookingOpenAddOnCategory,
  bookingSelectAddOnService,
  bookingToggleAddOnCategory,
  closeAllAddOnCategories,
  unselectAddOnServiceFromCategory,
} from '../../actions/bookings';
import CategoryServicesModal from './CategoryServicesModal';

import '../../assets/css/booking/booking_choose_add_ons.scss';
import { ADD_ON_FACE_BODY_CATEGORY, ADD_ON_FEET_CATEGORY, ADD_ON_HANDS_CATEGORY } from './consts';

/**
 * Component to render service add ons for InChair services
 */
const ChooseAddOnsWithCategories = () => {
  const addOns = getBookingAddOns();
  const baseServices = getBookingServices();
  const isAnyAddOnCategoryOpen = addOns.filter((addOnCategory) => addOnCategory.isOpen).length > 0;
  const currentlyOpenedAddOnCategory = useMemo(() => addOns.find((addOnCategory) => addOnCategory.isOpen) || { services: [] }, [addOns]);

  const dispatch = useDispatch();
  const CATEGORY_TYPES_ICONS = useMemo(() => ({
    [ADD_ON_HANDS_CATEGORY]: IconHands,
    [ADD_ON_FEET_CATEGORY]: IconFeet,
    [ADD_ON_FACE_BODY_CATEGORY]: IconFace,
  }));

  const activeCategories = useMemo(() => addOns.filter((category) => category.isActive).map((category) => category.categoryId), [addOns]);

  const toggleAddOnCategory = (isActive, categoryId) => {
    dispatch(bookingToggleAddOnCategory(categoryId));
    if (isActive) {
      dispatch(bookingOpenAddOnCategory(categoryId));
    } else {
      dispatch(unselectAddOnServiceFromCategory(categoryId));
    }
  };

  const onServicePickerClick = useCallback((addOnCategoryId) => {
    dispatch(bookingOpenAddOnCategory(addOnCategoryId));
  });

  const onSelectAddOnService = useCallback((service) => {
    dispatch(bookingSelectAddOnService(currentlyOpenedAddOnCategory, service));
    dispatch(closeAllAddOnCategories());
  });

  const findSelectedAddOn = (category) => {
    if (category.flattened) {
      return category.services.find((service) => service.selected);
    }

    const subCategoryWithService = category.subCategories.find((subCategory) => subCategory.services.find((s) => s.selected));
    if (subCategoryWithService) {
      return subCategoryWithService.services.find((service) => service.selected);
    }

    return { service_name: '' };
  };

  /**
   * This functions runs whenever the customer closes the service picker modal (regardless of the current category)
   *
   * If the popup closes but the customer didn't pick any service, that category is marked as unselected.
   */
  const closeAnyAddOnCategory = useCallback(() => {
    const selectedServiceOfCategoryBeingClosed = findSelectedAddOn(currentlyOpenedAddOnCategory);
    if (!selectedServiceOfCategoryBeingClosed || selectedServiceOfCategoryBeingClosed.service_name === '') {
      toggleAddOnCategory(false, currentlyOpenedAddOnCategory.categoryId);
    }
    dispatch(closeAllAddOnCategories());
  }, [currentlyOpenedAddOnCategory]);

  const baseServiceFor = (service) => {
    const baseServiceId = service.service_associated_id;
    let baseService = null;
    baseServices.forEach((category) => {
      category.subCategories.forEach((subCategory) => {
        const possibleBaseService = subCategory.services.find((sv) => sv.service_id === baseServiceId);
        if (possibleBaseService) {
          baseService = possibleBaseService;
        }
      });
    });

    if (baseService === null) {
      throw new Error(`Could not find base service with id ${baseServiceId}`);
    }

    return baseService.service_name;
  };

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full h-full">
      <BookingTimingOptionsModal isDateReadOnly showTimeSelector isTimeReadOnly />
      {isAnyAddOnCategoryOpen && currentlyOpenedAddOnCategory.flattened && (
        <CategoryServicesModal
          flattened
          autoHeight
          services={currentlyOpenedAddOnCategory.services}
          className="add_ons_services_popup"
          hasTopServices={false}
          onClose={closeAnyAddOnCategory}
          onSelectService={onSelectAddOnService}
        />
      )}
      {isAnyAddOnCategoryOpen && !currentlyOpenedAddOnCategory.flattened && (
        <CategoryServicesModal
          subCategories={currentlyOpenedAddOnCategory.subCategories}
          className="add_ons_services_popup"
          hasTopServices={false}
          onClose={closeAnyAddOnCategory}
          onSelectService={onSelectAddOnService}
        />
      )}
      <div className="separator" />
      <div className="w-full h-full px-5 pb-10 overflow-y-scroll scrollbar-none">
        <div className="services-title">
          <span>Select up to 3 Add-Ons</span>
          <span>(these will follow your main services)</span>
        </div>
        {addOns.map((category) => {
          const selectedAddOnService = findSelectedAddOn(category);
          return (
            <CategoryType
              category={category}
              key={category.categoryId}
              Icon={CATEGORY_TYPES_ICONS[category.name]}
              isActive={activeCategories.includes(category.categoryId)}
              onToggle={(isCategoryActive) => toggleAddOnCategory(isCategoryActive, category.categoryId)}
              onServicePickerClick={() => onServicePickerClick(category.categoryId)}
              selectedService={selectedAddOnService}
            >
              {selectedAddOnService && selectedAddOnService.service_name !== '' && (
                <div className="booking_choose_addons_addon_service">
                  This will start immediately after your
                  &nbsp;
                  <span className="highlighted">{baseServiceFor(selectedAddOnService)}</span>
                </div>
              )}
            </CategoryType>
          );
        })}
      </div>
    </div>
  );
};

export default ChooseAddOnsWithCategories;
