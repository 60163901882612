import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import axios from '../interceptor';
import { isValidEmail } from '../assets/js/constants';

import { CustomToast } from './Shared/withToast';

import 'react-datepicker/dist/react-datepicker.css';
import '../assets/css/signIn.scss';
import '../assets/css/shared.scss';

import logoTns from '../assets/img/logo_tns_black.svg';
import logoMarquee from '../assets/img/logo_marquee_black.svg';

import bgImage from '../assets/img/sign_in_background.png';
import { USER_SESSION_LOG_TYPES } from '../utils/api';
import LogInLogs from '../api/LogInLogs';

function Activation() {
  const { logSuccess, logError } = LogInLogs();

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthday: '',
    password: '',
    passwordConfirmation: '',
    error: false,
    tabSelected: 'login',
    logInLogId: '',
    redirectToBooking: false,
  });
  const {
    firstName,
    lastName,
    email,
    birthday,
    password,
    passwordConfirmation,
    error,
    logInLogId,
  } = state;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location.href);
    const urlLogInLogId = url.searchParams.get('logInLogId');
    const redirectToBooking = url.searchParams.get('booking');

    if (urlLogInLogId) {
      const payload = {
        value: 'Clicked Activation Email',
        logInLogId: urlLogInLogId,
        tag: USER_SESSION_LOG_TYPES.CLICKED_ACTIVATION_EMAIL,
      };

      logSuccess(payload)
        .then((res) => {
          dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: res.data.log_in_log_id });
          setState((prevState) => ({ ...prevState, logInLogId: res.data.log_in_log_id }));
        });
    }

    if (redirectToBooking) {
      setState((prevState) => ({ ...prevState, redirectToBooking: true }));
    }
  }, []);

  function checkForErrors() {
    if (firstName === '' || lastName === '' || email === '') {
      setState((prevState) => ({ ...prevState, error: true }));
      toast(<CustomToast type="error" text="First name, Last name and Email cant be blank." />);
      return true;
    }

    if (password.length < 6) {
      setState((prevState) => ({ ...prevState, error: true }));
      toast(<CustomToast type="error" text="Password must have at least 6 characters." />);
      return true;
    }

    if (password !== passwordConfirmation) {
      toast(<CustomToast type="error" text="Passwords must match." />);
      return true;
    }

    if (!isValidEmail(email)) {
      setState((prevState) => ({ ...prevState, error: true }));
      toast(<CustomToast type="error" text="Email is not valid." />);
      return true;
    }

    return false;
  }

  function register() {
    const url = new URL(window.location.href);
    const confirmationToken = url.searchParams.get('token');

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      birthday,
      password_confirmation: passwordConfirmation,
      confirmation_token: confirmationToken,
    };

    if (!checkForErrors()) {
      let logPayload = {
        value: `User ${firstName} ${lastName} with email ${email} activated`,
        logInLogId,
        tag: USER_SESSION_LOG_TYPES.USER_ACTIVATED,
      };

      axios.put('/api/v1/client/activate_client', { ...payload, create_sso_token: !!state.redirectToBooking })
        .then((response) => {
          logSuccess(logPayload)
            .then((res) => {
              dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: res.data.log_in_log_id });
            });
          if (state.redirectToBooking && response.data.sso_token) {
            history.push(`/booking?sso_token=${response.data.sso_token}`);
          } else {
            history.push('/clients/sign_in');
          }
          toast(<CustomToast type="success" text="Your account was activated." />);
        })
        .catch((err) => {
          if (get(err, 'response.data')) {
            logPayload = { ...logPayload, error: err.response.data.error_msg };
            logPayload.value = {
              first_name: firstName,
              last_name: lastName,
              email,
              confirmation_token: confirmationToken,
            };
            setState((prevState) => ({ ...prevState, error: true }));
            logError(logPayload)
              .then((res) => {
                dispatch({ type: 'LOG_IN_LOG_ID', logInLogId: res.data.log_in_log_id });
              });
            toast(<CustomToast type="error" text={err.response.data.errors[0].detail} />);
          }
        });
    }
  }
  function handleChange(e) {
    setState((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  }

  function handleDatePickerChange(e) {
    setState((prevState) => ({ ...prevState, birthday: e }));
  }

  return (
    <div className="activation-page">
      <div className="activation-bg-container">
        <img className="activation-bg" src={bgImage} alt="" />
      </div>
      <div className="my-10">
        <div className="logos">
          <div className="pr-5">
            <img className="logo-tns-sign-in" src={logoTns} alt="" />
          </div>
          <div>
            <img className="logo-marquee-sign-in" src={logoMarquee} alt="" />
          </div>
        </div>
      </div>
      <div className="px-5">
        <div className="flex flex-col overflow-y-scroll scrollbar-none tab-container mb-5">
          <span className="activation-text">Register</span>
          { state.redirectToBooking ? (
            <span className="tab-text">
              Please create your account below to proceed with your booking.
            </span>
          )
            : (
              <span className="tab-text">
                Welcome to our loyalty program.
                <br />
                &nbsp;
                To proceed with your activation type in your email and choose a password to login in the future.
              </span>
            )}
          <span className="text-primary-activation pb-3">First Name *</span>

          <input id="firstName" placeholder="Your First Name" value={firstName} onChange={handleChange} className={error ? 'input-signin-error mb-3' : 'input-signin mb-3'} type="text" />
          <span className="text-primary-activation pb-3">Last Name *</span>

          <input id="lastName" placeholder="Your Last Name" value={lastName} onChange={handleChange} className={error ? 'input-signin-error mb-2' : 'input-signin mb-2'} type="text" />
          <hr className="tab-separator-sign-in" />

          <span className="text-primary-activation pb-3">Email Address *</span>
          <input id="email" placeholder="Your Email Address" value={email} onChange={handleChange} className={error ? 'input-signin-error mb-3' : 'input-signin mb-3'} type="email" />

          <span className="text-primary-activation pb-3">Birthday (Optional)</span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              format="dd/MM/yyyy"
              value={birthday === '' ? null : birthday}
              placeholder="06/09/1994"
              views={['year', 'month', 'date']}
              InputProps={{ className: error ? 'input-signin-error mb-3 w-full' : 'input-signin mb-3 w-full' }}
              onChange={handleDatePickerChange}
              clearable
              animateYearScrolling
            />
          </MuiPickersUtilsProvider>

          <hr className="tab-separator-sign-in" />

          <span className="text-primary-activation pb-3">Password *</span>
          <input id="password" placeholder="*******" value={password} onChange={handleChange} className={error ? 'input-signin-error mb-3' : 'input-signin mb-3'} type="password" />

          <span className="text-primary-activation pb-3">Confirm Password *</span>
          <input id="passwordConfirmation" placeholder="*******" value={passwordConfirmation} onChange={handleChange} className={error ? 'input-signin-error mb-5' : 'input-signin mb-5'} type="password" />

          <div className="flex mt-5 justify-center">
            <button
              onClick={register}
              className="button-sign-in flex justify-center md:w-1/2 sm: w-full"
              type="button"
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Activation;
