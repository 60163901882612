import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as workerTimers from 'worker-timers';

function Timer({ callTimeout, flag }) {
  const [counter, setCounter] = useState(process.env.REACT_APP_TIMER_FOR_BOOKING);

  useEffect(() => {
    let timer = 0;

    if (counter > 0) {
      if (flag && (counter <= process.env.REACT_APP_TIME_WAIT_FOR_MILLENNIUM_BOOKING_CONFIRM)) {
        timer = workerTimers.setInterval(() => setCounter(counter), 1000);
      } else {
        timer = workerTimers.setInterval(() => setCounter(counter - 1), 1000);
      }
    } else {
      callTimeout();
    }

    return () => workerTimers.clearInterval(timer);
  }, [counter, flag]);

  return (
    <div>
      <span className="timer-text">
        Book in
        &nbsp;
        {new Date(counter * 1000).toISOString().substring(14, 19)}
      </span>
    </div>
  );
}

Timer.propTypes = {
  callTimeout: PropTypes.func.isRequired,
  flag: PropTypes.string,
};

Timer.defaultProps = {
  flag: undefined,
};

export default Timer;
