/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect } from 'react';
import PropTypes from 'prop-types';

import '../../../assets/css/dashboard/popUpModal.scss';

export const POPUP_INSIDE_EVENT_ID = 'shared-popup-id';

/**
 * Modal for any ReactElement.
 *
 * @param {function} handleClose handle how the popup will be closed in the parent component
 * @param {ReactNode} children the component that will be rendered
 * @returns ReactElement
 */
function PopUpComponentModal({
  handleClose,
  fullWidth,
  children,
  className,
  footerRenderFn,
}) {
  useEffect(() => {
    const isInsideEvent = (e) => {
      const containers = document.querySelectorAll(`[popup-id=${POPUP_INSIDE_EVENT_ID}]`);

      const isOutside = [...containers].every((container) => {
        const {
          top, left, right, bottom,
        } = container.getBoundingClientRect();

        return !(e.clientX > left && e.clientX < right && e.clientY > top && e.clientY < bottom);
      });

      if (isOutside) {
        handleClose();
      }
    };

    window.addEventListener('click', isInsideEvent);
    return () => window.removeEventListener('click', isInsideEvent);
  }, []);

  return (
    <div className="popup-modal">
      <div className={`popup-modal-container ${className}`} style={{ width: fullWidth ? '100%' : '85%' }}>
        {children}
      </div>
      {footerRenderFn && <div style={{ width: fullWidth ? '100%' : '85%' }} className="popup-modal-footer">{footerRenderFn()}</div>}
    </div>
  );
}

export default PopUpComponentModal;

PopUpComponentModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  footerRenderFn: PropTypes.func,
};

PopUpComponentModal.defaultProps = {
  fullWidth: false,
  className: '',
  footerRenderFn: null,
};
