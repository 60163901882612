import omit from 'lodash/omit';
import { getDateInAPIFormat } from '../../utils/date';
import { BOOKING_AVAILABLE_STARTING_TIMES, BOOKING_CLEAR_STARTING_TIMES, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = [];

const bookingAvailableStartingTimesReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_AVAILABLE_STARTING_TIMES:
      return removeUnallowedStartingTimes(action.availableStartingTimes);
    case BOOKING_CLEAR_STARTING_TIMES:
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingAvailableStartingTimesReducer;

function removeUnallowedStartingTimes(apiStartingTimes) {
  const currentDayApiFormat = getDateInAPIFormat(new Date());
  if (apiStartingTimes[currentDayApiFormat]) {
    // when processing the current day, removing starting times that are not possible to book.
    // For example, if today is 10th of December, 10am, and the API returns 9am as starting time for that day, that time shouldn't be selectable
    const minTimeForToday = getTime(new Date());
    const minHour = parseInt(minTimeForToday.split(':')[0], 10);
    const minMinute = parseInt(minTimeForToday.split(':')[1], 10);
    const newTimes = apiStartingTimes[currentDayApiFormat].filter((startingTime) => {
      const timesHour = parseInt(startingTime.split(':')[0], 10);
      const timesMinute = parseInt(startingTime.split(':')[1], 10);
      const yes = timesHour > minHour || (timesHour === minHour && timesMinute >= minMinute);
      return yes;
    });
    if (newTimes.length === 0) {
      return omit(apiStartingTimes, currentDayApiFormat);
    }

    return {
      ...apiStartingTimes,
      [currentDayApiFormat]: newTimes,
    };
  }

  return apiStartingTimes;
}

function getTime(date) {
  const hours = date.getHours() + parseInt(process.env.REACT_APP_MIN_HOURS_BEFORE_BOOKING, 10);
  const minutes = String(date.getMinutes());

  let textHours = hours;
  let textMinutes = '';

  if (minutes.length === 1) {
    if (parseInt(minutes[0], 10) > 0) {
      textMinutes = '10';
    } else {
      textMinutes = '00';
    }
  } else if (parseInt(minutes[1], 10) > 0) {
    if (parseInt(minutes[0], 10) === 5) {
      textHours = hours + 1;
      textMinutes = '00';
    } else {
      textMinutes = `${parseInt(minutes[0], 10) + 1}0`;
    }
  } else {
    textMinutes = `${parseInt(minutes[0], 10)}0`;
  }

  textHours = String(textHours).length > 1 ? textHours : `0${textHours}`;
  return `${textHours}:${textMinutes}`;
}
