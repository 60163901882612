/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { convertDate, convertTZ, timeConvert } from '../../assets/js/constants';
import Rating from '../Shared/Rating';

import '../../assets/css/booking/chooseServiceProviders.scss';
import '../../assets/css/booking/shared.scss';

import {
  bookingRemoveAllProviders,
  bookingServiceProviders as setBookingServiceProviders,
  bookingAddOns,
  bookingServiceProviders,
  bookingLockServiceProviderPreference,
  bookingMovable,
  bookingServiceProvidersSnapshot,
  bookingResetProvidersPreferences,
  resetMovable,
} from '../../actions/bookings';

import avatarIcon from '../../assets/icons/avatar.svg';
import { ReactComponent as ChairIcon } from '../../assets/icons/brand/in-chair.svg';
import { ReactComponent as RoomIcon } from '../../assets/icons/brand/in-room.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as DelayIcon } from '../../assets/icons/delay.svg';
import { CustomToast } from '../Shared/withToast';
import {
  bookingSoakOffFeet,
  bookingSoakOffHands,
  getBookingDate,
  getBookingLocation,
  getBookingLogId,
  getBookingServiceProviderPreferences,
  getBookingServiceProviders,
  getBookingServiceProvidersSnapshot,
  getBookingServices,
  getBookingServiceType,
  isSequentialBooking,
} from '../../selectors/booking';
import BookingTimingOptionsModal from './BookingTimingOptionsModal';
import BookingsAPI from '../../api/Booking';
import { getHoursAndMinutesFromDate } from '../../utils/date';
import {
  ignoreSoakOffServices,
  SOAK_OFF_CATEGORY,
  timeoutEndpointLogErrorPayload,
} from './consts';
import { isTimeoutError } from '../../utils/api';
import BookingDisabledNavigation from './BookingDisabledNavigation';

function ChooseServiceProvider({ color }) {
  const [state, setState] = useState({
    serviceProviders: [],
    showOverlay: false,
    openProviders: false,
    showLoader: true,
    location: getBookingLocation() || [],
    serviceType: getBookingServiceType() || [],
    servicesByProvider: [],
    serviceCategories: [],
  });

  const {
    serviceProviders,
    showOverlay,
    openProviders,
    showLoader,
    location,
    serviceType,
    servicesByProvider,
    serviceCategories,
    soakServices,
  } = state;

  const dispatch = useDispatch();
  const providersSnapshot = getBookingServiceProvidersSnapshot();
  const preferences = getBookingServiceProviderPreferences();
  const response = getBookingServiceProviders();
  const bookingDate = getBookingDate();
  const bookingLogId = getBookingLogId();
  const bookingServices = getBookingServices();
  const sequentialBooking = isSequentialBooking();
  const wantsSoakOffHands = bookingSoakOffHands();
  const wantsSoakOffFeet = bookingSoakOffFeet();

  // keep track of the service provider the customer wishes to change
  const currentServiceProviderChange = useRef(null);

  const useCustomerPreferences = (services) => {
    if (sequentialBooking) {
      services.forEach((service) => {
        const serviceProviderId = preferences[service.service_id];
        if (serviceProviderId !== 'any') {
          const providerDataIdx = service.service_providers.findIndex((provider) => provider.service_provider_id === serviceProviderId);
          if (providerDataIdx >= 0) {
            const providerData = service.service_providers[providerDataIdx];
            service.provider = providerData;
            const sameProviderIdx = service.service_providers.findIndex((sp) => sp.service_provider_id === serviceProviderId);
            service.service_providers[sameProviderIdx].available = false;
          }
        }
      });
    } else {
      Object.entries(preferences).forEach((entry) => {
        const [serviceId, serviceProviderId] = entry;
        if (serviceProviderId !== 'any') {
          // Choose the preferred service provider for each service
          const serviceDataIdx = services.findIndex((service) => service.service_id === parseInt(serviceId, 10));
          if (serviceDataIdx >= 0) {
            const serviceData = services[serviceDataIdx];
            const providerDataIdx = serviceData.service_providers.findIndex((provider) => provider.service_provider_id === serviceProviderId);
            if (providerDataIdx >= 0) {
              const providerData = serviceData.service_providers[providerDataIdx];
              services[serviceDataIdx].provider = providerData;
              const sameProviderIdx = services[serviceDataIdx].service_providers.findIndex((sp) => sp.service_provider_id === serviceProviderId);
              services[serviceDataIdx].service_providers[sameProviderIdx].available = false;

              const otherServices = services.filter((service) => service.service_id !== parseInt(serviceId, 10));
              otherServices.forEach((service) => {
                const sameProviderForDifferentServices = service.service_providers.filter((sp) => sp.service_provider_id === serviceProviderId);
                sameProviderForDifferentServices.forEach((sp) => {
                  sp.available = false;
                });
              });
            }
          }
        }
      });
    }
    return services;
  };

  useEffect(() => {
    if (!response.new) {
      response.new = true;
      dispatch(bookingRemoveAllProviders());

      response.forEach((service) => {
        service.service_providers.forEach((provider) => {
          if (provider.system_assigned) {
            service.identifier_provider_id = provider.service_provider_id;
            service.system_assigned_provider = provider;
          }

          // Because the provider might have already been set by the preferences step
          if (service.provider === null || service.provider === undefined) {
            service.provider = '';
          }
        });
      });

      const providersWithPreferences = useCustomerPreferences(response);

      dispatch(setBookingServiceProviders(sortServiceProviders(providersWithPreferences)));
    }

    const joinServicesByProvider = [];
    const aggregateCategories = [];
    const soakServicesProviders = [];

    const setCategories = (service) => {
      if (!aggregateCategories.includes(service.category_name)) {
        aggregateCategories.push(service.category_name);
      }
    };

    response.forEach((service) => {
      if (service.category_name === SOAK_OFF_CATEGORY) {
        const newSoakProvider = { ...service };
        newSoakProvider.services = [];
        newSoakProvider.services.push({
          ...service,
          services: [{
            service_display_name: service.service_display_name,
            category_name: service.category_name,
            service_id: service.service_id,
            system_assigned_service_length_in_minutes: service.system_assigned_service_length_in_minutes,
          }],
        });
        soakServicesProviders.push(newSoakProvider);
        setCategories(service);
      } else {
        const provider = joinServicesByProvider.filter((newService) => newService.identifier_provider_id === service.identifier_provider_id);
        if (provider.length > 0) {
          provider[0].services.push({
            service_display_name: service.service_display_name,
            service_id: service.service_id,
            category_name: service.category_name,
            system_assigned_service_length_in_minutes: service.system_assigned_service_length_in_minutes,
          });

          setCategories(service);
        } else {
          const newProvider = { ...service };
          newProvider.services = [];
          newProvider.services.push({
            service_display_name: newProvider.service_display_name,
            category_name: newProvider.category_name,
            service_id: newProvider.service_id,
            system_assigned_service_length_in_minutes: newProvider.system_assigned_service_length_in_minutes,
          });
          setCategories(newProvider);
          delete newProvider.service_display_name;
          delete newProvider.service_id;
          joinServicesByProvider.push(newProvider);
        }
      }
    });

    setState((prevState) => ({
      ...prevState,
      serviceProviders: response,
      showLoader: false,
      servicesByProvider: joinServicesByProvider,
      serviceCategories: aggregateCategories,
      soakServices: soakServicesProviders,
    }));
  }, [response, preferences]);

  /**
   * In-place sort service providers by availability and number of times they served a service.
   *
   * @param {Array<Object>} providers an array of hash objects, the `service_providers`
   *  key is required.
   * @returns sorted providers.
   */
  function sortServiceProviders(providers) {
    providers.forEach((service) => {
      service.service_providers
        .sort((x, y) => y.available - x.available)
        .sort((x, y) => {
          if (x.available > y.available) return -1;
          if (x.available < y.available) return 1;

          if (x.count_served_times < y.count_served_times) return 1;
          if (x.count_served_times > y.count_served_times) return -1;

          return 0;
        });
    });

    return providers;
  }

  /**
  * In-place sort service providers by having served
  * previously the currently selected services.
  *
  * @param {Array<Object>} providers an array of service providers.
  * @returns sorted providers.
  */
  function removeDuplicateProvidersByServedDate(toFilter) {
    // pull the providers that have previously served the services up,
    // this makes it so that the `providers.findIndex` grabs the provider
    // with the correct data first
    toFilter.sort((x, y) => (x.served_previously_on ? 1 : 0) < (y.served_previously_on ? 1 : 0));
    return toFilter.filter((provider, idx) => toFilter.findIndex((providerIdx) => providerIdx.service_provider_id === provider.service_provider_id) === idx);
  }

  function returnProviderTime(service, provider) {
    let time = '';
    if (provider.provider) {
      time = serviceProviders.find((serv) => serv.service_id === service.service_id).provider.service_length_in_minutes;
    } else {
      time = serviceProviders.find((serv) => serv.service_id === service.service_id).system_assigned_service_length_in_minutes;
    }

    return <span className="service-length">{time >= 60 ? timeConvert(time) : `${time} min`}</span>;
  }

  function resetProviderOnChange(serviceProvider) {
    const oldProvider = cloneDeep(serviceProvider.provider);

    serviceProvider.provider = '';
    serviceProvider.service_providers.find((sp) => sp.service_provider_id === oldProvider.service_provider_id).available = true;

    servicesByProvider.filter((cat) => cat.category_id !== serviceProvider.category_id).forEach((cat) => {
      // When we select a service provider we switch the system assigned provider on other services of the same
      // category. When we reset we must also swap the system assigned provider because at that moment the other
      // services will have the same system assigned.
      if (cat.system_assigned_provider.service_provider_id === serviceProvider.system_assigned_provider.service_provider_id) {
        cat.system_assigned_provider = { ...oldProvider };
      }

      cat.service_providers.forEach((sp) => {
        if (sp.service_provider_id === oldProvider.service_provider_id) {
          // Check if this provider was available in the first place. If it was and now its not, lets set it as available again
          const snapshotData = providersSnapshot.find((snapshotCat) => snapshotCat.category_id === cat.category_id);
          const providerSnapshot = snapshotData.service_providers.find((spSnapshot) => spSnapshot.service_provider_id === sp.service_provider_id);
          sp.available = providerSnapshot.available;
        }
      });
    });

    serviceProvider.services.forEach((service) => {
      dispatch(bookingLockServiceProviderPreference(service.service_id, 'any'));
      dispatch(bookingMovable({ serviceId: service.service_id, movable: true }));

      serviceProviders.forEach((sp) => {
        if (sp.service_id === service.service_id) {
          sp.provider = '';
        }
      });
    });

    setState((prevState) => ({
      ...prevState,
      serviceProviders,
      servicesByProvider,
    }));
  }

  function getProviderTime(identifierProviderId, providerId) {
    let time = 0;
    const filteredProviders = ignoreSoakOffServices(serviceProviders).filter((serviceProvider) => serviceProvider.identifier_provider_id === identifierProviderId);
    filteredProviders.forEach((serviceProvider) => {
      const provider = serviceProvider.service_providers.find((prov) => prov.service_provider_id === providerId);
      time += provider.service_length_in_minutes;
    });
    return time >= 60 ? timeConvert(time) : `${time} min`;
  }

  function selectServicesProvider(identifierProviderId, providerId) {
    // Provider that will be changed
    const selectedProvider = currentServiceProviderChange.current;
    // Provider Services
    const providerServices = selectedProvider.services;
    // Chosen Provider
    const chosenProvider = selectedProvider.service_providers.find((prov) => prov.service_provider_id === providerId);

    servicesByProvider.filter((provider) => provider.system_assigned_provider_id !== identifierProviderId).forEach((provider) => {
      provider.service_providers.forEach((serviceProvider) => {
        if (serviceProvider.service_provider_id === providerId) {
          serviceProvider.available = false;
        }
      });
    });

    selectedProvider.provider = chosenProvider;
    providerServices.forEach((service) => {
      selectProvider(service.service_id, providerId, servicesByProvider);
    });
  }

  function selectProvider(serviceId, providerId, servByProviders) {
    dispatch(bookingLockServiceProviderPreference(serviceId, providerId));
    const currProvider = serviceProviders.find((service) => service.service_id === serviceId).provider;
    const assignedProvider = serviceProviders.find((service) => service.service_id === serviceId).service_providers.find((provider) => provider.system_assigned);

    serviceProviders.forEach((service) => {
      if (service.service_id === serviceId) {
        const provider = service.service_providers.find((prov) => prov.service_provider_id === providerId);
        service.provider = provider;
      } else {
        service.service_providers.filter((provider) => provider.service_provider_id === providerId).forEach((foundProvider) => {
          foundProvider.available = false;
        });
      }

      if (currProvider) {
        service.service_providers.filter((provider) => provider.service_provider_id === currProvider.service_provider_id).forEach((foundProvider) => {
          if (foundProvider.service_provider_id !== providerId) {
            foundProvider.available = true;
          }
        });
      } else {
        service.service_providers.filter((provider) => provider.service_provider_id === assignedProvider.service_provider_id).forEach((foundProvider) => {
          if (foundProvider.service_provider_id !== providerId) {
            foundProvider.available = true;
          }
        });
      }
    });

    switchSystemAssignedProviderIfNeeded(providerId, servByProviders);
    const sortedServiceProviders = sortServiceProviders(serviceProviders);
    dispatch(setBookingServiceProviders(sortedServiceProviders));
    dispatch(bookingAddOns([]));
    dispatch(bookingMovable({ serviceId, movable: providerId === 'any' }));
    setState((prevState) => ({
      ...prevState, serviceProviders: sortedServiceProviders, showOverlay: false, openProviders: false, servicesByProvider: servByProviders,
    }));
  }

  /**
   * This function is here to solve the following problem:
   * Service 1 -> System Assigned Provider: SP 1 (available SPs: SP 1, SP 3)
   * Service 2 -> System Assigned Provider: SP 2 (available SPs: SP 2, SP 1)
   * User locks SP 1 for service 2.
   *
   * If nothing else changed, then SP 2 would be locked for both Service 2 (because the user actually locked it) and Service 1 (because it was system assigned), which is a conflict.
   * This function makes sure that, in this scenario, the new system assigned provider for Service 1 would be SP 3.
   *
   * Note that this function does not need to run for sequential bookings because there'd never be parallel services being run, so no conflict
   * is possible.
   *
   * @param {Integer} providerId the provider id
   * @param {Hash} servByProviders an hash with the services by categories
   */
  function switchSystemAssignedProviderIfNeeded(providerId, servByProviders) {
    if (sequentialBooking) {
      return;
    }

    // find service which has as system assigned the provider being assigned now (and not locked already by the user)
    const assignedForOtherService = serviceProviders.find((sv) => (sv.provider === '' || sv.provider === undefined) && sv.system_assigned_provider !== undefined && sv.system_assigned_provider.service_provider_id === providerId);

    // Soak off services always start before the base services, no need to switch the provider (can be the same)
    if (assignedForOtherService && assignedForOtherService.category_name !== 'Soak-Off (Gel Removal)') {
      // In this case we need to change the system assigned provider for that other service
      const otherAvailableProvidersForOtherService = assignedForOtherService.service_providers.filter((provider) => provider.available);
      if (otherAvailableProvidersForOtherService.length > 0) {
        // lets choose the first available one as system assigned for that one
        const newSystemAssignedProvider = otherAvailableProvidersForOtherService[0];
        assignedForOtherService.system_assigned_provider = {
          ...newSystemAssignedProvider,
          available: true,
          system_assigned: true,
        };

        // Also update the services by category hash (this hash is accessed whenever the user opens the modal with the SPs available to each service)
        const category = servByProviders.find((cat) => cat.category_id === assignedForOtherService.category_id);
        if (category) {
          category.service_providers.forEach((sp) => {
            if (sp.service_provider_id === newSystemAssignedProvider.service_provider_id) {
              sp.system_assigned = true;
            } else if (sp.system_assigned) {
              // Remove the old system assigned
              sp.system_assigned = false;
            }
          });
        }
      }
    }
  }

  function renderCategories(provider) {
    const newServices = serviceCategories.map((category, index) => {
      // get the total number of services the service provider will perform
      const servicesLength = provider.services.length;
      // get the number of services per category that the provider will perform
      const servicesPerCategoryLength = provider.services.filter((serv) => serv.category_name === category).length;
      // if the subtraction of lengths is not 0, then there are more services than categories.
      // if the index of categories we are checking is greater than 0, then we need to use 'service-card-header-top'.
      const disjointLength = Math.abs(servicesLength - servicesPerCategoryLength);
      return (
        <div key={category} className="flex flex-col">
          {
            servicesPerCategoryLength > 0
              ? (
                <div>
                  <div className={disjointLength > 0 && index > 0 ? 'service-card-header-top' : 'service-card-header'}>
                    <div className="w-full flex">
                      <div className="w-2/4 service-category-name">{category}</div>
                      <div className="w-2/4 flex items-center">
                        {
                          serviceType.name === 'In-chair services'
                            ? <ChairIcon className="resource-type-icon" alt="" style={{ fill: color }} />
                            : <RoomIcon className="resource-type-icon" alt="" style={{ fill: color }} />
                        }
                        <span className="service-resource-type">{serviceType.name}</span>
                      </div>
                    </div>
                  </div>
                  {
                    renderServices(provider, provider.services.filter((serv) => serv.category_name === category))
                  }
                </div>
              )
              : ''
          }
        </div>
      );
    });

    return newServices;
  }

  function renderServices(provider, services) {
    const newServices = services.map((service) => (
      <div key={service.service_id} className="flex flex-col">
        <div className="service-card-body">
          <div className="flex justify-between py-1 grey-divider">
            <div className="flex w-2/3 items-center">
              <span className="service-name mr-6" style={{ color }}>{service.service_display_name}</span>
            </div>
            <div className="flex">
              <ClockIcon className="service-length-icon" alt="" style={{ fill: color }} />
              {returnProviderTime(service, provider)}
            </div>
          </div>
        </div>
      </div>
    ));

    return newServices;
  }

  const renderProvider = (provider, startTime, isChangeable) => (
    <div key={provider.identifier_provider_id} className={`service-card ${!isChangeable && 'service-card-read-only'}`}>
      <div>
        {renderCategories(provider)}
        <div className="flex w-full justify-between items-center px-2 py-4">
          <div className="flex pr-1">
            <div className={provider.provider.service_provider_photo ? 'selected-provider-img' : 'selected-provider-img'} style={{ backgroundImage: `url(${provider.provider.service_provider_photo ? provider.provider.service_provider_photo : avatarIcon})` }} />
            <div className="flex flex-col self-center">
              <span className="selected-provider-name">
                {
                  provider.provider
                    ? provider.provider.service_provider_display_name
                    : (location.location_category_id === 1 ? 'Any Therapist' : 'Any Stylist')
                }
              </span>
              {
                provider.provider && provider.provider.service_provider_tenure !== null
                  ? (
                    <div className="flex flex-col">
                      <span className="selected-provider-tenure">{`With us since ${convertDate(new Date(provider.provider.service_provider_tenure), '', true)}`}</span>
                    </div>
                  ) : ''
              }
              <div className="flex justify-left items-center">
                <DelayIcon className="selected-provider-delay-icon" alt="" />
                <span className="selected-provider-start-time">{getHoursAndMinutesFromDate(convertTZ(new Date(startTime)))}</span>
                {provider.provider && provider.provider.delay_time_in_minutes > 0 && <span className="selected-provider-delay">{`${provider.provider.delay_time_in_minutes} min delay`}</span>}
              </div>
            </div>
          </div>
          {isChangeable && (
            <div className="flex items-center selected-provider-actions">
              <button
                type="button"
                onClick={() => {
                  if (provider.provider && provider.provider !== '') {
                    resetProviderOnChange(provider);
                  }

                  currentServiceProviderChange.current = { ...cloneDeep(provider) };

                  setState((prevState) => ({
                    ...prevState,
                    openProviders: true,
                    showOverlay: true,
                  }));
                }}
                className="change-button-service-provider"
                style={{ backgroundColor: color }}
              >
                Change
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function renderJoinedServicesByProvider() {
    const servicesToRender = ignoreSoakOffServices(servicesByProvider);
    const servicesOrdered = servicesToRender.sort((s1, s2) => {
      const providerS1StartTime = new Date((s1.provider?.provider || s1.system_assigned_provider).start_time);
      const providerS2StartTime = new Date((s2.provider?.provider || s2.system_assigned_provider).start_time);

      return providerS1StartTime > providerS2StartTime;
    });

    const baseServicesBoxes = servicesOrdered.map((provider) => {
      const startTime = provider.provider ? provider.provider.start_time : provider.system_assigned_provider.start_time;
      return renderProvider(provider, startTime, true);
    });

    if (servicesByProvider.length === 0) {
      return baseServicesBoxes;
    }

    // Check if the customer has requested a soak off hands
    // If so, get the start time from the response and render the box
    const soakServicesBoxes = [];

    if (wantsSoakOffHands) {
      const provider = soakServices.find((sv) => sv.service_display_name === 'Soak-Off (Hands)');
      const startTime = provider.provider ? provider.provider.start_time : provider.system_assigned_provider.start_time;
      soakServicesBoxes.push(renderProvider(provider, startTime, false));
    }

    if (wantsSoakOffFeet) {
      const provider = soakServices.find((sv) => sv.service_display_name === 'Soak-Off (Feet)');
      const startTime = provider.provider ? provider.provider.start_time : provider.system_assigned_provider.start_time;
      soakServicesBoxes.push(renderProvider(provider, startTime, false));
    }

    return [
      ...baseServicesBoxes,
      soakServicesBoxes,
    ];
  }

  function renderProvidersByServices() {
    const selectedProvider = currentServiceProviderChange.current;

    let sortedServiceProviders = null;
    if (sequentialBooking) {
      // We need service provider data that belongs to different service categories
      // this leads to duplicated data
      const allServiceProviders = serviceProviders.flatMap((provider) => provider.service_providers);
      // remove duplicates and sort, sorting by served date guarantees that the service
      // providers with the relevant data will appear first and be filtered correctly
      sortedServiceProviders = removeDuplicateProvidersByServedDate(allServiceProviders);
      sortServiceProviders([{ service_providers: sortedServiceProviders }]);
    } else {
      sortServiceProviders([selectedProvider]);
      sortedServiceProviders = selectedProvider.service_providers;
    }

    return sortedServiceProviders.map((provider) => (
      <div key={provider.service_provider_id} className="relative">
        {!provider.available && <div className="locked" />}
        <div className="provider px-3">
          <div className="flex w-full justify-between py-3">
            <div className="flex items-center">
              <div className={provider.service_provider_photo ? 'provider-img' : 'provider-img provider-img-filter'} style={{ backgroundImage: `url(${provider.service_provider_photo ? provider.service_provider_photo : avatarIcon})` }} />
              <div className="flex flex-col self-center">
                <span className="provider-name">{provider.service_provider_display_name}</span>
                {(provider.available && provider.service_provider_tenure !== null) && (
                  <span className="selected-light-grey">{`With us since ${convertDate(new Date(provider.service_provider_tenure), '', true)}`}</span>
                )}
                {(provider.available && provider.served_previously_on && !sequentialBooking) && (
                  <div>
                    <span className="selected-light-grey">{`Served you on ${convertDate(new Date(provider.served_previously_on), '', true)}`}</span>
                    <br />
                    <div className="flex justify-between items-center">
                      {(provider.service_rating !== 0)
                        ? (
                          <span className="selected-light-grey">You rated</span>
                        ) : (
                          <span className="selected-light-grey">Not rated</span>
                        )}
                      <div>
                        <Rating ratingStore={{ rating: provider.service_rating }} color={color} disabled disabledColor={color} marginClass="my-2" />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex">
                  {provider.available && (
                    <div className="flex">
                      <ClockIcon className="provider-service-length-icon" alt="" style={{ fill: color }} />
                      <span className="provider-service-length">{getProviderTime(selectedProvider.identifier_provider_id, provider.service_provider_id)}</span>
                    </div>
                  )}
                  {!provider.available && <span className="provider-service-length">Unavailable</span>}
                  {
                    provider && provider.delay_time_in_minutes > 0
                      ? (
                        <div className="flex">
                          <DelayIcon className="provider-service-delay-icon" alt="" />
                          <span className="provider-delay">{`${provider.delay_time_in_minutes} min delay`}</span>
                        </div>
                      ) : ''
                  }
                </div>
              </div>
            </div>
            {provider.available && (
              <button
                type="button"
                onClick={() => selectServicesProvider(selectedProvider.identifier_provider_id, provider.service_provider_id)}
                className={provider.available ? 'change-button' : 'change-button-disable'}
                style={{ backgroundColor: color }}
              >
                Select
              </button>
            )}
          </div>
        </div>
      </div>
    ));
  }

  const onChangeStartingTime = (newTime) => {
    setState((prevState) => ({ ...prevState, showLoader: true }));

    let workingServices = bookingServices;
    if (serviceType.name === 'In-chair services') {
      workingServices = bookingServices.flatMap((category) => category.subCategories);
    }

    const servs = workingServices.map((c) => ({ services: c.services.filter((s) => s.selected) })).filter((c) => c.services.length > 0);
    const servicesForApi = [].concat(...servs.map((category) => category.services.map((service) => service.service_id)));

    const { getServiceProvidersAvailability, saveLogError } = BookingsAPI();
    getServiceProvidersAvailability({
      bookingDate,
      bookingTime: newTime,
      bookingLocationId: location.id,
      services: servicesForApi,
      bookingLogId,
      soakOffHands: wantsSoakOffHands,
      soakOffFeet: wantsSoakOffFeet,
      serviceTypeId: serviceType.id,
      bookingServiceProviderPreferences: preferences,
    })
      .then((resp) => {
        // because the current selected provider might not be available in the new time
        dispatch(bookingResetProvidersPreferences());
        dispatch(resetMovable());

        dispatch(bookingServiceProviders(resp.data));
        dispatch(bookingServiceProvidersSnapshot(resp.data));
      })
      .catch((error) => {
        if (isTimeoutError(error)) {
          saveLogError(timeoutEndpointLogErrorPayload({ bookingLogId, error }));
        }
        toast(<CustomToast type="error" text="No providers are available for the services chosen at this date." />);
        setState((prevState) => ({ ...prevState, buttonState: true, buttonText: 'Next' }));
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, showLoader: false }));
      });
  };

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full">
      <BookingTimingOptionsModal isDateReadOnly showTimeSelector onChangeTime={onChangeStartingTime} />
      {showOverlay && <BookingDisabledNavigation />}
      {
        openProviders
          ? (
            <div className="service-providers-container">
              <div className="overflow-y-auto scrollbar-none">
                <div className="services-provider-header w-full">
                  <span className="service-providers-title p-3">{location.location_category_id === 1 ? 'Select a Therapist' : 'Select a Stylist'}</span>
                  <div role="button" tabIndex={0} onClick={() => setState((prevState) => ({ ...prevState, openProviders: false, showOverlay: false }))}>
                    <CloseIcon className="close-providers-icon pt-2 mr-1" alt="" style={{ fill: color }} />
                  </div>
                </div>
                <div className="service-providers-list">
                  {renderProvidersByServices()}
                </div>
              </div>
            </div>
          ) : ''
      }
      <div className="separator" />
      <div className="flex flex-col w-full items-center services-cards px-5">
        {
          showLoader
            ? (
              <div className="loader-container">
                <div className="loader" />
              </div>
            ) : ''
        }
        <div className="w-full py-6">
          <div className="flex flex-col w-full items-center">
            {renderJoinedServicesByProvider()}
          </div>
        </div>
      </div>
    </div>
  );
}

ChooseServiceProvider.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ChooseServiceProvider;
