export const BOOKING_LOG_ID = 'BOOKING_LOG_ID';
export const BOOKING_LOCATION = 'BOOKING_LOCATION';
export const BOOKING_LOCATIONS = 'BOOKING_LOCATIONS';
export const BOOKING_DAY = 'BOOKING_DAY';
export const BOOKING_TIME = 'BOOKING_TIME';
export const BOOKING_CLEAR_BOOKING_TIME = 'BOOKING_CLEAR_BOOKING_TIME';
export const BOOKING_AVAILABLE_STARTING_TIMES = 'BOOKING_AVAILABLE_STARTING_TIMES';
export const BOOKING_CLEAR_STARTING_TIMES = 'BOOKING_CLEAR_STARTING_TIMES';
export const BOOKING_SERVICE_TYPE = 'BOOKING_SERVICE_TYPE';
export const BOOKING_CALENDAR_HIGHLIGHTED = 'BOOKING_CALENDAR_HIGHLIGHTED';
export const BOOKING_SERVICES = 'BOOKING_SERVICES';
export const BOOKING_TOGGLE_SERVICE_CATEGORY_TYPE = 'BOOKING_TOGGLE_SERVICE_CATEGORY_TYPE';
export const BOOKING_UNSELECT_SERVICE_FROM_CATEGORY = 'BOOKING_UNSELECT_SERVICE_FROM_CATEGORY';
export const BOOKING_CURRENTLY_OPENED_SERVICE_CATEGORY = 'BOOKING_CURRENTLY_OPENED_SERVICE_CATEGORY';
export const BOOKING_ADDONS = 'BOOKING_ADDONS';
export const BOOKING_CURRENTLY_OPENED_ADD_ON_CATEGORY = 'BOOKING_CURRENTLY_OPENED_ADD_ON_CATEGORY';
export const BOOKING_TOGGLE_ADD_ON_CATEGORY = 'BOOKING_TOGGLE_ADD_ON_CATEGORY';
export const BOOKING_SELECT_ADD_ON_SERVICE = 'BOOKING_SELECT_ADD_ON_SERVICE';
export const BOOKING_UNSELECT_ADD_ON_FROM_CATEGORY = 'BOOKING_UNSELECT_ADD_ON_FROM_CATEGORY';
export const BOOKING_RESTRICTIONS = 'BOOKING_RESTRICTIONS';
export const BOOKING_REMOVE_ALL_PROVIDERS = 'BOOKING_REMOVE_ALL_PROVIDERS';
export const BOOKING_SERVICE_PROVIDERS = 'BOOKING_SERVICE_PROVIDERS';
export const BOOKING_SERVICE_PROVIDERS_SNAPSHOT = 'BOOKING_SERVICE_PROVIDERS_SNAPSHOT';
export const BOOKING_LOCK_SERVICE_PROVIDER_ID = 'BOOKING_LOCK_SERVICE_PROVIDER_ID';
export const BOOKING_RESET_PROVIDERS_PREFERENCES = 'BOOKING_RESET_PROVIDERS_PREFERENCES';
export const BOOKING_SERVICE_PROVIDER_FOR_SERVICES = 'BOOKING_SERVICE_PROVIDER_FOR_SERVICES';
export const BOOKING_MOVABLE = 'BOOKING_MOVABLE';
export const BOOKING_MOVABLE_DEL = 'BOOKING_MOVABLE_DEL';
export const RESET_MOVABLE = 'RESET_MOVABLE';
export const BOOKING_APPOINTMENT = 'BOOKING_APPOINTMENT';
export const BOOKING_APPOINTMENT_END_TIME = 'BOOKING_APPOINTMENT_END_TIME';
export const BOOKING_SPECIAL_REQUEST = 'BOOKING_SPECIAL_REQUEST';
export const BOOKING_CONFIRMED = 'BOOKING_CONFIRMED';
export const CLEAN_BOOKING = 'CLEAN_BOOKING';
export const TOGGLE_BOOKING_TIMING_OPTIONS = 'TOGGLE_BOOKING_TIMING_OPTIONS';
export const CLOSE_ALL_SERVICE_CATEGORIES = 'CLOSE_ALL_SERVICE_CATEGORIES';
export const CLOSE_ALL_ADD_ON_CATEGORIES = 'CLOSE_ALL_ADD_ON_CATEGORIES';
export const SELECT_SERVICE = 'BOOKING_SELECT_SERVICE';
export const BOOKING_SOAK_OFF_HANDS = 'BOOKING_SOAK_OFF_HANDS';
export const BOOKING_SOAK_OFF_FEET = 'BOOKING_SOAK_OFF_FEET';

export const bookingLogId = (bookingLog) => ({
  type: BOOKING_LOG_ID,
  bookingLogId: bookingLog,
});

export const bookingLocation = (location) => ({
  type: BOOKING_LOCATION,
  location,
});

export const bookingLocations = (locations) => ({
  type: BOOKING_LOCATIONS,
  locations,
});

export const bookingDay = (day) => ({
  type: BOOKING_DAY,
  day,
});

export const bookingTime = (time) => ({
  type: BOOKING_TIME,
  time,
});

export const bookingServiceType = (serviceType) => ({
  type: BOOKING_SERVICE_TYPE,
  serviceType,
});

export const bookingCalendarHighlighted = (calendarHighlighted) => ({
  type: BOOKING_CALENDAR_HIGHLIGHTED,
  calendarHighlighted,
});

export const bookingServices = (services) => ({
  type: BOOKING_SERVICES,
  services,
});

export const bookingSelectService = (categoryType, service) => ({
  type: SELECT_SERVICE,
  service,
  categoryType,
});

export const bookingToggleServiceCategory = (categoryTypeId) => ({
  type: BOOKING_TOGGLE_SERVICE_CATEGORY_TYPE,
  categoryTypeId,
});

export const bookingOpenServiceCategory = (categoryTypeId) => ({
  type: BOOKING_CURRENTLY_OPENED_SERVICE_CATEGORY,
  categoryTypeId,
});

export const unselectServiceFromCategory = (categoryTypeId) => ({
  type: BOOKING_UNSELECT_SERVICE_FROM_CATEGORY,
  categoryTypeId,
});

export const bookingAddOns = (addOns) => ({
  type: BOOKING_ADDONS,
  addOns,
});

export const bookingOpenAddOnCategory = (addOnCategoryId) => ({
  type: BOOKING_CURRENTLY_OPENED_ADD_ON_CATEGORY,
  addOnCategoryId,
});

export const bookingToggleAddOnCategory = (addOnCategoryId) => ({
  type: BOOKING_TOGGLE_ADD_ON_CATEGORY,
  addOnCategoryId,
});

export const closeAllAddOnCategories = () => ({
  type: CLOSE_ALL_ADD_ON_CATEGORIES,
});

export const bookingSelectAddOnService = (category, service) => ({
  type: BOOKING_SELECT_ADD_ON_SERVICE,
  service,
  category,
});

export const unselectAddOnServiceFromCategory = (categoryId) => ({
  type: BOOKING_UNSELECT_ADD_ON_FROM_CATEGORY,
  categoryId,
});

export const bookingRestrictions = (restrictions) => ({
  type: BOOKING_RESTRICTIONS,
  restrictions,
});

export const bookingRemoveAllProviders = () => ({
  type: BOOKING_REMOVE_ALL_PROVIDERS,
});

export const bookingServiceProviders = (serviceProviders) => ({
  type: BOOKING_SERVICE_PROVIDERS,
  serviceProviders,
});

export const bookingServiceProvidersSnapshot = (serviceProviders) => ({
  type: BOOKING_SERVICE_PROVIDERS_SNAPSHOT,
  serviceProviders,
});

export const bookingServiceProviderForServices = (serviceProviders) => ({
  type: BOOKING_SERVICE_PROVIDER_FOR_SERVICES,
  serviceProviders,
});

export const bookingMovable = ({ serviceId, movable }) => ({
  type: BOOKING_MOVABLE,
  serviceId,
  movable,
});

export const bookingMovableDel = ({ serviceId }) => ({
  type: BOOKING_MOVABLE_DEL,
  serviceId,
});

export const resetMovable = () => ({ type: RESET_MOVABLE });

export const bookingAppointment = (appointmentId) => ({
  type: BOOKING_APPOINTMENT,
  appointmentId,
});

export const bookingSpecialRequest = (request) => ({
  type: BOOKING_SPECIAL_REQUEST,
  request,
});

export const bookingConfirmed = (confirmed) => ({
  type: BOOKING_CONFIRMED,
  confirmed,
});

export const bookingAppointmentEndTime = (appointmentEndTime) => ({
  type: BOOKING_APPOINTMENT_END_TIME,
  appointmentEndTime,
});

export const toggleBookingTimingOptionsModal = () => ({
  type: TOGGLE_BOOKING_TIMING_OPTIONS,
});

export const closeAllServicesCategories = () => ({
  type: CLOSE_ALL_SERVICE_CATEGORIES,
});

export const bookingAvailableStartingTimes = (availableStartingTimes) => ({
  type: BOOKING_AVAILABLE_STARTING_TIMES,
  availableStartingTimes,
});

export const bookingLockServiceProviderPreference = (serviceId, serviceProviderId) => ({
  type: BOOKING_LOCK_SERVICE_PROVIDER_ID,
  serviceId,
  serviceProviderId,
});

export const bookingResetProvidersPreferences = () => ({
  type: BOOKING_RESET_PROVIDERS_PREFERENCES,
});

export const bookingSoakOffHands = (intent) => ({
  type: BOOKING_SOAK_OFF_HANDS,
  intent,
});

export const bookingSoakOffFeet = (intent) => ({
  type: BOOKING_SOAK_OFF_FEET,
  intent,
});

export const cleanBooking = () => ({ type: CLEAN_BOOKING });
export const clearBookingTime = () => ({ type: BOOKING_CLEAR_BOOKING_TIME });
export const clearBookingStartingTimes = () => ({ type: BOOKING_CLEAR_STARTING_TIMES });
