import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import BookingTimingOptionsModal from './BookingTimingOptionsModal';
import '../../assets/css/booking/lockServiceProviders.scss';
import {
  getBookingLocation,
  getBookingSelectedServices,
  getBookingServiceProviderPreferences,
  getServiceProviderForServices,
  isSequentialBooking,
} from '../../selectors/booking';
import Select from '../Shared/Select';
import avatarIcon from '../../assets/icons/avatar.svg';
import { bookingLockServiceProviderPreference, bookingMovable, clearBookingTime } from '../../actions/bookings';
import { convertDate } from '../../assets/js/constants';
import Rating from '../Shared/Rating';

const helpText = (locationCategoryId) => `Select your preferred ${locationCategoryId}, if any`;

const LockServiceProviders = ({ color }) => {
  const dispatch = useDispatch();
  const selectedServices = getBookingSelectedServices();
  const locationCategoryId = getBookingLocation().location_category_id;
  const serviceProvidersPerService = getServiceProviderForServices();
  const lockedPreferredServiceProviders = getBookingServiceProviderPreferences();
  const sequentialBooking = isSequentialBooking();
  const defaultOption = useMemo(() => ({
    key: 'any',
    value: "I'm flexible",
    photo: avatarIcon,
  }));

  const checkServiceProviderIsAvailableToLock = (serviceProviderId, checkingServiceId) => {
    const otherServices = selectedServices.filter((service) => service.service_id !== checkingServiceId);
    const otherServiceLockedForThisProvider = otherServices.find((service) => {
      const locked = lockedPreferredServiceProviders[service.service_id];
      return locked && locked === serviceProviderId;
    });
    return otherServiceLockedForThisProvider === undefined || otherServiceLockedForThisProvider === null;
  };

  const getAvailableProvidersForService = (serviceId) => {
    const providers = getProvidersForService(serviceId);
    const available = providers.map((provider) => ({ key: provider.id, value: provider.display_name }));
    const availableExceptAlreadyLocked = available.filter(({ key }) => checkServiceProviderIsAvailableToLock(key, serviceId))
      .sort((x, y) => {
        if (serviceId === 'all') {
          return 0;
        }

        const serviceProviders = serviceProvidersPerService[serviceId];
        const xServedTimes = serviceProviders.find((sp) => sp.id === x.key).count_served_times;
        const yServedTimes = serviceProviders.find((sp) => sp.id === y.key).count_served_times;
        if (xServedTimes < yServedTimes) return 1;
        if (xServedTimes > yServedTimes) return -1;
        return 0;
      });
    return [
      defaultOption,
      ...availableExceptAlreadyLocked,
    ];
  };
  const getServiceProviderPhoto = (serviceId, providerId) => {
    const data = getServiceProviderData(serviceId, providerId);
    return data.photo || avatarIcon;
  };

  const getServiceProviderTenure = (serviceId, providerId) => {
    const data = getServiceProviderData(serviceId, providerId);
    return data.tenure || null;
  };

  const getServiceProviderLastSaleDate = (serviceId, providerId) => {
    const data = getServiceProviderData(serviceId, providerId);
    return data.served_previously_on || null;
  };

  const getServiceProviderServiceRating = (serviceId, providerId) => {
    const data = getServiceProviderData(serviceId, providerId);
    return data.service_rating || 0;
  };

  const getProvidersForService = React.useCallback((serviceId) => {
    let providers = [];
    if (serviceId === 'all') {
      providers = selectedServices.map((service) => serviceProvidersPerService[service.service_id]).flat();
    } else {
      providers = (serviceProvidersPerService[serviceId] || []).flat();
    }
    // Because the same provider can provide different services
    const uniqueProviders = removeDuplicateProvidersByServedDate(providers);
    return uniqueProviders;
  }, [serviceProvidersPerService]);

  /**
   * In-place sort service providers by having served
   * previously the currently selected services.
   *
   * @param {Array<Object>} providers an array of service providers.
   * @returns sorted providers.
   */
  function removeDuplicateProvidersByServedDate(toFilter) {
    // pull the providers that have previously served the services up,
    // this makes it so that the `toFilter.findIndex` grabs the provider
    // with the correct data first
    toFilter.sort((x, y) => (x.served_previously_on ? 1 : 0) < (y.served_previously_on ? 1 : 0));
    return toFilter.filter((provider, idx) => toFilter.findIndex((providerIdx) => providerIdx.id === provider.id) === idx);
  }

  const getServiceProviderData = (serviceId, providerId) => {
    const providers = getProvidersForService(serviceId);
    const providerIdx = providers.findIndex((provider) => provider.id === providerId);
    return providers[providerIdx] || defaultOption;
  };

  const findPreferredServiceProviderFor = (serviceId) => {
    if (serviceId === 'all') {
      return lockedPreferredServiceProviders[selectedServices[0].service_id];
    }

    return lockedPreferredServiceProviders[serviceId];
  };

  const onLockServiceProvider = (serviceId, serviceProviderId) => {
    if (serviceId === 'all') {
      selectedServices.forEach((service) => {
        dispatch(bookingLockServiceProviderPreference(service.service_id, serviceProviderId));
        dispatch(bookingMovable({ serviceId: service.service_id, movable: serviceProviderId === defaultOption.key }));
      });
    } else {
      dispatch(bookingLockServiceProviderPreference(serviceId, serviceProviderId));
      dispatch(bookingMovable({ serviceId, movable: serviceProviderId === defaultOption.key }));
    }
    // Reset selected start time because the customer changed the preferred service providers
    // and the old selected start time may not be possible anymore
    dispatch(clearBookingTime());
  };

  const renderSelectedTherapist = (therapistId, serviceId) => {
    if (therapistId === defaultOption.key) {
      return defaultOption.value;
    }

    const data = getServiceProviderData(serviceId, therapistId);
    return data.display_name;
  };

  const filterFn = ({ item, search }) => {
    const { value } = item;
    return value.toLowerCase().includes(search.toLowerCase());
  };

  const serviceProviderTitle = locationCategoryId === 1 ? 'therapist(s)' : 'stylist';

  const renderServiceProviderSelect = ({ serviceId, serviceName }) => (
    <div key={serviceId} className="lockServiceProviders__services__service" style={{ color }}>
      <div className="lockServiceProviders__services__service--name">{serviceName}</div>
      <Select
        startOpened={false}
        withSearch
        noSearchResultsText={`No ${serviceProviderTitle}s found`}
        searchPrompt={`Search a ${serviceProviderTitle}`}
        autoFocusSearch
        filterFn={filterFn}
        value={findPreferredServiceProviderFor(serviceId)}
        selectedValueRender={(therapistId) => renderSelectedTherapist(therapistId, serviceId)}
        classNames={{
          container: 'lockServiceProviders__services__service__provider--select',
        }}
        onChange={({ item }) => onLockServiceProvider(serviceId, item.key)}
        scrollToSelected={false}
        placeholder={`${serviceProviderTitle} preference`}
        prompt={`${serviceProviderTitle} preference`}
        collection={getAvailableProvidersForService(serviceId)}
        renderOption={({ item }) => (
          <div className="lockServiceProviders__services__service__provider">
            <div
              className="lockServiceProviders__services__service__provider--icon"
              style={{
                backgroundImage: `url(${getServiceProviderPhoto(serviceId, item.key)})`,
              }}
            />
            <div className="lockServiceProviders__services__service__provider--desc">
              <div>{item.value}</div>
              {(getServiceProviderTenure(serviceId, item.key) !== null)
                && (
                  <div>
                    <span className="lockServiceProviders__services__service__provider--tenure-title">With us since </span>
                    <span className="lockServiceProviders__services__service__provider--tenure-date">{convertDate(new Date(getServiceProviderTenure(serviceId, item.key)), '', true)}</span>
                  </div>
                )}
              {((getServiceProviderLastSaleDate(serviceId, item.key) && !sequentialBooking) && (
                <>
                  <span className="lockServiceProviders__services__service__provider--served-on">{`Served you on ${convertDate(new Date(getServiceProviderLastSaleDate(serviceId, item.key)), '', true)}`}</span>
                  <div className="flex justify-between items-center">
                    {
                      (getServiceProviderServiceRating(serviceId, item.key) !== 0)
                        ? (
                          <span className="lockServiceProviders__services__service__provider--rating">You rated</span>
                        )
                        : (
                          <span className="lockServiceProviders__services__service__provider--rating">Not rated</span>
                        )
                    }
                    <div>
                      <Rating ratingStore={{ rating: getServiceProviderServiceRating(serviceId, item.key) }} color={color} disabled disabledColor={color} marginClass="my-2" />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        )}
      />
    </div>
  );

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full lockServiceProviders h-full overflow-scroll">
      <BookingTimingOptionsModal />
      <div className="separator" />
      <div className="lockServiceProviders__help">
        {helpText(serviceProviderTitle)}
      </div>
      <div className="lockServiceProviders__services">
        {sequentialBooking
          ? renderServiceProviderSelect({ serviceId: selectedServices.length > 1 ? 'all' : selectedServices[0].service_id, serviceName: selectedServices.length > 1 ? 'All services' : selectedServices[0].service_name })
          : selectedServices.map((service) => renderServiceProviderSelect({ serviceId: service.service_id, serviceName: service.service_name }))}
      </div>
    </div>
  );
};

LockServiceProviders.propTypes = {
  color: PropTypes.string.isRequired,
};

export default LockServiceProviders;
