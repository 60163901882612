import { ApiGet } from './Api';

/**
 * Package Items API calls
 */
export default () => ({
  /**
   *
   * @param {Object} params the params for the call.
   * @returns a list with all the current package items (includes active, expired and redeemed)
   */
  list: (params) => ApiGet({ endpoint: 'package_items', params }),
});
