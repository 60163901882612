/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import axios from '../../interceptor';

import { ReactComponent as BackIcon } from '../../assets/icons/arrow_left.svg';
import { ReactComponent as InviteFriendIcon } from '../../assets/icons/invite.svg';
import { ReactComponent as TnsLogo } from '../../assets/img/logo_tns.svg';
import iMessage from '../../assets/icons/social/imessage.svg';
import whatsapp from '../../assets/icons/social/whatsapp.svg';

import '../../assets/css/shared.scss';
import '../../assets/css/referrals/inviteFriend.scss';

import { CustomToast } from '../Shared/withToast';
import withUserRetriever from '../Shared/withUserRetriever';

import { getMobileOS, IOS_OS } from '../../utils/browser';

const InviteShare = ({ shareLogo, shareType, handleReferral }) => (
  <div
    role="button"
    tabIndex={0}
    className="invite-friend-share-choices-item"
    onClick={() => handleReferral(shareType)}
  >
    <img src={shareLogo} className="invite-friend-share-choices-item-icon" alt={shareType} />
    <span className="invite-friend-share-choices-item-text my-2">{shareType}</span>
  </div>
);

function InviteFriend() {
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const [state, setState] = useState({
    invitation: '',
    share: new URLSearchParams(window.location.search).get('share') === 'true',
    // WHATSAPP: "whatsapp://send?text=Hello%20do%20you%20want%20spa?"
    // SMS: "sms:&addresses=null&body=yourMessage"
    urls: {
      message: getMobileOS() === IOS_OS ? 'sms:&addresses=null&body=' : 'sms:?&body=',
      whatsapp: 'whatsapp://send?text=',
    },
  });

  const { invitation, share, urls } = state;

  const history = useHistory();

  const componentStyle = {
    color: level.level_color,
    colorDark: `var(--color-level${level.id}-dark)`,
  };

  useEffect(() => {
    axios.post('/api/v1/referrals')
      .then((res) => {
        setState((prevState) => ({ ...prevState, invitation: res.data }));
      })
      .catch(() => {
        toast(<CustomToast type="error" text="Unable to make invitation!" />);
      });
    // make sure the event is removed
    return () => window.removeEventListener('click', isInsideEvent);
  }, []);

  function isInsideEvent(e) {
    const container = document.getElementById('invite-share');
    if (container) {
      const {
        top, left, right, bottom,
      } = container.getBoundingClientRect();
      if (!(e.clientX > left && e.clientX < right && e.clientY > top && e.clientY < bottom)) {
        toggleShareRender();
        window.removeEventListener('click', isInsideEvent);
      }
    }
  }

  function handleReferralShare(shareType) {
    const message = encodeURIComponent(`Try out TNS and receive a voucher for AED 100 on your first visit! ${invitation.referral_link}`);
    const url = `${urls[shareType.toLowerCase()]}${message}`;
    window.location.href = url;
  }

  function toggleShareRender() {
    setState((prevState) => ({ ...prevState, share: !share }));
  }

  function renderShare() {
    window.addEventListener('click', isInsideEvent);

    return (
      <div
        id="invite-share"
        className="invite-friend-share-container"
        style={{ backgroundColor: componentStyle.colorDark }}
      >
        <div className="invite-friend-share-title">INVITE WITH</div>
        <div className="invite-friend-share-title-sep my-4" />
        <div className="invite-friend-share-choices px-6 pb-4">
          <InviteShare shareLogo={iMessage} shareType="Message" handleReferral={handleReferralShare} />
          <InviteShare shareLogo={whatsapp} shareType="Whatsapp" handleReferral={handleReferralShare} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="invite-friend-container view-boundaries-top" style={{ background: componentStyle.color }}>
        <div className="invite-friend-top">
          <div className="invite-friend-top-header">
            <div role="button" tabIndex={0} onClick={() => history.push('/dashboard')}>
              <BackIcon className="invite-friend-top-back-icon" stroke={componentStyle.color} />
            </div>
          </div>
        </div>
        <div className="invite-friend-bottom">
          <TnsLogo
            className="invite-friend-location-logo"
            style={{ background: componentStyle.color, borderColor: componentStyle.color }}
            fill="#FFFFFF"
          />
          <div className="invite-friend-bottom-content p-6">
            <div className="invite-friend-bottom-title mb-4 mt-6">
              One for you, One for them
            </div>
            <div className="invite-friend-bottom-text my-2">
              Refer a friend to TNS and you&apos;ll
              receive a voucher for 100 AED when they make their first visit.
              They&apos;ll also get one too!
            </div>

            <span className="invite-friend-share-link long-press-enabled">
              {invitation.referral_link}
            </span>

            <button
              type="button"
              className="invite-friend-button mt-6"
              style={{ color: componentStyle.color }}
              onClick={toggleShareRender}
            >
              <InviteFriendIcon
                className="invite-friend-button-icon"
                stroke={componentStyle.color}
                fill={componentStyle.color}
              />
              Invite a friend
            </button>
          </div>
        </div>
        {share && renderShare()}
      </div>
    </div>
  );
}

InviteShare.propTypes = {
  shareType: PropTypes.string.isRequired,
  shareLogo: PropTypes.string.isRequired,
  handleReferral: PropTypes.func.isRequired,
};

export default withUserRetriever(InviteFriend);
