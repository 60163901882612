import { useSelector } from 'react-redux';
import { reduce } from 'lodash';
import { convertTZ } from '../assets/js/constants';
import { getDateInAPIFormat } from '../utils/date';

export const getBookingLocation = () => useSelector((state) => state.booking.location);
export const getBookingLocations = () => useSelector((state) => state.booking.locations);
export const getBookingLogId = () => useSelector((state) => state.booking.logId);
export const getBookingDate = () => useSelector((state) => state.booking.day);
export const getBookingTime = () => useSelector((state) => state.booking.time);
export const getBookingServiceType = () => useSelector((state) => state.booking.serviceType);

// This is actually categories of services
export const getBookingServices = () => useSelector((state) => state.booking.services);

export const isServicesCategoriesModalOpen = () => useSelector((state) => state.booking.isServicesCategoriesModalOpen);

export const getBookingSelectedServices = () => {
  const categories = getBookingServices() || [];
  let result = [];
  categories.forEach((category) => {
    let categorySelectedServices = [];
    if ('subCategories' in category) {
      category.subCategories.forEach((subCategory) => {
        const subCategoriesSelectedServices = subCategory.services.filter((service) => service.selected);
        if (subCategoriesSelectedServices) {
          result = result.concat(subCategoriesSelectedServices);
        }
      });
    } else {
      categorySelectedServices = category.services.filter((service) => service.selected);
      result = result.concat(categorySelectedServices);
    }
  });

  return result.flat();
};

export const getBookingAddOns = () => useSelector((state) => state.booking.addOns);
export const getBookingAddOnsFlattened = () => useSelector((state) => state.booking.addOns.map((addOnCategory) => {
  if (addOnCategory.flattened) {
    return addOnCategory;
  }

  return addOnCategory.subCategories;
}).flat());
export const getBookingServiceProviders = () => useSelector((state) => state.booking.serviceProviders);
export const getBookingServiceProvidersSnapshot = () => useSelector((state) => state.booking.serviceProvidersSnapshot);
export const isServiceMovable = (serviceId) => useSelector((state) => state.booking.movable[serviceId]);
export const getMovableServicesState = () => useSelector((state) => state.booking.movable);
export const getBookingAppointmentId = () => useSelector((state) => state.booking.appointment);
export const getBookingConfirmed = () => useSelector((state) => state.booking.confirmed);
export const getBookingAppointmentEndTime = () => useSelector((state) => state.booking.appointmentEndTime);
export const getBookingSpecialRequest = () => useSelector((state) => state.booking.specialRequest);
export const getBookingServiceRestrictions = () => useSelector((state) => state.booking.restrictions);
export const getIsBookingsTimingModalOpen = () => useSelector((state) => state.booking.isTimingModalOpen);
export const getBookingLocationOpenHours = () => getBookingLocation().open_hour.split('T');
export const getBookingLocationCloseHours = () => getBookingLocation().close_hour.split('T');
export const getBookingAvailableStartingTimes = () => useSelector((state) => state.booking.availableStartingTimes);
export const getDaysForStartingTimes = () => Object.keys(getBookingAvailableStartingTimes()).map((date) => new Date(date).toDateString());
export const getStartingTimesForDay = (date) => {
  const tzDate = convertTZ(date);
  return getBookingAvailableStartingTimes()[getDateInAPIFormat(tzDate)] || [];
};
export const getCurrentDayStartingTimes = () => {
  const currentDay = getBookingDate();
  return getStartingTimesForDay(currentDay);
};
export const getServiceProviderForServices = () => useSelector((state) => state.booking.serviceProviderForServices);
export const isSequentialBooking = () => {
  const { name } = getBookingServiceType();
  return (name === 'In-room services' || name === 'Styling Stations');
};

export const getBookingServiceProviderPreferences = () => useSelector((state) => state.booking.serviceProviderPreferences) || {};

export const bookingSoakOffHands = () => useSelector((state) => state.booking.soakOffHands);
export const bookingSoakOffFeet = () => useSelector((state) => state.booking.soakOffFeet);

export const preferencesAdaptedToApiLog = () => {
  const preferences = getBookingServiceProviderPreferences();
  const serviceType = getBookingServiceType();
  let services = getBookingServices();

  // This is temporary code while InRoom and Styling Stations dont use the new designs
  if (serviceType && serviceType.name === 'In-chair services') {
    services = services.flatMap((category) => category.subCategories);
  }

  const providersForServices = getServiceProviderForServices();
  if (services === '' || providersForServices === '') {
    return {};
  }

  return reduce(preferences, (acc, providerId, serviceId) => {
    let serviceName = '';
    for (let i = 0; i < services.length; i += 1) {
      const cat = services[i];
      const sv = cat.services.find((service) => service.service_id === parseInt(serviceId, 10));
      if (sv) {
        serviceName = sv.service_name;
        break;
      }
    }

    if (providerId !== 'any') {
      const providersForService = providersForServices[serviceId];
      if (providersForService === undefined || providersForService === null) {
        return acc;
      }
      const providerData = providersForService.find((provider) => provider.id === providerId);
      if (providerData === undefined || providerData === null) {
        throw new Error(`Provider with id ${providerId} does not perform service ${serviceId}`);
      }

      return {
        ...acc,
        [serviceId]: {
          provider_id: parseInt(providerId, 10),
          provider_name: providerData.display_name,
          service_id: parseInt(serviceId, 10),
          service_name: serviceName,
        },
      };
    }

    return {
      ...acc,
      [serviceId]: {
        provider_name: 'Any',
        service_id: parseInt(serviceId, 10),
        service_name: serviceName,
      },
    };
  }, {});
};
