import { SET_SSO_TOKEN } from '../actions/user';

const initialState = null;

const ssoToken = (state = initialState, action) => {
  switch (action.type) {
    case SET_SSO_TOKEN:
      return action.token;
    default:
      return state;
  }
};

export default ssoToken;
