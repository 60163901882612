const notificationUpdates = (state = '', action) => {
  switch (action.type) {
    case 'NOTIFICATION_UPDATES':
      return action.notificationUpdates;
    default:
      return state;
  }
};

export default notificationUpdates;
