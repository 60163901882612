const logInLogId = (state = '', action) => {
  switch (action.type) {
    case 'LOG_IN_LOG_ID':
      return action.logInLogId;
    default:
      return state;
  }
};

export default logInLogId;
