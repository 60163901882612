import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { convertTZ } from '../../assets/js/constants';
import '../../assets/css/booking/shared.scss';
import '../../assets/css/shared.scss';
import { getCurrentBookingLocationWorkingTimes } from '../../utils/booking';
import BookingDatePicker from './BookingDatePicker';
import {
  bookingAddOns,
  bookingDay,
  bookingRemoveAllProviders,
  bookingServiceProviders,
  bookingSpecialRequest,
  clearBookingStartingTimes,
  clearBookingTime,
  resetMovable,
} from '../../actions/bookings';
import { getBookingDate, getBookingLocation } from '../../selectors/booking';

function ChooseDate() {
  const dispatch = useDispatch();
  const currentBookingLocation = getBookingLocation();
  const { closeHour, closeMinutes } = getCurrentBookingLocationWorkingTimes(currentBookingLocation);

  const currDate = getBookingDate() || getDate(closeMinutes, closeHour);

  const [state, setState] = useState({ currentDate: currDate });

  const { currentDate } = state;

  useEffect(() => {
    dispatch(bookingDay(typeof currentDate === 'string' ? currentDate : currentDate.toDateString()));
  }, []);

  function clearReduxData() {
    dispatch(bookingRemoveAllProviders());
    dispatch(bookingServiceProviders(''));
    dispatch(bookingAddOns([]));
    dispatch(resetMovable());
    dispatch(bookingSpecialRequest(''));
    dispatch(clearBookingTime());
    dispatch(clearBookingStartingTimes());
  }

  function getDate() {
    const today = convertTZ(new Date());

    const verifyIfCurrentHourSurpassesCloseHour = today.getHours() + parseInt(process.env.REACT_APP_MIN_HOURS_BEFORE_BOOKING, 10) > closeHour;
    const verifyIfCloseMinutesEqualsZero = closeMinutes === 0;

    const verifyIfCurrentHourEqualsCloseHourMinusOne = today.getHours() + parseInt(process.env.REACT_APP_MIN_HOURS_BEFORE_BOOKING, 10) === closeHour - 1;
    const verifyIfCurrentHourEqualsCloseHour = today.getHours() + parseInt(process.env.REACT_APP_MIN_HOURS_BEFORE_BOOKING, 10) === closeHour;

    const verifyIfCurrentMinutesBetweenZeroAndCloseMinutes = today.getMinutes() >= 0 && today.getMinutes() <= (closeMinutes === 0 ? 50 : closeMinutes - 10);

    if ((verifyIfCurrentHourEqualsCloseHour && verifyIfCloseMinutesEqualsZero) || verifyIfCurrentHourSurpassesCloseHour) {
      today.setDate(today.getDate() + 1);
      today.setHours(0, 0, 0);
    }
    if (verifyIfCloseMinutesEqualsZero) {
      if (verifyIfCurrentHourEqualsCloseHourMinusOne && !verifyIfCurrentMinutesBetweenZeroAndCloseMinutes) {
        today.setDate(today.getDate() + 1);
        today.setHours(0, 0, 0);
      }
    } else if (verifyIfCurrentHourEqualsCloseHour && !verifyIfCurrentMinutesBetweenZeroAndCloseMinutes) {
      today.setDate(today.getDate() + 1);
      today.setHours(0, 0, 0);
    }

    return today;
  }

  function setStartDate(startDate) {
    setState((prevState) => ({ ...prevState, currentDate: startDate }));
    clearReduxData();
    dispatch(bookingDay(startDate.toDateString()));
  }

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full">
      <div className="separator" />
      <div className="flex flex-col px-5 pt-6 w-full items-center overflow-scroll scrollbar-none">
        <BookingDatePicker
          onChangeDate={(changedDate) => setStartDate(changedDate)}
          isInline
          currentDate={new Date(currentDate)}
          isReadOnly={false}
        />
        <div className="pb-16" />
      </div>
    </div>
  );
}

export default ChooseDate;
