import { HIDE_PWA_INSTALLATION_MODAL, SHOW_PWA_INSTALLATION_MODAL } from '../actions';

export const initialState = {
  showModal: false,
};

const pwaInstallation = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PWA_INSTALLATION_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case HIDE_PWA_INSTALLATION_MODAL:
      return {
        ...state,
        showModal: false,
      };
    default:
      return state;
  }
};

export default pwaInstallation;
