import { BOOKING_SOAK_OFF_HANDS, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = false;

const bookingSoakOffHands = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_SOAK_OFF_HANDS:
      return action.intent;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingSoakOffHands;
