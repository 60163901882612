/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { compose } from 'redux';

import { useSelector } from 'react-redux';
import axios from '../../interceptor';

import { convertDate } from '../../assets/js/constants';

import transactionIcon from '../../assets/icons/toasts/transaction.svg';

import '../../assets/css/dashboard/dashboardTransactions.scss';

import DashboardHeader from './DashboardHeader';

import SubTitle from '../Shared/SubTitle';
import TransactionCardTile from '../Shared/CardTiles/TransactionCardTile';
import BookNowButton from '../Shared/BookNowButton';
import Loading from '../Loading';

import { CustomToast } from '../Shared/withToast';
import withNotificationPopUp from '../Shared/withNotificationPopUp';
import withUserRetriever from '../Shared/withUserRetriever';

function DashboardTransactions() {
  const [state, setState] = useState({
    transactions: '',
    isLoading: true,
  });

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    transactions, isLoading,
  } = state;

  const style = {
    color: level.level_color,
  };

  useEffect(() => {
    axios.get('/api/v1/transactions')
      .then((res) => {
        setState((prevState) => ({ ...prevState, transactions: res.data }));
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={transactionIcon} text="Transactions couldn't be retrieved" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, []);

  function renderTransactions(paidBookings) {
    if (!paidBookings || paidBookings.length === 0) {
      return (<span className="no-items">No transactions</span>);
    }

    return (
      paidBookings.length > 0
        ? (
          paidBookings.map((transaction) => (
            <div key={`${transaction.id}`} className="flex w-full">
              <TransactionCardTile
                id={transaction.id}
                location={transaction.location_name}
                locationCategory={transaction.location_category}
                amount={transaction.amount}
                date={convertDate(new Date(transaction.date), '', true)}
                backgroundImage={transaction.location_photo}
                canAnswer={!!transaction.can_answer}
                color={level.level_color}
              />
            </div>
          ))
        ) : <span className="no-items">No transactions</span>
    );
  }

  return (
    <div className="flex flex-col h-full dashboard-grey-background-color view-boundaries-bottom">
      <div className="flex flex-col mb-1">
        <DashboardHeader color={style.color} title="Transactions" />
      </div>
      <div className="flex justify-center w-full my-4">
        <SubTitle subTitle="Receipts" style={style} route="/dashboard" />
      </div>
      <div className="flex flex-col w-full transactions-container px-5 md:w-1/2 self-center sm:px-0">
        <div>
          <div className="transactions-list">
            {
              isLoading
                ? <Loading color={style.color} text="Transactions" />
                : renderTransactions(transactions)
            }
          </div>
        </div>
      </div>
      <BookNowButton color={style.color} />
    </div>

  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(DashboardTransactions);
