import { BOOKING_RESTRICTIONS, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = '';

const bookingRestrictions = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_RESTRICTIONS:
      return action.restrictions;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingRestrictions;
