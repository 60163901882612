/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';

import clearTimeouts from '../../../utils/utils';

import DashboardHeader from '../DashboardHeader';

import '../../../assets/css/shared.scss';
import '../../../assets/css/dashboard/shared.scss';
import '../../../assets/css/dashboard/vouchers/dashboardVouchers.scss';

import SubTitle from '../../Shared/SubTitle';
import VoucherCardTile from '../../Shared/CardTiles/VoucherCardTile';
import BookNowButton from '../../Shared/BookNowButton';
import Loading from '../../Loading';
import WithFilters from '../../Shared/Filters/WithFilters';
import FiltersBox from '../../Shared/Filters/FiltersBox';
import FilterLevelIcon from '../../Shared/Filters/FilterLevelIcon';
import RadioInput from '../../Shared/RadioInput';

import vouchersIcon from '../../../assets/icons/toasts/vouchers.svg';
import loadMoreIcon from '../../../assets/icons/loadMore.svg';

import { CustomToast } from '../../Shared/withToast';
import withNotificationPopUp from '../../Shared/withNotificationPopUp';
import withUserRetriever from '../../Shared/withUserRetriever';
import Vouchers from '../../../api/Vouchers';

function DashboardVouchers() {
  const dispatch = useDispatch();

  const maxRecordsPerPage = parseInt(process.env.REACT_APP_MAX_VOUCHER_RECORDS_PER_PAGE, 10) || 10;

  const [state, setState] = useState({
    walletTitle: 'Your Wallet',
    vouchers: {
      active: '',
      expired: '',
      redeemed: '',
    },
    currentViewType: 'active',
    showFilters: false,
    offset: useSelector((reduxState) => reduxState.vouchersRecordsOffset) || maxRecordsPerPage,
    page: useSelector((reduxState) => reduxState.vouchersRecordsPage) || 1,
    loadingSpin: '',
    showLoadMore: useSelector((reduxState) => reduxState.vouchersRecordsLoadMore),
    isLoading: true,
    timeouts: [],
  });

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    walletTitle,
    offset,
    page,
    vouchers,
    currentViewType,
    showFilters,
    loadingSpin,
    showLoadMore,
    isLoading,
    timeouts,
  } = state;

  const componentStyle = {
    color: level.level_color,
  };

  const { list: getVouchers, paginate: paginateVouchers } = Vouchers();

  useEffect(() => {
    const from = window.location.search.split('=')[1];
    let currPage = page;
    let currOffset = offset;
    let currLoadMore = showLoadMore;
    if (!from) {
      currPage = 1;
      currOffset = maxRecordsPerPage;
      currLoadMore = true;
      dispatch({ type: 'VOUCHERS_RECORDS_PAGE', page: currPage });
      dispatch({ type: 'VOUCHERS_RECORDS_OFFSET', offset: maxRecordsPerPage });
      dispatch({ type: 'VOUCHERS_RECORDS_LOAD_MORE', loadMore: currLoadMore });
    }

    const voucherParams = { max_records_per_page: maxRecordsPerPage * currPage };

    getVouchers(voucherParams)
      .then((res) => {
        setState((prevState) => ({
          ...prevState,
          vouchers: {
            active: res.data.active,
            expired: res.data.expired,
            redeemed: res.data.redeemed,
          },
          page: currPage,
          offset: currOffset,
          showLoadMore: currLoadMore,
        }));
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={vouchersIcon} text="Vouchers couldn't be retrieved" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });

    return () => clearTimeouts(timeouts);
  }, []);

  function verifyShowLoadMore() {
    return (vouchers.length > 0 && vouchers[currentViewType].length >= maxRecordsPerPage && vouchers[currentViewType].length < 30) && showLoadMore;
  }

  function renderVouchersTiles(filteredVouchers) {
    if (!filteredVouchers || filteredVouchers.length === 0) {
      return (<span className="no-items">No active vouchers available</span>);
    }

    return filteredVouchers.map((voucher, index) => (
      <div key={`${voucher.customer_voucher_id}`}>
        <div className="flex flex-col">
          <VoucherCardTile
            id={voucher.id}
            img={voucher.image}
            state={voucher.status}
            locationCategory={voucher.location_category}
            textColor={voucher.text_color}
            date={new Date(voucher.end_date)}
            name={voucher.short_name}
            style={componentStyle}
          />
          {(index + 1 < filteredVouchers.length) && <div className="separator-line my-4 opacity-30" />}
          {
            (index === filteredVouchers.length - 1) && verifyShowLoadMore() && (
              <div>
                <div
                  className="flex items-center justify-center w-full mb-3"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setState((prevState) => ({ ...prevState, loadingSpin: 3 }));
                    timeouts.push(setTimeout(() => {
                      loadMore();
                      setState((prevState) => ({ ...prevState, loadingSpin: 0 }));
                    }, 1500));
                  }}
                >
                  <img
                    className="bookings-load-more-icon"
                    style={{ animation: `spin ${loadingSpin || 0}s linear infinite` }}
                    src={loadMoreIcon}
                    alt=""
                  />
                  <button
                    type="button"
                    className="bookings-load-more"
                  >
                    Load More
                  </button>
                </div>
              </div>
            )
          }
        </div>
      </div>
    ));
  }

  function loadMore() {
    const paginateParams = {
      limit: maxRecordsPerPage,
      offset,
      status: currentViewType,
    };

    paginateVouchers(paginateParams)
      .then((res) => {
        const paginatedVouchers = vouchers[currentViewType].concat(res.data);
        const newPage = page + 1;
        const newOffset = offset + maxRecordsPerPage;

        const more = res.data.length === maxRecordsPerPage;

        if (res.data.length !== 0) {
          dispatch({ type: 'VOUCHERS_RECORDS_PAGE', page: newPage });
          dispatch({ type: 'VOUCHERS_RECORDS_OFFSET', offset: newOffset });
        }
        dispatch({ type: 'VOUCHERS_RECORDS_LOAD_MORE', loadMore: more });

        setState((prevState) => ({
          ...prevState, vouchers: { ...vouchers, [currentViewType]: paginatedVouchers }, offset: newOffset, page: newPage, showLoadMore: more,
        }));
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={vouchersIcon} text="Couldn't load more vouchers!" />);
      });
  }

  const updateRenderedVouchers = (newViewType) => {
    closeFiltersBox();
    setState((prevState) => ({ ...prevState, currentViewType: newViewType }));
  };

  const closeFiltersBox = () => {
    setState((prevState) => ({ ...prevState, showFilters: false }));
  };

  const openFiltersBox = () => {
    setState((prevState) => ({ ...prevState, showFilters: true }));
  };

  const onCancelFilters = (resetFilters) => {
    setState((prevState) => ({
      ...prevState,
      showFilters: false,
      currentViewType,
    }));
    resetFilters({ viewType: currentViewType });
  };

  const initialFilters = useMemo(() => ({
    viewType: 'active',
  }));

  return (
    <WithFilters initialFilters={initialFilters}>
      {({
        activeFilters,
        changeFilter,
        resetFilters,
      }) => (
        <div className="flex flex-col h-full dashboard-grey-background-color">
          <DashboardHeader color={componentStyle.color} title={walletTitle} />
          <div className="w-full mt-4 mb-6">
            <SubTitle
              subTitle="Vouchers"
              route="/dashboard/wallet"
              style={componentStyle}
              afterRender={() => <FilterLevelIcon onClick={openFiltersBox} />}
            />
          </div>
          <div className="w-full vouchers-container pb-5 px-5 md:w-1/2 self-center sm:px-0">
            {
              isLoading ? <Loading color={componentStyle.color} text="Vouchers" /> : renderVouchersTiles(vouchers[currentViewType])
            }
          </div>
          <BookNowButton color={componentStyle.color} />
          <FiltersBox isOpen={showFilters} onCancel={() => onCancelFilters(resetFilters)} onApply={() => updateRenderedVouchers(activeFilters.viewType)}>
            <div className="voucher-filters">
              <RadioInput id="active" className="voucher-filters-filter" label="Active" onChange={() => changeFilter('viewType', 'active')} isChecked={activeFilters.viewType === 'active'} />
              <RadioInput id="completed" className="voucher-filters-filter" label="Redeemed" onChange={() => changeFilter('viewType', 'redeemed')} isChecked={activeFilters.viewType === 'redeemed'} />
              <RadioInput id="expired" className="voucher-filters-filter" label="Expired" onChange={() => changeFilter('viewType', 'expired')} isChecked={activeFilters.viewType === 'expired'} />
            </div>
          </FiltersBox>
        </div>
      )}
    </WithFilters>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(DashboardVouchers);
