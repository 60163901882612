/* eslint-disable import/no-duplicates */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import '../../assets/css/booking/chooseLocation.scss';
import '../../assets/css/shared.scss';

import { CustomToast } from '../Shared/withToast';
import Loading from '../Loading';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import {
  bookingAddOns,
  bookingDay,
  bookingLocation,
  bookingRemoveAllProviders,
  bookingServiceProviders,
  bookingServices,
  bookingServiceType,
  bookingSpecialRequest,
  bookingTime,
  bookingLogId as changeBookingLogId,
  clearBookingTime,
  clearBookingStartingTimes,
  resetMovable,
  bookingLocations,
} from '../../actions/bookings';
import { getBookingLocation, getBookingLocations, getBookingLogId } from '../../selectors/booking';
import BookingsAPI from '../../api/Booking';

function ChooseLocation({ color }) {
  const [state, setState] = useState({
    search: '',
    location: getBookingLocation().id || '',
    locations: getBookingLocations(),
    bookingLogId: getBookingLogId(),
    isLoading: false,
    currentLevel: useSelector((reduxState) => reduxState.user).customer_loyalty_level.loyalty_level_id,
  });
  const {
    location,
    locations,
    search,
    bookingLogId,
    currentLevel,
    isLoading,
  } = state;
  const dispatch = useDispatch();
  const { getClientLocations } = BookingsAPI();

  const { clientLocations, otherLocations } = locations;

  useEffect(() => {
    if (clientLocations.length === 0 && otherLocations.length === 0) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      getClientLocations(bookingLogId)
        .then((res) => {
          const clientLocationsApi = res.data.client_locations.map((loc, index) => ({
            ...loc,
            isChecked: loc.id === location || index === 0,
          }));

          const otherLocationsApi = res.data.other_locations.map((d) => ({
            ...d,
            isChecked: d.id === location,
          }));

          const locationsData = {
            clientLocations: clientLocationsApi,
            otherLocations: otherLocationsApi,
          };

          dispatch(bookingLocations(locationsData));

          if (!location && res.data.client_locations.length > 0) {
            dispatch(bookingLocation(res.data.client_locations[0]));
          }

          dispatch(changeBookingLogId(res.data.booking_log_id));
          setState((prevState) => ({
            ...prevState,
            locations: locationsData,
          }));
        })
        .catch(() => {
          toast(<CustomToast type="error" text="Couldn't retrieve locations!" />);
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });
    }
  }, []);

  function renderClientLocations(filteredClientLocations) {
    return (
      <div className="mt-5">
        <div className="locations-title" style={{ color }}>Your Locations</div>
        {filteredClientLocations.length === 0
          ? <div className="locations-not-found mt-5">No location found</div>
          : filteredClientLocations.map((l) => renderLocation(l))}
      </div>
    );
  }

  function renderLocation(loc) {
    return (
      <div role="button" tabIndex={0} key={loc.id} className="location-container" onClick={() => changeLocation(loc.id)}>
        <label className="location-name">{loc.name}</label>
        <label className={`flex custom-checkbox-container-lvl${currentLevel}`}>
          <input key={loc.id} type="checkbox" checked={!!loc.isChecked} readOnly />
          <span className={`custom-checkbox-lvl${currentLevel}`} />
        </label>
      </div>
    );
  }

  function renderOtherLocations(filteredOtherLocations) {
    return (
      <div className="mt-10">
        <div className="locations-title" style={{ color }}>Other Locations</div>
        {filteredOtherLocations.length === 0
          ? <div className="locations-not-found mt-5">No location found</div>
          : filteredOtherLocations.map((l) => renderLocation(l))}
      </div>
    );
  }

  function renderLocations() {
    const filteredClientLocations = clientLocations.filter((loc) => loc.name.toLowerCase().includes(search.toLowerCase()));
    const filteredOtherLocations = otherLocations.filter((loc) => loc.name.toLowerCase().includes(search.toLowerCase()));

    if (filteredClientLocations.length === 0 && filteredOtherLocations.length === 0) {
      return <div className="locations-not-found" style={{ color }}>No location found</div>;
    }

    return (
      <div>
        {renderClientLocations(filteredClientLocations)}
        {renderOtherLocations(filteredOtherLocations)}
      </div>
    );
  }

  function changeLocation(id) {
    let currLocation = '';
    [
      ...clientLocations,
      ...otherLocations,
    ].forEach((lo) => {
      if (lo.id === id) {
        currLocation = lo;
        lo.isChecked = true;
      } else {
        lo.isChecked = false;
      }
    });

    setState((prevState) => ({ ...prevState, otherLocations, clientLocations }));

    dispatch(bookingLocation(currLocation));
    dispatch(clearBookingTime());
    dispatch(clearBookingStartingTimes());
    dispatch(bookingTime(''));
    dispatch(bookingDay(''));
    dispatch(bookingServices([]));
    dispatch(bookingAddOns([]));
    dispatch(bookingServiceType(''));
    dispatch(bookingRemoveAllProviders());
    dispatch(bookingServiceProviders(''));
    dispatch(resetMovable());
    dispatch(bookingSpecialRequest(''));
  }

  function handleChange(e) {
    setState((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  }

  return (
    <div className="flex flex-col items-center md:w-1/2 sm:px-0 w-full">
      <div className="separator" />
      <div className="w-full py-6 overflow-scroll scrollbar-none">
        <div className="px-5 pb-2 flex w-full relative search-container">
          <SearchIcon className="search-icon absolute" alt="" />
          <input id="search" className="input-primary w-full" onChange={(e) => handleChange(e)} placeholder="Search for a location" type="text" value={search} />
        </div>
        <div className="flex flex-col w-full px-5">
          <div className="pb-3">
            {
              isLoading
                ? <Loading color={color} backgroundColor="white" text="Locations" />
                : renderLocations()
            }
          </div>
        </div>
      </div>
    </div>
  );
}

ChooseLocation.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ChooseLocation;
