import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  // color for the dynamic bar
  bar1Determinate: {
    backgroundColor: (props) => props.backgroundColor,
  },
  // color for the background of the LinearProgress component
  colorPrimary: {
    backgroundColor: (props) => props.barBackgroundColor,
  },
});

const ColoredLinearProgress = ({ color, value }) => {
  const props = {
    backgroundColor: color,
    barBackgroundColor: 'white',
  };

  const classes = useStyles(props);

  return (
    <LinearProgress
      value={value}
      variant="determinate"
      classes={{
        colorPrimary: classes.colorPrimary,
        bar1Determinate: classes.bar1Determinate,
      }}
    />
  );
};

ColoredLinearProgress.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default ColoredLinearProgress;
