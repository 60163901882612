import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import '../../assets/css/impersonation/clientList.scss';

import phoneIcon from '../../assets/icons/phone.svg';
import emailIcon from '../../assets/icons/email.svg';
import userIcon from '../../assets/icons/user.svg';

import ClientsAPI from '../../api/Client';
import { setUserInfo } from '../../actions/user';

import { CustomToast } from '../Shared/withToast';

function ClientImpersonationCard({
  client, isImpersonating, color,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { postStartImpersonating, postStopImpersonating } = ClientsAPI();

  const {
    clientId, name, email, cellPhone,
  } = client;

  function impersonateClient() {
    postStartImpersonating(clientId)
      .then((res) => {
        dispatch(setUserInfo({ ...res.data.data.attributes }));
      })
      .catch(() => {
        toast(<CustomToast type="error" text="Couldn't impersonate client!" />);
      })
      .finally(() => {
        history.push('/dashboard');
      });
  }

  const stopImpersonating = () => {
    postStopImpersonating()
      .then((res) => {
        dispatch(setUserInfo({ ...res.data.data.attributes }));
      })
      .catch(() => {
        toast(<CustomToast type="error" text="Failed to stop impersonation!" />);
      })
      .finally(() => {
        history.push('/client-impersonation');
      });
  };

  function impersonateButton() {
    if (isImpersonating) {
      stopImpersonating();
    } else {
      impersonateClient(clientId);
    }
  }

  return (
    <div className="mx-4 client-card p-1 flex" style={isImpersonating ? { backgroundColor: color, color: 'white' } : {}}>
      <div className="flex flex-col client-details">
        <div className="ml-2 flex items-end">
          <img className={`client-detail${isImpersonating ? '-inverted' : ''}-icon`} src={userIcon} alt="" />
          <span className={`client-detail${isImpersonating ? '-inverted' : ''}-text`}>{name}</span>
        </div>
        <div className="ml-2 flex items-end">
          <img className={`client-detail${isImpersonating ? '-inverted' : ''}-icon`} src={emailIcon} alt="" />
          <span className={`client-detail${isImpersonating ? '-inverted' : ''}-text`}>{email}</span>
        </div>
        <div className="ml-2 flex items-end">
          <img className={`client-detail${isImpersonating ? '-inverted' : ''}-icon`} src={phoneIcon} alt="" />
          <span className={`client-detail${isImpersonating ? '-inverted' : ''}-text`}>{cellPhone}</span>
        </div>
      </div>
      <div className="flex">
        <button type="button" onClick={impersonateButton} className="impersonate-button" style={isImpersonating ? { backgroundColor: 'white', color } : { backgroundColor: color }}>{isImpersonating ? 'Stop' : 'Start' }</button>
      </div>
    </div>
  );
}

ClientImpersonationCard.propTypes = {
  client: PropTypes.shape({
    clientId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    cellPhone: PropTypes.string.isRequired,
  }).isRequired,
  isImpersonating: PropTypes.bool,
  color: PropTypes.string.isRequired,
};

ClientImpersonationCard.defaultProps = {
  isImpersonating: false,
};

export default ClientImpersonationCard;
