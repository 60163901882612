import React, { useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';

import '../../assets/css/signIn.scss';
import '../../assets/css/registration/accountVerification.scss';

import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { debounce, get } from 'lodash';
import phoneIcon from '../../assets/icons/phone.svg';
import logoTns from '../../assets/img/logo_tns_black.svg';
import logoMarquee from '../../assets/img/logo_marquee_black.svg';
import { CustomToast } from '../Shared/withToast';

import RegistrationsAPI from '../../api/Registration';
import { cellPhoneForPayload } from '../../assets/js/constants';

function AccountVerification() {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);

  const [state, setState] = useState({
    cellPhone: '',
    cellPhoneValid: false,
    processing: false,
    cellPhoneCountry: {},
    registrationType: urlParams.get('registration_type'),
  });

  const { sendVerification } = RegistrationsAPI();

  const {
    cellPhone,
    cellPhoneValid,
    processing,
    registrationType,
    cellPhoneCountry,
  } = state;

  const updateCellPhone = debounce((numberState, number, countryData) => {
    let finalNumber = '';
    const numbers = number.split('');
    const lastNumberIntroduced = numbers[numbers.length - 1];

    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(lastNumberIntroduced)) {
      finalNumber = number;
    } else {
      numbers.pop();
      finalNumber = numbers.join('');
    }

    setState((prevState) => ({
      ...prevState,
      cellPhone: finalNumber,
      cellPhoneValid: numberState,
      cellPhoneCountry: countryData,
    }));
  }, 250);

  const sendVerificationMessage = (regType) => {
    setState((prevState) => ({ ...prevState, processing: true }));

    sendVerification(cellPhoneForPayload(cellPhoneCountry, cellPhone), regType)
      .then((response) => {
        if (response.data.redirect) {
          const toastInfoText = `It looks like you're already registered with us!
            Please enter your credentials to log in.
            If you forgot your password, please click Forgot Password`;
          toast(<CustomToast type="info" text={toastInfoText} />, { autoClose: 10000 });
          history.push('/');
        } else {
          toast(<CustomToast type="success" text="A message has been sent!" />);
          history.push('/verification-info');
        }
      })
      .catch((error) => {
        if (get(error, 'response.data.errors')) {
          toast(<CustomToast type="error" text={get(error, 'response.data.errors[0].detail')} />);
        } else {
          toast(<CustomToast type="error" text="Could not send message!" />);
        }
        setState((prevState) => ({ ...prevState, processing: false }));
      });
  };

  return (
    <div className="sign-in-page-container">
      <div className="sign-in-page">
        <div className="logos my-10">
          <div className="pr-5">
            <img className="logo-tns-sign-in" src={logoTns} alt="" />
          </div>
          <div>
            <img className="logo-marquee-sign-in" src={logoMarquee} alt="" />
          </div>
        </div>
        <div className="flex self-center md:w-1/2 sm:px-0 w-full">
          <div className="flex flex-col px-5 w-full items-center">
            <div className="tab-container">
              <div className="tab ovf-none">
                <div className="account-verification-title">
                  <span className="account-verification-title-text">Account Registration</span>
                </div>
                <span className="account-verification-info pb-6">
                  Please enter your phone number below to receive a Whatsapp
                  message, through which you can complete your registration and
                  proceed with your booking.
                </span>
                <span className="phone-number-text label pb-3">Phone number</span>
                <div className="pb-3 w-100 relative">
                  <IntlTelInput
                    fieldId="cellPhone"
                    onPhoneNumberChange={(value, number, countryData) => updateCellPhone(value, number, countryData)}
                    defaultCountry="ae"
                    containerClassName="intl-tel-input"
                    inputClassName={cellPhoneValid ? 'input-signin-cell-phone' : 'input-signin-cell-phone-error'}
                  />
                  <img className="phone-icon" src={phoneIcon} alt="" />
                </div>
                <span className="account-verification-info">
                  Please use a Whatsapp enabled phone number.
                </span>
              </div>
              <div className="flex mt-5 justify-center">
                <button
                  type="button"
                  onClick={() => sendVerificationMessage(registrationType)}
                  className={!processing ? 'button-sign-in flex justify-center md:w-1/2 sm:py-10 w-full' : 'button-sign-in-grey flex justify-center md:w-1/2 sm:py-10 w-full'}
                >
                  {processing ? 'Processing...' : 'CONTINUE'}
                </button>
              </div>
            </div>

            <div className="sign-in-page-footer">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountVerification;
