const initialState = { survey: '', seen: false };

const lastSurvey = (state = initialState, action) => {
  switch (action.type) {
    case 'LAST_SURVEY':
      return {
        ...state,
        seen: action.lastSurvey.seen,
        survey: action.lastSurvey.survey,
      };
    default:
      return state;
  }
};

export default lastSurvey;
