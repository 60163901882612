import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import withToast from '../Shared/withToast';
import { convertDate } from '../../assets/js/constants';
import Rating, { addRatingsRangeAttribute, updateStarRatings } from '../Shared/Rating';

import '../../assets/css/shared.scss';
import '../../assets/css/dashboard/dashboardSurveyDetails.scss';

import personIcon from '../../assets/icons/avatar.svg';

import { POPUP_INSIDE_EVENT_ID } from '../Shared/Popups/PopUpComponentModal';

import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg';

export const SURVEY_MODAL_TOAST_CONTAINER_ID = 'Survey__Modal';

/**
 * Modal for the survey details
 *
 * @param {object} survey survey data to render the survey details
 * @param {object} style style for the component
 * @param {boolean} inPopUp if true will display with css rules for PopUp
 * @param {function} buttonAction the action that the modal permits
 * @param {function} updateParentSurvey this function updates the parent components
 * survey state, usually will be the `setState`
 * @param {function} handleClose handles how to close the modal
 * @param {boolean} modalDisabled disables editing the modal
 * @param {boolean} isNotification enables text on top with information about survey
 * @returns ReactElement
 */
function SurveyDetailsModal({
  survey, style, inPopUp, buttonAction, updateParentSurvey, handleClose, modalDisabled, isNotification,
}) {
  const [state, setState] = useState({
    buttonText: 'Send Feedback',
    buttonDisabledText: 'Go Back!',
    tellUsMore: 'Do you have any thoughts to share?',
    currentSurvey: survey,
  });

  const {
    tellUsMore, buttonText, buttonDisabledText, currentSurvey,
  } = state;

  useEffect(() => {
    survey.details.forEach((detail) => addRatingsRangeAttribute(detail));
    setState((prevState) => ({ ...prevState, currentSurvey: survey }));
    updateParentSurvey(currentSurvey);
  }, []);

  function renderSurveyDetails(surveyDetails) {
    return surveyDetails.map((detail, index) => (
      <div className="w-full" key={detail.detail_key}>
        <div className="survey-detail-container">
          <div
            className="survey-details-provider-image my-4"
            style={{
              backgroundImage: `url(
              ${detail.service_provider_photo ? detail.service_provider_photo : personIcon}
              )`,
            }}
          />
          <div>
            <span className="survey-details-service-text" style={{ color: style.color }}>
              {detail.service_name}
            </span>
            <span className="survey-details-text">{` by ${detail.service_provider_name}`}</span>
          </div>
          <Rating
            ratingStore={detail}
            color={style.color}
            disabled={modalDisabled}
            handleSelect={handleSelectedStar}
            marginClass="my-4"
          />
          {(index + 1 < surveyDetails.length) && <div className="separator-line my-6" />}
        </div>
      </div>
    ));
  }

  function handleSelectedStar(detail, newRating) {
    if (survey && (survey.status === 'closed')) return;

    updateStarRatings(detail, newRating);
    updateParentSurvey(survey);
    setState((prevState) => ({ ...prevState, currentSurvey: survey }));
  }

  function renderTellUsMore() {
    const setComment = debounce((e) => {
      updateParentSurvey({ ...currentSurvey, comment: e.target.value });
      survey.comment = e.target.value;
      setState((prevState) => ({ ...prevState, currentSurvey: survey, comment: e.target.value }));
    }, 150);

    return (
      <div className="survey-details-tell-us-more-container mb-6">
        <div className="survey-details-section-title mb-4" style={{ color: style.color }}>
          <span>{tellUsMore}</span>
        </div>
        {
          (survey && survey.status) === 'closed'
            ? (
              <div className="text-center">
                <div className="survey-details-text">
                  {survey.comment || ''}
                </div>
              </div>
            ) : (
              <textarea
                className="request"
                onChange={(e) => setComment(e)}
                defaultValue={survey.comment || ''}
                placeholder="Share your feedback with us"
                style={{ borderColor: style.color }}
              />
            )
        }
      </div>
    );
  }

  function modalButtonAction(surveyData) {
    if (modalDisabled) {
      handleClose();
    } else if (isButtonDisabled()) {
      if (!isNotification) {
        document.querySelectorAll(`[popup-id=${POPUP_INSIDE_EVENT_ID}]`)[0].scrollIntoView();
      }
      buttonAction(surveyData);
    } else {
      buttonAction(surveyData);
    }
  }

  function isButtonDisabled() {
    return !modalDisabled && survey.details.some((detail) => detail.rating === 0);
  }

  return (
    <div popup-id={POPUP_INSIDE_EVENT_ID} className="survey-details dashboard-grey-background-color">
      {
        inPopUp && (
          <button type="button" onClick={() => handleClose()} className="survey-details-close-button">
            <CloseIcon className="survey-details-close-icon" fill={style.color} />
          </button>
        )
      }
      {/* Details */}
      <div className="survey-details-section-title mt-4" style={{ color: style.color }}>
        Give Your Feedback
      </div>
      {isNotification && (
        <div className="text-center mx-2 mt-4">
          {`Your last sale was on ${convertDate(new Date(currentSurvey.date), '', true)} on ${currentSurvey.location_name}
        , don't forget to give us feedback`}
        </div>
      )}

      {survey && renderSurveyDetails(survey.details.slice(0, -1))}
      <div className="survey-details-heavy-separator my-6" style={{ borderColor: style.color }} />
      <div className="survey-details-section-title" style={{ color: style.color }}>
        Shop Assistants Feedback
      </div>
      {/* Tell us more */}
      <div className="survey-details-shop-feedback">
        <div className="survey-details-text px-4 my-4">
          How would you rate the service you received from the front-desk and/or management staff?
        </div>
        {survey && (
          <Rating
            ratingStore={survey.details[survey.details.length - 1]}
            color={style.color}
            disabled={modalDisabled}
            handleSelect={handleSelectedStar}
            marginClass="my-4"
          />
        )}
      </div>

      <div className="survey-details-heavy-separator my-6" style={{ borderColor: style.color }} />
      {renderTellUsMore()}
      {
        inPopUp && (
          <div className="w-full flex justify-center h-16">
            <button
              type="button"
              className={`survey-details-button ${isButtonDisabled() && 'cursor-not-allowed'}`}
              style={isButtonDisabled()
                ? { backgroundColor: '#DFDFDF', borderColor: '#C6C6C6', color: '#B9B8B8' }
                : { backgroundColor: style.color, borderColor: style.color }}
              onClick={() => modalButtonAction(survey)}
            >
              {modalDisabled ? buttonDisabledText : buttonText}
            </button>
          </div>
        )
      }
    </div>
  );
}

SurveyDetailsModal.propTypes = {
  survey: PropTypes.shape({
    status: PropTypes.string,
    comment: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
  inPopUp: PropTypes.bool,
  buttonAction: PropTypes.func,
  updateParentSurvey: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  modalDisabled: PropTypes.bool,
  isNotification: PropTypes.bool,
};

SurveyDetailsModal.defaultProps = {
  inPopUp: false,
  modalDisabled: false,
  isNotification: false,
  handleClose: () => { },
  buttonAction: () => { },
};

export default withToast(SurveyDetailsModal, { containerId: SURVEY_MODAL_TOAST_CONTAINER_ID });
