import { combineReducers } from 'redux';

import addOns from './bookingAddOns';
import appointment from './bookingAppointment';
import appointmentEndTime from './bookingAppointmentEndTime';
import confirmed from './bookingConfirmed';
import day from './bookingDay';
import location from './bookingLocation';
import locations from './bookingLocations';
import logId from './bookingLogId';
import movable from './bookingMovable';
import restrictions from './bookingRestrictions';
import serviceProviders from './bookingServiceProviders';
import serviceProvidersSnapshot from './bookingServiceProvidersSnapshot';
import serviceProviderForServices from './bookingServiceProviderForServices';
import serviceProviderPreferences from './bookingServiceProviderPreferences';
import services from './bookingServices';
import serviceType from './bookingServiceType';
import specialRequest from './bookingSpecialRequest';
import time from './bookingTime';
import availableStartingTimes from './bookingAvailableStartingTimes';
import isTimingModalOpen from './bookingTimingModalOpen';
import soakOffHands from './bookingSoakOffHands';
import soakOffFeet from './bookingSoakOffFeet';

const bookingsReducer = combineReducers({
  addOns,
  appointment,
  appointmentEndTime,
  confirmed,
  day,
  location,
  locations,
  logId,
  movable,
  restrictions,
  serviceProviders,
  serviceProviderForServices,
  serviceProviderPreferences,
  serviceProvidersSnapshot,
  services,
  serviceType,
  specialRequest,
  time,
  availableStartingTimes,
  isTimingModalOpen,
  soakOffHands,
  soakOffFeet,
});

export default bookingsReducer;
