import { convertTZ } from '../assets/js/constants';

export const compareDates = (date1, date2) => {
  const date1Comparable = getDateInAPIFormat(convertTZ(new Date(date1.toString())));
  const date2Comparable = getDateInAPIFormat(convertTZ(new Date(date2.toString())));
  return date1Comparable === date2Comparable;
};

/**
 *
 * @param {Date} date the date
 * @returns the date in a standard API format like: '2022-10-10'
 */
export const getDateInAPIFormat = (date) => {
  const day = (`0${date.getDate()}`).slice(-2);
  const month = (`0${date.getMonth() + 1}`).slice(-2);

  return `${date.getFullYear()}-${month}-${day}`;
};

/**
 *
 * @param {Date} date the date
 * @returns a string like HOURS:MINUTES.
 * For example, a date like 2022-10-10 09:01 would be transformed into "09:01"
 */
export const getHoursAndMinutesFromDate = (date) => `${String(date.getHours()).padStart(2, 0)}:${String(date.getMinutes()).padStart(2, 0)}`;

// eslint-disable-next-line
Date.prototype.withoutTime = function() {
  const date = new Date(this);
  date.setHours(0, 0, 0, 0);
  return date;
};
