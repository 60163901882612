import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import PopUpComponentModal, { POPUP_INSIDE_EVENT_ID } from '../Shared/Popups/PopUpComponentModal';

import '../../assets/css/booking/booking_category_services_popup.scss';
import SubCategory from './SubCategory';

import CategoryService from './CategoryService';

/**
 * Popup modal that shows the sub categories and the top services of a given service category.
 *
 * @param {Array} subCategories the sub categories of services to show.
 * @param {Boolean} flattened if true, services will be rendered all at the same level hierarchy level (not grouped
 * by categories). This expects that the prop "services" is provided!
 * @param {Array} services The array of flattened services (only used if flattened is true)
 * @param {Function} onSelectService The callback that runs when a service is selected.
 * @param {Boolean} autoHeight If true, the popup will not be rendered with fixed height, but with a dynamic height (grows
 *
 */
const CategoryServicesModal = ({
  subCategories,
  flattened,
  services,
  autoHeight,
  onSelectService,
  onClose,
  hasTopServices,
}) => {
  /**
   * Calculate the top 3 services for the open category and adds selection information
   * to them
   */
  const topServicesWithSelectionInfo = useMemo(() => {
    if (hasTopServices) {
      const serviceCounts = subCategories.map((subCategory) => Object.entries(subCategory.counts)).flat().sort((entryLeft, entryRight) => entryRight[1] - entryLeft[1]);
      return serviceCounts.slice(0, 3).map((entry) => {
        const [serviceId] = entry;
        const serviceDataCategory = subCategories.find((subCategory) => subCategory.services.find((service) => service.service_id === parseInt(serviceId, 10)));
        const serviceData = serviceDataCategory.services.find((service) => service.service_id === parseInt(serviceId, 10));
        const isTopServiceSelected = subCategories.find((subCategory) => subCategory.services.find((sv) => sv.service_id === parseInt(serviceId, 10) && sv.selected));
        return ({
          ...serviceData,
          selected: !!isTopServiceSelected,
        });
      });
    }
    return [];
  }, [subCategories, hasTopServices]);

  const renderTopCategoryServices = () => {
    if (hasTopServices && topServicesWithSelectionInfo.length > 0) {
      return (
        <SubCategory
          title="Your Most Performed Services"
          services={topServicesWithSelectionInfo}
          onSelectService={onSelectService}
        />
      );
    }

    return null;
  };

  const renderSubCategories = () => subCategories.map((subCategory) => (
    <SubCategory
      title={subCategory.category_name}
      key={subCategory.category_name}
      services={subCategory.services}
      onSelectService={onSelectService}
    />
  ));

  const renderPopupFooter = () => (
    <div className="category_services_popup_footer">
      Note: You can only select
      &nbsp;
      <span className="category_services_popup_footer_highlight">one</span>
      &nbsp;
      service per subcategory.
    </div>
  );

  const renderServices = () => services.map((service) => (
    <CategoryService
      key={service.service_name}
      service={{ ...service, name: service.service_name, selected: service.selected }}
      onSelect={onSelectService}
    />
  ));

  return (
    <PopUpComponentModal
      handleClose={onClose}
      className={`category_services_popup ${autoHeight ? 'category_services_popup--autoHeight' : ''}`}
      footerRenderFn={renderPopupFooter}
    >
      <div className="category_services_popup_section" popup-id={POPUP_INSIDE_EVENT_ID}>
        {hasTopServices && renderTopCategoryServices()}
        {!flattened && renderSubCategories()}
        {flattened && renderServices()}
      </div>
    </PopUpComponentModal>
  );
};

CategoryServicesModal.propTypes = {
  subCategories: PropTypes.arrayOf(PropTypes.shape),
  flattened: PropTypes.bool,
  autoHeight: PropTypes.bool,
  services: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectService: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hasTopServices: PropTypes.bool,
};

CategoryServicesModal.defaultProps = {
  subCategories: [],
  flattened: false,
  autoHeight: false,
  hasTopServices: true,
  services: [],
};

export default CategoryServicesModal;
