/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';

import { useSelector } from 'react-redux';
import axios from '../../interceptor';

import { timeConvert } from '../../assets/js/constants';

import '../../assets/css/shared.scss';
import '../../assets/css/dashboard/dashboardBookingDetails.scss';

import locationTnsDefaultImg from '../../assets/img/location_tns.jpg';
import locationMarqueeDefaultImg from '../../assets/img/location_marquee.jpg';

import cancelBooking from '../../assets/icons/cancelbooking.svg';
import personIcon from '../../assets/icons/avatar.svg';

import { ReactComponent as DurationIcon } from '../../assets/icons/duration.svg';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';

import { ReactComponent as ServicesIcon } from '../../assets/icons/services.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg';

import PopUpModal from '../Shared/Popups/PopUpModal';
import PopUpComponentModal from '../Shared/Popups/PopUpComponentModal';

import DashboardHeader from './DashboardHeader';
import DashboardHeaderImage from './DashboardHeaderImage';
import BookNowButton from '../Shared/BookNowButton';

import { CustomToast } from '../Shared/withToast';
import withNotificationPopUp from '../Shared/withNotificationPopUp';
import withUserRetriever from '../Shared/withUserRetriever';

import Loading from '../Loading';

function DashboardBookingDetails() {
  const history = useHistory();

  const [state, setState] = useState({
    booking: '',
    bookingTitle: 'Booking',
    popup: false,
    isLoading: true,
  });

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    booking, bookingTitle, popup, isLoading,
  } = state;

  const { id } = useParams();

  useEffect(() => {
    let earlyErrorExit = false;

    axios.get(`/api/v1/appointments/${id}`)
      .then((res) => {
        setState((prevState) => ({ ...prevState, booking: res.data }));
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={cancelBooking} text="Booking couldn't be retrieved!" />);
        earlyErrorExit = true;
      })
      .finally(() => {
        if (earlyErrorExit) {
          history.push('/dashboard/bookings');
        } else {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        }
      });
  }, []);

  function getTileImage() {
    if (!booking.location_photo) {
      if (booking.location_category === 'tns') {
        return locationTnsDefaultImg;
      }
      return locationMarqueeDefaultImg;
    }
    return booking.location_photo;
  }

  function renderBookingDetailsServices(serviceDetails) {
    return serviceDetails.map((serviceDetail, index) => {
      const serviceDuration = serviceDetail.service_duration >= 60
        ? timeConvert(serviceDetail.service_duration) : `${serviceDetail.service_duration} min`;
      // Don't show service provider name when the booking is upcoming and no specific provider was selected (movable)
      const serviceProviderName = `by ${serviceDetail.movable && booking.is_upcoming ? booking.default_provider_name : serviceDetail.service_provider_name}`;
      // Don't show service provider photo when the booking is upcoming and no specific provider was selected (movable)
      const serviceProviderPhoto = serviceDetail.movable && booking.is_upcoming
        ? personIcon : serviceDetail.service_provider_photo || personIcon;

      return (
        <div className="px-2" key={serviceDetail.service_id}>
          <div>
            <div className="w-full flex justify-between py-2">
              <div className="booking-details-text w-4/6" style={{ color: level.level_color }}>
                {serviceDetail.service_name}
              </div>
              <div className="flex justify-end w-2/6">
                <DurationIcon className="booking-details-duration-icon" alt="" />
                <span className="booking-details-duration-text">{serviceDuration}</span>
              </div>
            </div>
            <div className="flex items-center">
              <div
                className={`${serviceDetail.service_provider_photo ? 'provider-image' : 'provider-image provider-image-fill'}`}
                style={{ backgroundImage: `url(${serviceProviderPhoto}` }}
              />
              <span className="booking-details-service-provider-name">{serviceProviderName}</span>
            </div>
            {index + 1 < serviceDetails.length ? <div className="separator-line mt-6 mb-4" /> : ''}
          </div>
        </div>
      );
    });
  }

  function renderBookingDetailsTimes(startTime, endTime) {
    return (
      <div>
        <div className="flex flex-col gap-2 p-2">
          <div className="items-center">
            <div className="justify-between data-title">
              <div className="booking-details-text">Start Time</div>
              <div className="booking-details-text" style={{ color: 'black' }}>{startTime}</div>
            </div>
          </div>
          <div className="items-center">
            <div className="justify-between data-title">
              <div className="booking-details-text">Approx. End Time</div>
              <div className="booking-details-text" style={{ color: 'black' }}>{endTime}</div>
            </div>
          </div>
          <div className="booking-details-row-separator pt-4" />
        </div>
      </div>
    );
  }

  function renderCancelBooking() {
    const payload = {
      id: booking.id,
    };

    const handleOnCloseModal = () => setState((prevState) => ({ ...prevState, popup: false }));

    const openModal = () => setState((prevState) => ({ ...prevState, popup: true }));

    const handleCancellation = () => {
      axios.post('api/v1/appointments/cancel', payload)
        .then(() => {
          setState((prevState) => ({ ...prevState, popup: false, booking: { ...booking, status: 'canceled' } }));
          toast(<CustomToast type="success" text="Successfully cancelled booking." />);
        })
        .catch(() => {
          handleOnCloseModal();
          toast(<CustomToast type="error" text="Unable to cancel booking. Contact support." />);
        });
    };

    const buttonCancelText = 'Cancel Booking';
    const popupTitleText = 'Booking cancellation';
    const popupText = 'Are you sure you want to cancel this booking session?\nYou can\'t undo this action.';
    const style = {
      color: getComputedStyle(document.documentElement).getPropertyValue('--color-cancel'),
    };

    return (
      <div className="flex items-center justify-center mb-6">
        <CancelIcon className="button-cancel-icon" alt="" />
        <div role="button" tabIndex={0} onClick={() => openModal()} className="button-cancel">
          {buttonCancelText}
        </div>
        {
          popup && (
            <PopUpComponentModal handleClose={handleOnCloseModal}>
              <PopUpModal
                handleClose={handleOnCloseModal}
                buttonAction={handleCancellation}
                buttonText={buttonCancelText}
                iconConfig={{ image: cancelBooking }}
                titleConfig={{ content: popupTitleText }}
                content={popupText}
                goBack
                style={style}
              />
            </PopUpComponentModal>
          )
        }
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <DashboardHeader color={level.level_color} title={bookingTitle} />
      {
        isLoading
          ? <Loading color={level.level_color} text="Booking Details" />
          : (
            <div className="w-full booking-details-container md:w-1/2 self-center sm:px-0 view-boundaries-bottom">
              {/* Image Header */}
              <DashboardHeaderImage
                colors={level.level_color}
                title="Summary"
                imageSource={getTileImage()}
                locationName={booking.location_name}
                startDate={booking.start_date}
                routeBack="/dashboard/bookings"
              />
              {/* Container */}
              <div>
                <div className="booking-details">
                  {/* Booking Times */}
                  <div>
                    <div className="booking-details-row-header">
                      <ClockIcon className="booking-details-clock-icon" style={{ fill: level.level_color }} alt="" />
                      <span className="booking-details-text-title" style={{ color: level.level_color }}>
                        Time
                      </span>
                    </div>
                    {renderBookingDetailsTimes(booking.start_time, booking.end_time)}
                  </div>
                  {/* Services */}
                  <div>
                    <div className="booking-details-row-header py-2">

                      <ServicesIcon className="booking-details-services-icon" alt="" style={{ fill: level.level_color }} />
                      <span className="booking-details-text-title" style={{ color: level.level_color }}>
                        {`Services and ${booking.location_provider_type}`}
                      </span>
                    </div>
                    {renderBookingDetailsServices(booking.details)}
                  </div>
                  {/* Cancel booking */}
                  <div className="w-full">
                    {(booking.is_upcoming && booking.status !== 'canceled') && renderCancelBooking()}
                  </div>
                </div>
              </div>
            </div>
          )
      }
      <BookNowButton color={level.level_color} />
    </div>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(DashboardBookingDetails);
