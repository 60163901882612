import React, { useState } from 'react';
import filter from 'lodash/filter';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import size from 'lodash/size';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';

/**
 * Generic wrapper that can be used anywhere to provide filter information anywhere where it is used.
 *
 * @param {Object} initialFilters the initial filters
 * @param {Node} children the children node
 * @param {Function} isFilterActive the callback to check if a filter is active or not. By default, checks if the filter value is not undefined.
 */
const WithFilters = ({ initialFilters, children, isFilterActive }) => {
  const [filters, setFilters] = useState(initialFilters);
  const changeFilter = (key, value) => setFilters({
    ...filters,
    [key]: value,
  });
  const resetFilters = (newFilters = initialFilters) => setFilters(newFilters);
  const activeFilterKeys = filter(keys(filters), (key) => isFilterActive(key, filters[key]));
  const activeFilters = pick(filters, activeFilterKeys);

  const numberActiveFilters = size(activeFilters);

  return (
    <>
      {children({
        activeFilters,
        numberActiveFilters,
        initialFilters,
        changeFilter,
        resetFilters,
      })}
    </>
  );
};

WithFilters.propTypes = {
  initialFilters: PropTypes.shape().isRequired,
  children: PropTypes.func.isRequired,
  isFilterActive: PropTypes.func,
};

WithFilters.defaultProps = {
  isFilterActive: (_key, val) => !isUndefined(val),
};

export default WithFilters;
