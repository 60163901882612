import { ApiGet, ApiPost } from './Api';

/**
 * Booking API calls
 */
export default () => ({

  /**
   * @returns whether the booking was deleted or not
   */
  deletePreBook: ({
    bookingId,
    bookingLogId,
    message,
  }) => ApiPost({
    endpoint: 'appointments/delete_pre_book',
    payload: {
      appointment_id: bookingId,
      booking_log_id: bookingLogId,
      message,
    },
  }),

  /**
   *
   * @returns the available locations.
   */
  getClientLocations: ({
    bookingLocationId,
  }) => ApiGet({
    endpoint: 'appointments/client_locations',
    payload: {
      booking_log_id: bookingLocationId,
    },
  }),

  /**
   *
   * @returns the available service types for a location.
   */
  getServiceTypes: ({
    locationId,
    bookingLogId,
  }) => ApiPost({
    endpoint: 'appointments/location_service_types',
    payload: {
      location_id: locationId,
      booking_log_id: bookingLogId,
    },
  }),

  getServices: ({
    bookingLocationId,
    serviceTypeId,
    bookingLogId,
  }) => ApiPost({
    endpoint: 'appointments/services',
    payload: {
      location_id: bookingLocationId,
      service_type_id: serviceTypeId,
      booking_log_id: bookingLogId,
    },
  }),

  /**
   *
   * @returns the available starting times for a booking in a given date and location for a set of services.
   */
  getAvailableStartingTimes: ({
    bookingDate,
    bookingLocationId,
    services,
    serviceTypeId,
    serviceProviderPreferences,
    soakOffHands,
    soakOffFeet,
  }) => ApiPost({
    endpoint: 'appointments/starting_times',
    payload: {
      booking_date: convertBookingDate(bookingDate),
      location_id: bookingLocationId,
      service_ids: services,
      service_type_id: serviceTypeId,
      preferences: serviceProviderPreferences,
      soak_off_hands: soakOffHands,
      soak_off_feet: soakOffFeet,
    },
    timeout: 30000, // the start times api is particularly slow, lets add a larger timeout
  }),

  /**
   * Stores a booking log
   * @param {Object} payload the log payload
   * @returns
   */
  saveLog: (payload) => ApiPost({ endpoint: 'appointments_logging/save_booking_log_process', payload }),

  /**
   * Stores an error booking log
   *
   * @param {Object} payload the payload
   * @returns
   */
  saveLogError: (payload) => ApiPost({ endpoint: 'appointments_logging/save_booking_log_error', payload }),

  /**
   *
   * @returns the available service providers for a given service, date, location, time and services
   */
  getServiceProvidersAvailability: ({
    bookingDate,
    bookingTime,
    bookingLocationId,
    services,
    soakOffHands,
    soakOffFeet,
    bookingLogId,
    serviceTypeId,
    bookingServiceProviderPreferences,
  }) => {
    const payload = {
      booking_date: convertBookingDate(bookingDate),
      booking_time: bookingTime,
      location_id: bookingLocationId,
      services,
      soak_off_hands: soakOffHands,
      soak_off_feet: soakOffFeet,
      booking_log_id: bookingLogId,
      service_type_id: serviceTypeId,
      preferences: bookingServiceProviderPreferences,
    };
    return ApiPost({ endpoint: 'appointments/service_providers_availability', payload });
  },

  getServiceProvidersForServices: ({ serviceIds, locationId, sequentialBooking }) => {
    const params = {
      service_ids: serviceIds,
      location_id: locationId,
      sequential_booking: sequentialBooking,
    };
    return ApiGet({ endpoint: 'service_providers/for_services', params });
  },

  getAddOnsAvailability: ({
    bookingLogId, bookingDate, locationId, serviceProviders,
  }) => {
    const payload = {
      booking_log_id: bookingLogId,
      booking_date: bookingDate,
      location_id: locationId,
      services: serviceProviders,
    };
    return ApiPost({ endpoint: 'appointments/add_ons_availability', payload });
  },

  /**
   *
   * @param {Number} appointmentId the appointmentId
   * @param {Number} bookingLogId the booking log id
   * @returns {Promise}
   */
  confirmBooking: ({ appointmentId, bookingLogId }) => {
    const payload = {
      appointment_id: appointmentId,
      booking_log_id: bookingLogId,
    };
    return ApiPost({ endpoint: 'appointments/confirm_booking', payload });
  },

  /**
   *
   * @param {Number} locationId the location id
   * @param {Number} appointmentId the appointment id
   * @param {Number} bookingLogId the booking log id
   * @param {Object} movableServices the services that are movable
   * @param {Object} serviceProviders the service providers of the booking
   * @returns {Promise}
   */
  preBook: ({
    locationId,
    serviceProviders,
    appointmentId,
    movableServices,
    bookingLogId,
  }) => {
    const payload = {
      location_id: locationId,
      service_service_providers: serviceProviders,
      old_appointment_id: appointmentId,
      movable_services: movableServices,
      booking_log_id: bookingLogId,
    };
    return ApiPost({ endpoint: 'appointments/pre_book', payload });
  },
});

const convertBookingDate = (date) => {
  if (typeof date === 'string') {
    return date;
  }

  return date.toDateString();
};
