import { BOOKING_SERVICE_PROVIDERS_SNAPSHOT, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = '';

const bookingServiceProvidersSnapshot = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_SERVICE_PROVIDERS_SNAPSHOT:
      return JSON.parse(JSON.stringify(action.serviceProviders));
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingServiceProvidersSnapshot;
