import React from 'react';

import '../../assets/css/shared.scss';
import '../../assets/css/dashboard/dashboardHeader.scss';

import PropTypes from 'prop-types';

import Header from '../Header';

function DashboardHeader({ color, title }) {
  return (
    <div className="header">
      <div
        className="bg-header view-boundaries-top"
        style={{
          background: `linear-gradient(to right, ${color.concat('E6')} 0%, ${color} 100%)`,
        }}
      />
      <div className="bg-header-top" />
      <Header title={title} color={color} />
    </div>
  );
}

DashboardHeader.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string,
};

DashboardHeader.defaultProps = {
  title: '',
};

export default DashboardHeader;
