/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import axios from '../../interceptor';

import DashboardHeader from './DashboardHeader';
import DashboardComponentHeader from '../Shared/DashboardComponentHeader';
import BookNowButton from '../Shared/BookNowButton';

import { convertDate, DEFAULT_LEVEL_COLOR } from '../../assets/js/constants';

import SlideTreats from '../Shared/SlideTreats';
import SlideWallet from '../Shared/SlideWallet';
import SlideBooking from '../Shared/SlideBooking';

import '../../assets/css/shared.scss';
import '../../assets/css/dashboard/cardTile.scss';
import '../../assets/css/dashboard/dashboard.scss';
import 'swiper/css';

import rightIcon from '../../assets/icons/chevron/right.svg';
import pointsImg from '../../assets/icons/transaction/celebrate.svg';
import vouchersImg from '../../assets/icons/vouchers.svg';
import stampsImg from '../../assets/icons/stamp.svg';
import giftImg from '../../assets/icons/gift_card.svg';
import packagesImg from '../../assets/icons/package.svg';
import bookingsIcon from '../../assets/icons/cancelbooking.svg';

import hairdresserImg from '../../assets/img/hairdresser.png';
import inviteFriend from '../../assets/icons/invite_friend.svg';
import SubTitle from '../Shared/SubTitle';

import withUserRetriever from '../Shared/withUserRetriever';
import withNotificationPopUp from '../Shared/withNotificationPopUp';

import { CustomToast } from '../Shared/withToast';

import Loading from '../Loading';

function Dashboard() {
  const user = useSelector((reduxState) => reduxState.user);
  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const [state, setState] = useState({
    bookings: '',
    vouchers: 0,
    stampCards: 0,
    giftCards: 0,
    packageItems: 0,
    isLoading: !level,
    isLoadingAppointments: true,
    isLoadingWallet: true,
  });

  const {
    bookings,
    vouchers,
    stampCards,
    giftCards,
    packageItems,
    isLoading,
    isLoadingAppointments,
    isLoadingWallet,
  } = state;

  const componentStyle = {
    color: level.level_color,
  };

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0 });

    axios.get('/api/v1/appointments', { params: { statuses: ['upcoming'] } })
      .then((res) => setState((prevState) => ({ ...prevState, bookings: res.data.upcoming || '' })))
      .catch(() => {
        toast(<CustomToast type="error" icon={bookingsIcon} text="Bookings couldn't be retrieved!" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoadingAppointments: false }));
      });

    axios.get('/api/v1/wallets/count')
      .then((res) => setState((prevState) => ({
        ...prevState,
        vouchers: parseInt(res.data.vouchers_count, 10),
        stampCards: parseInt(res.data.stamp_cards_count, 10),
        giftCards: parseInt(res.data.gift_certificates_count, 10),
        packageItems: parseInt(res.data.package_items_count, 10),
      })))
      .catch(() => {
        toast(<CustomToast type="error" text="Wallet items couldn't be retrieved!" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoadingWallet: false }));
      });
  }, [user]);

  function renderWallet() {
    const settingsWallet = {
      slidesPerView: 2.1,
    };

    return (
      <div>
        <Swiper {...settingsWallet}>
          <SwiperSlide>
            <SlideWallet color={componentStyle.color} walletTitle="Points" amount={user.points === null ? 0 : user.points} imgUrl={pointsImg} route="/dashboard/wallet" />
          </SwiperSlide>
          <SwiperSlide>
            <SlideWallet color={componentStyle.color} walletTitle="Vouchers" amount={vouchers} imgUrl={vouchersImg} route="/dashboard/vouchers" />
          </SwiperSlide>
          <SwiperSlide>
            <SlideWallet color={componentStyle.color} walletTitle="Packages" amount={packageItems} imgUrl={packagesImg} route="/dashboard/packages" />
          </SwiperSlide>
          <SwiperSlide>
            <SlideWallet color={componentStyle.color} walletTitle="Stamp Cards" amount={stampCards} imgUrl={stampsImg} route="/dashboard/stampcards" />
          </SwiperSlide>
          <SwiperSlide>
            <SlideWallet color={componentStyle.color} walletTitle="Gift Cards" amount={giftCards} imgUrl={giftImg} route="/dashboard/giftcards" />
          </SwiperSlide>
          <span slot="wrapper-start" style={{ marginLeft: '4%' }} />
        </Swiper>
      </div>
    );
  }

  function renderTreats() {
    return (
      <div className="section-cards-container px-4" style={{ display: 'none' }}>
        <span className="title-slide-treat mb-4 opacity-90" style={{ color: componentStyle.color }}>Exclusive Treats</span>
        <SlideTreats color={componentStyle.color} />
      </div>
    );
  }

  function renderBookings(bookingsData) {
    if (!bookingsData || bookingsData.length === 0) {
      return (<span className="no-items mb-4">No upcoming bookings</span>);
    }

    const settingsBookings = {
      slidesPerView: bookingsData.length > 1 ? 1.09 : 1,
    };

    return (
      <div>
        <Swiper {...settingsBookings}>
          {
            bookingsData.map((book) => (
              <SwiperSlide key={book.id}>
                <SlideBooking
                  id={book.id}
                  date={convertDate(new Date(book.date), '', true)}
                  hour={book.time}
                  location={book.location_name}
                  backgroundImage={book.location_photo}
                  locationCategory={book.location_category}
                  color={level.level_color}
                />
              </SwiperSlide>
            ))
          }
          <span slot="wrapper-start" style={{ marginLeft: '4%' }} />
        </Swiper>
      </div>
    );
  }

  function renderInviteFriend() {
    return (
      user.can_refer && (
        <div className="section-cards-container mb-1">
          <DashboardComponentHeader
            title="Invite a Friend"
            color={componentStyle.color}
          />
          <div className="w-full h-full px-4">
            <div
              role="button"
              tabIndex={0}
              className="section-referral"
              style={{ backgroundColor: componentStyle.color }}
              onClick={() => history.push('/invite-friend')}
            >
              <div className="section-referral-content ml-4">
                <div className="section-referral-content-title mt-4"> SPREAD THE LOVE </div>
                <div className="section-referral-content-text mb-4">
                  Enjoy 100 AED worth of services when your friend tries TNS for the first time!
                </div>
              </div>
              <div className="section-referral-image-container">
                <img className="section-img" src={inviteFriend} alt="" />
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    <div className="flex flex-col h-full">
      {
        isLoading
          ? (
            <Loading isCover color={DEFAULT_LEVEL_COLOR} />
          ) : (
            <>
              <DashboardHeader color={componentStyle.color} />
              <div className="dashboard-container">
                <div
                  className="dashboard-bg-content-top"
                  style={{
                    background: `linear-gradient(to bottom right,
                                  ${componentStyle.color.concat('E6')} 0%,
                                  ${componentStyle.color} 100%)`,
                  }}
                >
                  <div className="pt-4">
                    <SubTitle subTitle={`Welcome, ${user.name}`} style={{ color: 'white' }} />
                  </div>
                </div>
                <div className="dashboard-bg-opacity" />
                <div>
                  <div className="dashboard-body-container body-container md:w-1/2 sm:px-0 view-boundaries-bottom">
                    <div className="dashboard-card-container mt-2">
                      <div className="primary-card mb-8 card-img" style={{ backgroundImage: `url(${hairdresserImg})` }}>
                        <img className="dashboard-current-level" src={level.logo} alt="" />
                        <div className="dashboard-current-level-text">
                          <span className="dashboard-text">This is your current level on our Loyalty Program</span>
                          <div role="button" tabIndex={0} onClick={() => history.push('/loyalty-program')}>
                            <span className="dashboard-learn-more">
                              Learn more
                              <img className="dashboard-learn-more-icon" src={rightIcon} alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="section-cards-container">
                      <DashboardComponentHeader
                        title="Wallet"
                        color={componentStyle.color}
                      />
                      {
                        isLoadingWallet
                          ? <Loading color={componentStyle.color} text="Wallet" />
                          : renderWallet()
                      }
                    </div>
                    <div className="section-cards-container mb-1">
                      <DashboardComponentHeader
                        title="Upcoming Bookings"
                        color={componentStyle.color}
                        routeText="See all"
                        route="/dashboard/bookings"
                      />
                      {
                        isLoadingAppointments
                          ? <Loading color={componentStyle.color} text="Bookings" />
                          : renderBookings(bookings)
                      }
                    </div>
                    {
                      renderTreats()
                    }
                    {
                      renderInviteFriend()
                    }
                  </div>
                </div>
                <div className="footer-spacing" />
              </div>
              <BookNowButton color={componentStyle.color} />
            </>
          )
      }
    </div>
  );
}

export default compose(
  withUserRetriever,
  withNotificationPopUp,
)(Dashboard);
