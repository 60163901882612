/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import '../../assets/css/dashboard/withToast.scss';

import successIcon from '../../assets/icons/toasts/success.svg';
import infoIcon from '../../assets/icons/toasts/info.svg';
import missingIcon from '../../assets/icons/toasts/missing.svg';
import closeIcon from '../../assets/icons/cancel.svg';

/**
 * Higher-Order component to cross cut concerns
 *
 * @param {ReactNode} Component the component to be composed
 * @param {object} config specific configuration for the ToastContainer
 * @returns
 */
const withToast = (Component, config) => (props) => (
  <>
    <>
      <Component {...props} />
    </>
    <ToastContainer
      containerId={config && config.containerId}
      position="top-center"
      autoClose={5000}
      newestOnTop={false}
      hideProgressBar
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      enableMultiContainer
      limit={3}
    />
  </>
);

export function CustomToast({ type, icon, text }) {
  let styleClass = '';
  let iconSrc = icon;
  switch (type) {
    case 'error': {
      styleClass = 'Custom__Toast_error';
      iconSrc = iconSrc || missingIcon;
      break;
    }
    case 'success': {
      styleClass = 'Custom__Toast_success';
      iconSrc = iconSrc || successIcon;
      break;
    }
    case 'info': {
      styleClass = 'Custom__Toast_info';
      iconSrc = iconSrc || infoIcon;
      break;
    }
    case 'warn': {
      styleClass = 'Custom__Toast_warning';
      iconSrc = iconSrc || missingIcon;
      break;
    }
    default: {
      styleClass = 'Custom__Toast_error';
      iconSrc = iconSrc || missingIcon;
    }
  }

  return (
    <div className={`Custom__Toast_container ${styleClass}`}>
      <div className="Custom__Toast_body">
        <img className="Custom__Toast_type-icon mr-2" src={iconSrc} alt="" />
        <div className="Custom__Toast_message">
          {text}
        </div>
      </div>
      <div className="absolute right-0 p-2">
        <img className="Custom__Toast_close-icon" src={closeIcon} alt="" />
      </div>
    </div>
  );
}

CustomToast.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
};

CustomToast.defaultProps = {
  icon: '',
};

export default withToast;
