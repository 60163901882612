import React, { useState } from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/no-extraneous-dependencies */
import addDays from 'date-fns/addDays';
import { compareDates, getDateInAPIFormat } from '../../utils/date';
import '../../assets/css/booking/chooseStartingTimeDate.scss';
import { convertTZ } from '../../assets/js/constants';
import WeekDay from './WeekDay';
import { getBookingLocation } from '../../selectors/booking';
import { getCurrentLoyaltyLevel } from '../../selectors/user';
import getDate, { getCurrentBookingLocationWorkingTimes } from '../../utils/booking';

// Even though each loyalty level is limited into how many days in the future a customer can make a booking, we want them
// to be able to choose up to 3 days after that limit on this screen (business decision)
const MAX_DAYS_INTO_THE_FUTURE_AFTER_LIMIT = 3;

function ChooseStartingTimeDate({
  initialDate,
  data,
  onChange,
}) {
  const bookingLocation = getBookingLocation();
  const currentLevel = getCurrentLoyaltyLevel();
  const {
    closeHour,
    closeMinutes,
  } = getCurrentBookingLocationWorkingTimes(bookingLocation);
  const today = getDate(closeMinutes, closeHour);
  const maxDate = addDays(today, (parseInt(currentLevel.booking_date_range, 10) + MAX_DAYS_INTO_THE_FUTURE_AFTER_LIMIT) - 1);

  const maxSelectableDate = (apiDay) => {
    const date = convertTZ(new Date(apiDay));
    return date.withoutTime() <= maxDate.withoutTime();
  };

  const days = Object.keys(data).filter(maxSelectableDate);
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const monthOfCurrentDate = convertTZ(new Date(selectedDate.toString())).toLocaleString('en-US', { month: 'long', year: 'numeric' });

  const isEmptyDay = (day) => {
    const tzDate = convertTZ(day);
    return data[getDateInAPIFormat(tzDate)].length === 0;
  };

  const localOnChange = (day) => {
    setSelectedDate(day);
    onChange(convertTZ(new Date(day)).toDateString()); // to maintain compatibility with redux store format
  };

  return (
    <div className="chooseStartingTimeDate">
      <div className="chooseStartingTimeDate_month">{monthOfCurrentDate}</div>
      <div className="chooseStartingTimeDate_daysWrapper">
        {days.map((day, idx) => (
          <WeekDay
            day={convertTZ(new Date(day))}
            key={day}
            onClick={() => localOnChange(day)}
            disabled={isEmptyDay(day)}
            isActive={compareDates(day, selectedDate)}
            idx={idx}
          />
        ))}
      </div>
    </div>
  );
}

ChooseStartingTimeDate.propTypes = {
  data: PropTypes.shape().isRequired,
  initialDate: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ChooseStartingTimeDate;
