/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { useSelector } from 'react-redux';

import axios from '../../interceptor';
import DashboardHeader from './DashboardHeader';
import GenericButton from '../Shared/GenericButton';
import DashboardHeaderImage from './DashboardHeaderImage';

import '../../assets/css/shared.scss';
import '../../assets/css/dashboard/dashboardSurveyDetails.scss';

import closeIcon from '../../assets/icons/cancel.svg';

import locationTnsDefaultImg from '../../assets/img/location_tns.jpg';
import locationMarqueeDefaultImg from '../../assets/img/location_marquee.jpg';
import surveysIcon from '../../assets/icons/toasts/survey.svg';

import SurveyDetailsModal from './SurveyDetailsModal';

import { CustomToast } from '../Shared/withToast';
import withNotificationPopUp from '../Shared/withNotificationPopUp';

function DashboardSurveyDetails() {
  const user = useSelector((reduxState) => reduxState.user);
  const level = user.loyalty_level;

  const [state, setState] = useState({
    survey: '',
    transactionTitle: 'Transaction',
    buttonText: 'Submit Feedback',
    showBanner: true,
  });

  const {
    survey, transactionTitle, buttonText, showBanner,
  } = state;

  const history = useHistory();

  const componentStyle = {
    colorHeader: level.level_color,
    color: survey && survey.status === 'closed'
      ? 'var(--color-expired)'
      : level.level_color,
  };

  const { id } = useParams();

  useEffect(() => {
    axios.get(`/api/v1/surveys/${id}`)
      .then((res) => {
        setState((prevState) => ({ ...prevState, survey: res.data }));
      })
      .catch(() => {
        history.push('/dashboard/surveys');
        toast(<CustomToast type="error" icon={surveysIcon} text="Survey couldn't be retrieved" />);
      });
  }, []);

  function renderStatusBanner(status) {
    let bannerStatus = '';

    switch (status) {
      case 'pending':
        bannerStatus = {
          text: 'Please rate your experience and share your feedback below',
          cssBackground: 'var(--color-yellow)',
        };
        break;
      case 'answered':
        bannerStatus = {
          text: 'Thank you for your feedback! You can still edit this survey.',
          cssBackground: 'var(--color-green)',
        };
        break;
      case 'closed':
        bannerStatus = {
          text: 'This feedback has expired. You can no longer edit.',
          cssBackground: 'var(--color-expired)',
        };
        break;
      default:
        bannerStatus = '';
    }

    return (
      (showBanner && bannerStatus) && (
        <div
          className="relative survey-feedback-info"
          style={{ backgroundColor: bannerStatus.cssBackground }}
        >
          <div className="text-center my-4 mx-16 sm:mx-12">
            <span>{bannerStatus.text}</span>
          </div>
          <div
            role="button"
            tabIndex={0}
            className="absolute right-0 p-2"
            onClick={() => setState((prevState) => ({ ...prevState, showBanner: !showBanner }))}
          >
            <img className="survey-feedback-close-icon" src={closeIcon} alt="" />
          </div>
        </div>
      )
    );
  }

  function getTileImage(surveyData) {
    let fallbackImage = '';
    if (surveyData.location_category === 'tns') {
      fallbackImage = locationTnsDefaultImg;
    } else {
      fallbackImage = locationMarqueeDefaultImg;
    }

    return surveyData.location_photo || fallbackImage;
  }

  function handleSubmitFeedback(surveyData) {
    const payload = {
      survey_answers: surveyData.details.map((detail) => ({ id: detail.detail_id, rating: detail.rating })),
      comment: surveyData.comment,
    };

    if (payload.survey_answers.some((answer) => answer.rating === 0)) {
      toast(<CustomToast type="warn" text="To submit your feedback rate all providers." />);
    } else if (survey.status !== 'closed') {
      axios.put(`api/v1/surveys/${id}`, payload)
        .then((res) => {
          toast(<CustomToast type="success" text="Successfully updated survey." />);
          setState((prevState) => ({ ...prevState, survey: { ...survey, ...res.data } }));
        })
        .catch(() => toast(<CustomToast type="error" icon={surveysIcon} text="Unable to update survey. Contact support." />));
    }
  }

  const handleUpdateSurvey = (data) => {
    setState((prevState) => ({ ...prevState, survey: data }));
  };

  return (
    <div className="flex flex-col h-full">
      {
        survey && (
          <>
            <DashboardHeader color={componentStyle.colorHeader} title={transactionTitle} />
            <div className="w-full survey-details-container md:w-1/2 self-center sm:px-0">
              {/* Image */}
              <DashboardHeaderImage
                colors={componentStyle.colorHeader}
                title="Feedback"
                imageSource={getTileImage(survey)}
                locationName={survey.location_name}
                startDate={survey.date}
              />
              {/* Banner */}
              <div>
                {renderStatusBanner(survey.status)}
              </div>
              {/* Container */}
              <div>
                {
                  survey && (
                    <SurveyDetailsModal
                      survey={survey}
                      style={componentStyle}
                      updateParentSurvey={handleUpdateSurvey}
                    />
                  )
                }
              </div>
            </div>
          </>
        )
      }
      <GenericButton
        color={componentStyle.color}
        text={buttonText}
        disable={!!(survey && (survey.status === 'closed' || survey.details.some((detail) => detail.rating === 0)))}
        action={() => handleSubmitFeedback(survey)}
      />
    </div>
  );
}

export default compose(
  withNotificationPopUp,
)(DashboardSurveyDetails);
