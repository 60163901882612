import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PackageCardTile from '../Shared/CardTiles/PackageCardTile';
import SubTitle from '../Shared/SubTitle';
import BookNowButton from '../Shared/BookNowButton';
import DashboardHeader from './DashboardHeader';

import Loading from '../Loading';

import packagesIcon from '../../assets/icons/toasts/packages.svg';

import '../../assets/css/dashboard/dashboardPackages.scss';
import { CustomToast } from '../Shared/withToast';
import withNotificationPopUp from '../Shared/withNotificationPopUp';
import withUserRetriever from '../Shared/withUserRetriever';
import PackageItems from '../../api/PackageItems';

function DashboardPackages() {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    walletTitle: 'Your Wallet',
    packageItems: useSelector((reduxState) => reduxState.packages) || {
      active: '',
    },
    isLoading: true,
  });

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    walletTitle, packageItems, isLoading,
  } = state;

  const componentStyle = {
    color: level.level_color,
  };

  const { list: getPackageItems } = PackageItems();
  const packageParams = { max_records: process.env.REACT_APP_MAX_PACKAGE_ITEM_RECORDS || 10 };

  useEffect(() => {
    getPackageItems(packageParams)
      .then((res) => {
        dispatch({ type: 'PACKAGES', packages: res.data });
        setState((prevState) => ({
          ...prevState,
          packageItems: {
            active: res.data.active,
          },
        }));
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={packagesIcon} text="Package Items couldn't be retrieved" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, []);

  function renderPackageItems(items) {
    if (!items || items.length === 0) {
      return (<span className="no-items">No packages available</span>);
    }

    return items.map((packageItem, index) => (
      <div key={`${packageItem.id}`}>
        <PackageCardTile
          locationCategory={packageItem.location_category}
          status={packageItem.status}
          photo={packageItem.display_photo}
          remaining={packageItem.remaining_quantity}
          service={packageItem.description}
          date={packageItem.expires_on}
          style={componentStyle}
        />
        {index + 1 < items.length && <div className="separator-line my-4 opacity-30" />}
      </div>
    ));
  }

  return (
    <div className="flex flex-col h-full dashboard-grey-background-color view-boundaries-bottom">
      <DashboardHeader color={componentStyle.color} title={walletTitle} />
      <div className="mt-4 mb-6">
        <SubTitle
          subTitle="Packages"
          route="/dashboard/wallet"
          style={componentStyle}
        />
      </div>
      <div className="w-full packages-container pb-5 px-5 md:w-1/2 self-center sm:px-0">
        {
          isLoading
            ? <Loading color={componentStyle.color} text="Packages" />
            : renderPackageItems(packageItems.active)
        }
      </div>
      <BookNowButton color={componentStyle.color} />
    </div>
  );
}

export default compose(
  withNotificationPopUp,
  withUserRetriever,
)(DashboardPackages);
