import groupBy from 'lodash/groupBy';
import random from 'lodash/random';
import React from 'react';

import { ReactComponent as PrizeIcon } from '../../../assets/icons/gift_card.svg';
import { ReactComponent as TNSLogo } from '../../../assets/img/logo_tns.svg';
import { ReactComponent as MarqueeLogo } from '../../../assets/img/logo_marquee.svg';

/**
 * Render a stamp.
 *
 * @param {number} index the index of the stamp
 * @param {number} maxStamps maximum expected stamps
 * @param {Array < number, string>} stamps current stamps with index and brand
 * @param {string} awardPathAction function to redirect to the prize of the stamp
 * @param {string} baseClass the CSS style class for the stamps
 * @param {string} color color for the last stamp
 * @returns an <li> HTML fragment for a list <ul>
 */
export default function renderStamp(index, maxStamps, stamps, handleAwardAction, baseClass, color) {
  let content = '';
  const isLast = index + 1 === maxStamps;
  const isPrizeStamp = stamps.length >= maxStamps && isLast;

  if (isLast && !isPrizeStamp) {
    content = (
      <PrizeIcon
        className={`${baseClass}-last`}
        style={{ color: '#FFFFFF' }}
      />
    );
  } else if (index < stamps.length && !isPrizeStamp) {
    if (stamps[index][1] === 'tns') {
      content = (
        <TNSLogo
          className={`${baseClass}-stamped`}
          style={{ transform: `rotate(${random(-60, 60)}deg) translate(${random(-1, 1)}px)` }}
          fill="#724467"
        />
      );
    } else if (stamps[index][1] === 'marquee') {
      content = (
        <MarqueeLogo
          className={`${baseClass}-stamped`}
          style={{ transform: `rotate(${random(-60, 60)}deg) translate(${random(-1, 1)}px)` }}
          fill="#724467"
        />
      );
    }
  }

  return (
    <li
      key={index}
      className={baseClass}
      style={{
        backgroundColor: isLast ? color : 'white',
        boxShadow: isLast
          ? 'inset 0px 1px 12px 0px rgba(0, 0, 0, 0.11)'
          : 'inset 0px 1px 3px 0px rgba(0, 0, 0, 0.27)',
      }}
    >
      {content}
    </li>
  );
}

/**
 * Prepare notifications updates regarding business rules for stamp card notifications.
 * This method only modifies notifications whose type refers to stamp cards.
 *
 * @param {Array<Object>} notifications the notification updates, it is required
 * that the notifications passed have a `type` and an `award_id`
 * @returns {Array<Object>} notifications handled according to business rules.
 * Show only the first and last notifications if there are multiple:
 *  New => Stamps... => Completed -> prepare notifications to have only New => Completed
 *  New => Stamps.../LastStamp    ->                                    New => LastStamp
 *  Stamps../LastStamp            ->                                    LastStamp
 */
export function orderStampNotifications(notifications) {
  const idGroups = groupBy(notifications, (o) => o.award_id);

  const orderedStamps = Object.keys(idGroups).map((id) => {
    const group = idGroups[id];
    const groupTypes = group.map((n) => n.type);

    if (groupTypes.includes('stamp_card_completed')) {
      return group.filter((n) => n.type !== 'stamp_card_tick');
    }

    // find last occurrence of the stamp_card_tick
    const lastIndex = groupTypes.lastIndexOf('stamp_card_tick');
    // filter all stamps except the last occurrence
    return group.filter((n, i) => n.type !== 'stamp_card_tick' || i === lastIndex);
  });

  return orderedStamps.flat();
}
