export const SAFARI_BROWSER = 'safari';
export const CHROME_BROWSER = 'chrome';

export const ANDROID_OS = 'Android';
export const IOS_OS = 'iOS';
export const OTHER_OS = 'Other';

export const NATIVE_APP_ANDROID_PRODUCTION_PKG_NAME = 'com.repeto.enspa.beyouty_bonus';
export const NATIVE_APP_ANDROID_STAGING_PKG_NAME = 'com.repeto.enspa.beyouty_bonus.staging';

/**
 *
 * @returns the browser name. For now we are only interested in detecting either safari or chrome browsers.
 */
export const detectBrowser = () => {
  const { userAgent } = navigator;

  if (userAgent.match(/safari/i) && !userAgent.match(/chrome/i)) {
    return SAFARI_BROWSER;
  }

  return CHROME_BROWSER;
};

/**
 *
 * @returns the user's operating system name
 */
export const getMobileOS = () => {
  const { userAgent } = navigator;

  if (/android/i.test(userAgent)) {
    return ANDROID_OS;
  }
  if ((/iPad|iPhone|iPod/.test(userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return IOS_OS;
  }
  return OTHER_OS;
};

/**
 *
 * @returns true if the user is browsing the app through the native ios app
 */
export const isUsingNativeIOSApp = () => {
  const { userAgent } = navigator;

  // This user agent is set specifically on the iOS app, so thats why it works.
  return /beyouty-bonus-ios/i.test(userAgent);
};

/**
 *
 * @returns true if the user is browsing the app through the native android app
 */
export const isUsingNativeAndroidApp = () => {
  const { referrer } = document;

  return referrer.includes(`android-app://${NATIVE_APP_ANDROID_PRODUCTION_PKG_NAME}`)
    || referrer.includes(`android-app://${NATIVE_APP_ANDROID_STAGING_PKG_NAME}`);
};

export const addSSOTokenToSearchParams = (token) => {
  const url = new URLSearchParams();
  url.append('sso_token', token);
};
