import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

function LogoutPopup({ color, onDismissPopUp }) {
  const history = useHistory();

  const logOutFunc = () => {
    history.push('/logout');
  };

  return (
    <div className="logout-warning">
      <div className="logout-warning-container">
        <span className="logout-warning-container-title">Are you sure you want to log out?</span>
        <div className="flex justify-between w-100">
          <button type="button" onClick={logOutFunc} className="button-logout-secondary button-secondary" style={{ color, border: `.0625rem solid ${color}` }}>Yes</button>
          <button type="button" onClick={onDismissPopUp} className="button-logout-primary button-primary" style={{ background: color }}>No</button>
        </div>
      </div>
    </div>
  );
}

export default LogoutPopup;

LogoutPopup.propTypes = {
  color: PropTypes.string.isRequired,
  onDismissPopUp: PropTypes.func,
};

LogoutPopup.defaultProps = {
  onDismissPopUp: () => { },
};
