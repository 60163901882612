import { BOOKING_SERVICE_TYPE, CLEAN_BOOKING } from '../../actions/bookings';

const initialState = '';

const bookingServiceType = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_SERVICE_TYPE:
      return action.serviceType;
    case CLEAN_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingServiceType;
