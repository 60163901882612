/*
 * Utils file to store varied functions
 */

/**
 * Clears all timeouts that are explicitly set on the app
 *
 * @param {Array<Number>} timeouts array of timeout ids
 */
export default function clearTimeouts(timeouts) {
  timeouts.forEach((tid) => clearTimeout(tid));
}
