/**
 * File dedicated to all client related store actions.
 * TODO: since this represents functions with additional logic that requires using the store, we could consider using
 * sagas for this in the future.
 */

import Client from '../api/Client';
import { accountIssue, setUserInfo } from '../actions/user';

/**
 * @param {Function} dispatch the redux dispatch method
 *
 * Tries to fetch client data from the API and throws an error and updates the store accordingly if it can't.
 */
export default ({ dispatch }) => {
  const { get: getClient } = Client();
  return getClient()
    .then((result) => {
      const userInfo = result.data.data.attributes;
      if (!(userInfo.loyalty_level && userInfo.customer_loyalty_level)) {
        dispatch(accountIssue());
        // early exit will reach the catch and consequently be handled
        // by interceptor.js
        throw new Error('Something went wrong!');
      }
      dispatch(setUserInfo(userInfo));
    });
};
