const statusFilter = (state = '', action) => {
  switch (action.type) {
    case 'STATUS_FILTER':
      return action.statusFilter;
    default:
      return state;
  }
};

export default statusFilter;
