/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '../../../assets/icons/cancel.svg';

import { POPUP_INSIDE_EVENT_ID } from './PopUpComponentModal';

import '../../../assets/css/dashboard/popUpModal.scss';

/**
 * Deterministic modal, will have a specific design.
 * Image with title, followed by content and an actionable button.
 *
 * @param {string} type the type of popup modal
 * @param {function} handleClose the parent function that decides how the modal is closed
 * @param {function} buttonAction the parent function that decides what the button does
 * @param {string} buttonText text for the button
 * @param {object} iconConfig configuration to choose between image or component. Also
 * contains the style class
 * @param {object} titleConfig title configuration for modal. Contains content and style
 * class
 * @param {string} content the content of the modal
 * @param {boolean} goBack meant to be used by standalone popups that are controlled by
 * a parent component
 * @param {object} style contains global style configurations
 * @returns {ReactNode}
 */
function PopUpModal({
  handleClose, buttonAction, buttonText, iconConfig, titleConfig, content, goBack, style,
}) {
  const [state, setState] = useState({
    processing: false,
    processingText: 'Processing...',
  });

  const { processing, processingText } = state;

  // The coloring is decided using the type as a suffix for certain css rules
  return (
    <div popup-id={POPUP_INSIDE_EVENT_ID} className="shared-popup-modal" style={{ backgroundColor: style.color }}>
      <button
        type="button"
        disabled={processing}
        onClick={() => handleClose()}
        className="popup-close-button"
      >
        <CloseIcon className="popup-close-icon" />
      </button>
      <>
        {
          iconConfig.image
            ? <img className={`popup-modal-icon ${iconConfig.styleClass || ''}`} src={iconConfig.image} alt="" />
            : (
              <div className={`popup-modal-component-icon ${iconConfig.styleClass || ''}`}>
                <iconConfig.Component {...iconConfig.props} />
              </div>
            )
        }
        <div className={`popup-title ${titleConfig.styleClass} m-2`}>{titleConfig.content}</div>
      </>
      <div className="popup-body">
        <div className="popup-text mb-6">{content}</div>
      </div>
      <div className="popup-buttons">
        <button
          type="button"
          disabled={processing}
          style={{ color: style.color }}
          onClick={() => {
            buttonAction();
            setState((prevState) => ({ ...prevState, processing: true }));
          }}
          className={`${processing ? 'button-grey rounded w-full' : 'popup-action-button'} px-5`}
        >
          {processing ? processingText : buttonText}
        </button>
        {
          goBack && (
            <button
              type="button"
              disabled={processing}
              onClick={() => handleClose()}
              className="popup-back-button"
            >
              Go back
            </button>
          )
        }
      </div>
    </div>
  );
}

PopUpModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  buttonAction: PropTypes.func,
  iconConfig: PropTypes.shape({
    image: PropTypes.string,
    Component: PropTypes.elementType,
    props: PropTypes.shape({}),
    styleClass: PropTypes.string,
  }),
  titleConfig: PropTypes.shape({
    content: PropTypes.string,
    styleClass: PropTypes.string,
  }),
  content: PropTypes.string,
  buttonText: PropTypes.string,
  goBack: PropTypes.bool,
  style: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
};

PopUpModal.defaultProps = {
  buttonAction: () => {},
  iconConfig: { image: '' },
  titleConfig: { content: '' },
  content: '',
  buttonText: '',
  goBack: false,
};

export default PopUpModal;
