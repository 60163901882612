import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { compose } from 'redux';
import DashboardHeader from './DashboardHeader';
import SubTitle from '../Shared/SubTitle';
import BookNowButton from '../Shared/BookNowButton';

import '../../assets/css/dashboard/messages.scss';

import notificationsIcon from '../../assets/icons/toasts/notifications.svg';

import { ReactComponent as CelebrateIcon } from '../../assets/icons/transaction/celebrate.svg';
import { ReactComponent as ExpiryIcon } from '../../assets/icons/level_expiry.svg';
import { ReactComponent as CloseUpgradeIcon } from '../../assets/icons/close_upgrade.svg';
import { ReactComponent as SoftExpireIcon } from '../../assets/icons/soft_expire.svg';
import { ReactComponent as NewVoucherIcon } from '../../assets/icons/vouchers.svg';
import { ReactComponent as StampCardIcon } from '../../assets/icons/stamp.svg';

import axios from '../../interceptor';
import { convertDate } from '../../assets/js/constants';
import { CustomToast } from '../Shared/withToast';
import withUserRetriever from '../Shared/withUserRetriever';

import Loading from '../Loading';

function Messages() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    messagesTitle: 'Settings',
    messages: '',
    isLoading: true,
  });

  const level = useSelector((reduxState) => reduxState.user.loyalty_level);

  const {
    messagesTitle, messages, isLoading,
  } = state;

  const style = {
    color: level.level_color,
    color_unread: level.level_color.concat('1A'),
  };

  useEffect(() => {
    axios.get('/api/v1/app_notifications')
      .then((res) => {
        // clone the response data to prevent background changes
        // from affecting information shown to the user
        setState((prevState) => ({ ...prevState, messages: res.data.slice() }));
        if (res.data.length > 0) {
          const unseenMessagesIds = { id: res.data.filter((m) => !m.read).map((m) => m.id) };
          if (unseenMessagesIds.id.length > 0) {
            axios.post('/api/v1/app_notifications/mark_as_seen', unseenMessagesIds);
          }
        }
      })
      .catch(() => {
        toast(<CustomToast type="error" icon={notificationsIcon} text="Notifications couldn't be retrieved!" />);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
    // when component dismounts mark all notification updates as empty
    return () => dispatch({ type: 'NOTIFICATION_UPDATES', notificationUpdates: '' });
  }, []);

  const COMPONENTS = {
    loyalty_upgrade: CelebrateIcon,
    level_expiry: ExpiryIcon,
    close_to_upgrade: CloseUpgradeIcon,
    soft_points_expiry: SoftExpireIcon,
    new_voucher: NewVoucherIcon,
    stamp_card_tick: StampCardIcon,
  };

  function renderIcon(message) {
    const Component = COMPONENTS[message.category] || CelebrateIcon;
    return <Component className="message-icon" fill={style.color} />;
  }

  function getLinkTo(category, awardId) {
    switch (category) {
      case 'level_upgrade':
      case 'level_expiry':
      case 'close_to_upgrade':
        return '/loyalty-program';
      case 'soft_points_expiry':
        return '/dashboard/wallet';
      case 'new_voucher':
      case 'birthday':
      case 'referred':
      case 'referrer':
        return `/dashboard/vouchers/${awardId}`;
      case 'announcement': // TODO: change this to be generic in the future
        return '/invite-friend';
      case 'stamp_card_tick':
      case 'new_stamp_card':
      case 'stamp_card_completed':
        return `/dashboard/stampcards/${awardId}`;
      default:
        return '/dashboard';
    }
  }

  function renderMessages() {
    const lenMessages = messages.length;

    if (lenMessages === 0) {
      return (
        <div className="flex justify-center">
          <span className="no-messages">No Messages available</span>
        </div>
      );
    }

    const retMessages = messages.map((message, i) => (
      <React.Fragment key={message.id}>
        <div
          role="button"
          tabIndex={0}
          className="message-card"
          style={{ backgroundColor: message.read ? '' : style.color_unread }}
          onClick={() => history.push(getLinkTo(message.category, message.award_id))}
        >
          <div className="flex justify-between items-center pb-2">
            <div className="flex items-center">
              {renderIcon(message)}
              <span className="message-title" style={{ color: style.color }}>{message.title}</span>
              {!message.read && <span className="message-new-green-oval"> </span> }
            </div>
            <span className="message-date">{convertDate(new Date(message.date), '', true).slice(0, -4)}</span>
          </div>
          <span className="message-text pb-2">{message.message}</span>
        </div>
        { (lenMessages !== i + 1) && <span className="message-grey-divider my-2" /> }
      </React.Fragment>
    ));

    return retMessages;
  }

  return (
    <div className="flex flex-col h-full dashboard-grey-background-color">
      <DashboardHeader color={style.color} title={messagesTitle} />
      <div className="w-full my-4">
        <SubTitle subTitle="Notifications" route="back" style={style} />
      </div>
      <div className="pb-3 overflow-y-auto h-full scrollbar-none grey-background container-center">
        {
          isLoading
            ? (
              <Loading color={style.color} text="Notifications" />
            )
            : (
              messages && (
                <div className="flex messages-profile-container body-container md:w-1/2 self-center sm:px-0 w-full">
                  {renderMessages()}
                </div>
              )
            )
        }
      </div>
      <div className="footer-spacing" />
      <BookNowButton color={style.color} />
    </div>
  );
}

export default compose(
  withUserRetriever,
)(Messages);
