import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/shared/select-box.scss';

import { ReactComponent as Triangle } from '../../assets/icons/triangle.svg';
import { getCurrentLoyaltyLevel } from '../../selectors/user';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

const Select = ({
  collection,
  value,
  prompt,
  classNames,
  onChange,
  readOnly,
  startOpened,
  placeholder,
  openable,
  selectedValueRender,
  scrollToSelected,
  renderOption,
  withSearch,
  searchPrompt,
  filterFn,
  customOnClickFn,
  autoFocusSearch,
  noSearchResultsText,
}) => {
  const [isOpen, toggleIsOpen] = useState(startOpened || false);
  const [filteredCollection, setFilteredCollection] = useState(collection);
  const searchInput = useRef(null);

  useEffect(() => {
    if (scrollToSelected && isOpen && value) {
      const optionForSelectedValue = document.getElementById(value);
      if (optionForSelectedValue) {
        optionForSelectedValue.scrollIntoView({ block: 'center' });
      }
    }

    if (autoFocusSearch && searchInput.current) {
      searchInput.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setFilteredCollection(collection);
  }, [collection]);

  const level = getCurrentLoyaltyLevel();

  const onClickSelectHeader = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (readOnly) {
      return;
    }

    if (openable) {
      toggleIsOpen(!isOpen);
    }

    customOnClickFn(e);
  };

  const onClickItem = ({ event, item, idx }) => {
    event.stopPropagation();
    event.preventDefault();
    if (readOnly) {
      return;
    }
    setFilteredCollection(collection); // reset the filtered collection
    toggleIsOpen(false);
    onChange({ isOpen, item, idx });
  };

  const onSearch = (e) => {
    setFilteredCollection(collection.filter((item, index, array) => filterFn({
      item,
      index,
      collection: array,
      search: e.target.value,
    })));
  };

  return (
    <div id="selectBox" className={`select-box ${readOnly ? 'select-box--readOnly' : ''} ${classNames.container}`}>
      <div
        className={`select-box-header ${readOnly ? 'select-box-header--readOnly' : ''} select-box-header--level-${level.id} select-box-header--level-${level.id}--${isOpen ? 'active' : ''} ${classNames.header}`}
        onClick={onClickSelectHeader}
        role="button"
        tabIndex={0}
      >
        <div className={`select-box-header ${readOnly ? 'select-box-header--readOnly' : ''} select-box-header--label`}>
          {value && (
            <>
              <span className="light">
                {prompt && `${prompt}:`}
              </span>
              &nbsp;
              <span className="bold">{selectedValueRender(value)}</span>
            </>
          )}
          {!value && placeholder}
        </div>
        {!readOnly && <Triangle />}
      </div>
      <div className={`select-box-options select-box-options--${isOpen ? 'active' : ''} select-box-options--level-${level.id} ${classNames.options}`}>
        {isOpen && withSearch && (
          <div className={`select-box-item select-box-item--level-${level.id} select-box-search`}>
            <div className={`select-box-search-icon select-box-search-icon--level-${level.id} `}>
              <SearchIcon />
            </div>
            <input type="text" ref={searchInput} placeholder={searchPrompt} onChange={onSearch} />
          </div>
        )}
        {isOpen && filteredCollection.length > 0 && filteredCollection.map((item, idx) => (
          <div
            key={item.key}
            className={`select-box-item select-box-item--level-${level.id} select-box-item--level-${level.id}--${value === item.key ? 'active' : ''}`}
            role="button"
            id={item.key}
            tabIndex={idx}
            onClick={(e) => onClickItem({ event: e, item, idx })}
          >
            {renderOption ? renderOption({ item, isSelected: value === item.key }) : item.value}
          </div>
        ))}
        {isOpen && filteredCollection.length <= 0 && (
          <div className={`select-box-item select-box-item--level-${level.id}`}>
            {noSearchResultsText}
          </div>
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prompt: PropTypes.string,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    options: PropTypes.string,
    header: PropTypes.string,
  }),
  onChange: PropTypes.func,
  selectedValueRender: PropTypes.func,
  renderOption: PropTypes.func,
  customOnClickFn: PropTypes.func,
  filterFn: PropTypes.func,
  readOnly: PropTypes.bool,
  autoFocusSearch: PropTypes.bool,
  withSearch: PropTypes.bool,
  openable: PropTypes.bool,
  searchPrompt: PropTypes.string,
  placeholder: PropTypes.string,
  noSearchResultsText: PropTypes.string,
  startOpened: PropTypes.bool,
  scrollToSelected: PropTypes.bool,
};

Select.defaultProps = {
  classNames: {},
  prompt: null,
  onChange: () => {},
  filterFn: (_item, collection) => collection,
  customOnClickFn: () => {},
  renderOption: null,
  value: null,
  readOnly: false,
  openable: true,
  startOpened: false,
  autoFocusSearch: false,
  withSearch: false,
  searchPrompt: 'Search...',
  placeholder: 'Select a value',
  noSearchResultsText: 'No results found',
  scrollToSelected: true,
  selectedValueRender: (val) => val,
};

export default Select;
