import React from 'react';
import PropTypes from 'prop-types';

const LoadingIcon = ({ color }) => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 109.13 109.16">
    <defs>
      <style>
        {'.cls-1 { fill: url(#radial-gradient); } .cls-2 { fill:url(#linear-gradient); } .cls-3 { fill:url(#linear-gradient-2); }'}
      </style>
      <radialGradient id="radial-gradient" cx="-115.11" cy="485.3" r="1" gradientTransform="matrix(-54.55, 0, 0, 54.55, -6224.13, -26416.55)" gradientUnits="userSpaceOnUse">
        <stop offset="1" stopColor={color} />
        <stop offset="1" stopColor={color} stopOpacity="0" />
      </radialGradient>
      <linearGradient id="linear-gradient" x1="54.89" y1="-9.33" x2="53.29" y2="463.02" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor={color} stopOpacity="0" />
        <stop offset="0.25" stopColor={color} />
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="12.47" y1="69.34" x2="42.56" y2="94.68" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor={color} />
        <stop offset="0" stopColor={color} stopOpacity="0.95" />
        <stop offset="1" stopColor={color} stopOpacity="0" />
      </linearGradient>
    </defs>
    <path className="cls-1" d="M.09,54.33A54.54,54.54,0,0,1,54.64-.22V18.87A35.46,35.46,0,0,0,19.18,54.33Z" transform="translate(-0.09 0.22)" />
    <path className="cls-2" d="M54.68-.16A54.55,54.55,0,1,1,.13,54.39,54.55,54.55,0,0,1,54.68-.16Zm0,90A35.45,35.45,0,1,0,19.22,54.39,35.45,35.45,0,0,0,54.68,89.84Z" transform="translate(-0.09 0.22)" />
    <path className="cls-3" d="M33.76,104.66A54.54,54.54,0,0,1,.09,54.26H19.18A35.49,35.49,0,0,0,41.07,87" transform="translate(-0.09 0.22)" />
  </svg>
);

LoadingIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default LoadingIcon;
